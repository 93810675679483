import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { makeStyles } from '@material-ui/core/styles';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useLastLocation } from 'react-router-last-location';
import { Button } from '../../../components/ui';
import { Input } from '../../../components/ui/inputs';
import { ROUTES } from '../../../components/router/routes/routes';
import { APIforgotPassword } from '../../../api/backendRoutes';
import { errorParser } from '../../../utilities/helper';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  typography: {
    lineHeight: '23.92px',
    fontWeight: 400,
    fontSize: '16px',
    color: '#505050',
    '& span': {
      color: '#509FEF',
      marginLeft: '7px',
      cursor: 'pointer',
    },
  },
});

const ForgotPassword = () => {
  const classes = useStyles();
  const history = useHistory();
  const lastLocation = useLastLocation();

  const [formData, setFormData] = useState({ email: '', fetching: false });

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    const { email } = formData;
    const userEmail = {
      email,
      // callbackUrl: window.location.origin,
    };
    setFormData((d) => ({ ...d, fetching: true }));
    
    try {
      const resp = await APIforgotPassword(userEmail);
      if (resp.data.message === 'ok') {
        toast.success('Check Your Email for reset link');
        setFormData((d) => ({ ...d, fetching: false }));
        history.push(ROUTES.SIGN_IN.path);
      } else {
        setFormData((d) => ({ ...d, fetching: false }));
        throw resp?.data?.error || 'error resetting password';
      }
    } catch (error) {
      toast.error(errorParser(error) || 'error resetting password');
      setFormData((d) => ({ ...d, fetching: false }));
    }
    // post(API_URL.FORGOT_PASSWORD, { email }, { showMessage: true }).finally(() => {
    //   setFormData((d) => ({ ...d, fetching: false }));
    // });
  };

  const handleInputChange = ({ name, value }) => {
    setFormData((v) => ({ ...v, [name]: value }));
  };

  return (
    <>
      <div style={{ fontSize: 16 }}>Enter the email address used for this account</div>
      <form className={classes.root} style={{ marginTop: 40 }} onSubmit={handleForgotPassword}>
        <Row>
          <Col>
            <Input
              label="Email Address"
              placeholder="Enter Email Address"
              name="email"
              onChange={handleInputChange}
              defaultValue={formData.email}
              validate="required|email"
            />
          </Col>
        </Row>

        <Row>
          <Col sm="6">
            <Button
              value="Back"
              variant="contained"
              group="outline"
              disabled={!lastLocation || formData.fetching}
              onClick={() => history.push(ROUTES.SIGN_IN.path)}
              type="button"
            />
          </Col>
          <Col sm="6">
            <Button
              value="Send Link"
              variant="contained"
              loading={formData.fetching}
              type="submit"
              style={isMobile ? { flex: 1, marginLeft: 5 } : {}}
            />
          </Col>
        </Row>
      </form>
    </>
  );
};

export default ForgotPassword;
