import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { Modal, Button, Icon } from '../../../components/ui';
import { Input, InputSelect } from '../../../components/ui/inputs';
import { APIlistCountry, APIrequestSenderId } from '../../../api/backendRoutes';
import { fetchUserData } from '../../../storage/sessionStorage';
import { errorParser } from '../../../utilities/helper';

const StyledSuccess = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  text-align: center;
  .header {
    font-weight: bold;
    font-size: 22px;
  }
  .subtitle {
    font-weight: 300;
  }
  .opt {
    display: flex;
    align-items: center;
    color: var(--primary-color);
  }
`;
const CreateSenderID = ({ show, onClose, fetchId }) => {
  const user = fetchUserData();
  const [formData, setFormData] = useState({
    sender_id: '',
    country: '',
    fetching: false,
    messageSample: '',
    error: false,
    errMessage: '',
  });
  const [showSuccess, setShowSuccess] = useState(false);
  // const makeRequest = useCallback(async(body)=>{
  //   try{
  //     const resp = await APImakeRequest(body)
  //     if((resp.data.message === 'ok')){
  //       console.log(resp)
  //     }else{
  //       throw resp?.data?.error
  //     }
  //   }catch(err){
  //     console.log(err)
  //   }
  // },[])
  // const createSenderID = (e) => {
  //   e.preventDefault();

  //   const { sender_id, country } = formData;
  // };

  const handleInputChange = ({ name, value }) => {
    setFormData((v) => ({ ...v, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      name: formData.sender_id,
      countryId: formData.country,
      messageSample: formData.messageSample,
      companyId: user.companyId,
    };
    try {
      setFormData((v) => ({ ...v, fetching: true }));
      const resp = await APIrequestSenderId(body);
      if (resp.data.message === 'ok') {
        fetchId();
        setFormData((v) => ({ ...v, fetching: false }));
        setShowSuccess(true);
        onClose();
      } else {
        setFormData((v) => ({ ...v, fetching: false }));
        throw resp?.data?.error;
      }
    } catch (err) {
      setFormData((v) => ({
        ...v,
        error: true,
        fetching: false,
        errMessage: errorParser(err) || 'Unable to create sender ID',
      }));
    }
  };

  useEffect(async () => {
    try {
      const resp = await APIlistCountry();
      if (resp.data.message === 'ok') {
        const newData = resp.data.data;
        const newCountry = newData
          .map((d) => ({ label: d.name, value: d._id }))
          // .sort((a, b) => {
          //   return a.label < b.label ? -1 : 1;
          // });
        setFormData((v) => ({ ...v, countries: newCountry }));
        
        
      } else {
        throw resp?.data?.error;
      }
    } catch (err) {
      // console.log(err);
    }
  }, []);

  const SuccessModal = () => {
    return (
      <Modal show={showSuccess} closeModal={() => setShowSuccess(false)}>
        <StyledSuccess>
          <Icon icon="success" height="40px" />
          <div className="header">
            Your ID is being verified by our Admin, this process would take 24-hours
          </div>
          <div className="subtitle">
            Click the button below if you would want to continue your campaign with our default
            Sender ID.
          </div>
          <Button value="Continue" onClick={() => setShowSuccess(false)} />
        </StyledSuccess>
      </Modal>
    );
  };
  return (
    <>
      <Modal show={show} onClose={onClose} title="Setup Sender ID">
        <form style={{ marginTop: 20 }} onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Input
                placeholder="Enter ID (Sender Name) - Minimum of 8 characters"
                label="Sender ID"
                onChange={handleInputChange}
                defaultValue={formData.sender_id}
                name="sender_id"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <InputSelect
                placeholder="Select Country"
                label="Select Country"
                onChange={handleInputChange}
                defaultValue={formData.country}
                options={formData.countries}
                name="country"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                placeholder="Enter a sample of the message you would be sending with this ID"
                label="Sample message"
                name="messageSample"
                rows={10}
                onChange={handleInputChange}
                defaultValue={formData.messageSample}
                multiline
              />
            </Col>
          </Row>
          <Row>
            <Col className="center-container">
              <Button
                value="Submit"
                type="submit"
                margin={[10]}
                center
                size="lg"
                width={200}
                loading={formData.fetching}
              />
            </Col>
          </Row>
          {formData.error && (
            <div
              style={{
                fontSize: '14px',
                color: 'red',
                marginTop: '10px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {formData.errMessage}
            </div>
          )}
        </form>
      </Modal>
      <SuccessModal />
    </>
  );
};

export default CreateSenderID;
