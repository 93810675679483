import React, { useEffect, useState } from 'react';
import { Table as BTable } from 'react-bootstrap';
import styled from 'styled-components';
import { Box } from 'rebass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Filter from './Filter';
import Button from './Button';
import Loader from './Loader';
import NoTransaction from '../../assets/images/no-transaction.svg';
import Image from './Image';

const Table = ({
  header,
  data,
  className,
  variant = 'primary',
  striped = true,
  hideHeader,
  noBorder,
  showFilter,
  filter,
  filterAction,
  options,
  paginate,
  meta = { page: 0, total: 25 },
  handlePagination,
  loading,
  emptyStateMessage,
}) => {
  const [filterData, setFilterData] = useState({ ...meta, limit: 25 });
  const [next, setNext] = useState(false);
  const [prev, setPrev] = useState(true);
  useEffect(() => {
    setPrev(meta.page === 0);
    setNext(meta?.page + 1 === Math.ceil(meta?.total / meta?.limit));
  }, [meta]);

  const nextPage = () => {
    const { limit } = filterData;
    const total = filterData.total || meta.total;
    // console.log({ total });
    const totalPage = Math.ceil(total / limit);
    const nextPageNum = meta.page + 1;
    if (totalPage > nextPageNum) {
      handlePagination({ ...filterData, page: nextPageNum, limit });
    } else {
      setNext(true);
    }
  };
  const prevPage = () => {
    const previous = meta.page;
    handlePagination({ ...filterData, page: previous - 1 });
  };
  const handleFilter = (params = {}) => {
    filterAction({ ...filterData, ...params });
  };
  const clearFilter = () => {
    filterAction({ limit: 25 });
    setFilterData({ ...meta, limit: 25 });
  };

  return (
    <>
      {showFilter && (
        <Filter
          action={handleFilter}
          clearFilter={clearFilter}
          filter={{
            ...filter,
            limit: [
              { value: 25, label: '25' },
              { value: 50, label: '50' },
              { value: 100, label: '100' },
              { value: 200, label: '200' },
            ],
          }}
          options={options}
          setFilterData={setFilterData}
        />
      )}
      <div className="table-wrapper">
        <BTable hover striped={striped} responsive>
          {header && !hideHeader && (
            <thead>
              <tr>
                {header.map(({ key, label }) => (
                  <th key={key}>{label}</th>
                ))}
              </tr>
            </thead>
          )}
          <tbody className={variant}>
            {!loading &&
              data.length > 0 &&
              data.map((datum, i) => (
                <tr data-id={datum?.id} key={i}>
                  {header.map(({ key, label, render }, j) => {
                    return (
                      <td key={`${i}-${j}-${key}`}>
                        {render ? render(datum[key], datum) : datum[key]}
                      </td>
                    );
                  })}
                </tr>
              ))}
          </tbody>
        </BTable>
        {loading && (
          <div className="loader">
            <Loader color="black" size="lg" />
          </div>
        )}
        {!loading && data.length === 0 && (
          <Box p="5rem">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: 40,
              }}
            >
              <Image src={NoTransaction} alt="No Transactions" />
              <div style={{ marginTop: 30 }}>{emptyStateMessage || ''}</div>
              <div>
                <Button
                  group="primary"
                  value="Refetch"
                  onClick={() => {
                    setFilterData({ limit: 25, page: 0, total: filterData.total });
                    handlePagination();
                  }}
                />
              </div>
            </div>
          </Box>
        )}

        {!loading && paginate && Boolean(data.length) && (
          <div className="pagination-wrapper">
            <div>
              show <span className="limit">{meta.limit}</span>Entries
            </div>
            <div className="buttons">
              <Button disabled={prev} value="Prev" group="secondary" onClick={prevPage} />
              <Button disabled={next} value="Next" onClick={nextPage} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const StyledAction = styled.span.attrs(({ className }) => ({ className }))`
  cursor: pointer;
  color: #17568b;
  background: #e0eefc;
  font-size: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;

  &:not(last-child) {
    margin-right: 10px;
  }

  &.danger {
    color: #bd2a2a;
    background: #fce0e3;
  }
`;

Table.Actions = ({ actions = [] }) => {
  return (
    <div style={{ display: 'flex' }}>
      {actions.map(({ icon, type = '', name, title = '', action }, key) => (
        <StyledAction className={type} key={key} title={title} onClick={action}>
          {icon && <FontAwesomeIcon icon={icon} />}
          {name}
        </StyledAction>
      ))}
    </div>
  );
};

export default Table;
