import React, { useState, useEffect } from 'react';
import {
  InputLabel,
  Select as MSelect,
  MenuItem,
  FormHelperText,
  FormControl,
  InputAdornment,
} from '@material-ui/core';
import { validator } from '../../../../utilities/helper';
import Loader from '../../Loader';
import Icon from '../../Icon';
import InputHint from '../common/InputHint/InputHint';

const InputSelect = ({
  label,
  placeholder = '',
  disabled = false,
  variant = 'outlined',
  hint,
  value = '',
  name,
  validate,
  onChange,
  defaultValue,
  iconStart,
  options = [
    { value: 'a', label: 'a', icon: 'i' },
    { value: 'b', label: 'b', icon: 'i' },
    { value: 'c', label: 'c', icon: 'i' },
  ],
  styleContainer,
  icon,
  meta,
  idx,
  loading = false,
  ...rest
}) => {
  const [errors, setErrors] = useState([]);
  const [data, setData] = useState(value);

  useEffect(() => {
    if (onChange) {
      onChange({ name, value: data, ext: meta, id: idx });
    }
  }, []);

  const handleValidation = (e) => {
    const { name: n, value: v } = e.target;
    const errs = validate ? validator(v, validate) : [];
    setErrors(errs);
    if (onChange) {
      onChange({ name: n, value: v, ext: meta, id: idx, error: errs.join('\n') });
    }
  };

  const handleOnChange = (e) => {
    const { name: n, value: v } = e.target;
    setData(v);
    if (errors) {
      handleValidation(e);
    } else if (onChange) {
      onChange({ name: n, value: v, ext: meta, id: idx });
    }
  };

  return (
    <div className="input-wrapper" style={styleContainer}>
      <FormControl
        defaultValue={defaultValue}
        label={label}
        variant={variant}
        aria-label={label}
        aria-describedby={label}
      >
        <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
        <MSelect
          name={name}
          placeholder={placeholder ?? label}
          disabled={disabled}
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          label={label}
          value={data}
          onChange={handleOnChange}
          onBlur={handleValidation}
          error={errors.length > 0}
          // helperText={errors.length > 0 ? errors[0] : ''}
          required={!!validate}
          endAdornment={
            icon ? (
              <InputAdornment>
                <Icon icon={icon} />
              </InputAdornment>
            ) : null
          }
          startAdornment={
            loading ? (
              <InputAdornment>
                <Loader color="#16568b" size="sm" />
              </InputAdornment>
            ) : null
          }
          {...rest}
        >
          {options.map((item, index) => (
            <MenuItem value={item.value} key={index}>
              <div style={{ display: 'flex', gap: '10px', fontSize: '16px', alignItems: 'center' }}>
                {iconStart && <Icon icon={item.icon} width={50} />}
                {item.label}
              </div>
            </MenuItem>
          ))}
        </MSelect>
        {errors.length > 0 && (
          <FormHelperText style={{ color: '#f44336' }}>
            {errors.length > 0 ? errors[0] : ''}
          </FormHelperText>
        )}
      </FormControl>
      {errors.length === 0 && hint && <InputHint>{hint}</InputHint>}
    </div>
  );
};

export default InputSelect;
