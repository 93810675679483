// import Toast from 'react-hot-toast';
import { APIfetchMerchantServiceReports } from '../../api/backendRoutes';
import {
  fetchServiceReportSuccessReducer,
  fetchingServiceReducer,
  fetchingServiceReportErrorReducer,
} from '../slices/serviceReport';

export const fetchServiceReportAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchingServiceReducer());
    try {
      const resp = await APIfetchMerchantServiceReports(params);
      if (resp.data.message === 'ok') {
        dispatch(fetchServiceReportSuccessReducer(resp.data));
      } else {
        dispatch(fetchingServiceReportErrorReducer());
        throw resp?.data?.error;
      }
    } catch (error) {
      // Toast.error(error?.data?.message || 'Server error');
    }
  };
