import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Button, Card } from '../../../components/ui';
import { Input, InputSelect, PhoneInput } from '../../../components/ui/inputs';
import WalletBalance from '../../wallet/components/WalletBalance';
import SuccessModal from '../../wallet/components/PaymentSuccess';
import CheckOut from '../../wallet/components/CheckOut';
import {
  APIfetchBillers,
  APIPayBillers,
  APIvalidateBillersElectricity,
} from '../../../api/backendRoutes';
import { fetchWallet } from '../../../redux/actions/wallet';
import { errorParser } from '../../../utilities/helper';

const Electricity = ({ id }) => {
  const dispatch = useDispatch();
  const [showCheckOut, setShowCheckOut] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState({ show: false, headerText: null });
  const [mt, setMt] = useState([]);
  const [price, setPrice] = useState(0);
  const [biller, setBiller] = useState([]);
  const [selectedBiller, setSelectedBiller] = useState();
  const [loading, setLoading] = useState(false);
  const [serverMessage, setServerMessage] = useState();
  const [resolvedData, setResolvedData] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const fetchUserWallet = useCallback(() => {
    dispatch(fetchWallet());
  }, [dispatch]);

  const handleChange = async ({ name, value }) => {
    setSelectedBiller((v) => ({ ...v, [name]: value }));
    setPrice(selectedBiller?.amount || 0);
    if (name === 'customerID' && value?.length > 9 && selectedBiller.customerID !== value) {
      try {
        setLoading(true);
        const resolve = await APIvalidateBillersElectricity({
          customerID: value,
          serviceCode: selectedBiller.biller,
          meterType: selectedBiller.meterType,
          apiServiceId: biller?.filter((dt) => dt.slug === selectedBiller.biller)[0].id,
          // phoneNumber: selectedBiller.phoneNumber,
          serviceId: id,
        });
        if (resolve.data.message === 'ok') {
          setLoading(false);
          const { data } = resolve.data;
          if (data.name === undefined) {
            setResolvedData({ error: 'Unable to verify data, please try agains' });
          } else {
            setResolvedData(data);
          }
        } else {
          throw resolve?.data?.error;
        }
      } catch (error) {
        setLoading(false);
        setResolvedData({ error: 'Unable to verify data, please try again' });
      }
    }
    if (name === 'amount') {
      setPrice(value);
    }
    if (name === 'biller') {
      setPrice(0);
      setResolvedData(null);
      const meterType = biller.find((d) => d?.slug?.toLowerCase() === value)?.metadata;
      const meterTypeArray = [];
      if (meterType?.prepaid) meterTypeArray.push({ value: 'prepaid', label: 'prepaid' });
      if (meterType?.postpaid) meterTypeArray.push({ value: 'postpaid', label: 'postpaid' });
      setMt(meterTypeArray);
    }
  };
  const { balance } = useSelector((state) => {
    const newId = state.services.services.filter((s) => s.serviceName === 'PayBills')[0];
    return {
      balance: state.wallet.walletNfo.balance,
      serviceId: newId._id,
    };
  });

  useEffect(async () => {
    try {
      const resp = await APIfetchBillers({
        serviceId: id,
      });
      if (resp.data.message === 'ok') {
        const { data } = resp.data;
        setBiller(data);
        // setDisabled(false);
      } else {
        throw resp?.data?.error;
      }
    } catch (err) {
      // console.log(err);
    }
  }, []);

  const handleSend = async () => {
    const newPayload = {
      customerID: selectedBiller.customerID,
      amount: price,
      serviceId: id,
      apiServiceId: biller?.filter((dt) => dt.slug === selectedBiller.biller)[0].id,
      serviceCode: selectedBiller.biller,
      phoneNumber: selectedBiller.phoneNumber,
      meterType: selectedBiller.meterType,
      customerName: resolvedData?.name,
      customerAddress: resolvedData?.address,
      serviceType: 'electricity',
    };
    setLoading(true);
    try {
      const resp = await APIPayBillers(newPayload);

      if (resp.data.message === 'ok') {
        setLoading(false);
        const { message } = resp.data.data;
        setServerMessage(message);
        setShowSuccessModal({
          show: true,
          transactionId: resp.data.data.transactionReference,
          token: resp.data.data.token,
          receipt: true,
        });
      } else {
        setLoading(false);
        setResolvedData({ error: 'error purchasing electricity' });
      }
    } catch (err) {
      setResolvedData({
        ...resolvedData,
        error: errorParser(err),
      });
      setLoading(false);
    }
    setShowCheckOut(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (price >= 1000) {
      // setPrice(newprice);
      setShowCheckOut(true);
    } else {
      setResolvedData({ error: 'Amount must be up to 1000' });
    }
  };

  const handleCloseSuccessModal = () => {
    // fetchUserWallet();
    // setShowSuccessModal({ show: false, headerText: null });
    window.location.reload();
  };
  return (
    <div className="electicity-wrapper">
      <section>
        <Row>
          <Col md={8} style={{ marginBottom: 20 }}>
            <form onSubmit={handleSubmit}>
              <Card>
                <Row>
                  <Col>
                    <InputSelect
                      placeholder="Enter or Select Biller"
                      validate="required"
                      label="Enter or Select Biller"
                      name="biller"
                      options={biller?.map((b) => ({
                        value: b?.slug,
                        label: b?.service,
                      }))}
                      onChange={handleChange}
                      // iconStart
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputSelect
                      placeholder="Select Meter Type"
                      validate="required"
                      label="Select Meter Type"
                      options={mt}
                      name="meterType"
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      placeholder={
                        selectedBiller?.meterType === 'prepaid'
                          ? 'Enter Meter Number'
                          : 'Enter Customer Number'
                      }
                      validate="required"
                      label={
                        selectedBiller?.meterType === 'prepaid'
                          ? 'Enter Meter Number'
                          : 'Enter Customer Number'
                      }
                      name="customerID"
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {resolvedData?.name && (
                      <div
                        style={{
                          borderLeft: '2px solid #16568b',
                          padding: '10px 15px',
                          background: 'rgba(80, 159, 239, 0.03)',
                          fontSize: '12px',
                          color: '#505050',
                          marginBottom: '20px',
                        }}
                      >
                        Account Name:
                        <span
                          style={{
                            fontWeight: 'bold',
                            fontSize: '14px',
                            color: '#17568B',
                            cursor: 'pointer',
                          }}
                        >
                          {resolvedData?.name}
                        </span>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      placeholder="Enter Amount"
                      validate="required"
                      label="Enter Amount"
                      name="amount"
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <PhoneInput
                      label="Enter Phone Number"
                      placeholder="Enter Phone Number "
                      name="phoneNumber"
                      validate="required|phone_number"
                      hint=""
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row className="submit">
                  <Col>
                    <Button
                      value="Pay"
                      margin={[10]}
                      size="lg"
                      width="100%"
                      type="submit"
                      loading={loading}
                      disabled={resolvedData?.meterNumber === undefined}
                    />
                  </Col>
                  {/* <Col md={6}>
                    <Button
                      value="Schedule for Later"
                      group="outline"
                      margin={[10]}
                      width="100%"
                      size="lg"
                      onClick={() => setShowSchedule(true)}
                    />
                  </Col> */}
                </Row>
                {(resolvedData?.error || resolvedData === {}) && (
                  <div className="error">
                    {resolvedData?.error || 'Unable to resolve Customer Number'}
                  </div>
                )}
              </Card>
            </form>
          </Col>
          <Col md={4}>
            <WalletBalance price={price} />
          </Col>
        </Row>
      </section>
      <SuccessModal
        show={showSuccessModal.show}
        transactionId={showSuccessModal.transactionId}
        token={showSuccessModal.token}
        onClose={handleCloseSuccessModal}
        price={price}
        message={serverMessage}
        receipt={showSuccessModal.receipt}
        setShowSuccessModal={setShowSuccessModal}
      />
      <CheckOut
        show={showCheckOut}
        onClose={() => setShowCheckOut(false)}
        execute={handleSend}
        price={price}
        disableWallet={price > balance}
      />
    </div>
  );
};

export default Electricity;
