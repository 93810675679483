import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { makeStyles } from '@material-ui/core/styles';
import toast from 'react-hot-toast';
import { Button } from '../../../components/ui';
import { Input, PasswordInput } from '../../../components/ui/inputs';
import { ROUTES } from '../../../components/router/routes/routes';
import { APIlogin } from '../../../api/backendRoutes';
import { AppContext } from '../../../utilities/context';
import { storeUserData, storeUserDataTemp } from '../../../storage/sessionStorage';
import { errorParser } from '../../../utilities/helper';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  typography: {
    lineHeight: '23.92px',
    fontWeight: 400,
    fontSize: '16px',
    color: '#505050',
    '& span': {
      color: '#509FEF',
      marginLeft: '7px',
      cursor: 'pointer',
    },
  },
});

const SignIn = () => {
  const history = useHistory();
  const { partnerInfo } = useContext(AppContext);
  const signIn = async ({ email, password, setLoginError }) => {
    try {
      const resp = await APIlogin({ email, password, companyId: partnerInfo.id });
      if (resp.data.message === 'ok') {
        const { data } = resp.data;
        const isEmpty = Object.keys(data).length === 0;
        // setPersonalInfo({ phone_number: data.phoneNumber, email: data.email });
        const newData = {
          email,
          ...data,
          user_id: data.id,
          token_exp: Date.now() + 10800000, // 3hrs = 10800000
          token: data.token,
        };

        if (isEmpty) {
          storeUserDataTemp(newData);
          history.push(ROUTES.TFA.path);
        } else if (data.isPhoneVerified) {
          localStorage.setItem('token', data.token);
          localStorage.setItem('expiration', newData.token_exp);
          storeUserData(data);
          history.push(ROUTES.DASHBOARD.path);
        } else {
          storeUserDataTemp(newData);
          history.push(ROUTES.VERIFY_OTP.path);
        }
      } else {
        throw resp?.data?.error || 'Server Error';
      }
    } catch (error) {
      setLoginError([true, errorParser(error)]);
      toast.error(errorParser(error));
    }
  };
  const classes = useStyles();
  const [formData, setFormData] = useState({ email: '', password: '', fetching: false });
  const [loginError, setLoginError] = useState([true, null]);

  const handleSignIn = (e) => {
    e.preventDefault();

    const { email, password } = formData;

    setFormData((d) => ({ ...d, fetching: true }));
    signIn({ email, password, setLoginError }).finally((a) => {
      setFormData((d) => ({ ...d, fetching: false }));
    });
  };

  const handleInputChange = ({ name, value }) => {
    setFormData((v) => ({ ...v, [name]: value }));
    setLoginError([]);
  };
  const pageTransition = {
    in: {
      opacity: 1,
      // y: 0,
      x: 0,
    },
    out: {
      opacity: 0,
      // y: '-2%',
      x: '2%',
    },
  };
  return (
    <motion.form
      className={`${classes.root} position-relative login-container`}
      style={{ marginTop: 40 }}
      onSubmit={handleSignIn}
      initial="out"
      animate="in"
      exit="out"
      transition={{ type: 'spring', duration: 0.8 }}
      variants={pageTransition}
    >
      <Row>
        <Col md={11}>
          <Input
            label="Company Email"
            name="email"
            // errors={errors}
            type="email"
            hint="*This email should be an admin or a primary contact for your brand"
            onChange={handleInputChange}
            value={formData.email}
            validate="required|email"
          />
        </Col>
      </Row>

      <Row>
        <Col md={11}>
          <PasswordInput
            label="Password"
            name="password"
            onChange={handleInputChange}
            value={formData.password}
            validate="required"
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Button value="Login" variant="contained" loading={formData.fetching} type="submit" />
          {loginError[0] && (
            <div
              style={{
                margin: '5px 0px',
                color: 'red',
                fontSize: '12px',
              }}
            >
              {loginError[1]}
            </div>
          )}
        </Col>
      </Row>

      <div className="extra-actions">
        <div className="forgot-password">
          <p>
            Forgot Password?
            <span>
              <Link to={ROUTES.FORGOT_PASSWORD.path}>Reset</Link>
            </span>
          </p>
        </div>
        <div className="need-help">
          <p>
            Need Help?
            <span>
              <a href="https://bluintouch.com/contact-us" target='_blank' rel="noreferrer">Contact us</a>
            </span>
          </p>
        </div>
      </div>
    </motion.form>
  );
};

export default SignIn;
