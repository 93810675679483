import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Toast from 'react-hot-toast';
import parsePhoneNumber from 'libphonenumber-js';
import { PhoneInput, Input } from '../../../components/ui/inputs';
import { Icon, Button, Table, Modal } from '../../../components/ui';
import { errorParser } from '../../../utilities/helper';
import { APIAddContact, APIDeleteContact, APIUpdateContact } from '../../../api/backendRoutes';

const EditContact = ({ currentContactGroup, setShowEdit }) => {
  const [showAddNumber, setShowAddNumber] = useState(false);
  const [showEditNumber, setShowEditNumber] = useState(false);
  const [showDeleteNumber, setShowDeleteNumber] = useState(false);
  const { groupName } = currentContactGroup[0];
  const [formData, setFormData] = useState({ phoneNumber: '' });

  const handleAddContact = async (param) => {
    const phoneNumber = parsePhoneNumber(param);
    setFormData((v) => ({ ...v, isLoading: true }));
    try {
      const payload = {
        group: currentContactGroup[0].id,
        phoneNumbers: [
          {
            phoneNumber: param.slice(4, param.length),
            countryCode: phoneNumber.countryCallingCode,
          },
        ],
      };
      const resp = await APIAddContact(payload);
      if (resp.data.message === 'ok') {
        Toast.success('Contact added successfully');
        setFormData((v) => ({ ...v, phoneNumber: '', isLoading: false }));
        setShowAddNumber(false);
        window.location.reload();
      } else {
        throw resp?.data?.error;
      }
    } catch (err) {
      setFormData((v) => ({ ...v, isLoading: false }));
      Toast.error(errorParser(err) || 'Unable to add contact');
    }
  };
  const handleEditContact = async (param) => {
    setFormData((v) => ({ ...v, isLoading: true }));
    try {
      const payload = {
        phoneNumber: param,
        countryCode: formData.countryCode,
      };

      const resp = await APIUpdateContact(payload, formData.id);
      if (resp.data.message === 'ok') {
        Toast.success('Contact updated successfully');
        setFormData((v) => ({ ...v, phoneNumber: '', isLoading: false }));
        setShowEditNumber(false);
        setShowEdit(false);
      } else {
        throw resp?.data?.error;
      }
    } catch (err) {
      setFormData((v) => ({ ...v, isLoading: false }));
      Toast.error(errorParser(err) || 'Unable to update contact');
    }
  };

  const handleDeleteContact = async (param) => {
    setFormData((v) => ({ ...v, isLoading: true }));
    try {
      const resp = await APIDeleteContact(formData.id);
      if (resp.data.message === 'ok') {
        Toast.success('Contact deleted successfully');
        setFormData((v) => ({ ...v, phoneNumber: '', isLoading: false }));
        setShowEditNumber(false);
        window.location.reload();
      } else {
        throw resp?.data?.error;
      }
    } catch (err) {
      setFormData((v) => ({ ...v, isLoading: false }));
      Toast.error(errorParser(err) || 'Unable to update contact');
    }
  };
  return (
    <div className="edit-contact-wrapper">
      <div className="header">
        <h5>{groupName}</h5>

        <Button
          group="secondary round"
          value="Add Numbers"
          icon="plus-circle"
          size="md"
          width={160}
          margin={[0, 0, 0, 10]}
          onClick={() => setShowAddNumber(true)}
        />
      </div>
      <Table
        header={[
          { key: 'countryCode', label: 'Country Code' },
          { key: 'phoneNumber', label: 'Phone Number' },
          {
            key: 'menu',
            render: () => (
              <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <Icon
                  icon="pencil"
                  onClick={(e) => {
                    setShowEditNumber(true);
                    const { id } = e.target.closest('tr').dataset;
                    const { phoneNumber, countryCode } = currentContactGroup[0].contact.find(
                      (contact) => contact.id === id
                    );

                    setFormData((v) => ({
                      ...v,
                      phoneNumber,
                      countryCode,
                      id,
                    }));
                  }}
                />
                <Icon
                  icon="trash"
                  onClick={(e) => {
                    setShowDeleteNumber(true);
                    const { id } = e.target.closest('tr').dataset;

                    setFormData((v) => ({
                      ...v,
                      id,
                    }));
                  }}
                />
              </div>
            ),
          },
        ]}
        data={currentContactGroup[0].contact}
      />
      {showAddNumber && (
        <Modal
          show={showAddNumber}
          onClose={() => {
            setShowAddNumber(false);
            window.location.reload();
          }}
          title="Add Number To Group"
        >
          <Row>
            <Col>
              <PhoneInput
                label="Phone Number"
                placeholder="Phone Number e.g 090xxxxxxxx"
                name="phoneNumber"
                value={formData.phoneNumber}
                hint=""
                onChange={({ name, value }) => {
                  setFormData((v) => ({ ...v, [name]: value }));
                }}
              />
            </Col>
          </Row>
          {/* {currentContactGroup[0].category === 'Airtime' && (
            <Row>
              <Col>
                <Input name="number" type="text" label="Enter Amount" placeholder="Enter Amount" />
              </Col>
            </Row>
          )} */}
          <Row>
            <Col>
              <Button
                value="Add"
                center
                width={80}
                onClick={() => handleAddContact(formData.phoneNumber)}
                loading={formData.isLoading}
                disabled={formData?.phoneNumber?.length < 8}
              />
            </Col>
          </Row>
        </Modal>
      )}
      {showEditNumber && (
        <Modal
          show={showEditNumber}
          onClose={() => {
            setShowEditNumber(false);
            // window.location.reload();
          }}
          title="Edit Number"
        >
          <Row>
            <Col>
              <Input
                label="Country code"
                placeholder="Country code e.g 234"
                name="countryCode"
                defaultValue={formData.countryCode}
                hint=""
                onChange={({ name, value }) => {
                  setFormData((v) => ({ ...v, [name]: value }));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                label="Phone Number"
                placeholder="Phone Number e.g 090xxxxxxxx"
                name="phoneNumber"
                validate="required|phone_number"
                defaultValue={formData.phoneNumber}
                hint=""
                onChange={({ name, value }) => {
                  setFormData((v) => ({ ...v, [name]: value }));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                value="Update"
                center
                width={80}
                onClick={() => handleEditContact(formData.phoneNumber)}
                loading={formData.isLoading}
                // disabled={formData?.phoneNumber?.length !== 15}
              />
            </Col>
          </Row>
        </Modal>
      )}
      {showDeleteNumber && (
        <Modal
          show={showDeleteNumber}
          onClose={() => {
            setShowDeleteNumber(false);
            // window.location.reload();
          }}
          title="Delete Number"
        >
          <Row>
            <Col>
              <div className="mb-4">Are you sure you want to delete this number?</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                value="Cancel"
                center
                width={80}
                onClick={() => setShowDeleteNumber(false)}
                loading={formData.isLoading}
              />
            </Col>
            <Col>
              <Button
                value="Delete"
                group="secondary danger"
                center
                width={80}
                onClick={() => handleDeleteContact(formData)}
                loading={formData.isLoading}
              />
            </Col>
          </Row>
        </Modal>
      )}
    </div>
  );
};

export default EditContact;
