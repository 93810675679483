// import Toast from 'react-hot-toast';
import { APIservices, APIserviceTypes } from '../../api/backendRoutes';
import {
  fetchServicesSuccessReducer,
  fetchingServiceReducer,
  fetchingServiceErrorReducer,
  fetchingServiceTypesReducer,
  fetchServiceTypesSuccessReducer,
  fetchingServiceTypesErrorReducer,
} from '../slices/services';

export const fetchServices =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchingServiceReducer());
    try {
      const resp = await APIservices(params);
      if (resp.data.message === 'ok') {
        dispatch(fetchServicesSuccessReducer(resp.data));
      } else {
        dispatch(fetchingServiceErrorReducer());
        throw resp?.data?.error;
      }
    } catch (error) {
      dispatch(fetchingServiceErrorReducer());
      // Toast.error(error?.data?.error || 'Server error');
    }
  };

export const fetchServiceTypesAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchingServiceTypesReducer());
    try {
      const resp = await APIserviceTypes(params);
      if (resp.data.message === 'ok') {
        dispatch(fetchServiceTypesSuccessReducer(resp.data));
      } else {
        dispatch(fetchingServiceTypesErrorReducer());
        throw resp?.data?.error;
      }
    } catch (error) {
      dispatch(fetchingServiceTypesErrorReducer());
      // Toast.error(error?.data?.error || 'Server error');
    }
  };
