import React, { useCallback, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { Row, Col } from 'react-bootstrap';
import { fetchWallet } from '../../../redux/actions/wallet';
import { Image, Modal, Button } from '../../../components/ui';
import { Input } from '../../../components/ui/inputs';
import { fetchUserData } from '../../../storage/sessionStorage';
import { AppContext } from '../../../utilities/context';

const EnterAmount = ({ onChange, onSubmit, onClose, error, loading }) => {
  const user = fetchUserData();

  const handleInputChange = ({ name, value }) => {
    if (onChange) {
      onChange({ name, value });
    }
  };

  return (
    <div className="fundwallet-wrapper">
      <div className="left">
        <Image src="/logo-blue.svg" width={100} />
      </div>
      <div className="right">
        <div>
          <small>{user.email}</small>
        </div>
        <div>How much would you like to fund your wallet with?</div>
        <form onSubmit={onSubmit}>
          <Row>
            <Col>
              <Input
                placeholder="Enter Amount (₦)"
                label="Enter Amount (₦)"
                type="number"
                onChange={handleInputChange}
                name="amount"
                required
                validate="required"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                value="Fund"
                type="submit"
                margin={[10, 10, 20, 10]}
                center
                size="lg"
                width="100%"
                loading={loading}
              />
            </Col>
          </Row>
          <div style={{ textAlign: 'center', padding: '2rem 0' }}>
            <span style={{ cursor: 'pointer' }} onClick={onClose}>
              Cancel
            </span>
          </div>
          {error[0] && <div className="error">{error[1]}</div>}
        </form>
      </div>
    </div>
  );
};

const FundWallet = ({ show, onClose, getCards }) => {
  const { setCheckoutData } = useContext(AppContext);
  const [formData, setFormData] = useState({ sender_id: '', country: '', fetching: false });
  const [error, setError] = useState([false, '']);
  const dispatch = useDispatch();
  const fetchUserWallet = useCallback(() => {
    dispatch(fetchWallet());
  }, [dispatch]);

  const refetch = () => {
    fetchUserWallet();
    onClose();
    if (getCards) {
      getCards();
    }
  };
  const handleCheckout = async (e) => {
    e.preventDefault();

    if (formData.amount >= 1000) {
      const { merchantOrganizationId, email } = fetchUserData();
      const payload = {
        walletId: 'master',
        amount: parseInt(formData.amount, 10),
        currency: 'NGN',
        metadata: {
          merchant_wallet_reference: merchantOrganizationId,
          inApp: true,
          email,
        },
      };

      const url = `${process.env.REACT_APP_CHECKOUT_URL}/checkout/initialize`;

      const Authorization = process.env.REACT_APP_PAYMENT_API_KEY;
      const response = await axios.post(url, payload, {
        headers: { Authorization, 'X-Api-Key': process.env.REACT_APP_X_API_KEY },
      });
      const { status, data } = response;

      if (status === 200) {
        setCheckoutData({
          url: data?.data?.authorization_url,
          refetch,
        });
        onClose();
      } else {
        setError([true, data.message]);
      }
    } else {
      setError([true, 'Please enter an amount greater than or equal to 1000']);
    }
  };
  const handleInputChange = ({ name, value }) => {
    setError([false, '']);
    setFormData((v) => ({ ...v, [name]: value }));
  };

  return (
    <>
      <Modal show={show} onClose={onClose} showHeader={false} nopadding size="md">
        <EnterAmount
          onChange={handleInputChange}
          onSubmit={handleCheckout}
          onClose={onClose}
          error={error}
          loading={formData.fetching}
        />
      </Modal>
    </>
  );
};

export default FundWallet;
