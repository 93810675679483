import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
// import { ROUTES } from '../../../components/router/routes/routes';
import { Icon, Modal } from '../../../components/ui';
import RecieptModal from '../../Receipt/ReceiptModal';
// import { Switch } from '../../../components/ui/inputs';

const SuccessModal = ({
  show,
  onClose,
  price,
  headerText,
  subtitle,
  transactionId,
  message,
  token,
  respData,
  receipt = false,
  setShowSuccessModal,
}) => {
  const [showRct, setShowRct] = useState(false);
  const [id, setId] = useState(null);
  return (
    <>
      <Modal show={show} onClose={onClose}>
        <div className="success__group">
          <Icon icon="pending" height="40px" />
          <div className="header">{headerText || 'Transaction Processing'}</div>
          {price && (
            <div className="subtitle">
              The Payment of
              <NumberFormat
                displayType="text"
                thousandSeparator
                prefix="₦"
                fixedDecimalScale
                decimalScale={2}
                value={price}
                style={{ margin: '0px 5px' }}
              />
              was deducted successfully from your wallet
            </div>
          )}
          {token && <div className="header">Token: {token}</div>}
          {subtitle && <div className="subtitle">{subtitle}</div>}
          {message && <div className="message">{message}</div>}

          {receipt && (
            <div
              className="opt"
              style={{
                gap: '10px',
                cursor: 'pointer',
              }}
              onClick={() => {
                setId(transactionId);
                // window.open(`${ROUTES.RECIEPT.path}/${transactionId}`, '_blank');
                setShowRct(true);
                setShowSuccessModal((s) => ({ ...s, show: false }));
              }}
            >
              <Icon icon="receipt" width={15} /> <div>View Receipt</div>
            </div>
          )}
        </div>
      </Modal>
      {showRct && (
        <RecieptModal
          id={id}
          show={showRct}
          token={token}
          respData={respData}
          onClose={() => {
            onClose();
            setShowRct(false);
          }}
        />
      )}
    </>
  );
};

export default SuccessModal;
