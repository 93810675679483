import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  senderId: [],
  fetching: false,
  error: false,
};

export const senderIdSlice = createSlice({
  name: 'senderId',
  initialState,
  reducers: {
    fetchSenderIdReducer: (state, { payload }) => {
      state.senderId = payload;
      state.error = false;
      state.fetching = false;
    },
    fetchingSenderIdReducer: (state) => {
      state.senderId = [];
      state.error = false;
      state.fetching = true;
    },
    errorReducer: (state) => {
      state.dataPlans = [];
      state.error = true;
      state.fetching = false;
    },
  },
});

export const { fetchSenderIdReducer, fetchingSenderIdReducer, errorReducer } =
  senderIdSlice.actions;

export default senderIdSlice.reducer;
