import React, { useState, useEffect } from 'react';
import { TimePicker } from 'antd';
import styled from 'styled-components';
import InputHint from '../common/InputHint/InputHint';

const StyledTime = styled.div`
  .timePicker {
    width: 100% !important;
    border: 1px solid #bdbdbd;
    margin-bottom: 10px;
    border-radius: 8px;
    padding: 12px 10px;
  }
`;
const TimeInput = ({
  label,
  placeholder = '',
  disabled = false,
  type = 'time',
  variant = 'outlined',
  hint,
  value,
  name,
  defaultValue,
  multiline = false,
  rows = 3,
  onChange,
  validate,
  icon,
  iconPosition,
  ...rest
}) => {
  const [errors] = useState([]);
  const [data, setData] = useState(defaultValue);

  useEffect(() => {
    if (onChange) {
      onChange({ name, value: data });
    }
  }, [data]);

  const handleOnChange = (time) => {
    setData(time);
    onChange({ name, value: time?.toISOString(true) });
    // const { name: n, value: v } = e.target;
    // setData(v);
    // if (errors) {
    //   handleValidation(e);
    // } else if (onChange) {
    //   onChange({ name: n, value: v });
    // }
  };

  return (
    <StyledTime>
      <TimePicker
        use12Hours
        className="timePicker"
        format="HH:mm a"
        // label="Select Start Time"
        onChange={handleOnChange}
        placeholder={placeholder}
      />
      {errors.length === 0 && hint && <InputHint>{hint}</InputHint>}
    </StyledTime>
  );
};

export default TimeInput;
