import Toast from 'react-hot-toast';
import {
  APIcontactGroups,
  APIcontactsGrouped,
  APIcontactsUngrouped,
  APIcreateContactGroup,
  APIuploadContacts,
} from '../../api/backendRoutes';
import { errorParser } from '../../utilities/helper';
import {
  fetchContactsSuccessReducer,
  fetchingContactsReducer,
  fetchingContactsErrorReducer,
  fetchingContactGroupsReducer,
  fetchContactGroupsSuccessReducer,
  fetchingContactGroupsErrorReducer,
  fetchGroupedContactsSuccessReducer,
  fetchingGroupedContactReducer,
  fetchingGroupedContactsErrorReducer,
  uploadContactSuccessReducer,
  uploadingContactReducer,
  uploadContactErrorReducer,
  createContactGroupSuccessReducer,
  creatingContactGroupSuccessReducer,
  createContactGroupErrorReducer,
} from '../slices/contacts';

export const fetchUngroupedContactsAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchingContactsReducer());
    try {
      const resp = await APIcontactsUngrouped(params);
      if (resp.data.message === 'ok') {
        dispatch(fetchContactsSuccessReducer(resp.data));
      } else {
        dispatch(fetchingContactsErrorReducer());
        throw resp?.data?.error;
      }
    } catch (error) {
      // Toast.error(error?.data?.error || 'Server error');
    }
  };

export const fetchContactGroupsAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchingContactGroupsReducer());
    try {
      const resp = await APIcontactGroups(params);
      if (resp.data.message === 'ok') {
        dispatch(fetchContactGroupsSuccessReducer(resp.data));
      } else {
        dispatch(fetchingContactGroupsErrorReducer());
        throw resp?.data?.error;
      }
    } catch (error) {
      // Toast.error(error?.data?.error || 'Server error');
    }
  };

export const fetchGroupedContactsAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchingGroupedContactReducer());
    try {
      const resp = await APIcontactsGrouped(params);
      if (resp.data.message === 'ok') {
        const cleanedData = [];

        resp.data.data.forEach((d) => {
          cleanedData.push({
            contactGroup: d?.contactGroup,
            contacts: d?.contacts,
            count: d.count,
            createdAt: d?.contactGroup?.createdAt,
            contactGroupId: d?.contactGroup?._id,
            contactGroupName: d?.contactGroup?.name,
            category: d?.contactGroup.category || {},
          });
        });

        dispatch(fetchGroupedContactsSuccessReducer({ ...resp.data, data: cleanedData }));
      } else {
        dispatch(fetchingGroupedContactsErrorReducer());
        throw resp?.data?.error;
      }
    } catch (error) {
      dispatch(fetchingGroupedContactsErrorReducer());
      // Toast.error(error?.data?.error || 'Server error');
    }
  };

export const uploadContactsAction =
  (formData = {}) =>
  async (dispatch) => {
    dispatch(uploadingContactReducer());
    try {
      const resp = await APIuploadContacts(formData);
      if (resp.data.message === 'ok') {
        dispatch(uploadContactSuccessReducer({ data: true }));
      } else {
        dispatch(uploadContactErrorReducer());
        throw resp?.data?.error;
      }
    } catch (error) {
      dispatch(uploadContactErrorReducer());
      Toast.error(errorParser(error) || 'Server error');
    }
  };

export const createContactGroupAction =
  (payload = {}) =>
  async (dispatch) => {
    dispatch(creatingContactGroupSuccessReducer());
    try {
      const resp = await APIcreateContactGroup(payload);
      if (resp.data.message === 'ok') {
        dispatch(createContactGroupSuccessReducer(resp.data));
        Toast.success('Contact Group Created Successfully');
        dispatch(fetchGroupedContactsAction());
      } else {
        dispatch(createContactGroupErrorReducer());
        throw resp?.data?.error;
      }
    } catch (error) {
      dispatch(createContactGroupErrorReducer());
      Toast.error(errorParser(error) || 'Server error');
    }
  };
