import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Card, Button } from '../../../components/ui';
import { Input, InputSelect } from '../../../components/ui/inputs';
import WalletBalance from '../../wallet/components/WalletBalance';
import SuccessModal from '../../wallet/components/PaymentSuccess';
import CheckOut from '../../wallet/components/CheckOut';
import {
  APIGetBillersCategoryServices,
  APIListInternetplans,
  APIPayBillers,
} from '../../../api/backendRoutes';
import { fetchWallet } from '../../../redux/actions/wallet';
import { errorParser } from '../../../utilities/helper';

const Internet = ({ id }) => {
  const dispatch = useDispatch();
  const [biller, setBiller] = useState([]);
  const [selectedBiller, setSelectedBiller] = useState();
  const [price, setPrice] = useState(0);
  const [plans, setPlans] = useState([]);
  const [showCheckOut, setShowCheckOut] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState({ show: false, headerText: null });
  const [resolvedData, setResolvedData] = useState(null);
  const handleChange = async ({ name, value }) => {
    if (name === 'biller' && value === 'smile') {
      setPrice(0);
      setLoading(true);
      try {
        const resp = await APIListInternetplans({
          serviceId: biller?.filter((dt) => dt.slug === value)[0].id,
          serviceName: 'Smile',
        });
        if (resp.data.message === 'ok') {
          const { data } = resp.data;
          setPlans(data);
        } else {
          throw resp?.data?.error;
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setResolvedData({
          error: errorParser(err),
        });
      }
    }
    setSelectedBiller((v) => ({ ...v, [name]: value }));
    if (name === 'amount') {
      setPrice(value||0);
    }
    if (name === 'plans') {
      setPrice(value?.split('|')[0] || 0);
    }
  };

  useEffect(async () => {
    try {
      const resp = await APIGetBillersCategoryServices({
        serviceId: id,
      });
      if (resp.data.message === 'ok') {
        const { data } = resp.data;
        setBiller(data);
      } else {
        throw resp?.data?.error;
      }
    } catch (err) {
      // console.log(err);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedBiller.customerID.length < 5) {
      setResolvedData({ error: 'Invalid Customer Number' });
      return;
    }
    setShowCheckOut(true);
  };

  // eslint-disable-next-line no-unused-vars
  const fetchUserWallet = useCallback(() => {
    dispatch(fetchWallet());
  }, [dispatch]);

  const handleSend = async () => {
    setShowSuccessModal(false);
    const newPayload = {
      phoneNumber: selectedBiller.customerID,
      amount: price,
      apiServiceId: biller?.filter((dt) => dt.slug === selectedBiller.biller)[0].id,
      serviceId: id,
      // serviceId: selectedBiller.biller,
      serviceCode: selectedBiller.plans?.split('|')[1] || selectedBiller.customerID,
      operator: selectedBiller.biller,
      // phoneNumber: selectedBiller.phoneNumber,
      serviceType: 'internet',
    };
    setLoading(true);
    try {
      const resp = await APIPayBillers(newPayload);
      if (resp.data.message === 'ok') {
        setLoading(false);
        setShowSuccessModal({
          show: true,
          transactionId: resp.data.data.transactionReference,
          receipt: true,
        });
      }
    } catch (err) {
      // console.log({ err });
      setResolvedData({
        error: errorParser(err),
      });
      setLoading(false);
    }
    setShowCheckOut(false);
  };
  const handleCloseSuccessModal = () => {
    // fetchUserWallet();
    // setShowSuccessModal({ show: false, headerText: null });
    window.location.reload();
  };

  return (
    <div className="internet-wrapper">
      <section>
        <Row>
          <Col md={8} lg={9}>
            <form onSubmit={handleSubmit}>
              <Card style={{ padding: '30px 15px' }}>
                <Row>
                  <Col>
                    <InputSelect
                      placeholder="Enter Internet Provider"
                      label="Select Provider"
                      name="biller"
                      validate="required"
                      onChange={handleChange}
                      options={biller?.map((b) => ({
                        value: b?.slug,
                        label: b?.service,
                      }))}
                    />
                  </Col>
                </Row>
                {selectedBiller?.biller === 'smile' && (
                  <Row>
                    <Col>
                      <InputSelect
                        placeholder="Plans"
                        label="Select Plans"
                        name="plans"
                        validate="required"
                        onChange={handleChange}
                        options={plans?.map((b) => ({
                          value: `${b?.amount}|${b?.productId}`,
                          label: `${b?.productId} | ${b?.validity}`,
                        }))}
                      />
                    </Col>
                  </Row>
                )}
                {selectedBiller?.biller !== 'smile' && (
                  <Row>
                    <Col>
                      <Input
                        placeholder="Enter amount"
                        label="Amount"
                        name="amount"
                        onChange={handleChange}
                        validate="required"
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <Input
                      placeholder="Enter IUC"
                      label="Enter IUC"
                      name="customerID"
                      onChange={handleChange}
                      validate="required"
                    />
                  </Col>
                </Row>
                {/* <Row>
                  <Col>
                    <Input
                      placeholder="Enter Phone Number"
                      label="Enter Phone Number"
                      type="number"
                      onChange={handleChange}
                      name="phoneNumber"
                      validate="required"
                    />
                  </Col>
                </Row> */}
                <Row>
                  <Col style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      loading={loading}
                      value="Pay"
                      type="submit"
                      margin={[10]}
                      size="lg"
                      width={200}
                    />
                  </Col>
                </Row>
                {(resolvedData?.error || resolvedData === {}) && (
                  <div className="error">
                    {resolvedData?.error || 'Unable to resolve Customer Number'}
                  </div>
                )}
              </Card>
            </form>
          </Col>
          <Col md={4} lg={3}>
            <WalletBalance price={price} />
          </Col>
        </Row>
      </section>
      <SuccessModal
        show={showSuccessModal.show}
        transactionId={showSuccessModal.transactionId}
        onClose={handleCloseSuccessModal}
        receipt={showSuccessModal.receipt}
        setShowSuccessModal={setShowSuccessModal}
        price={price}
      />
      <CheckOut
        show={showCheckOut}
        onClose={() => setShowCheckOut(false)}
        execute={handleSend}
        price={price}
      />
    </div>
  );
};

export default Internet;
