import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import {
  // Switch,
  CheckBox,
} from '../../../components/ui/inputs';
import { APIgetNotifications, APIupdateNotifications } from '../../../api/backendRoutes';
import { errorParser } from '../../../utilities/helper';

const StyledNotification = styled.div`
  padding: 40px;
  color: #505050;
  .pn_wrapper {
    margin: 20px 0px;
  }
  .header {
    font-size: 20px;
    margin: 5px 0px;
    letter-spacing: 1px;
  }
  .subtitle {
    font-size: 16px;
  }
`;
const Notifications = () => {
  const [notifications, setNotifications] = React.useState({ data: [], isLoading: false });
  async function getNotifications() {
    setNotifications({ ...notifications, isLoading: true });
    try {
      const resp = await APIgetNotifications();
      if (resp.data.message === 'ok') {
        setNotifications({
          ...notifications,
          data: resp.data.data.filter((dt) => dt.settingId.category !== 'Wallet-Notification'),
          isLoading: false,
        });
      } else {
        setNotifications({ ...notifications, isLoading: false });
      }
    } catch (error) {
      setNotifications({ ...notifications, isLoading: false });
    }
  }
  async function updateNotification(params) {
    try {
      const resp = await APIupdateNotifications(params);
      if (resp.data.message === 'ok') {
        getNotifications();
        toast.success('Notification settings updated');
      } else {
        throw resp?.data?.error;
      }
    } catch (error) {
      toast.error(errorParser(error) || 'Server error');
      setNotifications({ ...notifications, isLoading: false });
    }
  }
  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <StyledNotification>
      <Row>
        <Col md={6}>
          <div className="pn_wrapper" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <div className="header">Email Notifications</div>
              <div className="subtitle">Get an email notifications for your transactions</div>
            </div>
            {/* <Switch /> */}
          </div>
        </Col>
      </Row>

      <div>
        {notifications?.data?.map((notification) => (
          <CheckBox
            label={notification.settingId.description}
            value={notification.is_active}
            onClick={() => {
              updateNotification({
                settingId: notification._id,
                actionType: notification.is_active ? 'deactivate' : 'activate',
              });
            }}
          />
        ))}
      </div>
    </StyledNotification>
  );
};

export default Notifications;
