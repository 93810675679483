import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dataPlans: [],
  dataTopUp: {},
  error: false,
  fetchingOperator: false,
  buyingData: false,
  success: false,
};

export const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    fetchDataPlansReducer: (state, { payload }) => {
      state.dataPlans = payload.bundles;
      state.error = false;
      state.fetchingOperator = false;
      state.buyingData = false;
    },
    fetchingDataReducer: (state) => {
      state.dataPlans = [];
      state.error = false;
      state.fetchingOperator = true;
      state.buyingData = false;
    },
    buyingDataReducer: (state) => {
      state.dataPlans = [];
      state.error = false;
      state.success = false;
      state.fetchingOperator = false;
      state.buyingData = true;
    },
    buyDataReducer: (state, { payload }) => {
      state.dataPlans = [];
      state.error = false;
      state.fetchingOperator = false;
      state.buyingData = false;
      state.dataTopUp = payload;
      state.success = true;
    },
    errorReducer: (state) => {
      state.dataPlans = [];
      state.error = true;
      state.fetchingOperator = false;
      state.buyingData = false;
      state.dataTopUp = {};
      state.success = false;
    },
  },
});

export const {
  fetchDataPlansReducer,
  fetchingDataReducer,
  errorReducer,
  buyDataReducer,
  buyingDataReducer,
} = dataSlice.actions;

export default dataSlice.reducer;
