import React from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'antd/dist/antd.css';

// import { Normalize } from 'styled-normalize';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { LastLocationProvider } from 'react-router-last-location';
import GlobalStyle from './GlobalStyle';
import theme from './utilities/materialTheme';
import { AppProvider } from './utilities/context/AppContext';
import PrivateRoutes, { ProtectedRoutesGroup } from './components/router/privateRoutes';

// const ResetPassword = lazy(() => import('./pages/auth/components/ResetPassword'));
import PublicRoutes, { PublicRoutesGroup } from './components/router/PublicRoutes';

library.add(fas);



const App = () => {
  return (
    <>
      {/* The rest of your application */}
      <GlobalStyle />
      {/* <Normalize /> */}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Toaster position="top-center" />
          <Router>
            <LastLocationProvider>
              <AppProvider>
                <Switch>
                  <PublicRoutes path="/auth" component={PublicRoutesGroup} />
                  <PrivateRoutes path="/" component={ProtectedRoutesGroup} />
                  <Route exact path="*" component={() => <h1>Page Not Found</h1>} />
                </Switch>
              </AppProvider>
            </LastLocationProvider>
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
