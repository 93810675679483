import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Input from './inputs/Input/Input';
import DateInput from './inputs/DateInput/DateInput';
import Button from './Button';
import Menu from './Menu';
import Icon from './Icon';
import { getDate } from '../../utilities/helper';

const Filter = ({ action, filter, options, clearFilter, setFilterData }) => {
  const [filterOptions, setOptions] = useState([]);
  const [hasFiltered, setHasFiltered] = useState(false);
  const [menuCtr, setMenuCtr] = useState({
    anchor: null,
    show: null,
  });
  const [showDate, setShowDate] = useState(false);

  useEffect(() => {
    const computedFilter = Object.keys(filter).map((k) => ({
      name: k,
      options: filter[k],
      value: k,
      label: k,
    }));
    setOptions(computedFilter);
  }, []);

  const handleMenu = (e, name) => {
    const temp = {
      anchor: e.currentTarget,
      show: name,
    };
    setMenuCtr(temp);
  };

  const handleCloseMenu = () => {
    setMenuCtr({
      anchor: null,
      show: null,
    });
  };

  const handleOptChange = (value, name, label) => {
    const temp = filterOptions.map((f) => {
      if (f.name === name) {
        return {
          ...f,
          value,
          label,
        };
      }
      return { ...f };
    });
    setOptions(temp);
    if (name === 'limit') {
      setFilterData((d) => ({
        ...d,
        limit: value,
      }));
      action({ limit: value });
    }
    if (name === 'Date' && value !== 'Period') {
      const newDate = getDate(value);
      setFilterData((d) => ({
        ...d,
        from: newDate?.toISOString(),
        to: moment().toISOString(),
      }));
    } else if (value !== 'Period' && name !== value) {
      setFilterData((f) => ({ ...f, [name]: value }));
    }
    if (value === 'Period') {
      setShowDate(true);
    } else {
      setShowDate(false);
    }
    handleCloseMenu();
  };

  const handleChange = ({ name, value }) => {
    if (name === 'search' && value !== undefined) {
      setFilterData((f) => ({ ...f, [name]: value }));
    } else if (name === 'keyword' && value !== undefined) {
      setFilterData((f) => ({ ...f, [name]: value }));
    } else if (name === 'to') {
      setFilterData((f) => ({ ...f, [name]: moment(value).endOf('day').toISOString() }));
    } else if (name === 'from') {
      setFilterData((f) => ({ ...f, [name]: moment(value).startOf('day').toISOString() }));
    }
  };
  const handleFilterTable = () => {
    action();
    setHasFiltered(true);
  };

  return (
    <div className="filter-wrapper">
      <div className="filter">
        <div className="other-filter-wrapper">
          <div className="title">Filter</div>
          {filterOptions?.map((f, idx) => {
            switch (f.name) {
              case 'Date':
                return (
                  <>
                    <div key={idx} className="filterType">
                      <div className="date" onClick={(e) => handleMenu(e, 'date')}>
                        <div>{f.value}</div>
                        <Icon icon="calendar" />
                      </div>
                      <div className="caret">
                        <Icon width={15} icon="caretdownsm" />
                      </div>
                      <Menu
                        name={f.name}
                        open={menuCtr.show === 'date'}
                        handleClose={handleCloseMenu}
                        anchor={menuCtr.anchor}
                        handleChange={handleOptChange}
                        options={f.options}
                      />
                    </div>
                    {showDate && (
                      <>
                        <div className="filterType d-flex align-items-center">
                          <div className="mr-2">From:</div>
                          <DateInput
                            disableToolbar
                            name="from"
                            type="date"
                            id="outlined"
                            format="DD MMM YYYY"
                            onChange={handleChange}
                            maxDate={moment()}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </div>
                        <div className="filterType  d-flex align-items-center">
                          <div className="mr-2">To:</div>
                          <DateInput
                            disableToolbar
                            name="to"
                            type="date"
                            defaultValue={moment()}
                            onChange={handleChange}
                            id="outlined"
                            format="DD MMM YYYY"
                            maxDate={moment()}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </div>
                      </>
                    )}
                  </>
                );
              case 'bulkType':
                return (
                  <>
                    {!showDate && (
                      <>
                        <div key={idx} className="filterType">
                          <div className="activity" onClick={(e) => handleMenu(e, 'bulkType')}>
                            {f.label === 'bulkType' ? 'Bulk Type' : f.label}
                          </div>
                          <div className="caret">
                            <Icon width={15} icon="caretdownsm" />
                          </div>
                          <Menu
                            name={f.name}
                            open={menuCtr.show === 'bulkType'}
                            handleClose={handleCloseMenu}
                            anchor={menuCtr.anchor}
                            handleChange={handleOptChange}
                            options={f.options}
                          />
                        </div>
                      </>
                    )}
                  </>
                );
              case 'country':
                return (
                  <>
                    {!showDate && (
                      <>
                        <div key={idx} className="filterType">
                          <div className="activity" onClick={(e) => handleMenu(e, 'country')}>
                            {f.value}
                          </div>
                          <div className="caret">
                            <Icon width={15} icon="caretdownsm" />
                          </div>
                          <Menu
                            name="countryName"
                            open={menuCtr.show === 'country'}
                            handleClose={handleCloseMenu}
                            anchor={menuCtr.anchor}
                            handleChange={handleOptChange}
                            options={f.options}
                          />
                        </div>
                      </>
                    )}
                  </>
                );
              case 'activityType':
                return (
                  <>
                    {!showDate && (
                      <>
                        <div key={idx} className="filterType">
                          <div className="activity" onClick={(e) => handleMenu(e, 'activityType')}>
                            {f.value === 'activityType' ? 'Activity Type' : f.value}
                          </div>
                          <div className="caret">
                            <Icon width={15} icon="caretdownsm" />
                          </div>
                          <Menu
                            name={f.name}
                            open={menuCtr.show === 'activityType'}
                            handleClose={handleCloseMenu}
                            anchor={menuCtr.anchor}
                            handleChange={handleOptChange}
                            options={f.options}
                          />
                        </div>
                      </>
                    )}
                  </>
                );
              case 'serviceType':
                return (
                  <>
                    {!showDate && (
                      <>
                        <div key={idx} className="filterType">
                          <div className="activity" onClick={(e) => handleMenu(e, 'serviceType')}>
                            {f.value === 'serviceType' ? 'Service Type' : f.value}
                          </div>
                          <div className="caret">
                            <Icon width={15} icon="caretdownsm" />
                          </div>
                          <Menu
                            name={f.name}
                            open={menuCtr.show === 'serviceType'}
                            handleClose={handleCloseMenu}
                            anchor={menuCtr.anchor}
                            handleChange={handleOptChange}
                            options={f.options}
                          />
                        </div>
                      </>
                    )}
                  </>
                );
              case 'service':
                return (
                  <>
                    {!showDate && (
                      <>
                        <div key={idx} className="filterType">
                          <div className="activity" onClick={(e) => handleMenu(e, 'service')}>
                            {f.value === 'service' ? 'Service' : f.value}
                          </div>
                          <div className="caret">
                            <Icon width={15} icon="caretdownsm" />
                          </div>
                          <Menu
                            name={f.name}
                            open={menuCtr.show === 'service'}
                            handleClose={handleCloseMenu}
                            anchor={menuCtr.anchor}
                            handleChange={handleOptChange}
                            options={f.options}
                          />
                        </div>
                      </>
                    )}
                  </>
                );
              case 'serviceName':
                return (
                  <>
                    {!showDate && (
                      <>
                        <div key={idx} className="filterType">
                          <div className="activity" onClick={(e) => handleMenu(e, 'serviceName')}>
                            {f.value === 'serviceName' ? 'Service Name' : f.value}
                          </div>
                          <div className="caret">
                            <Icon width={15} icon="caretdownsm" />
                          </div>
                          <Menu
                            name={f.name}
                            open={menuCtr.show === 'serviceName'}
                            handleClose={handleCloseMenu}
                            anchor={menuCtr.anchor}
                            handleChange={handleOptChange}
                            options={f.options}
                          />
                        </div>
                      </>
                    )}
                  </>
                );
              case 'status':
                return (
                  <>
                    {!showDate && (
                      <>
                        <div key={idx} className="filterType">
                          <div className="activity" onClick={(e) => handleMenu(e, 'status')}>
                            {f.value}
                          </div>
                          <div className="caret">
                            <Icon width={15} icon="caretdownsm" />
                          </div>
                          <Menu
                            name={f.name}
                            open={menuCtr.show === 'status'}
                            handleClose={handleCloseMenu}
                            anchor={menuCtr.anchor}
                            handleChange={handleOptChange}
                            options={f.options}
                          />
                        </div>
                      </>
                    )}
                  </>
                );
              case 'category':
                return (
                  <>
                    {!showDate && (
                      <>
                        <div key={idx} className="filterType">
                          <div className="activity" onClick={(e) => handleMenu(e, 'category')}>
                            {f.value}
                          </div>
                          <div className="caret">
                            <Icon width={15} icon="caretdownsm" />
                          </div>
                          <Menu
                            name={f.name}
                            open={menuCtr.show === 'category'}
                            handleClose={handleCloseMenu}
                            anchor={menuCtr.anchor}
                            handleChange={handleOptChange}
                            options={f.options}
                          />
                        </div>
                      </>
                    )}
                  </>
                );
              case 'search':
                return (
                  <>
                    {!showDate && (
                      <>
                        <div md={3} className="filterType">
                          <Input
                            name="search"
                            type="text"
                            placeholder="search by name"
                            icon="search"
                            onChange={handleChange}
                          />
                        </div>
                      </>
                    )}
                  </>
                );
              case 'keyword':
                return (
                  <>
                    {!showDate && (
                      <>
                        <div md={3} className="filterType">
                          <Input
                            name="keyword"
                            type="text"
                            placeholder="search by keyword"
                            icon="search"
                            onChange={handleChange}
                          />
                        </div>
                      </>
                    )}
                  </>
                );
              default:
                return null;
            }
          })}
          <div md={2} className="filterType filterButton d-flex">
            <Button value="Apply" size="lg" onClick={handleFilterTable} />
            {hasFiltered && (
              <div className="ml-2">
                <Button
                  value="Clear"
                  size="sm"
                  onClick={() => {
                    clearFilter();
                    setHasFiltered(false);
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="filterType limit_filter">
          <div className="activity" onClick={(e) => handleMenu(e, 'limit')}>
            {filterOptions[filterOptions.length - 1]?.value === 'limit'
              ? 25
              : filterOptions[filterOptions.length - 1]?.value}
          </div>
          <div className="caret">
            <Icon width={15} icon="caretdownsm" />
          </div>
          <Menu
            name="limit"
            open={menuCtr.show === 'limit'}
            handleClose={handleCloseMenu}
            anchor={menuCtr.anchor}
            handleChange={handleOptChange}
            options={filter.limit}
          />
        </div>
      </div>
    </div>
  );
};

export default Filter;
