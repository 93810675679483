import React from 'react';
import { ReactSVG } from 'react-svg'
import * as icons from '../../assets/icons';

// const StyledIcon = styled.div.attrs((props) => ({ ...props }))`
//   margin: ${(props) => props.margin};
//   padding: ${(props) => props.padding};
//   width: ${({ width }) => (width ? `${width}px` : '')};
//   height: ${({ height }) => (height ? `${height}px` : '')};

//   div {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }

//   svg {
//     path {
//       fill: ${(props) => props.color} !important;
//     }
//   }

//   .logo svg {
//     background: pink !important;
//   }
// `;


export const SvgIcon = ({
  icon = '',
  margin = [],
  padding = [],
  color = '',
  size = 'sm',
  width,
  height,
  onClick,
  ...rest
}) => {
  return (
    <div
    className="icon-wrapper"
      margin={margin.length > 0 && `${margin.join('px ')}px;`}
      padding={padding.length > 0 && `${padding.join('px ')}px;`}
      color={color}
      size={size}
      width={width}
      height={height}
      {...rest}
    >
      <div
        onClick={(e) => onClick && onClick(e)}
        style={{ cursor: onClick ? 'pointer' : 'default' }}
      >
        <ReactSVG
          alt={icon}
          src={icons[icon.replace(/-/g, '')]}
          className="icon__svg"
          width={width || 'auto'}
          height={height || 'auto'}
        />
      </div>
    </div>
  );
};

const Icon = ({
  icon = '',
  margin = [],
  padding = [],
  color = '',
  size = 'sm',
  width,
  height,
  onClick,
  ...rest
}) => {

  return (
    <div
    className="icon-wrapper"
      margin={margin.length > 0 && `${margin.join('px ')}px;`}
      padding={padding.length > 0 && `${padding.join('px ')}px;`}
      color={color}
      size={size}
      width={width}
      height={height}
      {...rest}
    >
      <div
        onClick={(e) => onClick && onClick(e)}
        style={{ cursor: onClick ? 'pointer' : 'default' }}
      >
        <img
          alt={icon}
          src={icons[icon.replace(/-/g, '')]}
          className="logo"
          width={width || 'auto'}
          height={height || 'auto'}
        />
      </div>
    </div>
  );
};

export default Icon;
