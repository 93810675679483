import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  auditTrails: [],
  fetchingAuditTrails: false,
  auditTrailsMeta: {},
};

export const auditSlice = createSlice({
  name: 'audit',
  initialState,
  reducers: {
    fetchAuditTrailsSuccessReducer: (state, { payload }) => {
      state.auditTrails = payload.data;
      state.fetchingAuditTrails = false;
      state.auditTrailsMeta = {
        limit: payload.limit,
        page: payload.page,
        total: payload.total,
      };
    },
    fetchingAuditTrailsReducer: (state) => {
      state.fetchingAuditTrails = true;
      state.auditTrails = [];
      state.auditTrailsMeta = {};
    },
    fetchingAuditTrailsErrorReducer: (state) => {
      state.fetchingAuditTrails = false;
      state.auditTrails = [];
      state.auditTrailsMeta = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  fetchAuditTrailsSuccessReducer,
  fetchingAuditTrailsReducer,
  fetchingAuditTrailsErrorReducer,
} = auditSlice.actions;

export default auditSlice.reducer;
