/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, Route, Switch, Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { motion } from 'framer-motion';
import SignIn from './components/Login';
import SignUp from './components/SignUp';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import { AUTH_DATA } from '../../utilities/constants';
import PageDetails from './components/PageDetails';
import { ROUTES } from '../../components/router/routes/routes';
import { Logo } from '../../components/ui';
import ProviderSwitch from './components/ProviderSwitch';
import { AppContext } from '../../utilities/context/AppContext';

const Auth = () => {
  const [route, setRoute] = useState(ROUTES.SIGN_UP.path);
  const history = useHistory();
  const [data, setData] = useState({});
  const { partnerInfo } = useContext(AppContext);
  const { pathname } = history.location;

  useEffect(() => {
    if (pathname.startsWith('/auth/reset-password')) {
      setRoute(ROUTES.RESET_PASSWORD.path);
      setData(AUTH_DATA[ROUTES.RESET_PASSWORD.path]);
    } else {
      setRoute(pathname);
      setData(AUTH_DATA[pathname]);
    }
  }, [history.location.pathname]);

  const onSwitchTab = (r) => {
    history.push(r);
  };

  const pageTransition = {
    in: {
      opacity: 1,
      x: 0,
    },
    out: {
      opacity: 0,
      x: 0,
    },
  };
  return (
    <div className="auth__container">
      <motion.div
        className="container auth--container"
        initial="out"
        animate="in"
        exit="out"
        variants={pageTransition}
      >
        <div className="left-side">
          <PageDetails pathname={history.location.pathname} />
        </div>
        <div className="right-side">
          <div className="container">
            <Row className="justify-content-center">
              <Col md={12}>
                <Link to="/" className="home-link-img">
                  <Logo src={partnerInfo.logoUrl || '/logo.svg'} name="Blusalt" />
                </Link>
                {/* <ProviderSwitch /> */}
                {(history.location.pathname === ROUTES.SIGN_UP.path ||
                  history.location.pathname === ROUTES.SIGN_IN.path) && (
                  <div className="switch-tab">
                    <div
                      className={`tab ${
                        history.location.pathname === '/auth/signup' ? 'active' : ''
                      }`}
                      onClick={() => onSwitchTab(ROUTES.SIGN_UP.path)}
                    >
                      Sign Up
                    </div>
                    <div
                      className={`tab ${
                        history.location.pathname === '/auth/signin' ? 'active' : ''
                      }`}
                      onClick={() => onSwitchTab(ROUTES.SIGN_IN.path)}
                    >
                      Login
                    </div>
                  </div>
                )}
                <Switch>
                  <Route path={ROUTES.SIGN_IN.path} component={SignIn} />
                  <Route path={ROUTES.SIGN_UP.path} component={SignUp} />
                  <Route path={ROUTES.FORGOT_PASSWORD.path} component={ForgotPassword} />
                  <Route path={ROUTES.RESET_PASSWORD.path} component={ResetPassword} />
                </Switch>
              </Col>
            </Row>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Auth;
