
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  walletNfo: {
    balance: 0,
    bank: {
      account_number: null,
      bank_name: null,
    },
  },
  fetchingWallet: false,
  error: false,

  walletActivities: [],
  fetchingWalletActivities: false,
  errorFetchingWalletActivities: false,
  walletActivitiesMeta: {},

  monthlyAnalytics: [],
  fetchingMonthlyAnalytics: false,
  errorFetchingMonthlyAnalytics: false,
};

export const walletSlice = createSlice({
  name: 'wallets',
  initialState,
  reducers: {
    fetchUserWallet: (state, { payload }) => {
      state.walletNfo = payload.data;
      state.fetchingWallet = false;
      state.error = false;
    },
    fetchingUserWallet: (state) => {
      state.fetchingWallet = true;
    },
    errorFetchingUserWallet: (state) => {
      state.error = true;
    },

    fetchWalletActivitySuccessReducer: (state, { payload }) => {
      state.walletActivities = payload.data;
      state.fetchingWalletActivities = false;
      state.walletActivitiesMeta = {
        limit: payload.limit,
        page: payload.page,
        total: payload.total,
      };
    },
    fetchingWalletActivityReducer: (state) => {
      state.walletActivities = [];
      state.fetchingWalletActivities = true;
      state.errorFetchingWalletActivities = false;
    },
    fetchingWalletActivityErrorReducer: (state) => {
      state.walletActivities = [];
      state.fetchingWalletActivities = false;
      state.errorFetchingWalletActivities = true;
    },

    fetchMonthlyWalletAnalyticsSuccessReducer: (state, { payload }) => {
      state.monthlyAnalytics = payload.data;
      state.fetchingMonthlyAnalytics = false;
    },
    fetchingMonthlyWalletAnalyticsReducer: (state) => {
      state.monthlyAnalytics = [];
      state.fetchingMonthlyAnalytics = true;
      state.errorFetchingMonthlyAnalytics = false;
    },
    fetchingMonthlyWalletAnalyticsErrorReducer: (state) => {
      state.walletActivities = [];
      state.fetchingMonthlyAnalytics = false;
      state.errorFetchingMonthlyAnalytics = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  fetchUserWallet,
  fetchingUserWallet,
  errorFetchingUserWallet,
  fetchWalletActivitySuccessReducer,
  fetchingWalletActivityReducer,
  fetchingWalletActivityErrorReducer,
  fetchMonthlyWalletAnalyticsSuccessReducer,
  fetchingMonthlyWalletAnalyticsReducer,
  fetchingMonthlyWalletAnalyticsErrorReducer,
} = walletSlice.actions;

export default walletSlice.reducer;
