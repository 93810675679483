import React, { useState } from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import {Card, Button} from '../../../components/ui';
import FundWallet from './FundWallet';

const StyledWalletBalance = styled.div.attrs((props) => ({ ...props }))`
  font-weight: 300;
  color: ${({ price }) => (price >= 0 ? `#111111` : '#bbbbbb')};

  ${({ price }) =>
    price >= 0
      ? `
  .price {
    color: var(--primary-color);
  }

  .balance {
    color: var(--primary-color);
  }

  .balance.low {
    color: red;
  }
  `
      : ''}
`;

const WalletBalance = ({ price }) => {
  const [showFundWalletModal, setShowFundWalletModal] = useState(false);
  const { balance } = useSelector((state) => {
    return {
      ...state.wallet.walletNfo,
    };
  });
  return (
    <>
      <Card>
        <StyledWalletBalance price={price}>
          {price !== undefined && (
            <div style={{ marginBottom: 30 }}>
              <div style={{ fontWeight: 400 }}>Price</div>
              <div className="price" style={{ fontSize: 26 }}>
                <NumberFormat displayType="text" thousandSeparator prefix="₦" value={price} />
              </div>
            </div>
          )}
          <div style={{ marginBottom: 20 }}>
            <div style={{ fontWeight: 400 }}>Available Wallet Balance</div>
            <div style={{ fontSize: 26 }} className={price > balance ? 'balance low' : 'balance'}>
              <NumberFormat
                value={balance}
                displayType="text"
                thousandSeparator
                // prefix={bank?.currency || '₦'}
                prefix="₦"
                fixedDecimalScale
                decimalScale={2}
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            {price > balance && (
              <div
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                  marginBottom: 20,
                  borderLeft: '3px solid red',
                  padding: 10,
                  backgroundColor: '#fafafa',
                  color: '#555555',
                }}
              >
                You do not have enough funds to proceed
              </div>
            )}
            <Button
              value="Fund Wallet"
              size="lg"
              width="100%"
              onClick={() => setShowFundWalletModal(true)}
            />
          </div>
        </StyledWalletBalance>
      </Card>
      <FundWallet show={showFundWalletModal} onClose={() => setShowFundWalletModal(false)} />
    </>
  );
};

export default WalletBalance;
