import React, { useCallback, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import Cards from 'react-credit-cards';
import Toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import { Button, Table } from '../../components/ui';
import { SvgIcon as Icon } from '../../components/ui/Icon';
import { CheckBox } from '../../components/ui/inputs';
import { fetchWalletActivitiesAction } from '../../redux/actions/wallet';
import FundWallet from './components/FundWallet';
import {
  APIGetPaymentMethods,
  APIgetNotifications,
  APIupdateNotifications,
} from '../../api/backendRoutes';
import { errorParser, titleCase, toCurrency } from '../../utilities/helper';
import 'react-credit-cards/es/styles-compiled.css';

const Wallet = () => {
  const [cards, setCards] = useState([]);
  const [showFundWalletModal, setShowFundWalletModal] = useState(false);
  const [hideBal, setHideBal] = useState(true);
  const [notifications, setNotifications] = React.useState({ data: [], isLoading: false });
  async function getNotifications() {
    setNotifications({ ...notifications, isLoading: true });
    try {
      const resp = await APIgetNotifications();
      if (resp.data.message === 'ok') {
        setNotifications({
          ...notifications,
          data: resp.data.data.filter((dt) => dt.settingId.category === 'Wallet-Notification'),
          isLoading: false,
        });
      } else {
        setNotifications({ ...notifications, isLoading: false });
      }
    } catch (error) {
      setNotifications({ ...notifications, isLoading: false });
    }
  }
  async function updateNotification(params) {
    try {
      const resp = await APIupdateNotifications(params);
      if (resp.data.message === 'ok') {
        getNotifications();
        Toast.success('Notification settings updated');
      } else {
        throw resp?.data?.error;
      }
    } catch (error) {
      Toast.error(errorParser(error) || 'Server error');
      setNotifications({ ...notifications, isLoading: false });
    }
  }
  const { balance, bank } = useSelector((state) => {
    return {
      ...state.wallet.walletNfo,
    };
  });

  const { walletActivities, fetchingWalletActivities, meta } = useSelector((state) => {
    return {
      walletActivities: state.wallet.walletActivities,
      fetchingWalletActivities: state.wallet.fetchingWalletActivities,
      errorFetchingWalletActivities: state.wallet.errorFetchingWalletActivities,
      meta: state.wallet.walletActivitiesMeta,
    };
  });

  const dispatch = useDispatch();

  const fetchWalletActivities = useCallback(
    (params = { limit: 25 }) => {
      dispatch(fetchWalletActivitiesAction(params));
    },
    [dispatch]
  );

  const getCards = async () => {
    try {
      const { data } = await APIGetPaymentMethods();
      setCards(data.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  useEffect(() => {
    fetchWalletActivities();
    getNotifications();
    getCards();
  }, []);
  const copyToClipboard = () => {
    navigator.clipboard.writeText(bank?.account_number);
    Toast.success('copied');
  };

  return (
    <>
      <div className="page-wallet">
        <div className="--cards">
          <div className="wallet-card">
            <div className="balance-nfo">
              <div className="top">
                <span>Wallet balance</span>
                <span>
                  <Icon
                    icon={!hideBal ? 'eye' : 'eyeclose'}
                    onClick={() => setHideBal(() => !hideBal)}
                  />
                </span>
              </div>
              <div className="bottom">
                {!hideBal ? (
                  <NumberFormat
                    value={balance}
                    displayType="text"
                    thousandSeparator
                    // prefix={bank?.currency}
                    prefix="₦"
                    fixedDecimalScale
                    decimalScale={2}
                  />
                ) : (
                  '******'
                )}
              </div>
            </div>
            <div className="fundWallet">
              <Button size="sm" value="Fund wallet" onClick={() => setShowFundWalletModal(true)} />
            </div>
            <div className="bank-nfo">
              <div className="detail">
                <Icon icon="bank" />
                <div className="bottom">
                  <span>{bank?.bank_name}</span>
                  <span>
                    {!hideBal
                      ? bank?.account_number
                      : `${bank?.account_number?.slice(0, 3)}*******`}
                  </span>
                </div>
              </div>
              <div className="copy" onClick={copyToClipboard}>
                <span>copy</span>
                <span>
                  <Icon icon="copy" />
                </span>
              </div>
            </div>
          </div>
          <div className="wallet-card">
            <div className="d-flex justify-content-between">
              <h5 className="pt-4 pl-4">Card Payment Methods</h5>
            </div>
            <div className="card__container">
              {cards.length > 0 ? (
                cards.map((dt) => (
                  <div className="p-4">
                    <Cards
                      number={dt.card.bin}
                      name={dt.card.cardholder}
                      expiry=""
                      cvc={100}
                      focused={9898}
                    />
                  </div>
                ))
              ) : (
                <>
                  <div className="m-4 py-4">
                    No Payment Method Available, Fund wallet to add payment methods.
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="wallet-card">
            <div className="header">Wallet Settings</div>
            {/* <div className="setting">
              <div className="top">
                <Icon icon="info" /> Enable auto top-up
              </div>
              <div>
                <Switch />
              </div>
            </div> */}
            <div className="notification">
              {notifications?.data?.map((notification) => (
                <CheckBox
                  label={notification.settingId.description}
                  value={notification.is_active}
                  onClick={() => {
                    updateNotification({
                      settingId: notification._id,
                      actionType: notification.is_active ? 'deactivate' : 'activate',
                    });
                  }}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="transactions-table">
          <div>
            <h4>Latest Transactions</h4>
            <div>
              <Table
                header={[
                  { key: 'reference', label: 'Reference' },
                  {
                    key: 'amount',
                    label: 'Amount',
                    render: (data) => (
                      <NumberFormat
                        value={data}
                        displayType="text"
                        thousandSeparator
                        fixedDecimalScale
                        decimalScale={2}
                      />
                    ),
                  },
                  { key: 'currency', label: 'Currency' },
                  { key: 'balance', label: 'Balance', render: (_id, _) => toCurrency(_.balance) },
                  {
                    key: 'action',
                    label: 'Action',
                    render: (action) => (
                      <div
                        style={{
                          color: `${action === 'debit' ? '#F2994A' : '#63B67F'}`,
                        }}
                      >
                        {action}
                      </div>
                    ),
                  },
                  {
                    key: 'source',
                    label: 'Payment Method',
                    render: (source) => <div>{titleCase(source)}</div>,
                  },
                  {
                    key: 'status',
                    label: 'Transaction Status',
                    render: (status) => (
                      <div
                        style={{
                          color: `${status === 'debit' ? '#F2994A' : '#63B67F'}`,
                        }}
                      >
                        {status}
                      </div>
                    ),
                  },
                  {
                    key: 'createdAt',
                    label: 'Date',
                    render: (date) => (
                      <div style={{ width: 100 }}>
                        <Moment format="D MMM, YYYY" date={date} /> <br />
                        <Moment format="h:mm:SSa" date={date} />
                      </div>
                    ),
                  },
                ]}
                data={walletActivities}
                loading={fetchingWalletActivities}
                paginate
                meta={meta}
                handlePagination={fetchWalletActivities}
                emptyStateMessage="When you fund your wallet, you can view your transactions here"
              />
            </div>
          </div>
        </div>
      </div>
      <FundWallet
        show={showFundWalletModal}
        getCards={getCards}
        onClose={() => setShowFundWalletModal(false)}
      />
    </>
  );
};

export default Wallet;
