import React from 'react';
import {
  LineChart,
  Line as L,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

const StyledToolTip = styled.div`
  background: #509fef;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  padding: 10px;
  color: #ffffff;
  .header {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.25);
    padding: 7px;
    border-radius: 7px;
  }
`;

const CustomToolTip = ({ payload, label, active }) => {
  if (active && payload?.length > 0) {
    return (
      <StyledToolTip>
        <div className="header">Total Transactions</div>
        <NumberFormat
          value={payload[0]?.payload?.amount}
          displayType="text"
          thousandSeparator
          prefix="₦"
          fixedDecimalScale
          decimalScale={2}
        />
        <div>17,{payload[0]?.payload?.month} 2020</div>
      </StyledToolTip>
    );
  }
  return null;
};
const Line = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="100%" className="graph-reposition">
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 10,
          left: 10,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis dataKey="amount" />
        <Tooltip content={<CustomToolTip />} />
        <L type="monotone" dataKey="amount" stroke="#828282" activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export { Line };
