import React, { useState, useEffect, useCallback } from 'react';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import CreateUser from './CreateUser';
import { Finalize, Table, Button, Icon, Modal } from '../../../components/ui';
import { APIlistTeam, APIDeleteUser } from '../../../api/backendRoutes';
import { errorParser } from '../../../utilities/helper';

const StyledTeam = styled.div``;
const Users = ({ showUser, setCurrentMember, roles }) => {
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [team, setTeam] = useState([]);
  const [deleteId, setDeleteId] = useState('');

  const getTeam = useCallback(async () => {
    try {
      const resp = await APIlistTeam({ page: 0, limit: 10 });
      if (resp.data.message === 'successful') {
        const newData = resp.data?.data;
        const teamMember = newData.map((n) => ({
          fullname: `${n.user?.firstName} ${n.user?.lastName}`,
          email: n.user?.email,
          role: n?.role?.roleName,
          id: n?.id,
          firstName: n.user?.firstName,
        }));
        setTeam(teamMember);
      } else {
        throw resp?.data?.error;
      }
    } catch (err) {
      // console.log(err);
    }
  });

  useEffect(() => {
    getTeam();
  }, []);

  const handleShowSuccess = () => {
    setShowSuccess(true);
    setShowCreateUserModal(false);
  };
  const handleCloseSuccess = () => {
    setShowSuccess(false);
    getTeam();
  };
  return (
    <StyledTeam className="px-2">
      <Table
        header={[
          { key: 'fullname', label: 'Name' },
          { key: 'email', label: 'Email Address' },
          { key: 'role', label: 'Role' },
          // {
          //   key: 'last_login',
          //   label: 'Last Login',
          //   render: (date) =>
          //     typeof date === 'number' ? <Moment format="D MMM, YYYY" date={date} /> : date,
          // },
          {
            key: 'id',
            label: '',
            render: (status, record) => (
              <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <Button
                  value="Details"
                  group="secondary"
                  onClick={() => {
                    setCurrentMember(record);
                    showUser();
                  }}
                />
                <Icon
                  icon="trash"
                  width={15}
                  onClick={() => {
                    setDeleteId(record.id);
                    setShowDeleteModal(true);
                  }}
                />
              </div>
            ),
          },
        ]}
        data={team}
      />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          group="secondary round"
          value="Add User"
          icon="plus-circle"
          onClick={() => setShowCreateUserModal(true)}
        />
      </div>
      <Finalize
        show={showSuccess}
        onClose={handleCloseSuccess}
        headerText="Team Mate Created Successfully"
      />
      <CreateUser
        show={showCreateUserModal}
        onClose={() => setShowCreateUserModal(false)}
        roles={roles}
        next={handleShowSuccess}
      />
      <ConfirmDelete
        show={showDeleteModal}
        getTeam={getTeam}
        setShow={setShowDeleteModal}
        id={deleteId}
      />
    </StyledTeam>
  );
};

export default Users;

/* eslint-disable no-unused-expressions */

const Wrapper = styled.div`
  .confirm-container {
  }
`;

const ConfirmDelete = ({ show, setShow, id, getTeam }) => {
  const [loading, setLoading] = useState(false);
  const deleteRole = async () => {
    try {
      setLoading(true);
      const resp = await APIDeleteUser({
        memberId: id,
      });
      if (resp.data.success) {
        getTeam();
        toast.success('User removed successfully');
        setShow(false);
        setLoading(false);
      } else {
        setLoading(false);
        setShow(false);
        throw resp?.data?.error;
      }
    } catch (err) {
      setLoading(false);
      setShow(false);
      toast.error(errorParser(err) || 'Server error');
    }
  };
  return (
    <Modal title="Confirm Delete" show={show} onClose={() => setShow(false)}>
      <Wrapper>
        <div className="confirm-container">
          <h4 className="text-center my-4">This action is irreversible, proceed with caution!</h4>
          <div className="btn-container">
            <Button value="Delete" center loading={loading} onClick={() => deleteRole()} />
          </div>
        </div>
      </Wrapper>
    </Modal>
  );
};
