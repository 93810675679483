import React, { useEffect, useState } from 'react';
import MuiPhoneNumber from 'material-ui-phone-number';
import InputHint from '../common/InputHint/InputHint';
import { validator } from '../../../../utilities/helper';

const PhoneInput = ({
  resetInput,
  label,
  placeholder = '',
  disabled = false,
  type = 'text',
  variant = 'outlined',
  hint,
  value,
  name,
  index,
  size = 'small',
  defaultValue,
  multiline = false,
  rows = 3,
  onChange,
  validate,
  accept = '',
  defaultCountry = 'ng',
  ...rest
}) => {
  const [data, setData] = useState(value || defaultValue);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (onChange) {
      onChange({ name, value: data, index });
    }
  }, []);

  useEffect(() => {
    if (resetInput) {
      setData('');
      setErrors([]);
    }
  }, [resetInput]);

  const handleValidation = (e) => {
    const { value: v } = e.target;
    setErrors(validate ? validator(v, validate) : []);
  };

  const handleOnChange = (v, { dialCode }) => {
    setData(v);

    if (onChange) {
      let val = `+${dialCode}${v
        .replace(`+${dialCode}`, '')
        .replace('-', '')
        .replace(' ', '')
        .replace(' ', '')}`;
      if (dialCode.startsWith('1')) {
        val = v
          .replace('-', '')
          .replace('(', '')
          .replace(')', '')
          .replace(' ', '')
          .replace(' ', '');
      }
      onChange({
        name,
        value: val,
        idx: index,
      });
    }
  };

  return (
    <div className="input-wrapper">
      <MuiPhoneNumber
        defaultCountry={defaultCountry}
        countryCodeEditable={false}
        size={size}
        name={name}
        defaultValue={defaultValue}
        label={label}
        type={type}
        placeholder={placeholder || label}
        disabled={disabled}
        variant={variant}
        aria-label={label}
        aria-describedby={label}
        multiline={multiline}
        rows={rows}
        value={data}
        // autoComplete={false}
        onChange={handleOnChange}
        onBlur={handleValidation}
        error={errors.length > 0}
        helperText={errors.length > 0 ? errors[0] : ''}
        required={!!validate}
        inputProps={{ accept, autoComplete: 'none' }}
        {...rest}
      />
      {hint && <InputHint>{hint}</InputHint>}
    </div>
  );
};

export default PhoneInput;
