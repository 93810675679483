import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Nav, Navbar } from 'react-bootstrap';
import Icon from '../ui/Icon';
import Avatar from '../ui/Avatar';
import AvatarImage from '../../assets/images/avatar.jpg';
import { AppContext } from '../../utilities/context';
// import { Input } from '../components/ui/inputs';
import { ROUTES } from '../router/routes/routes';
import { fetchUserData } from '../../storage/sessionStorage';
import Notification from './Notification';

const Header = ({ title = '' }) => {
  const history = useHistory();
  const { toggleSideMenu, goBackMethod, setGoBackMethod, goBack } = useContext(AppContext);

  const user = fetchUserData();

  const handleSignOut = () => {
    localStorage.clear();
    history.push(ROUTES.SIGN_IN.path);
  };

  return (
    <div className="header-wrapper">
      <Navbar>
        <div className="hamburger" onClick={() => toggleSideMenu()}>
          <Icon icon="burger" />
        </div>
        <Navbar.Brand>
          {!goBackMethod && <h4 style={{ fontWeight: 600 }}>{title}</h4>}
          {goBackMethod && (
            <span
              style={{
                cursor: 'pointer',
                display: 'flex',
                fontWeight: 600,
                fontSize: 14,
                alignItems: 'center',
                color: '#17568B',
              }}
              onClick={() => {
                goBack();
                setGoBackMethod(null);
              }}
            >
              <Icon icon="goback" width={20} margin={[0, 10, 0, 0]} /> Back
            </span>
          )}
        </Navbar.Brand>

        <Navbar.Collapse className="justify-content-end">
          <Nav className="align-items-center">
            {/* <Input
             className="search"
              name="search"
              type="text"
              placeholder="search"
              icon="search"
              padding="0px"
              height="36px"
              // onChange={handleChange}
            /> */}

            <>
              <Notification/>
              <Avatar
                name={`${user.firstName} ${user.lastName}`.trim()}
                src={user.image_url || AvatarImage}
                options={[
                  { label: 'Profile', action: () => history.push(ROUTES.SETTINGS.path) },
                  {
                    label: 'FAQ',
                    action: () =>
                      window.open('https://bluintouch.com/privacy-policy', '_blank').focus(),
                  },
                  {
                    label: 'Help',
                    action: () =>
                      window.open('https://bluintouch.com/contact-us', '_blank').focus(),
                  },
                  { label: 'SignOut', action: () => handleSignOut() },
                ]}
                margin={isMobile && [0]}
              />
            </>
          </Nav>
        </Navbar.Collapse>
        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
      </Navbar>
    </div>
  );
};

export default Header;
