import React, { useContext } from 'react';
import { Route, Redirect, withRouter, Switch } from 'react-router-dom';
import VerifyAccount from '../../../pages/auth/components/VerifyAccount';
import VerifyOtp from '../../../pages/auth/components';
import { ROUTES } from '../routes/routes';
import Auth from '../../../pages/auth';
import { fetchUserData } from '../../../storage/sessionStorage';
import { AppContext } from '../../../utilities/context';
import { Loader } from '../../ui';
import TwoFactorAuth from '../../../pages/auth/components/TwoFactorAuth';

export const publicRoutes = [
  {
    component: VerifyOtp,
    routes: ROUTES.VERIFY_OTP,
  },
  {
    component: TwoFactorAuth,
    routes: ROUTES.TFA,
  },
  {
    component: Auth,
    routes: ROUTES.SIGN_UP,
  },
  {
    component: Auth,
    routes: ROUTES.SIGN_IN,
  },
  {
    component: Auth,
    routes: ROUTES.RESET_PASSWORD,
  },
  {
    component: Auth,
    routes: ROUTES.FORGOT_PASSWORD,
  },
  {
    component: VerifyAccount,
    routes: ROUTES.VERIFY_ACCOUNT,
  },
];

const PublicRoutes = ({ component: Component, ...rest }) => {
  const { token } = fetchUserData();

  return (
    <Route
      {...rest}
      render={(props) => (token ? <Redirect to="/dashboard" /> : <Component {...props} />)}
    />
  );
};

export default withRouter(PublicRoutes);

export const PublicRoutesGroup = () => {
  const { dataLoading } = useContext(AppContext);
  return (
    <>
      {dataLoading ? (
        <Loader />
      ) : (
        <Switch>
          {publicRoutes.map((route, index) => (
            <Route key={index} exact component={route.component} {...route.routes} />
          ))}
        </Switch>
      )}
    </>
  );
};
