import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Box, Flex } from 'rebass';
import Toast from 'react-hot-toast';

import { Modal, Button, Upload } from '../../../components/ui';
import { Input, InputSelect } from '../../../components/ui/inputs';

import {
  fetchContactGroupsAction,
  uploadContactsAction,
  createContactGroupAction,
} from '../../../redux/actions/contacts';
import { fetchCampaignsAction } from '../../../redux/actions/campaign';
import { fetchServiceTypesAction } from '../../../redux/actions/services';

const CreateContact = ({ show, onClose }) => {
  const [formData] = useState({
    group: '',
    phone_numbers: '',
    category: '',
    file: null,
    fetching: false,
  });

  const [newGroup, setNewGroup] = useState(false);
  const [payload, setPayload] = useState({
    phoneNumbers: [],
    countryCode: '',
  });
  const [uploadData, setUploadData] = useState(null);
  const [newGroupUpload, setNewGroupUpload] = useState(null);
  const [groupPayload, setContactGroupPayload] = useState({});

  const {
    contactGroups,
    uploadSuccess,
    uploadingContacts,
    createdContactGroup,
    creatingContactGroup,
    serviceTypes,
  } = useSelector((state) => {
    return {
      contactGroups: state?.contacts?.contactGroups?.map((d) => ({
        ...d,
        value: d?._id,
        label: d?.name,
      })),
      fetching: state?.contacts?.fetchingContactGroups,
      uploadSuccess: state.contacts.uploadSuccess,
      uploadingContacts: state.contacts.uploadingContacts,
      errorUploadingContacts: state.contacts.errorUploadingContacts,

      listCampaigns: state?.campaign?.listCampaigns?.map((d) => ({
        ...d,
        value: d?._id,
        label: d?.campaignName,
      })),
      fetchingCampaigns: state.campaign.fetchingCampaigns,
      errorFetchingCampaign: state.campaign.errorFetchingCampaign,

      createdContactGroup: state.contacts.createContactGroupSuccess,
      creatingContactGroup: state.contacts.creatingContactGroup,
      createContactGroupError: state.contacts.createContactGroupError,

      serviceTypes: state.services.serviceTypes,
      fetchingServiceTypes: state.services.fetchingServiceTypes,
    };
  });

  const dispatch = useDispatch();

  const fetchServiceTypes = useCallback(
    (params = {}) => {
      dispatch(fetchServiceTypesAction(params));
    },
    [dispatch]
  );

  const fetchContactGroups = useCallback(
    (params = {}) => {
      dispatch(fetchContactGroupsAction(params));
    },
    [dispatch]
  );

  const uploadContacts = useCallback(
    (params = {}) => {
      dispatch(uploadContactsAction(params));
    },
    [dispatch]
  );

  const fetchCampaigns = useCallback(
    (params = {}) => {
      dispatch(fetchCampaignsAction(params));
    },
    [dispatch]
  );

  const createNewContactGroup = useCallback(
    (params = {}) => {
      dispatch(createContactGroupAction(params));
    },
    [dispatch]
  );

  const handleUpload = (e) => {
    e.preventDefault();
    uploadContacts(uploadData);
  };

  const handleFileUpload = ({ name, value }) => {
    if (value) {
      const newformData = new FormData();
      newformData.append('group', payload?.group || null);
      newformData.append('contacts', value[0]);

      setUploadData(newformData);
      return;
    }
    setUploadData(null);
  };

  const handleNewContactUpload = ({ name, value }) => {
    if (value) {
      setNewGroupUpload(value[0]);
      return;
    }
    setNewGroupUpload(null);
  };

  const handleCreateGroupAction = (e) => {
    e.preventDefault();

    const newformData = new FormData();
    newformData.append('category', groupPayload?.category);
    newformData.append('name', groupPayload?.name);
    newformData.append('contacts', newGroupUpload);

    createNewContactGroup(newformData);
  };

  const handleSelect = ({ name, value }) => {
    setPayload({
      ...payload,
      [name]: value,
    });
  };

  const handleCreateGroup = ({ name, value }) => {
    setContactGroupPayload({
      ...groupPayload,
      [name]: value,
    });
  };

  useEffect(() => {
    fetchContactGroups();
    fetchCampaigns();
    fetchServiceTypes();
  }, []);

  useEffect(() => {
    if (uploadSuccess) {
      onClose();
      Toast.success('Contacts Uploaded successfully');
    }
  }, [uploadSuccess]);

  useEffect(() => {
    onClose();
    setNewGroup(false);
    if (Object.keys(createdContactGroup).length) {
      setPayload({
        ...payload,
        group: createdContactGroup._id,
      });
      // window.location.reload();
    }
  }, [createdContactGroup]);

  return (
    <>
      <Modal
        show={show}
        onClose={onClose}
        title={newGroup ? 'Create New Group' : 'Add Contacts to Phonebook'}
      >
        <form style={{ marginTop: 20 }}>
          <Row>
            <Col>
              {newGroup && (
                <>
                  <Input
                    placeholder="Group Name"
                    label="Group Name"
                    name="name"
                    onChange={handleCreateGroup}
                    value={groupPayload.name}
                  />

                  <InputSelect
                    placeholder="Select A Category"
                    label="Select Category"
                    onChange={handleCreateGroup}
                    value={groupPayload.category}
                    name="category"
                    options={serviceTypes.filter(
                      (dt) =>
                        dt.serviceTypeName === 'Airtime' ||
                        dt.serviceTypeName === 'Data' ||
                        dt.serviceTypeName === 'SMS'
                    )}
                  />

                  <Upload
                    icon="upload"
                    text="Upload Contacts"
                    hint={
                      <>
                        (<div style={{ fontSize: '12px' }}>csv file formats only</div>)
                      </>
                    }
                    name="file"
                    onChange={handleNewContactUpload}
                    show
                  />
                </>
              )}

              {!newGroup && (
                <Box>
                  <InputSelect
                    placeholder="Select Existing Group"
                    label="Select Group"
                    onChange={handleSelect}
                    defaultValue={formData.group}
                    name="group"
                    options={contactGroups}
                    hint={
                      <>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span>Did not find group?</span>{' '}
                          <span
                            className="link"
                            style={{ fontWeight: 500, marginLeft: 5 }}
                            onClick={() => setNewGroup(true)}
                          >
                            Create New Group
                          </span>
                        </div>
                      </>
                    }
                  />
                  <Box>
                    <Upload
                      icon="upload"
                      text="Upload Contacts"
                      hint={
                        <>
                          (<div style={{ fontSize: '12px' }}>csv file formats only</div>)
                        </>
                      }
                      name="file"
                      onChange={handleFileUpload}
                      show
                    />
                  </Box>
                </Box>
              )}
            </Col>
          </Row>

          <Row>
            {newGroup && (
              <Col md={10}>
                <Flex justifyContent="space-between">
                  <Button
                    value="Back"
                    margin={[10]}
                    center
                    size="lg"
                    width={150}
                    group="outline"
                    onClick={() => setNewGroup(false)}
                  />

                  {/* {Object.keys(createdContactGroup).length > 0 && ( */}
                  <Button
                    value="Create Group"
                    onClick={handleCreateGroupAction}
                    loading={creatingContactGroup}
                    margin={[10]}
                    center
                    size="lg"
                    width={150}
                  />
                  {/* )} */}

                  {/* {Boolean(Object.keys(createdContactGroup).length) && (
                    <Button
                      value="Upload Contacts"
                      onClick={handleUpload}
                      loading={uploadingContacts}
                      margin={[10]}
                      center
                      size="lg"
                      width={200}
                    />
                  )} */}
                </Flex>
              </Col>
            )}

            {!newGroup && (
              <Col>
                {uploadData && (
                  <Button
                    value="Add"
                    onClick={handleUpload}
                    loading={uploadingContacts}
                    margin={[10]}
                    center
                    size="lg"
                    width={200}
                  />
                )}
              </Col>
            )}
          </Row>
        </form>
      </Modal>
    </>
  );
};

export default CreateContact;
