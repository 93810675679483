import React from 'react';
import { Switch as MSwitch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 35,
    height: 18,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 3,
    color: theme.palette.common.white,
    '&$checked': {
      transform: 'translateX(18px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#509FEF',
        borderColor: '#509FEF',
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#828282',
  },
  checked: {},
}))(MSwitch);

const Switch = ({
  label,
  disabled = false,
  variant = 'outlined',
  hint,
  value,
  name,
  defaultValue,
  onChange,
  validate,
  radio = true,
  padding = 0,
  color = 'primary',
  ...rest
}) => {
  const handleOnChange = (e) => {
    const { name: n, checked: v } = e.target;
    if (onChange) {
      onChange({ name: n, value: v });
    }
  };

  return (
    <div>
      <AntSwitch
        disabled={disabled}
        inputProps={{ 'aria-label': label, 'aria-describedby': label }}
        style={{ margin: 0 }}
        // color={color}
        checked={value}
        onChange={handleOnChange}
        name={name}
        required={!!validate}
        label={label}
      />
    </div>
  );
};

export default Switch;
