import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toast, { toast } from 'react-hot-toast';
import { Row, Col } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button } from '../../../components/ui';
import { PasswordInput } from '../../../components/ui/inputs';
import { APIResetPassword } from '../../../api/backendRoutes';
import { ROUTES } from '../../../components/router/routes/routes';
import { errorParser } from '../../../utilities/helper';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  typography: {
    lineHeight: '23.92px',
    fontWeight: 400,
    fontSize: '16px',
    color: '#505050',
    '& span': {
      color: '#509FEF',
      marginLeft: '7px',
      cursor: 'pointer',
    },
  },
});

const ResetPassword = () => {
  const classes = useStyles();
  const history = useHistory();
  const { token } = useParams();

  const [formData, setFormData] = useState({ password: '', confirmPassword: '', fetching: false });

  const handleResetPassword = async (e) => {
    e.preventDefault();

    const { password, confirmPassword } = formData;

    if (password === confirmPassword) {
      try {
        setFormData((d) => ({ ...d, fetching: true }));
        const resp = await APIResetPassword({ password, token });
        if (resp.data.message === 'ok') {
          toast.success('Password Changed Successfully');
          history.push(ROUTES.SIGN_IN.path);
        } else {
          throw resp?.data?.error || 'Server Error';
        }
      } catch (error) {
        toast.error(errorParser(error) || 'error');
      }
      setFormData((v) => ({ ...v, fetching: false }));
    } else {
      Toast.error('Passwords do not match');
    }
  };

  const handleInputChange = ({ name, value }) => {
    setFormData((v) => ({ ...v, [name]: value }));
  };

  return (
    <>
      <div style={{ fontSize: 16 }}>Enter new password for your account</div>
      <form className={classes.root} style={{ marginTop: 40 }} onSubmit={handleResetPassword}>
        <Row>
          <Col>
            <PasswordInput
              label="New Password"
              placeholder="Enter New Password"
              name="password"
              onChange={handleInputChange}
              defaultValue={formData.password}
              validate="required|password"
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <PasswordInput
              label="Confirm Password"
              placeholder="Confirm New Password"
              name="confirmPassword"
              onChange={handleInputChange}
              defaultValue={formData.confirmPassword}
              validate="required|password"
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Button
              value="Reset Password"
              variant="contained"
              loading={formData.fetching}
              type="submit"
            />
          </Col>
          <Col>
            <p className="my-3">
              Back to Login?
              <span>
                <Link to={ROUTES.SIGN_IN.path}> Login</Link>
              </span>
            </p>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default ResetPassword;
