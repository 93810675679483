import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { Button, Loader, Modal } from '../ui';
import Header from './Header';
import SideMenu from './SideMenu';
import { AppContext } from '../../utilities/context';
import { fetchUserData, storeUserData } from '../../storage/sessionStorage';
import { OTPInput } from '../ui/inputs';
import { APIGenerateEmailToken, APIverifyEmail } from '../../api/backendRoutes';
import { fetchWallet } from '../../redux/actions/wallet';
import { fetchServices } from '../../redux/actions/services';
import { errorParser } from '../../utilities/helper';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  height: 100%;
  background: #ffffff;
  position: relative;

  main {
    flex: 1 100%;
    height: 100vh;
    overflow: hidden;

    > section {
      padding: ${isMobile ? '20px' : '20px 20px'};
      height: calc(100vh - 56px);
      overflow: auto;
      background: #e5e5e542;
    }
    .dashboard__container {
      background: #f2f2f2;
      // background: rgba(254, 254, 254, 0.96);
    }
  }
`;

const Container = ({ children, showHeader = false, showSideMenu = false, meta = {} }) => {
  // const { showSideMenu: mobileShow, toggleSideMenu } = useContext(AppContext);
  const { dataLoading } = useContext(AppContext);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const dispatch = useDispatch();
  const { companyId } = fetchUserData();
  useEffect(() => {
    dispatch(fetchWallet());
    dispatch(
      fetchServices({
        companyId,
        isActive: true,
      })
    );
  }, []);
  return (
    <>
      {dataLoading ? (
        <Loader />
      ) : (
        <>
          <Helmet>
            <title>BlueInTouch</title>
            <meta name="description" content={meta.description} />
          </Helmet>
          <StyledContainer>
            {showSideMenu && <SideMenu />}
            <main aria-hidden>
              {showHeader && <Header {...meta} />}
              <section className="dashboard__container scrollbar">{children}</section>
            </main>
          </StyledContainer>
          <VerifyToast setShowOtpForm={setShowOtpForm} />
          <VerifyEmailModal show={showOtpForm} onClose={() => setShowOtpForm(false)} />
        </>
      )}
    </>
  );
};

export default Container;

const VerifyToast = ({ setShowOtpForm }) => {
  const { isEmailVerified } = fetchUserData();
  const generateToken = async () => {
    await APIGenerateEmailToken();
    setShowOtpForm(true);
  };
  return (
    !isEmailVerified && (
      <VerifyToast.Wrapper className="shadow">
        <span className="text-danger">
          Your email has not been verified!
          <button className="btn btn-info ml-3" type="button" onClick={generateToken}>
            Verify
          </button>
        </span>
      </VerifyToast.Wrapper>
    )
  );
};

VerifyToast.Wrapper = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 10;
  padding: 20px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.824);
`;

const VerifyEmailModal = ({ show, onClose }) => {
  const [OTP, setOTP] = useState('');

  const handleVerification = async (e) => {
    e.preventDefault();
    try {
      const resp = await APIverifyEmail({
        token: OTP,
      });

      if (resp.data.success) {
        const { message } = resp.data;
        // const user = fetchUserData();
        localStorage.setItem('token', resp.data.data.token);
        localStorage.setItem('expiration', Date.now() + 10800000);
        storeUserData({
          ...resp.data.data,
          token_exp: Date.now() + 10800000,
        });
        onClose();
        toast.success(message);
      } else {
        throw resp?.data?.error;
      }
    } catch (error) {
      toast.error(errorParser(error));
    }
  };
  return (
    <Modal
      show={show}
      onClose={onClose}
      title="Enter the OTP sent to your email"
      goBack={false}
      goBackMethod={() => {}}
    >
      <div className="pt-4 ">
        <form onSubmit={handleVerification} className="col-md-10 mx-auto">
          <OTPInput
            required
            autoFocus
            // isNumberInput
            length={6}
            className="otpContainer"
            inputClassName="otpInput"
            onChangeOTP={(otp) => setOTP(otp)}
          />
          <div className="col-12 mt-4 pt-4 px-auto">
            <Button value="Verify" type="submit" width="100%" />
          </div>
        </form>
      </div>
    </Modal>
  );
};
