import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  totalCampaign: 0,
  fetchingTotalCampaign: false,
  errorTotalCampaign: false,

  totalPendingCampaign: 0,
  fetchingPendingCampaign: false,
  errorPendingCampaign: false,

  listCampaigns: [],
  listCampaignsMeta: {},
  fetchingCampaigns: false,
  errorFetchingCampaign: false,

  campaign: [],
  runningCampaign: false,
  errorRunningCampaign: false,
  success: false,

  creatingSmsCampaign: false,
  errorCreatingSmsCampaign: false,
  successCreatingSmsCampaign: false,

  creatingAirtimeCampaign: false,
  errorCreatingAirtimeCampaign: false,
  successCreatingAirtimeCampaign: false,

  creatingSchedulledSmsCampaign: false,
  successCreatingSchedulledSmsCampaign: false,
  errorCreatingSchedulledSmsCampaign: false,

  creatingSchedulledAirtimeCampaign: false,
  successCreatingSchedulledAirtimeCampaign: false,
  errorCreatingSchedulledAirtimeCampaign: false,

  creatingSchedulledDataCampaign: false,
  successCreatingSchedulledDataCampaign: false,
  errorCreatingSchedulledDataCampaign: false,

  creatingDataCampaign: false,
  errorCreatingDataCampaign: false,
  successCreatingDataCampaign: false,

  scheduledCampaign: [],
  errorFetchingScheduled: false,
  fetchingScheduled: false,
};

export const campaignSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    fetchTotalCampaignSuccessReducer: (state, { payload }) => {
      state.totalCampaign = payload?.data?.total_campaigns;
      state.fetchingTotalCampaign = false;
    },
    fetchingTotalCampaignReducer: (state) => {
      state.totalCampaign = 0;
      state.fetchingTotalCampaign = true;
      state.errorTotalCampaign = false;
    },
    fetchingTotalCampaignErrorReducer: (state) => {
      state.totalCampaign = 0;
      state.fetchingTotalCampaign = false;
      state.errorTotalCampaign = true;
    },

    fetchPendingCampaignSuccessReducer: (state, { payload }) => {
      state.totalPendingCampaign = payload?.data?.pending_campaigns;
      state.fetchingPendingCampaign = false;
    },
    fetchingPendingCampaignReducer: (state) => {
      state.totalPendingCampaign = 0;
      state.fetchingPendingCampaign = true;
      state.errorPendingCampaign = false;
    },
    fetchingPendingCampaignErrorReducer: (state) => {
      state.totalPendingCampaign = 0;
      state.fetchingPendingCampaign = false;
      state.errorPendingCampaign = true;
    },

    fetchCampaignsSuccessReducer: (state, { payload }) => {
      state.listCampaigns = payload.data;
      state.listCampaignsMeta = { limit: payload.limit, page: payload.page, total: payload.total };
      state.fetchingCampaigns = false;
    },
    fetchingCampaignsReducer: (state) => {
      state.listCampaigns = [];
      state.fetchingCampaigns = true;
      state.errorFetchingCampaign = false;
    },
    fetchingCampaignsErrorReducer: (state) => {
      state.listCampaigns = [];
      state.fetchingCampaigns = false;
      state.errorFetchingCampaign = true;
    },

    // run compaign
    runCampaignSuccessReducer: (state, { payload }) => {
      state.campaign = payload?.data;
      state.success = true;
      state.runningCampaign = false;
      state.errorRunningCampaign = false;
    },
    runningCampaignReducer: (state) => {
      state.campaign = [];
      state.runningCampaign = true;
      state.errorRunningCampaign = false;
    },
    errorRunningCampaignReducer: (state) => {
      state.campaign = [];
      state.runningCampaign = false;
      state.errorRunningCampaign = true;
    },

    createSmsCampaignReducer: (state, { payload }) => {
      state.creatingSmsCampaign = false;
      state.errorCreatingSmsCampaign = false;
      state.successCreatingSmsCampaign = true;
      state.smsPayload = payload;
    },
    creatingSmsCampaignReducer: (state) => {
      state.creatingSmsCampaign = true;
      state.errorCreatingSmsCampaign = false;
      state.successCreatingSmsCampaign = false;
    },
    errorCreatingSmsCampaignReducer: (state, { payload }) => {
      state.creatingSmsCampaign = false;
      state.errorCreatingSmsCampaign = payload;
      state.successCreatingSmsCampaign = false;
    },

    createAirtimeCampaignReducer: (state, { payload }) => {
      state.creatingAirtimeCampaign = false;
      state.errorCreatingAirtimeCampaign = false;
      state.successCreatingAirtimeCampaign = true;
    },
    creatingAirtimeCampaignReducer: (state) => {
      state.creatingAirtimeCampaign = true;
      state.errorCreatingAirtimeCampaign = false;
      state.successCreatingAirtimeCampaign = false;
    },
    errorCreatingAirtimeCampaignReducer: (state, { payload }) => {
      state.creatingAirtimeCampaign = false;
      state.errorCreatingAirtimeCampaign = payload;
      state.successCreatingAirtimeCampaign = false;
    },

    createDataCampaignReducer: (state, { payload }) => {
      state.creatingDataCampaign = false;
      state.errorCreatingDataCampaign = false;
      state.successCreatingDataCampaign = true;
    },
    creatingDataCampaignReducer: (state) => {
      state.creatingDataCampaign = true;
      state.errorCreatingDataCampaign = false;
      state.successCreatingDataCampaign = false;
    },
    errorCreatingDataCampaignReducer: (state, { payload }) => {
      state.creatingDataCampaign = false;
      state.errorCreatingDataCampaign = payload;
      state.successCreatingDataCampaign = false;
    },

    createSchedulledSmsCampaignReducer: (state, { payload }) => {
      state.creatingSchedulledSmsCampaign = false;
      state.successCreatingSchedulledSmsCampaign = true;
      state.errorCreatingSchedulledSmsCampaign = false;
    },
    creatingSchedulledSmsCampaignReducer: (state) => {
      state.creatingSchedulledSmsCampaign = true;
      state.successCreatingSchedulledSmsCampaign = false;
      state.errorCreatingSchedulledSmsCampaign = false;
    },
    errorCreatingSchedulledSmsCampaignReducer: (state) => {
      state.creatingSchedulledSmsCampaign = false;
      state.errorCreatingSchedulledSmsCampaign = true;
      state.successCreatingSchedulledSmsCampaign = false;
    },

    createSchedulledAirtimeCampaignReducer: (state, { payload }) => {
      state.creatingSchedulledAirtimeCampaign = false;
      state.successCreatingSchedulledAirtimeCampaign = true;
      state.errorCreatingSchedulledAirtimeCampaign = false;
    },
    creatingSchedulledAirtimeCampaignReducer: (state) => {
      state.creatingSchedulledAirtimeCampaign = true;
      state.errorCreatingSchedulledAirtimeCampaign = false;
      state.successCreatingSchedulledAirtimeCampaign = false;
    },
    errorCreatingSchedulledAirtimeCampaignReducer: (state, { payload }) => {
      state.creatingSchedulledAirtimeCampaign = false;
      state.errorCreatingSchedulledAirtimeCampaign = payload;
      state.successCreatingSchedulledAirtimeCampaign = false;
    },
    createSchedulledDataCampaignReducer: (state, { payload }) => {
      state.creatingSchedulledDataCampaign = false;
      state.successCreatingSchedulledDataCampaign = true;
      state.errorCreatingSchedulledDataCampaign = false;
    },
    creatingSchedulledDataCampaignReducer: (state) => {
      state.creatingSchedulledDataCampaign = true;
      state.errorCreatingSchedulledDataCampaign = false;
      state.successCreatingSchedulledDataCampaign = false;
    },
    errorCreatingSchedulledDataCampaignReducer: (state, { payload }) => {
      state.creatingSchedulledDataCampaign = false;
      state.errorCreatingSchedulledDataCampaign = payload;
      state.successCreatingSchedulledDataCampaign = false;
    },
    // scheduled campaign List
    fetchScheduledCampaignReducer: (state, { payload }) => {
      state.scheduledCampaign = payload.data;
      state.errorFetchingScheduled = false;
      state.fetchingScheduled = false;
    },
    errorFetchingScheduledCampaignReducer: (state) => {
      state.scheduledCampaign = [];
      state.errorFetchingScheduled = true;
      state.fetchingScheduled = false;
    },
    fetchingScheduledCampaignReducer: (state) => {
      state.scheduledCampaign = [];
      state.errorFetchingScheduled = false;
      state.fetchingScheduled = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  fetchTotalCampaignSuccessReducer,
  fetchingTotalCampaignReducer,
  fetchingTotalCampaignErrorReducer,

  fetchPendingCampaignSuccessReducer,
  fetchingPendingCampaignReducer,
  fetchingPendingCampaignErrorReducer,

  fetchCampaignsSuccessReducer,
  fetchingCampaignsReducer,
  fetchingCampaignsErrorReducer,

  runCampaignSuccessReducer,
  runningCampaignReducer,
  errorRunningCampaignReducer,

  createSmsCampaignReducer,
  creatingSmsCampaignReducer,
  errorCreatingSmsCampaignReducer,

  createAirtimeCampaignReducer,
  creatingAirtimeCampaignReducer,
  errorCreatingAirtimeCampaignReducer,

  createSchedulledSmsCampaignReducer,
  creatingSchedulledSmsCampaignReducer,
  errorCreatingSchedulledSmsCampaignReducer,

  createSchedulledAirtimeCampaignReducer,
  creatingSchedulledAirtimeCampaignReducer,
  errorCreatingSchedulledAirtimeCampaignReducer,

  createSchedulledDataCampaignReducer,
  creatingSchedulledDataCampaignReducer,
  errorCreatingSchedulledDataCampaignReducer,

  createDataCampaignReducer,
  creatingDataCampaignReducer,
  errorCreatingDataCampaignReducer,
  fetchScheduledCampaignReducer,
  errorFetchingScheduledCampaignReducer,
  fetchingScheduledCampaignReducer,
} = campaignSlice.actions;

export default campaignSlice.reducer;
