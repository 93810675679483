import React, { useState } from 'react';
import { Button, Tabs } from '../../components/ui';
import GroupedContacts from './GroupedContacts';
import UngroupedContacts from './UngroupedContacts';
import CreateContact from './components/CreateContact';
import EditContact from './components/EditContact';

const Contacts = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [currentContactGroup, setCurrentContactGroup] = useState({});
  const [refresh, setRefresh] = useState(false);

  return (
    <>
      {showEdit && (
        <EditContact
          currentContactGroup={currentContactGroup}
          setShowEdit={setShowEdit}
        />
      )}
      {!showEdit && (
        <div className="contact-wrapper">
          <div className="heading">
            <div className="title">Add, Edit or Delete Contacts</div>

            <Button
              group="secondary round"
              value="Add Grouped Contacts"
              icon="plus-circle"
              onClick={() => setShowCreateModal(true)}
            />
          </div>

          <Tabs
            header={[
              { key: 'grouped', label: 'Grouped Contacts' },
              {
                key: 'ungrouped',
                label: 'Ungrouped',
              },
            ]}
            data={{
              grouped: (
                <GroupedContacts
                  setCurrentContactGroup={setCurrentContactGroup}
                  setShowEdit={setShowEdit}
                  refresh={refresh}
                />
              ),
              ungrouped: <UngroupedContacts refresh={refresh} />,
            }}
          />
        </div>
      )}
      <CreateContact
        refresh={refresh}
        setRefresh={setRefresh}
        show={showCreateModal}
        onClose={() => setShowCreateModal(false)}
      />
    </>
  );
};

export default Contacts;
