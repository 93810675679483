import React from 'react';
import Icon from './Icon';


const Back = ({ icon = 'caretleft', onClick, value }) => {
  const goBack = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <div className="back-wrapper" onClick={goBack}>
      {icon && <Icon icon={icon} width={8} margin={[0, 10, 0, 0]} />}{' '}
      {value && <span>{value}</span>}
    </div>
  );
};

export default Back;
