import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  payload: {
    phone_numbers: [],
    amount: 0,
  },
  buyingAirtime: false,
  success: false,
  error: false,
};

export const reportSlice = createSlice({
  name: 'airtime',
  initialState,
  reducers: {
    buyAirTimeSuccessReducer: (state, { payload }) => {
      state.buyingAirtime = false;
      state.success = true;
      state.payload.phone_numbers = payload;
    },
    buyingAirTimeReducer: (state) => {
      state.buyingAirtime = true;
      state.success = false;
      state.payload.phone_numbers = [];
    },
    buyingAirTimeErrorReducer: (state) => {
      state.buyingAirtime = false;
      state.success = false;
      state.payload.phone_numbers = [];
      state.error = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const { buyAirTimeSuccessReducer, buyingAirTimeReducer, buyingAirTimeErrorReducer } =
  reportSlice.actions;

export default reportSlice.reducer;
