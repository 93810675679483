import Toast from 'react-hot-toast';
import {
  APIcampaignCount,
  APIcreateAirtimeCampaign,
  APIcreateDataCampaign,
  APIcreateSchedulledAirtimeCampaign,
  APIcreateSchedulledDataCampaign,
  APIcreateSchedulledSMSCampaign,
  APIcreateSMSCampaign,
  APIgetCampaigns,
  APIlistScheduledCampaign,
  APIrunCampaign,
} from '../../api/backendRoutes';
import { errorParser } from '../../utilities/helper';
import {
  fetchTotalCampaignSuccessReducer,
  fetchingTotalCampaignReducer,
  fetchingTotalCampaignErrorReducer,
  fetchPendingCampaignSuccessReducer,
  fetchingPendingCampaignReducer,
  fetchingPendingCampaignErrorReducer,
  fetchCampaignsSuccessReducer,
  fetchingCampaignsReducer,
  fetchingCampaignsErrorReducer,
  runCampaignSuccessReducer,
  runningCampaignReducer,
  errorRunningCampaignReducer,
  createSmsCampaignReducer,
  creatingSmsCampaignReducer,
  errorCreatingSmsCampaignReducer,
  createAirtimeCampaignReducer,
  creatingAirtimeCampaignReducer,
  errorCreatingAirtimeCampaignReducer,
  createDataCampaignReducer,
  creatingDataCampaignReducer,
  errorCreatingDataCampaignReducer,
  fetchScheduledCampaignReducer,
  fetchingScheduledCampaignReducer,
  errorFetchingScheduledCampaignReducer,
  createSchedulledSmsCampaignReducer,
  creatingSchedulledSmsCampaignReducer,
  errorCreatingSchedulledSmsCampaignReducer,
  createSchedulledAirtimeCampaignReducer,
  creatingSchedulledAirtimeCampaignReducer,
  errorCreatingSchedulledAirtimeCampaignReducer,
  createSchedulledDataCampaignReducer,
  creatingSchedulledDataCampaignReducer,
  errorCreatingSchedulledDataCampaignReducer,
} from '../slices/campaign';

export const fetchTotalCampaignAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchingTotalCampaignReducer());
    try {
      const resp = await APIcampaignCount(params);
      if (resp.data.message === 'ok') {
        dispatch(fetchTotalCampaignSuccessReducer(resp.data));
      } else {
        dispatch(fetchingTotalCampaignErrorReducer());
        throw resp?.data?.error;
      }
    } catch (error) {
      // Toast.error(error?.data?.message || 'Server error');
    }
  };

export const fetchTotalPendingCampaignAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchingPendingCampaignReducer());
    try {
      const resp = await APIcampaignCount(params);
      if (resp.data.message === 'ok') {
        dispatch(fetchPendingCampaignSuccessReducer(resp.data));
      } else {
        dispatch(fetchingPendingCampaignErrorReducer());
        throw resp?.data?.error;
      }
    } catch (error) {
      dispatch(fetchingPendingCampaignErrorReducer());
      // Toast.error(error?.data?.message || 'Server error');
    }
  };

export const fetchCampaignsAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchingCampaignsReducer());
    try {
      const resp = await APIgetCampaigns(params);
      if (resp.data.message === 'ok') {
        dispatch(fetchCampaignsSuccessReducer(resp.data));
      } else {
        dispatch(fetchingCampaignsErrorReducer());
        throw resp?.data?.error;
      }
    } catch (error) {
      dispatch(fetchingCampaignsErrorReducer());
      // Toast.error(error?.data?.message || 'Server error');
    }
  };

export const runCampaignAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(runningCampaignReducer());
    try {
      const resp = await APIrunCampaign(params);
      if (resp.data.message === 'ok') {
        dispatch(runCampaignSuccessReducer(resp.data));
      } else {
        dispatch(errorRunningCampaignReducer());
        throw resp?.data?.error;
      }
    } catch (error) {
      dispatch(errorRunningCampaignReducer());
      Toast.error(errorParser(error) || 'Server error');
    }
  };

export const createSmsCampaignAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(creatingSmsCampaignReducer());
    try {
      const resp = await APIcreateSMSCampaign(params);
      if (resp.data.message === 'ok') {
        dispatch(createSmsCampaignReducer(resp.data));
        Toast.success('Bulk Service successfully created.');
      } else {
        dispatch(errorCreatingSmsCampaignReducer(resp?.data?.error));
        throw resp?.data?.error;
      }
    } catch (error) {
      Toast.error(errorParser(error) || 'Server error');
      dispatch(errorCreatingSmsCampaignReducer(errorParser(error) || 'Server error'));
    }
  };

export const createAirtimeCampaignAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(creatingAirtimeCampaignReducer());
    try {
      const resp = await APIcreateAirtimeCampaign(params);
      if (resp.data.message === 'ok') {
        dispatch(createAirtimeCampaignReducer(resp.data));
        Toast.success('Bulk Service successfully created.');
      } else {
        dispatch(errorCreatingAirtimeCampaignReducer(resp?.data?.error));
        throw resp?.data?.error;
      }
    } catch (error) {
      Toast.error(errorParser(error) || 'Server error');
      dispatch(errorCreatingAirtimeCampaignReducer(errorParser(error) || 'Server error'));
    }
  };

export const createDataCampaignAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(creatingDataCampaignReducer());
    try {
      const resp = await APIcreateDataCampaign(params);
      if (resp.data.message === 'ok') {
        dispatch(createDataCampaignReducer(resp.data));
        Toast.success('Bulk Service successfully created.');
      } else {
        dispatch(errorCreatingDataCampaignReducer(resp?.data?.error));
        throw resp?.data?.error;
      }
    } catch (error) {
      Toast.error(errorParser(error) || 'Server error');
      errorCreatingSchedulledDataCampaignReducer(errorParser(error) || 'Server error');
    }
  };

export const createSchedulledSMSCampaignAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(creatingSchedulledSmsCampaignReducer());
    try {
      const resp = await APIcreateSchedulledSMSCampaign(params);
      if (resp.data.message === 'ok') {
        dispatch(createSchedulledSmsCampaignReducer(resp.data));
        Toast.success('Bulk Service successfully schedulled.');
      } else {
        dispatch(errorCreatingSchedulledSmsCampaignReducer());
        throw resp?.data?.error;
      }
    } catch (error) {
      Toast.error(errorParser(error) || 'Server error');
      dispatch(errorCreatingSchedulledSmsCampaignReducer());
    }
  };

export const createSchedulledAirtimeCampaignAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(creatingSchedulledAirtimeCampaignReducer());
    try {
      const resp = await APIcreateSchedulledAirtimeCampaign(params);
      if (resp.data.message === 'ok') {
        dispatch(createSchedulledAirtimeCampaignReducer(resp.data));
        Toast.success('Bulk Service successfully created.');
      } else {
        dispatch(errorCreatingSchedulledAirtimeCampaignReducer());
        throw resp?.data?.error;
      }
    } catch (error) {
      Toast.error(errorParser(error) || 'Server error');
      dispatch(errorCreatingSchedulledAirtimeCampaignReducer(errorParser(error) || 'Server error'));
    }
  };

export const createSchedulledDataCampaignAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(creatingSchedulledDataCampaignReducer());
    try {
      const resp = await APIcreateSchedulledDataCampaign(params);
      if (resp.data.message === 'ok') {
        dispatch(createSchedulledDataCampaignReducer(resp.data));
        Toast.success('Bulk Service successfully created.');
      } else {
        dispatch(errorCreatingSchedulledDataCampaignReducer());
        throw resp?.data?.error;
      }
    } catch (error) {
      Toast.error(errorParser(error) || 'Server error');
      dispatch(errorCreatingSchedulledDataCampaignReducer(errorParser(error) || 'Server error'));
    }
  };

export const fetchScheduledCampaignAction = () => async (dispatch) => {
  dispatch(fetchingScheduledCampaignReducer());
  try {
    const resp = await APIlistScheduledCampaign();
    if (resp.data.message === 'ok') {
      dispatch(fetchScheduledCampaignReducer(resp.data));
    } else {
      dispatch(errorFetchingScheduledCampaignReducer());
      throw resp?.data?.error;
    }
  } catch (err) {
    dispatch(errorFetchingScheduledCampaignReducer());
    // Toast.error(err?.data?.message || 'Server error');
  }
};
