import Toast from 'react-hot-toast';
import { APIsmsServiceHandler } from '../../api/backendRoutes';
import { errorParser } from '../../utilities/helper';
import { sendingSmsReducer, sendSmsSuccessReducer, sendingSmsErrorReducer } from '../slices/sms';

export const sendSmsAction =
  (payload = {}) =>
  async (dispatch) => {
    dispatch(sendingSmsReducer());
    try {
      const resp = await APIsmsServiceHandler(payload);
      if (resp.data.message === 'ok') {
        dispatch(sendSmsSuccessReducer(resp.data.data));
        Toast.success('SMS sent successfully');
      } else {
        dispatch(sendingSmsErrorReducer(resp.data));
        throw resp?.data?.error;
      }
    } catch (error) {
      dispatch(sendingSmsErrorReducer());
      Toast.error(errorParser(error) || 'Server error');
    }
  };
