import React, { createContext, useEffect, useMemo, useState } from 'react';
import { APIfetchAccountTypes, APIfetchUserProfile } from '../../api/backendRoutes';
import { fetchUserData } from '../../storage/sessionStorage';

export const UserTypesContext = createContext({});

const UserTypesProvider = ({ children }) => {
  const [userTypes, setUserTypes] = useState([]);
  const [user, setUser] = useState({});
  const [fetchingUser, setFetchingUser] = useState(true);

  const fetchAccountTypes = async () => {
    try {
      const res = await APIfetchAccountTypes();
      setUserTypes(res.data?.data);
    } catch (error) {
      // console.log(error);
    }
  };
  const { token } = fetchUserData();
  const fetchUserProfile = async () => {
    try {
      setFetchingUser(true);
      const res = await APIfetchUserProfile();
      setUser(res?.data?.data || {});
      setFetchingUser(false);
    } catch (error) {
      setFetchingUser(false);
    }
  };

  useEffect(() => {
    fetchAccountTypes();
    fetchUserProfile();
    if (token) fetchUserProfile();
  }, []);

  const value = useMemo(
    () => ({
      userTypes,
      setUserTypes,
      user: {
        user,
        state: {
          fetchingUser,
        },
        setUser,
      },
    }),
    [userTypes, setUserTypes, user, fetchingUser]
  );

  return <UserTypesContext.Provider value={value}>{children}</UserTypesContext.Provider>;
};

export default UserTypesProvider;
