// import Toast from 'react-hot-toast';
import {
  APIfetchMerchantTransactionReports,
  APIgetRecentTransactions,
} from '../../api/backendRoutes';
import {
  fetchTransactionReportSuccessReducer,
  fetchingTransactionReducer,
  fetchingTransactionReportErrorReducer,
  fetchingRecentTransactionReducer,
  fetchRecentTransactioSuccessReducer,
  fetchingRecentTransactionReportErrorReducer,
} from '../slices/transactionReport';

export const fetchTransactionReportAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchingTransactionReducer());
    try {
      const resp = await APIfetchMerchantTransactionReports(params);
      if (resp.data.message === 'ok') {
        dispatch(fetchTransactionReportSuccessReducer(resp.data));
      } else {
        dispatch(fetchingTransactionReportErrorReducer());
        throw resp?.data?.error;
      }
    } catch (error) {
      dispatch(fetchingTransactionReportErrorReducer());
      // Toast.error(error?.data?.message || 'Server error');
    }
  };

export const fetchRecentTransactionsAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchingRecentTransactionReducer());
    try {
      const resp = await APIgetRecentTransactions(params);
      if (resp.data.message === 'ok') {
        dispatch(fetchRecentTransactioSuccessReducer(resp.data));
      } else {
        dispatch(fetchingRecentTransactionReportErrorReducer());
        throw resp?.data?.error;
      }
    } catch (error) {
      dispatch(fetchingRecentTransactionReportErrorReducer());
      // Toast.error(error?.data?.message || 'Server error');
    }
  };
