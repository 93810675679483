import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Logo } from '../../../components/ui';
import { AppContext } from '../../../utilities/context/AppContext';
import AuthenticationLogo from '../../../assets/images/AuthenticationLogo';

const PageDetails = ({ pathname }) => {
  const { partnerInfo } = useContext(AppContext);
  const pageTransition = {
    in: {
      opacity: 1,
      x: 0,
    },
    out: {
      opacity: 0,
      x: '2%',
    },
  };

  return (
    <motion.div
      initial="out"
      animate="in"
      exit="out"
      variants={pageTransition}
      transition={{ type: 'spring', duration: 0.8 }}
      className="page-details"
    >
      <div>
        <Link to="/">
          <Logo src={partnerInfo.logoUrl || '/logo-black.svg'} name="Blusalt" />
        </Link>
        <h1 className="mt-5">
          {pathname === '/auth/signin' ? (
            <span>
              Welcome back,
              <br /> Login to continue
            </span>
          ) : pathname === '/auth/signup' ? (
            <span>
              All-In-One platform for <br /> businesses to access digital
              <br /> services
            </span>
          ) : pathname === '/auth/forgot-password' ? (
            <span>
              Reset <br /> your Password
            </span>
          ) : null}
        </h1>
        <h5>
          {pathname === '/auth/signin'
            ? ``
            : pathname === '/auth/signup'
            ? ' Airtime, Data bundles, Messaging, Bill payments and more across borders'
            : null}
        </h5>
      </div>
      <AuthenticationLogo scheme={partnerInfo} />
    </motion.div>
  );
};

PageDetails.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default PageDetails;
