import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import Image from './Image';


const Avatar = ({ name, src, margin, options = [], width='36',height='36' }) => {
  return (
    <div className="avatar-wrapper" >
      {options.length > 0 ? (
        <NavDropdown
          title={
            <div className="img-wrapper">
              <Image src={src} width={36} height={36} round margin={margin || [0, 10]} />
              {name && <span className='profile'>{name}</span>}
            </div>
          }
        >
          {options.map(({ label, action }, key) => (
            <NavDropdown.Item onClick={() => action()} key={key}>
              {label}
            </NavDropdown.Item>
          ))}
        </NavDropdown>
      ) : (
        <div className="img-wrapper">
          <Image src={src} width={width} height={height} round margin={margin || [0, 10]} />
          {name && <div>{name}</div>}
        </div>
      )}
    </div>
  );
};

export default Avatar;
