import Toast from 'react-hot-toast';
import { APIairtimeServiceHandler } from '../../api/backendRoutes';
import { errorParser } from '../../utilities/helper';
import {
  buyAirTimeSuccessReducer,
  buyingAirTimeReducer,
  buyingAirTimeErrorReducer,
} from '../slices/airtime';

export const buyAirtimeAction =
  (payload = {}) =>
  async (dispatch) => {
    dispatch(buyingAirTimeReducer());
    try {
      const resp = await APIairtimeServiceHandler(payload);
      if (resp.data.message === 'ok') {
        dispatch(buyAirTimeSuccessReducer(resp.data.data));
      } else {
        dispatch(buyingAirTimeErrorReducer(resp.data));
        throw resp?.data?.error;
      }
    } catch (err) {
      dispatch(buyingAirTimeErrorReducer());
      Toast.error(errorParser(err) || 'Server error');
    }
  };
