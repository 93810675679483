import baseAPI from '.';

const qs = (params) => {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');
};

export const APIfetchUserProfile = () => baseAPI.get(`/auth/users/profile`);
export const APIfetchUsersByType = (params = {}) => baseAPI.get(`/auth/users/admin`, params);
export const APIfetchAccountTypes = (params = {}) =>
  baseAPI.get(`/auth/list-account-types`, params);
export const APIcreateNewMerchant = (params = {}) => baseAPI.post(`/auth/create-merchant`, params);
export const APIfetchcostModel = () => baseAPI.get(`/core/list/cost/model`);
export const APIfetchpriceModel = () => baseAPI.get(`/core/list/price/model`);
export const APIcreateProvider = (params = {}) => baseAPI.post(`/auth/create-provider`, params);
export const APIverifyBankDetails = (params = {}) => baseAPI.post(`/auth/verify/bank`, params);
export const APIfetchBankList = () => baseAPI.get(`/auth/list/bank`);
export const APIsignUp = (params = {}) => baseAPI.post(`/auth/create-merchant/signup`, params);
export const APIlogin = (params = {}) => baseAPI.post(`/auth/login`, params);
export const APIforgotPassword = (params = {}) =>
  baseAPI.post(`/auth/forget/password/reset`, params);
export const APIResetPassword = (params = {}) =>
  baseAPI.put(`/auth/forget/password/reset/verify`, params);
export const APIwalletBalance = () => baseAPI.get(`/auth/wallet/balance`);
export const APIverifyOtp = (params = {}) =>
  baseAPI.post(`/auth/account/verification/verify`, params);
export const APIresendOtp = (params = {}) =>
  baseAPI.post(`/auth/account/resend/2fa/code`, params);
export const APIUpdateRole = (params = {}) => baseAPI.put(`/auth/team/update`, params);
export const APIDeleteUser = (params = {}) =>
  baseAPI.delete(`/auth/remove-team-member?${qs(params)}`);
export const APIbuyairtime = (params = {}) => baseAPI.post(`/core/airtimes/buy`, params);
export const APIsendSms = (params = {}) => baseAPI.post(`/service/service/resolver`, params);
export const APIdataOperators = (params = {}) => baseAPI.post(`/core/data/operators`, params);
export const APIdataTopUp = (params = {}) => baseAPI.post(`/core/api-service/data`, params);
export const APIservices = (params = {}) => baseAPI.get(`/core/list/service`, { params });
export const APIserviceTypes = (params = {}) => baseAPI.get(`/core/list/service/type`, params);
export const APIfetchMerchantAuditReports = (params = {}) =>
  baseAPI.get(`/reports/audit-trails?${qs(params)}`);
export const APIfetchMerchantServiceReports = (params = {}) =>
  baseAPI.get(`/reports/service-reports?${qs(params)}`);
export const APIfetchMerchantServiceReportDetail = (params = {}) =>
  baseAPI.get(`/reports/service-reports-details?${qs(params)}`);
export const APIfetchMerchantTransactionReports = (params = {}) =>
  baseAPI.get(`/reports/transactions?${qs(params)}`);
export const APIGetReciept = (params = {}) => baseAPI.get(`/reports/transactions/${params}`);
export const APIfetchBillers = (params) =>
  baseAPI.post(`core/bills/list-biller-category-services`, params);
export const APIvalidateBillers = (params) => baseAPI.post(`/core/bills/billers-validate`, params);
export const APIvalidateBillersCableTv = (params) =>
  baseAPI.post(`/core/bills/validate-cabletv`, params);
export const APIvalidateBillersElectricity = (params) =>
  baseAPI.post(`/core/bills/validate-electricity`, params);
export const APIpayBillers = (params) => baseAPI.post(`/core/bills/billers-purchase`, params);
export const APIserviceHandler = (params = {}) => baseAPI.post(`/core/service/handler`, params);
export const APIairtimeServiceHandler = (params = {}) =>
  baseAPI.post(`/core/api-service/airtime`, params);
export const APIlistCountry = () => baseAPI.get(`/core/list/country`);
export const APIrequestSenderId = (params = {}) => baseAPI.post(`/core/senderid/request`, params);

export const APIcontactsUngrouped = (params = {}) =>
  baseAPI.get(`/core/contacts/ungrouped?${qs(params)}`, params);
export const APIcontactGroups = (params = {}) => baseAPI.get(`/core/contacts/groups`, params);
export const APIcontactsGrouped = (params = {}) =>
  baseAPI.get(`/core/contacts/grouped?${qs(params)}`, params);
export const APIcreateContacts = (body = {}) => baseAPI.post(`/core/contacts/grouped`, body);
export const APIcreateContactGroup = (body = {}) =>
  baseAPI.post(`/core/contacts/create-groups`, body);

export const APIuploadContacts = (formData = {}) =>
  baseAPI.post(`/core/contacts/uploads`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

export const APIcreateSMSCampaign = (formData = {}) =>
  baseAPI.post(`/core/campaigns/create/sms`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

export const APIcreateAirtimeCampaign = (formData = {}) =>
  baseAPI.post(`/core/campaigns/create/airtime`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

export const APIcreateDataCampaign = (formData = {}) =>
  baseAPI.post(`/core/campaigns/create/data`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

export const APIcreateSchedulledSMSCampaign = (formData = {}) =>
  baseAPI.post(`/core/campaigns/create/schedulled/sms`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

export const APIcreateSchedulledAirtimeCampaign = (formData = {}) =>
  baseAPI.post(`/core/campaigns/create/schedulled/airtime`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

export const APIcreateSchedulledDataCampaign = (formData = {}) =>
  baseAPI.post(`/core/campaigns/create/schedulled/data`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

export const APIcampaignCount = (params = {}) =>
  baseAPI.get(`/core/campaigns/count?${qs(params)}`);
export const APIgetCampaigns = (params = {}) => baseAPI.get(`/core/campaigns/list?${qs(params)}`);
export const APIlistSenderId = () => baseAPI.get(`/core/senderid/list/user`);
export const APIfetchRoles = () => baseAPI.get(`/auth/roles`);
export const APIaddTeamMate = (params = {}) => baseAPI.post(`/auth/team-member/add`, params);
export const APIlistTeam = (params = {}) => baseAPI.get(`/auth/team-member/list`, params);
export const APIlistPrices = () => baseAPI.get(`/core/service/price/list`);
export const APIfetchCampaign = (params) => baseAPI.get(`/core/campaigns/${params}`);
export const APIfetchContact = (params) => baseAPI.get(`/core/contacts/campaign/${params}`);

export const APIgetWalletMonthlyAnalytics = (params = {}) =>
  baseAPI.get(`/core/wallet/monthly-analytics`, params);

export const APIgetRecentTransactions = (params = {}) =>
  baseAPI.get(`/reports/transactions/recent`, params);
export const APIgetNotifications = (params = {}) => baseAPI.get(`/core/user-setting`, params);
export const APIupdateNotifications = (params = {}) =>
  baseAPI.put(`/core/update/user-setting`, params);

export const APIgetWalletActivities = (params = {}) =>
  baseAPI.get(`/core/wallet/activities?${qs(params)}`);
export const APIsmsServiceHandler = (payload = {}) =>
  baseAPI.post(`/core/api-service/sms`, payload);
export const APIrunCampaign = (params = {}) => baseAPI.post(`/campaign/create`, params);
export const APIfetchOperator = (params = {}) =>
  baseAPI.post(`/core/data/operators/detect`, params);
export const APIscheduleCampaign = (params = {}) =>
  baseAPI.post(`/campaign/create/schedule`, params);

export const APIscheduleService = (params = {}) =>
  baseAPI.post(`/core/campaign/service/create`, params);

export const APIreadFile = (params = {}) => baseAPI.post(`/core/campaign/csv/file/reader`, params);
export const APIchangePassword = (params = {}) =>
  baseAPI.patch(`/auth/change/password/reset`, params);

export const APIlistScheduledCampaign = () => baseAPI.get(`/core/campaigns/list/scheduled`);

export const APIscheduleAirtimeService = (params = {}) =>
  baseAPI.post(`/core/api-service/create/schedulled/airtime`, params);

export const APIscheduleDataService = (params = {}) =>
  baseAPI.post(`/core/api-service/create/schedulled/data`, params);

export const APIscheduleSmsService = (params = {}) =>
  baseAPI.post(`/core/api-service/create/schedulled/sms`, params);
export const APIGenerateEmailToken = (params = {}) =>
  baseAPI.post(`/auth/account/email/generate-token`, params);
export const APIverifyEmail = (params = {}) =>
  baseAPI.post(`/auth/account/verification/verify-email`, params);
export const APISendTransactionOTP = (params = {}) => baseAPI.post(`/auth/otp/checkout/send-otp`, params);
export const APIverifyTransactionOTP = (params = {}) =>
  baseAPI.post(`/auth/otp/checkout/verify-otp`, params);
export const APITwoFactorVerify = (params = {}) =>
  baseAPI.post(`/auth/account/verification/two-fa`, params);
export const APIDisable2FA = (params = {}) =>
  baseAPI.post(
    `/auth/account/verification/disable-two-fa
`,
    params
  );
export const APIgetCompanyDetails = (params = {}) =>
  baseAPI.get(`auth/companies-name/fetch`, { params });
export const APIgetCompanyDetailsByDomain = (params = {}) =>
  baseAPI.get(`auth/company/domain`, { params });
export const APIdefaultPrice = (params = {}) => baseAPI.post(`/core/get-default-price`, params);
export const APIExportServiceReport = (params = {}) =>
  baseAPI.get(`/reports/service-reports/export`, params);
export const APIExportTransactionReport = (params = {}) =>
  baseAPI.get(`/reports/transactions/export`, params);
export const APIExportAudit = (params = {}) => baseAPI.get(`/reports/audit-trails/export`, params);
export const APIGetTVPlans = (params = {}) =>
  baseAPI.post(`core/bills/billers-cabletv-plans`, params);

// new
export const APIGetBillersCategories = (params = {}) =>
  baseAPI.get(`core/bills/billers-categorires`, params);
export const APIGetBillersCategoryServices = (params = {}) =>
  baseAPI.post(`core/bills/list-biller-category-services`, params);
export const APIPayBillers = (params = {}) => baseAPI.post(`/core/bills/pay-billers`, params);
export const APIEditUserData = (params = {}) => baseAPI.put(`/auth/users/profile/edit`, params);
export const APIListInternetplans = (params = {}) =>
  baseAPI.post(`core/bills/list-internet-plans`, params);
export const APIDeativateUserAccount = (params = {}) =>
  baseAPI.put(`/auth/users/profile/deactivate`, params);
export const APIUpdateRecurringBulk = (id, params) =>
  baseAPI.put(`/core/campaigns/schedulled/${id}`, params);
export const APIAddContact = (params) => baseAPI.post(`/core/contacts/create`, params);
export const APIUpdateContact = (payload, id) => baseAPI.patch(`/core/contacts/${id}`, payload);
export const APIDeleteContact = (id) => baseAPI.delete(`/core/contacts/${id}`);
export const APIPriceTable = ({ params, id }) =>
  baseAPI.get(`/core/list/price/model/${id}?${qs(params)}`);
export const API2FA = (params) => baseAPI.post(`/auth/users/set2fa`, params);
export const APIgetBusinessType = (params = {}) => baseAPI.get(`/auth/list/business/type`);
export const APIGetSMSPricing = (params = {}) =>
  baseAPI.post(`core/price/model/sms-prices`, params);

export const APIgetCompanyProviders = (params = {}) => baseAPI.get(`auth/companies`);

export const APIGetPaymentMethods = (params = {}) => baseAPI.get(`/auth/wallet/payment-methods`);
export const APIAddCard = (params = {}) => baseAPI.post(`/auth/wallet/payment-methods`, params);
