import React, { useCallback, useEffect, useState } from 'react';
import Moment from 'react-moment';
import Toast from 'react-hot-toast';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Modal, Button } from '../../components/ui';
import { fetchUngroupedContactsAction } from '../../redux/actions/contacts';
import { errorParser } from '../../utilities/helper';
import { APIDeleteContact } from '../../api/backendRoutes';

const UngroupedContacts = ({ refresh }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ phoneNumber: '' });
  const [showDeleteNumber, setShowDeleteNumber] = useState(false);

  const fetchUngroupedContacts = useCallback(
    (params = { limit: 25 }) => {
      dispatch(fetchUngroupedContactsAction(params));
    },
    [dispatch]
  );

  const { contacts, meta, fetching } = useSelector((state) => {
    return {
      contacts: state.contacts.ungroupedContacts,
      fetching: state.contacts.ungroupedFetchingContacts,
      meta: state.contacts.ungroupedContactsMeta,
    };
  });
  useEffect(() => {
    fetchUngroupedContacts();
  }, []);

  useEffect(() => {
    if (refresh) {
      fetchUngroupedContacts();
    }
  }, [refresh]);

  const handleDeleteContact = async (param) => {
    setFormData((v) => ({ ...v, isLoading: true }));
    try {
      const resp = await APIDeleteContact(formData.id);
      if (resp.data.message === 'ok') {
        Toast.success('Contact deleted successfully');
        setFormData((v) => ({ ...v, phoneNumber: '', isLoading: false }));
        setShowDeleteNumber(false);
        window.location.reload();
      } else {
        throw resp?.data?.error;
      }
    } catch (err) {
      setFormData((v) => ({ ...v, isLoading: false }));
      Toast.error(errorParser(err) || 'Unable to update contact');
    }
  };

  return (
    <>
      {showDeleteNumber && (
        <Modal
          show={showDeleteNumber}
          onClose={() => {
            setShowDeleteNumber(false);
            // window.location.reload();
          }}
          title="Delete Number"
        >
          <Row>
            <Col>
              <div className="mb-4">Are you sure you want to delete this number?</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button value="Cancel" center width={80} onClick={() => setShowDeleteNumber(false)} />
            </Col>
            <Col>
              <Button
                value="Delete"
                group="secondary danger"
                center
                width={80}
                onClick={() => handleDeleteContact(formData)}
                loading={formData.isLoading}
              />
            </Col>
          </Row>
        </Modal>
      )}
      
      <Table
        header={[
          { key: 'countryCode', label: 'Country Code' },
          { key: 'phoneNumber', label: 'Phone Number' },
          {
            key: 'createdAt',
            label: 'Date Added',
            render: (date) => <Moment format="D MMM, YYYY  - hh:mma" date={date} />,
          },
          {
            key: 'menu',
            render: (key, datum) => (
              <Table.Actions
                actions={[
                  // { icon: 'eye', title: 'View Contact' },
                  // { icon: 'pencil-alt', title: 'Edit Group' },
                  {
                    icon: 'trash',
                    type: 'danger',
                    title: 'Delete Contact',
                    action: (e) => {
                      setShowDeleteNumber(true);
                      const id = datum._id;

                      setFormData((v) => ({
                        ...v,
                        id,
                      }));
                    },
                  },
                ]}
              />
            ),
          },
        ]}
        data={contacts}
        filter={{
          Date: [
            { value: 'Today', label: 'Today' },
            { value: 'This Week', label: 'This Week' },
            { value: 'This Month', label: 'This Month' },
            { value: 'Last 30 Days', label: 'Last 30 Days' },
            { value: 'This Year', label: 'This Year' },
            { value: 'Period', label: 'Specific Period' },
          ],
        }}
        paginate
        meta={meta}
        handlePagination={fetchUngroupedContacts}
        filterAction={fetchUngroupedContacts}
        loading={fetching}
        showFilter
      />
    </>
  );
};

export default UngroupedContacts;
