import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { Button, Upload, UploadResult, Finalize, Icon } from '../../../components/ui';
import { Input, InputSelect } from '../../../components/ui/inputs';
import SampleSMSCSV from '../../../assets/data/sms.csv';
import WalletBalance from '../../wallet/components/WalletBalance';
import Schedule from '../../services/components/Schedule';
import { fetchSenderIdAction } from '../../../redux/actions/senderId';
import CreateSenderID from './CreateSenderID';
import SuccessModal from '../../wallet/components/PaymentSuccess';
import CheckOut from '../../wallet/components/CheckOut';
import {
  fetchCampaignsAction,
  createSmsCampaignAction,
  createSchedulledSMSCampaignAction,
} from '../../../redux/actions/campaign';
import { fetchWallet } from '../../../redux/actions/wallet';
import { fetchServiceTypesAction } from '../../../redux/actions/services';
import { ROUTES } from '../../../components/router/routes/routes';
import { APIGetSMSPricing, APIreadFile } from '../../../api/backendRoutes';
import { useUpdate } from '../../../utilities/hooks/useUpdate';
import { fetchUserData } from '../../../storage/sessionStorage';
import GroupContactModal from '../../services/components/GroupedContactModal';

const mapStateToProps = ({ services, campaign }) => {
  return {
    serviceTypes: services.serviceTypes,
    creatingSmsCampaign: campaign.creatingSmsCampaign,
    creatingSchedulledSmsCampaign: campaign.creatingSchedulledSmsCampaign,
  };
};

const SMSCampaign = () => {
  const user = fetchUserData();
  const history = useHistory();
  const [showSchedule, setShowSchedule] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const [price, setPrice] = useState(0);
  const [recpt, setrecpt] = useState([]);
  const [showCreateId, setShowCreateId] = useState(false);
  const [showCheckOut, setShowCheckOut] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState({ show: false });
  const [payload, setPayload] = useState({});
  const [err, setErr] = useState([false, '']);
  const [showScheduleForLater, setShowScheduleForLater] = useState(false);
  const [formData, setFormData] = useState({
    campaign_name: null,
    message: '',
    smsRecpt: null,
    senderId: null,
    uploadSuccess: false,
  });
  const dispatch = useDispatch();
  // console.log(recpt);
  const fetchUserWallet = useCallback(() => {
    dispatch(fetchWallet());
  }, [dispatch]);

  const createSmsCampaign = useCallback(
    async (params = {}) => {
      await dispatch(createSmsCampaignAction(params));
    },
    [dispatch]
  );
  // const resetForm = () => {
  //   setFormData(()=>({
  //     campaign_name: null,
  //     message: null,
  //     smsRecpt: null,
  //     senderId: null,
  //     uploadSuccess: false,
  //   }))
  //   formRef.current.reset();
  // };
  const fetchServiceTypes = useCallback(
    (params) => dispatch(fetchServiceTypesAction(params)),
    [dispatch]
  );

  const { serviceTypes, creatingSmsCampaign, creatingSchedulledSmsCampaign, smsPayload } =
    useSelector(mapStateToProps);

  const allCampaign = useCallback(async () => {
    await dispatch(fetchCampaignsAction());
  }, [dispatch]);

  const createSchedulledSMSCampaign = useCallback(
    (params) => dispatch(createSchedulledSMSCampaignAction(params)),
    [dispatch]
  );

  // const { campaignOptions } = useSelector(({ campaign }) => {
  //   const computed = campaign.listCampaigns.map((c) => ({
  //     label: c.campaignName,
  //     value: c._id,
  //   }));
  //   return {
  //     campaignOptions: computed,
  //   };
  // });

  const {
    errorCreatingSmsCampaign,
    successCreatingSmsCampaign,
    successCreatingSchedulledSmsCampaign,
    errorCreatingSchedulledSmsCampaign,
    balance,
    smsMeta,
  } = useSelector((state) => {
    const service = state.services.services;

    return {
      ...state.campaign,
      balance: state.wallet.walletNfo.balance,
      smsMeta: service.filter((s) => s.serviceName === 'SMS')[0],
    };
  });

  const fetchId = useCallback(() => {
    dispatch(fetchSenderIdAction());
  }, [dispatch]);

  const { senderId } = useSelector((state) => {
    const newSenderId = state.senderId.senderId
      .filter((s) => s.approval_status === 'approved')
      .map((s) => ({ value: s.name, label: s.name }));
    return {
      senderId: newSenderId,
    };
  });

  useEffect(() => {
    fetchId();
    allCampaign();
    fetchServiceTypes();
  }, []);

   const updatePrice = async() => {
    const { message } = formData;

    if (message?.length && recpt.length) {
      const resp = await APIGetSMSPricing({
        serviceId: smsMeta._id,
        phoneNumbers: recpt.map((dt) => `+${dt['Country Code']}${dt.Phonenumber}`),
        message,
      });
      setPrice(resp.data.data.totalPrice);
    }
  }

  
  useUpdate(async () => {
    if (formData.smsRecpt) {
      const newPayload = new FormData();
      newPayload.append('campaignFile', formData?.smsRecpt[0]);
      newPayload.append('serviceCategory', 'SMS');
      try {
        const resp = await APIreadFile(newPayload);
        if (resp.data.success) {
          setrecpt(resp.data.data);
          setFormData((f) => ({ ...f, uploadSuccess: true }));
          updatePrice();
        } else {
          throw resp?.data?.error;
        }
      } catch (error) {
        toast.error(error);
      }
    }
  }, [formData.smsRecpt]);

  const handleChange = ({ name, value }) => {
    setErr(() => [false, '']);
    if (name === 'smsRecpt') {
      setFormData((v) => ({ ...v, [name]: value, contactGroupId: '' }));
    }
    setFormData((v) => ({ ...v, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!Object.values(formData).includes(null)) {
      const campaignBody = {
        senderId: formData.senderId,
        message: formData.message,
      };

      const newPayload = new FormData();
      // newPayload.append('campaignScheduleType', 'ScheduleCampaign');
      newPayload.append('campaignScheduleType', 'createCampaign');
      newPayload.append('campaignName', formData.campaign_name);
      newPayload.append('companyId', user.companyId);
      newPayload.append('campaignBody', JSON.stringify(campaignBody));
      newPayload.append('serviceId', smsMeta._id);
      newPayload.append('currencyType', 'NGN');
      newPayload.append('campaignType', 'immediate');
      newPayload.append('serviceType', serviceTypes.find((d) => d?.serviceTypeName === 'SMS')?._id);
      if (formData.smsRecpt !== '') {
        newPayload.append('campaignFile', formData.smsRecpt[0]);
      }
      if (formData.contactGroupId !== '') {
        newPayload.append('contactGroupId', formData.contactGroupId);
      }
      setPayload(newPayload);
      setShowCheckOut(true);
    } else {
      setErr(() => [true, 'please fill all fields']);
    }
  };

  const handleSend = async () => {
    setShowCheckOut(false);
    createSmsCampaign(payload);
  };

  useUpdate(() => {
    if (successCreatingSmsCampaign && price !== 0) {
      // console.log({ smsPayload });
      setShowSuccessModal({ show: true, transactionId: smsPayload?.transactionReference });
      fetchUserWallet();
    } else if (errorCreatingSmsCampaign) {
      setErr(() => [true, errorCreatingSmsCampaign || 'Error running campaign']);
    }
  }, [successCreatingSmsCampaign, errorCreatingSmsCampaign]);

  useUpdate(() => {
    if (successCreatingSchedulledSmsCampaign && price !== 0) {
      setShowScheduleForLater(true);
      setShowSchedule(false);
      fetchUserWallet();
    } else if (errorCreatingSchedulledSmsCampaign) {
      setErr(() => [true, 'Error running campaign']);
    }
  }, [successCreatingSchedulledSmsCampaign]);

  const handleCloseSuccessModal = () => {
    setFormData({ campaign_name: null, message: null, smsRecpt: null, senderId: null });
    setShowSuccessModal({ show: false });
    history.push(ROUTES.ALL_CAMPAIGN.path);
  };

  const handleShowSchedule = () => {
    if (!Object.values(formData).includes(null)) {
      setShowSchedule(true);
    } else {
      setErr(() => [true, 'please fill all fields']);
    }
  };

  const handleSchedule = async (scheduleData) => {
    if (!Object.values(formData).includes(null)) {
      const campaignBody = {
        senderId: formData.senderId,
        message: formData.message,
      };
      const newPayload = new FormData();
      // newPayload.append('campaignScheduleType', 'ScheduleCampaign');
      newPayload.append('campaignScheduleType', 'createCampaign');
      newPayload.append('campaignTypeString', 'schedulled');
      // newPayload.append('campaignFile', formData?.smsRecpt[0]);
      newPayload.append('campaignName', formData?.campaign_name);
      newPayload.append('companyId', user?.companyId);
      newPayload.append('serviceId', smsMeta?._id);
      newPayload.append('campaignBody', JSON.stringify(campaignBody));
      newPayload.append('currencyType', 'NGN');
      newPayload.append('campaignType', 'schedulled');
      newPayload.append('schedulledDate', scheduleData.startDate);
      newPayload.append('serviceType', serviceTypes.find((d) => d?.serviceTypeName === 'SMS')?._id);
      if (formData.smsRecpt !== '') {
        newPayload.append('campaignFile', formData.smsRecpt[0]);
      }
      if (formData.contactGroupId !== '') {
        newPayload.append('contactGroupId', formData.contactGroupId);
      }
      Object.keys(scheduleData).map((key) => {
        if (scheduleData[key] !== undefined) {
          newPayload.append(key, scheduleData[key]);
        }
        return null;
      });

      createSchedulledSMSCampaign(newPayload);
    } else {
      setErr(() => [true, 'please ensure you fill all fields']);
    }
  };

  return (
    <>
      <Row>
        <Col md={8} lg={9} style={{ marginBottom: 20 }}>
          <div className="campaign-wrapper">
            <form onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <Input
                    placeholder="Bulk Name"
                    label="Bulk Name"
                    name="campaign_name"
                    onChange={handleChange}
                    validate="required"
                    defaultValue={formData.campaign_name}
                  />
                </Col>
                {/* {savedCampaign && (
                  <Col md={6}>
                    <InputSelect
                      placeholder="Campaign Name"
                      label="Campaign Name"
                      name="campaign_name"
                      validate="required"
                      // onChange={handleChange}
                      defaultValue={formData.campaign_name}
                      options={campaignOptions}
                      hint={
                        <div>
                          Run a new campaign?
                          <span
                            onClick={() => setSavedCampaign(false)}
                            style={{
                              cursor: 'pointer',
                              fontWeight: 'bold',
                              color: '#17568B',
                            }}
                          >
                            Click Here
                          </span>
                        </div>
                      }
                    />
                  </Col>
                )} */}
                <Col md={6}>
                  <InputSelect
                    placeholder="Select Sender ID"
                    label="Select sender ID"
                    name="senderId"
                    validate="required"
                    onChange={handleChange}
                    defaultValue={formData.senderId}
                    options={senderId}
                    hint={
                      <div>
                        Don&apos;t have an ID?{' '}
                        <span
                          onClick={() => setShowCreateId(true)}
                          style={{
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            color: '#17568B',
                          }}
                        >
                          Create One
                        </span>
                      </div>
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col className="upload-wrapper">
                  <Upload
                    icon="upload"
                    text="Upload Contacts"
                    name="smsRecpt"
                    onChange={handleChange}
                    hint={
                      <>
                        (see&nbsp;
                        <a href={SampleSMSCSV} download>
                          sample file
                        </a>
                        )
                      </>
                    }
                  />
                  <div className="contact" onClick={() => setShowContacts(true)}>
                    <Icon icon="phone" margin={[0, 10, 0, 0]} />
                    Add from Contact
                  </div>
                </Col>
              </Row>
              <Row>
                <Col style={{ display: 'flex' }}>
                  {formData.smsRecpt && (
                    <UploadResult
                      files={formData.smsRecpt}
                      removeFile={() => setFormData((v) => ({ ...v, smsRecpt: null }))}
                      upload={formData.uploadSuccess}
                    />
                  )}
                </Col>
              </Row>
              {formData.smsRecpt === '' && (
                <Row>
                  <Col>
                    <h4>{formData.contactGroupName}</h4>
                    <UploadResult
                      files={formData.smsRecpt}
                      removeFile={() => setFormData((v) => ({ ...v, smsRecpt: null }))}
                      upload={formData.uploadSuccess}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <Input
                    placeholder="Enter your message here"
                    label="Enter message"
                    name="message"
                    validate="required"
                    onChange={handleChange}
                    defaultValue={formData.message}
                    rows={10}
                    onBlur={updatePrice}
                    multiline
                  />
                </Col>
              </Row>
              <Row>
                <Col className="btn-container">
                  <Button value="Send Now" loading={creatingSmsCampaign} type="submit" />
                  <Button value="Schedule for Later" group="outline" onClick={handleShowSchedule} />
                </Col>
              </Row>
              {err[0] && (
                <div
                  style={{
                    fontSize: '14px',
                    color: 'red',
                    marginTop: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {err[1]}
                </div>
              )}
            </form>
          </div>
        </Col>
        <Col md={4} lg={3}>
          <WalletBalance price={price} />
        </Col>
      </Row>
      <SuccessModal
        show={showSuccessModal.show}
        onClose={handleCloseSuccessModal}
        price={price || 0}
        headerText="Bulk SMS launched successfully"
        subtitle="Your bulk SMS is being processed"
      />
      <CheckOut
        show={showCheckOut}
        onClose={() => setShowCheckOut(false)}
        execute={handleSend}
        price={price}
        disableWallet={price > balance}
      />
      <GroupContactModal
        show={showContacts}
        setShow={setShowContacts}
        setrecpt={setrecpt}
        type="SMS"
        setFormData={setFormData}
      />
      <Schedule
        show={showSchedule}
        setShow={setShowSchedule}
        handleSchedule={handleSchedule}
        loading={creatingSchedulledSmsCampaign}
      />
      <Finalize
        show={showScheduleForLater}
        onClose={() => {
          setShowScheduleForLater(false);
          history.push(ROUTES.ALL_CAMPAIGN.path);
        }}
        headerText="Your bulk SMS has been scheduled"
      />
      <CreateSenderID show={showCreateId} onClose={setShowCreateId} />
    </>
  );
};

export default SMSCampaign;
