import axios from 'axios';
import { fetchUserData, fetchTempUserData } from '../storage/sessionStorage';

const baseURLs = [process.env.REACT_APP_BASE_URL, process.env.REACT_APP_DEV_BASE_URL];

const APIs = [];

baseURLs.forEach((url) => {
  // eslint-disable-next-line prefer-const
  let API = axios.create({ baseURL: url });

  API.interceptors.request.use((config) => {
    config.headers = { 'Content-Type': 'application/json' };

    const { token } = fetchUserData();
    const tempUser = fetchTempUserData();
    config.headers.Authorization = `Bearer: ${token ?? tempUser.token}`;
    return config;
  });

  API.interceptors.response.use(
    async (response) => response,
    async (error) => {
      return Promise.reject(error);
    }
  );

  APIs.push(API);
});
const baseAPI = APIs[0];

export const devAPI = APIs[1];
export default baseAPI;
