import React, { useState, useContext } from 'react';
import Countdown from 'react-countdown';
import Toast from 'react-hot-toast';
import { Typography, Grid, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { OTPInput } from '../../../components/ui/inputs';
import { ROUTES } from '../../../components/router/routes/routes';
import { useUpdate } from '../../../utilities/hooks/useUpdate';
import { APIresendOtp, APIverifyOtp } from '../../../api/backendRoutes';
import { fetchTempUserData, fetchUserData, storeUserData } from '../../../storage/sessionStorage';
import { errorParser } from '../../../utilities/helper';
import { Logo } from '../../../components/ui';
import { AppContext } from '../../../utilities/context/AppContext';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `20px`,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: `#F2F2F2`,
    minHeight: '100vh',
  },
  container: {
    backgroundColor: '#fff',
    boxShadow: '0px 5px 55px rgba(0, 0, 0, 0.04)',
    borderRadius: '10px',
    padding: '43px 30px 26px',
    width: 'auto',
  },
  typography: {
    textAlign: 'center',
    lineHeight: '54.6px',
    fontWeight: 700,
    fontSize: '42px',
    color: '#071B2B',
    marginBottom: '44px',
  },
  heading: {
    fontSize: '22px',
    fontWeight: 400,
    lineHeight: '29px',
    textAlign: 'center',
    color: '#505050',
    marginBottom: '40px',
  },
  code: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '21px',
    textAlign: 'left',
    '& span': {
      color: `${theme.palette.primary.main}`,
      cursor: 'pointer',
    },
  },
}));

const Verification = () => {
  const classes = useStyles();
  const [OTP, setOTP] = useState('');
  const [resendTime, setResendTime] = useState(Date.now() + 120000);
  const personalInfo = fetchUserData();
  const { partnerInfo } = useContext(AppContext);

  const history = useHistory();

  // const formatData = () => {
  //   return {
  //     phone_number: `${personalInfo.phone_number}`,
  //     otp: OTP,
  //   };
  // };
  const resendOTP = async () => {
    try {
      const resp = await APIresendOtp({
        email: fetchTempUserData().email,
      });
      if (resp.data.success) {
        Toast.success('OTP sent');
        setResendTime(() => Date.now() + 120000);
      } else {
        throw resp?.data?.error;
      }
    } catch (error) {
      Toast.error(errorParser(error) || 'error sending otp');
    }
  };
  // larryco.a.l.9.1@gmail.com
  useUpdate(() => {
    if (!fetchTempUserData().phone_number) {
      history.push(ROUTES.SIGN_UP.path);
    } else {
      resendOTP();
    }
  }, [fetchTempUserData().phone_number]);

  const handleVerification = async (e) => {
    e.preventDefault();
    try {
      const resp = await APIverifyOtp({
        token: OTP,
      });

      if (resp.data.message === 'successful') {
        const { data } = resp.data;
        const newData = {
          ...data,
          user_id: data.id,
          token_exp: Date.now() + 10800000, // 3hrs = 10800000
          token: data.token,
        };
        localStorage.setItem('token', data.token);
        localStorage.setItem('expiration', newData.token_exp);
        storeUserData({ ...data.user, token: data.token });
        history.push(ROUTES.DASHBOARD.path);
      } else {
        throw resp?.data?.error;
      }
    } catch (err) {
      Toast.error(errorParser(err));
    }
  };

  return (
    <>
      <Grid container spacing={0} className={classes.root} justify="center">
        <Grid
          item
          container
          className={classes.container}
          direction="column"
          spacing={0}
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item sm={12}>
            <Box mb={1} onClick={() => history.push('/')}>
              <Logo src={partnerInfo.logoUrl || '/logo-black.svg'} name="Blusalt" />
            </Box>
          </Grid>
          <Grid item sm={12}>
            <Typography variant="h1" className={classes.typography}>
              Verify your phone number
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <Typography className={classes.heading}>
              Enter the code sent to {personalInfo.phone_number}
              <Typography style={{ marginTop: 10 }}>Do not refresh this page!</Typography>
            </Typography>
          </Grid>
          <form style={{ width: '100%' }} onSubmit={handleVerification}>
            <Grid item sm={12} container justify="center">
              <Box mb="62px" width="297px">
                <OTPInput
                  required
                  autoFocus
                  // isNumberInput
                  length={6}
                  className="otpContainer"
                  inputClassName="otpInput"
                  onChangeOTP={(otp) => setOTP(otp)}
                />
              </Box>
            </Grid>
            <Grid item container direction="column" align="center">
              <Grid item sm={12}>
                <Box mb="17px" width="240px">
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={handleVerification}
                  >
                    Verify
                  </Button>
                </Box>
              </Grid>
              <Grid item sm={12}>
                <Box mb="18px" width="240px">
                  <Button
                    type="button"
                    color="primary"
                    variant="outlined"
                    onClick={() => history.push(ROUTES.SIGN_IN.path)}
                    fullWidth
                  >
                    Back
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
          <Grid item>
            <Typography className={classes.code}>
              Did not get code?{' '}
              <Countdown
                date={resendTime}
                renderer={({ total, hours, minutes, seconds }) => {
                  return (
                    <>
                      {total > 0 ? (
                        <>
                          {hours > 0 && <span>{hours}h</span>}
                          {(hours > 0 || minutes > 0) && <span>{minutes}m</span>}
                          {(hours > 0 || minutes > 0 || seconds > 0) && <span>{seconds}s</span>}
                        </>
                      ) : (
                        <Box component="span" onClick={() => resendOTP()}>
                          Resend
                        </Box>
                      )}
                    </>
                  );
                }}
              />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* <BackDrop open={isLoading} /> */}
    </>
  );
};

export default Verification;
