import React, { useState, useContext, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Tabs } from '../../components/ui';
import Users from './components/Users';
import Profile from './components/Profile';
import ChangePassword from './components/ChangePassword';
import Notifications from './components/Notifications';
import User from './components/UserDetail';
import { AppContext } from '../../utilities/context';
import { APIfetchRoles } from '../../api/backendRoutes';

const StyledSettings = styled.div`
  background-color: white;
`;

const Settings = () => {
  const [userDetail, setUserDetail] = useState(false);
  const { setGoBackMethod } = useContext(AppContext);
  const [currentMember, setCurrentMember] = useState({});
  const [roles, setRoles] = useState([]);
  const handleDetail = () => {
    setGoBackMethod(() => setUserDetail(false));
    setUserDetail(true);
  };
  const getRoles = useCallback(async () => {
    try {
      const resp = await APIfetchRoles();
      if (resp.data.success === true) {
        const newData = resp.data.data;
        setRoles(newData);
      } else {
        throw resp?.data?.error;
      }
    } catch (err) {
      // console.log(err);
    }
  });

  useEffect(() => {
    getRoles();
  }, []);
  return (
    <>
      {userDetail && <User currentMember={currentMember} roles={roles} />}
      {!userDetail && (
        <StyledSettings>
          <Tabs
            header={[
              { key: 'profile', label: 'Profile' },
              {
                key: 'security',
                label: 'Security',
              },
              {
                key: 'notification',
                label: 'Notifications',
              },
              {
                key: 'users',
                label: 'Team',
              },
            ]}
            data={{
              profile: <Profile />,
              security: <ChangePassword />,
              notification: <Notifications />,
              users: (
                <Users showUser={handleDetail} setCurrentMember={setCurrentMember} roles={roles} />
              ),
            }}
          />
        </StyledSettings>
      )}
    </>
  );
};

export default Settings;
