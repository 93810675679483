/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
// import { Page, Text, View, Document, PDFViewer, Image } from '@react-pdf/renderer';
import Moment from 'react-moment';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { fetchUserData } from '../../storage/sessionStorage';
import logo from '../../assets/images/Logo.svg';
import { APIGetReciept } from '../../api/backendRoutes';

// Create Document Component
const Reciept = () => {
  const { id } = useParams();
  const userData = fetchUserData();
  const [loading, setLoading] = useState({ status: true, error: '' });
  const [recieptData, setReciepData] = useState(null);
  useEffect(async () => {
    try {
      const res = await APIGetReciept(id);
      if (res.data.success && res.data.data && res.data.data.totalTransactionCost > 0) {
        setReciepData(res.data.data);
        setLoading({ status: false, error: '' });
      } else {
        setLoading({ status: false, error: 'No successful transaction, reciept does not exist' });
      }
    } catch (err) {
      setLoading({ status: false, error: 'Transaction reciept does not exist' });
    }
  }, []);
  return (
    <>
      {loading.status ? (
        <h5>Genrating report...</h5>
      ) : loading.error ? (
        <h5>{loading.error}</h5>
      ) : (
        // <Reciept.Wrapper>
        //   <PDFViewer showToolbar>
        //     <Document title="BluInTouch">
        //       <Page size="A4" orientation="portrait" style={{ padding: '20px 90px' }}>
        //         <View
        //           style={{
        //             display: 'flex',
        //             minWidth: '100%',
        //             justifyContent: 'center',
        //             alignItems: 'center',
        //             marginBottom: 20,
        //           }}
        //         >
        //           <Image source={logo} style={{ height: 40, width: 'auto', display: 'block' }} />
        //         </View>
        //         <View>
        //           <Text
        //             style={{
        //               textAlign: 'center',
        //               fontSize: 25,
        //               color: '#17568B',
        //               fontWeight: 'heavy',
        //               marginBottom: 50,
        //             }}
        //           >
        //             Purchase Reciept
        //           </Text>
        //         </View>
        //         <View>
        //           <Text
        //             style={{
        //               fontSize: 16,
        //               color: '#17568B',
        //               fontWeight: 'bolder',
        //               marginBottom: 20,
        //             }}
        //           >
        //             Hi {userData?.firstName},
        //           </Text>
        //         </View>
        //         <View>
        //           <Text
        //             style={{
        //               fontSize: 12,
        //               marginBottom: 20,
        //             }}
        //           >
        //             Thank you for your purchase. This document serves as a receipt for your
        //             purchase, No payment is due with this receipt.
        //           </Text>
        //         </View>
        //         <View>
        //           <Text
        //             style={{
        //               fontSize: 12,
        //               marginBottom: 30,
        //             }}
        //           >
        //             Please retain this document for your record. Also in your transactions, this
        //             purchase will appear as “Transaction name”
        //           </Text>
        //         </View>
        //         <View>
        //           <Text
        //             style={{
        //               textAlign: 'center',
        //               fontSize: 20,
        //               color: '#17568B',
        //               fontWeight: 'bold',
        //               marginBottom: 30,
        //             }}
        //           >
        //             Payment details
        //           </Text>
        //         </View>
        //         <View
        //           style={{
        //             padding: '0px 30px',
        //           }}
        //         >
        //           {recieptData?.totalTransactionCost > 0 && (
        //             <View
        //               style={{
        //                 display: 'flex',
        //                 flexDirection: 'row',
        //                 justifyContent: 'space-between',
        //               }}
        //             >
        //               <Text
        //                 style={{
        //                   fontSize: 12,
        //                   marginBottom: 10,
        //                 }}
        //               >
        //                 Amount
        //               </Text>
        //               <Text
        //                 style={{
        //                   fontSize: 12,
        //                   marginBottom: 5,
        //                 }}
        //               >
        //                 {recieptData?.totalTransactionCost || 0}
        //               </Text>
        //             </View>
        //           )}
        //           {/* <View
        //             style={{
        //               display: 'flex',
        //               flexDirection: 'row',
        //               justifyContent: 'space-between',
        //             }}
        //           >
        //             <Text
        //               style={{
        //                 fontSize: 12,
        //                 marginBottom: 10,
        //               }}
        //             >
        //               Surcharge
        //             </Text>
        //             <Text
        //               style={{
        //                 fontSize: 12,
        //                 marginBottom: 5,
        //               }}
        //             >
        //               100
        //             </Text>
        //           </View> */}
        //           {recieptData?.transactions[0]?.category && (
        //             <View
        //               style={{
        //                 display: 'flex',
        //                 flexDirection: 'row',
        //                 justifyContent: 'space-between',
        //                 paddingTop: 10,
        //                 borderTop: '1px solid #f2f2f2',
        //               }}
        //             >
        //               <Text
        //                 style={{
        //                   fontSize: 12,
        //                   marginBottom: 10,
        //                 }}
        //               >
        //                 Paid for
        //               </Text>
        //               <Text
        //                 style={{
        //                   fontSize: 12,
        //                   marginBottom: 5,
        //                 }}
        //               >
        //                 {recieptData?.transactions[0]?.category}
        //               </Text>
        //             </View>
        //           )}
        //           {/* <View
        //             style={{
        //               display: 'flex',
        //               flexDirection: 'row',
        //               justifyContent: 'space-between',
        //             }}
        //           >
        //             <Text
        //               style={{
        //                 fontSize: 12,
        //                 marginBottom: 10,
        //               }}
        //             >
        //               Campaign Name
        //             </Text>
        //             <Text
        //               style={{
        //                 fontSize: 12,
        //                 marginBottom: 5,
        //               }}
        //             >
        //               Campaign name
        //             </Text>
        //           </View> */}
        //           <View
        //             style={{
        //               display: 'flex',
        //               flexDirection: 'row',
        //               justifyContent: 'space-between',
        //             }}
        //           >
        //             <Text
        //               style={{
        //                 fontSize: 12,
        //                 marginBottom: 10,
        //               }}
        //             >
        //               SenderID
        //             </Text>
        //             <Text
        //               style={{
        //                 fontSize: 12,
        //                 marginBottom: 5,
        //               }}
        //             >
        //               Blusalt
        //             </Text>
        //           </View>
        //           {recieptData?.phoneNumbers?.length > 0 && (
        //             <View
        //               style={{
        //                 display: 'flex',
        //                 flexDirection: 'row',
        //                 justifyContent: 'space-between',
        //               }}
        //             >
        //               <Text
        //                 style={{
        //                   fontSize: 12,
        //                   marginBottom: 10,
        //                 }}
        //               >
        //                 Phone Number
        //               </Text>
        //               <Text
        //                 style={{
        //                   fontSize: 12,
        //                   marginBottom: 5,
        //                 }}
        //               >
        //                 {recieptData?.phoneNumbers.map((phone) => (
        //                   <span>{phone}</span>
        //                 ))}
        //               </Text>
        //             </View>
        //           )}
        //           {recieptData.transactions[0]?.category && (
        //             <View
        //               style={{
        //                 display: 'flex',
        //                 flexDirection: 'row',
        //                 justifyContent: 'space-between',
        //               }}
        //             >
        //               <Text
        //                 style={{
        //                   fontSize: 12,
        //                   marginBottom: 10,
        //                 }}
        //               >
        //                 Transaction Type
        //               </Text>
        //               <Text
        //                 style={{
        //                   fontSize: 12,
        //                   marginBottom: 5,
        //                 }}
        //               >
        //                 {recieptData.transactions[0]?.category}
        //               </Text>
        //             </View>
        //           )}
        //           {recieptData.transactions[0]?.transactionReference && (
        //             <View
        //               style={{
        //                 display: 'flex',
        //                 flexDirection: 'row',
        //                 justifyContent: 'space-between',
        //               }}
        //             >
        //               <Text
        //                 style={{
        //                   fontSize: 12,
        //                   marginBottom: 10,
        //                 }}
        //               >
        //                 Transaction Reference
        //               </Text>
        //               <Text
        //                 style={{
        //                   fontSize: 10,
        //                   marginBottom: 5,
        //                 }}
        //               >
        //                 {recieptData.transactions[0]?.transactionReference}
        //               </Text>
        //             </View>
        //           )}
        //           <View
        //             style={{
        //               display: 'flex',
        //               flexDirection: 'row',
        //               justifyContent: 'space-between',
        //             }}
        //           >
        //             <Text
        //               style={{
        //                 fontSize: 12,
        //                 marginBottom: 10,
        //               }}
        //             >
        //               Transaction Date
        //             </Text>
        //             <Text
        //               style={{
        //                 fontSize: 12,
        //                 marginBottom: 5,
        //               }}
        //             >
        //               <Moment format="D MMM, YYYY" date={recieptData.transactions[0]?.createdAt} />{' '}
        //               -
        //               <Moment format="h:mm:SSa" date={recieptData.transactions[0]?.createdAt} />
        //             </Text>
        //           </View>
        //           {recieptData.transactions[0]?.status && (
        //             <View
        //               style={{
        //                 display: 'flex',
        //                 flexDirection: 'row',
        //                 justifyContent: 'space-between',
        //               }}
        //             >
        //               <Text
        //                 style={{
        //                   fontSize: 12,
        //                   marginBottom: 10,
        //                 }}
        //               >
        //                 Status
        //               </Text>
        //               <Text
        //                 style={{
        //                   fontSize: 12,
        //                   marginBottom: 5,
        //                 }}
        //               >
        //                 {recieptData.transactions[0]?.status}
        //               </Text>
        //             </View>
        //           )}
        //         </View>
        //       </Page>
        //     </Document>
        //   </PDFViewer>
        // </Reciept.Wrapper>
        <h4>...</h4>
      )}
    </>
  );
};

Reciept.Wrapper = styled.div`
  iframe {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
  }
`;

export default Reciept;
