import React, { memo, useRef, useLayoutEffect } from 'react';
import { usePrevious } from 'react-use';
import styled from 'styled-components';

const StyledInput = styled.input`
  padding: ${(props) => props.padding || '0rem 1rem'};
  border: ${(props) => props.bordercolor || '1px solid var(--primary-color)'};
  border-radius: 5px;
  font-size: 1.6rem;
  margin: ${(props) => props.margin || '0rem'};
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '4.8rem'};
  background: ${(props) => props.background || 'var(--off-white)'};
  color: ${(props) => props.color || 'rgba(22, 16, 11, 0.38)'};
  &::placeholder {
    color: ${(props) => props.color || 'rgba(22, 16, 11, 0.38)'};
    font-size: 1.2rem;
  }
`;

const StyledFormGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin: ${(props) => props.margin || '0rem'};
  grid-column: ${(props) => props.gridcolumn || 'none'};
  grid-row: ${(props) => props.gridrow || 'none'};
  width: ${(props) => props.width || 'initial'};
  .label {
    color: var(--placeholder-grey);
    font-size: 1.4rem;
    line-height: 16px;
    margin-bottom: 1.3rem;
    font-weight: 600;
  }
`;

const OTPField = React.forwardRef(({ bordercolor, height, ...rest }, ref) => {
  return (
    <StyledFormGroup {...rest}>
      <StyledInput ref={ref} bordercolor={bordercolor} height={height} width="130%" {...rest} />
    </StyledFormGroup>
  );
});

export function SingleOTPInputComponent(props) {
  const { focus, autoFocus, ...rest } = props;
  const inputRef = useRef(null);
  const prevFocus = usePrevious(!!focus);
  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus();
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autoFocus, focus, prevFocus]);

  return (
    <OTPField
      ref={inputRef}
      margin="0"
      width="45px"
      height="62px"
      bordercolor=""
      // padding="30px"
      background="#fff"
      {...rest}
    />
  );
}

const SingleOTPInput = memo(SingleOTPInputComponent);
export default SingleOTPInput;
