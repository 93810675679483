import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  services: [],
  fetchingServices: false,
  error: false,

  serviceTypes: [],
  fetchingServiceTypes: false,
  errorServiceTypes: false,
};

export const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    fetchServicesSuccessReducer: (state, { payload }) => {
      state.services = payload.data;
      state.fetchingServices = false;
    },
    fetchingServiceReducer: (state) => {
      state.serviceReport = [];
      state.fetchingServices = true;
      state.error = false;
    },
    fetchingServiceErrorReducer: (state) => {
      state.serviceReport = [];
      state.fetchingServiceReport = true;
      state.error = true;
    },

    fetchServiceTypesSuccessReducer: (state, { payload }) => {
      state.serviceTypes = payload.data.map((d) => ({
        ...d,
        label: d.serviceTypeName,
        value: d?._id,
      }));
      state.fetchingServiceTypes = false;
      state.errorServiceTypes = false;
    },
    fetchingServiceTypesReducer: (state) => {
      state.serviceTypes = [];
      state.fetchingServiceTypes = true;
      state.errorServiceTypes = false;
    },
    fetchingServiceTypesErrorReducer: (state) => {
      state.serviceTypes = [];
      state.fetchingServiceTypes = true;
      state.errorServiceTypes = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  fetchServicesSuccessReducer,
  fetchingServiceReducer,
  fetchingServiceErrorReducer,
  fetchServiceTypesSuccessReducer,
  fetchingServiceTypesReducer,
  fetchingServiceTypesErrorReducer,
} = servicesSlice.actions;

export default servicesSlice.reducer;
