/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { APIgetCompanyProviders } from '../../../api/backendRoutes';

function ProviderSwitch() {
  const [providers, setProviders] = useState([]);
  useEffect(async () => {
    const resp = await APIgetCompanyProviders();
    setProviders(resp.data.data);
  }, []);

  const handleChange = (e) => {
    localStorage.setItem('serviceProvider__', e.target.value);
    window.location.reload();
  };
  return (
    <ProviderSwitchWrapper>
      <div className="input-group mb-3">
        <select className="custom-select" onChange={handleChange}>
          <option selected>Choose a partner</option>
          {providers.map((dt, i) => (
            <option key={{ i }} value={dt.companyName}>
              {dt.companyName}
            </option>
          ))}
        </select>
      </div>
    </ProviderSwitchWrapper>
  );
}

export default ProviderSwitch;

const ProviderSwitchWrapper = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
`;
