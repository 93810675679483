import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Button, Finalize, Icon, Upload, UploadResult } from '../../../components/ui';
import { Input } from '../../../components/ui/inputs';
import WalletBalance from '../../wallet/components/WalletBalance';
import AirtimeCsv from '../../../assets/data/airtime.csv';
import Schedule from '../../services/components/Schedule';
import SuccessModal from '../../wallet/components/PaymentSuccess';
import CheckOut from '../../wallet/components/CheckOut';
import {
  createAirtimeCampaignAction,
  createSchedulledAirtimeCampaignAction,
} from '../../../redux/actions/campaign';
import { useUpdate } from '../../../utilities/hooks/useUpdate';
import { fetchWallet } from '../../../redux/actions/wallet';
import { APIreadFile } from '../../../api/backendRoutes';
import { fetchUserData } from '../../../storage/sessionStorage';
import { ROUTES } from '../../../components/router/routes/routes';
import GroupContactModal from '../../services/components/GroupedContactModal';

const mapStateToProps = ({ services, campaign }) => {
  return {
    serviceTypes: services.serviceTypes,
    creatingAirtimeCampaign: campaign.creatingAirtimeCampaign,
    creatingSchedulledAirtimeCampaign: campaign.creatingSchedulledAirtimeCampaign,
  };
};

const AirtimeCampaign = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = fetchUserData();
  const [showSchedule, setShowSchedule] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const [showCheckOut, setShowCheckOut] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showScheduleForLater, setShowScheduleForLater] = useState(false);
  const [payload, setPayload] = useState({});
  const [price, setPrice] = useState(0);
  const [err, setErr] = useState([false, '']);
  const [formData, setFormData] = useState({
    campaignName: null,
    airtimeRecpt: null,
    amount: 0,
    uploadSuccess: false,
    campaignType: 'immediate',
  });

  // const runAirtimeCampaign = useCallback(
  //   async (params = {}) => {
  //     await dispatch(runCampaignAction(params));
  //   },
  //   [dispatch]
  // );
  const fetchUserWallet = useCallback(() => {
    dispatch(fetchWallet());
  }, [dispatch]);

  const createAirtimeCampaign = useCallback(
    async (params = {}) => {
      await dispatch(createAirtimeCampaignAction(params));
    },
    [dispatch]
  );

  const createSchedulledAirtimeCampaign = useCallback(
    async (params = {}) => {
      await dispatch(createSchedulledAirtimeCampaignAction(params));
    },
    [dispatch]
  );

  const { serviceTypes, creatingAirtimeCampaign, creatingSchedulledAirtimeCampaign } =
    useSelector(mapStateToProps);

  const {
    errorCreatingAirtimeCampaign,
    successCreatingAirtimeCampaign,
    successCreatingSchedulledAirtimeCampaign,
    errorCreatingSchedulledAirtimeCampaign,
  } = useSelector((state) => {
    return {
      success: state.campaign.successCreatingAirtimeCampaign,
      errorRunningCampaign: state.campaign.errorCreatingAirtimeCampaign,
      ...state.campaign,
    };
  });
  const { airtimeMeta, balance } = useSelector((state) => {
    const service = state.services.services;
    return {
      airtimeMeta: service.filter((s) => s.serviceName === 'Airtime')[0],
      balance: state.wallet.walletNfo.balance,
    };
  });
  const handleChange = ({ name, value }) => {
    setFormData((v) => ({ ...v, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!Object.values(formData).includes(null)) {
      const body = JSON.stringify({ amount: 100 });
      const newPayload = new FormData();
      newPayload.append('campaignType', formData.campaignType);
      newPayload.append('campaignScheduleType', 'createCampaign');
      newPayload.append('campaignName', formData.campaignName);
      newPayload.append('companyId', user.companyId);
      newPayload.append('serviceId', airtimeMeta._id);
      newPayload.append('campaignBody', body);
      newPayload.append(
        'serviceType',
        serviceTypes.find((d) => d?.serviceTypeName?.toLowerCase() === 'airtime')?._id
      );
      if (formData.airtimeRecpt) {
        newPayload.append('campaignFile', formData?.airtimeRecpt[0]);
      }
      if (formData.contactGroupId) {
        newPayload.append('contactGroupId', formData.contactGroupId);
      }

      setPayload(() => newPayload);
      setShowCheckOut(true);
    } else {
      setErr(() => [true, 'please fill all fields']);
    }
  };

  const handleSend = () => {
    setShowCheckOut(false);
    createAirtimeCampaign(payload);
  };

  useUpdate(async () => {
    if (formData.airtimeRecpt) {
      const newPayload = new FormData();
      newPayload.append('campaignFile', formData?.airtimeRecpt[0]);
      newPayload.append('serviceCategory', 'Airtime');
      try {
        const resp = await APIreadFile(newPayload);
        if (resp.data.success) {
          const recpt = resp.data.data || [];
          const computedPrice = recpt.reduce((acc, d) => {
            return acc + parseInt(d.amount, 10);
          }, 0);
          setPrice(computedPrice);
          setFormData((f) => ({ ...f, uploadSuccess: true }));
        } else {
          throw resp?.data?.error;
        }
      } catch (error) {
        // console.log(err);
      }
    }
  }, [formData.airtimeRecpt]);

  useUpdate(() => {
    if (successCreatingAirtimeCampaign && price !== 0) {
      setShowSuccessModal(true);
      fetchUserWallet();
    } else if (errorCreatingAirtimeCampaign) {
      setErr([true, errorCreatingAirtimeCampaign || 'Error running campaign']);
    }
  }, [successCreatingAirtimeCampaign, errorCreatingAirtimeCampaign]);

  useUpdate(() => {
    if (successCreatingSchedulledAirtimeCampaign && price !== 0) {
      setShowSchedule(false);
      setShowScheduleForLater(true);
      fetchUserWallet();
    } else if (errorCreatingSchedulledAirtimeCampaign) {
      setShowSchedule(false);
      setErr([true, errorCreatingSchedulledAirtimeCampaign]);
    }
  }, [successCreatingSchedulledAirtimeCampaign, errorCreatingSchedulledAirtimeCampaign]);
  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    history.push(ROUTES.ALL_CAMPAIGN.path);
  };

  const handleShowSchedule = () => {
    if (!Object.values(formData).includes(null)) {
      setFormData({ ...formData, campaignType: 'schedulled' });
      setShowSchedule(true);
    } else {
      setErr(() => [true, 'please fill all fields']);
    }
  };

  const handleSchedule = async (scheduleData) => {
    const newPayload = new FormData();
    // newPayload.append('campaignType', formData.campaignType);
    newPayload.append('campaignScheduleType', 'ScheduleCampaign');
    newPayload.append('campaignTypeString', 'schedulled');
    newPayload.append('campaignName', formData?.campaignName);
    newPayload.append('companyId', user?.companyId);
    newPayload.append('serviceId', airtimeMeta?._id);
    newPayload.append('campaignBody', JSON.stringify({}));
    newPayload.append('currencyType', 'NGN');
    newPayload.append('campaignType', 'schedulled');
    newPayload.append('schedulledDate', scheduleData.startDate);
    newPayload.append(
      'serviceType',
      serviceTypes.find((d) => d?.serviceTypeName === 'Airtime')?._id
    );
    if (formData?.airtimeRecpt !== '') {
      newPayload.append('campaignFile', formData?.airtimeRecpt[0]);
    }
    if (formData?.contactGroupId) {
      newPayload.append('contactGroupId', formData.contactGroupId);
    }
    Object.keys(scheduleData).map((key) => {
      if (scheduleData[key] !== undefined) {
        newPayload.append(key, scheduleData[key]);
      }
      return null;
    });

    createSchedulledAirtimeCampaign(newPayload);
  };

  return (
    <>
      <Row>
        <Col md={8} lg={9} style={{ marginBottom: 20 }}>
          <div className="campaign-wrapper">
            <form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Input
                    placeholder="Bulk Name"
                    label="Bulk Name"
                    name="campaignName"
                    validate="required"
                    onChange={handleChange}
                    defaultValue={formData.campaignName}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="upload-wrapper">
                  <Upload
                    icon="upload"
                    text="Upload Contacts"
                    name="airtimeRecpt"
                    onChange={handleChange}
                    validate="required"
                    hint={
                      <>
                        (see&nbsp;
                        <a href={AirtimeCsv} download>
                          sample file
                        </a>
                        )
                      </>
                    }
                  />
                  <div className="contact" onClick={() => setShowContacts(true)}>
                    <Icon icon="phone" margin={[0, 10, 0, 0]} />
                    Add from Contact
                  </div>
                </Col>
              </Row>
              <Row>
                <Col style={{ display: 'flex' }}>
                  {formData.airtimeRecpt && (
                    <UploadResult
                      files={formData.airtimeRecpt}
                      removeFile={() => setFormData((v) => ({ ...v, airtimeRecpt: null }))}
                      upload={formData.uploadSuccess}
                    />
                  )}
                </Col>
              </Row>
              {formData.airtimeRecpt === '' && (
                <Row>
                  <Col>
                    <h4>{formData.contactGroupName}</h4>
                    <UploadResult
                      files={formData.airtimeRecpt}
                      removeFile={() => setFormData((v) => ({ ...v, airtimeRecpt: null }))}
                      upload={formData.uploadSuccess}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col className="btn-container">
                  <Button
                    value="Send Now"
                    type="submit"
                    loading={creatingAirtimeCampaign}
                    disabled={price < 1}
                  />
                  <Button value="Schedule for Later" group="outline" onClick={handleShowSchedule} />
                </Col>
              </Row>
              {err[0] && (
                <div
                  style={{
                    fontSize: '14px',
                    color: 'red',
                    marginTop: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {err[1]}
                </div>
              )}
            </form>
          </div>
        </Col>
        <Col md={4} lg={3}>
          <WalletBalance price={price} />
        </Col>
      </Row>
      <Schedule
        show={showSchedule}
        setShow={setShowSchedule}
        handleSchedule={handleSchedule}
        loading={creatingSchedulledAirtimeCampaign}
      />
      <SuccessModal
        show={showSuccessModal}
        onClose={handleCloseSuccessModal}
        price={price}
        headerText="Bulk airtime launched successfully"
        subtitle="Your bulk airtime is being processed"
      />
      <CheckOut
        show={showCheckOut}
        onClose={() => setShowCheckOut(false)}
        execute={handleSend}
        price={price}
        disableWallet={price > balance}
      />
      <GroupContactModal
        show={showContacts}
        setShow={setShowContacts}
        setPrice={setPrice}
        type="Airtime"
        setFormData={setFormData}
      />
      <Finalize
        show={showScheduleForLater}
        onClose={() => {
          setShowScheduleForLater(false);
          history.push(ROUTES.ALL_CAMPAIGN.path);
        }}
        headerText="Your bulk airtime has been scheduled"
      />
    </>
  );
};

export default AirtimeCampaign;
