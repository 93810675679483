import React from 'react';
import styled from 'styled-components';
import { Tabs } from '../../components/ui';
import SMSCampaign from './components/SMSCampaign';
import DataCampaign from './components/DataCampaign';
import AirtimeCampaign from './components/AirtimeCampaign';
// import WhatsappCampaign from './components/WhatsappCampaign';

const StyledCreateCampaign = styled.div``;

const CreateCampaign = () => {
  return (
    <>
      <StyledCreateCampaign>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h5>Send Bulk</h5>

          {/* <Button
            group="secondary round"
            value="Manage Contacts"
            icon="address-book"
            size="md"
            width={160}
            margin={[0, 0, 0, 10]}
          /> */}
        </div>

        <Tabs
          header={[
            { key: 'sms', label: 'SMS' },
            // { key: 'whatsapp', label: 'WhatsApp' },
            { key: 'airtime', label: 'Airtime' },
            { key: 'data', label: 'Data' },
          ]}
          data={{
            sms: <SMSCampaign />,
            // whatsapp: <WhatsappCampaign />,
            airtime: <AirtimeCampaign />,
            data: <DataCampaign />,
          }}
        />
      </StyledCreateCampaign>
    </>
  );
};

export default CreateCampaign;
