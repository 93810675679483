export const ROUTES = {
  SIGN_IN: { path: '/auth/signin', meta: { title: 'Sign In' } },
  SIGN_UP: { path: '/auth/signup', meta: { title: 'Sign Up' } },
  FORGOT_PASSWORD: { path: '/auth/forgot-password', meta: { title: 'Forgot Password' } },
  RESET_PASSWORD: { path: '/auth/reset-password/:token', meta: { title: 'Reset Password' } },
  VERIFY_ACCOUNT: { path: '/auth/confirm-account/:token', meta: { title: 'Verify Account' } },
  VERIFY_OTP: { path: '/auth/verify', meta: { title: 'Verify OTP' } },
  TFA: { path: '/auth/2fa', meta: { title: 'Two Factor Authentication' } },
  DASHBOARD: { path: '/dashboard', meta: { title: 'Dashboard' } },
  REPORT: { path: '#report', meta: { title: 'Report' } },
  CONTACTS: { path: '/contacts', meta: { title: 'Manage Contacts' } },
  CAMPAIGN: { path: '#campaign', meta: { title: 'Bulk Service' } },
  ALL_CAMPAIGN: { path: '/campaign/all', meta: { title: 'Bulk Service' } },
  CREATE_CAMPAIGN: { path: '/campaign/create', meta: { title: 'Bulk Service' } },
  SENDER_ID: { path: '/campaign/sender', meta: { title: 'Sender ID' } },
  SERVICE: { path: '#service', meta: { title: 'Service' } },
  AUDIT_TRAIL: { path: '/report/audit', meta: { title: 'Report' } },
  SERVICE_REPORT: { path: '/report/service', meta: { title: 'Report' } },
  SERVICE_REPORT_DETAIL: { path: '/report/service/:id', meta: { title: 'Report Details' } },
  TRANSACTION_REPORT: { path: '/report/transaction', meta: { title: 'Report' } },
  WALLET: { path: '/wallet', meta: { title: 'Wallet' } },
  SETTINGS: { path: '/settings', meta: { title: 'Settings' } },
  SMS_SERVICE: { path: '/service/sms', meta: { title: 'Service' } },
  DATA_SERVICE: { path: '/service/data', meta: { title: 'Service' } },
  AIRTIME_SERVICE: { path: '/service/airtime', meta: { title: 'Service' } },
  WHATSAPP_SERVICE: { path: '/service/whatsapp', meta: { title: 'Service' } },
  PAY_BILLS: { path: '/service/paybills', meta: { title: 'Service' } },
  PRICES: { path: '/prices', meta: { title: 'Price' } },
  GIFT_CARDS: { path: '/service/gift', meta: { title: 'Service' } },
  RECIEPT: { path: '/reciept', meta: { title: 'Reciept' } },
  NOT_FOUND: '/404',
};
