import React, { useContext, useEffect } from 'react';
import Iframe from 'react-iframe';
import { Route, Redirect, withRouter, Router, Switch } from 'react-router-dom';
import Contacts from '../../../pages/contacts/Contacts';
import AirtimeService from '../../../pages/services/AirtimeService';
import SMSService from '../../../pages/services/SMSService';
import Settings from '../../../pages/settings/Settings';
import AuditTrail from '../../../pages/report/AuditTrail';
import ServiceReport from '../../../pages/report/ServiceReport';
import TransactionReport from '../../../pages/report/TransactionReport';
import SenderId from '../../../pages/campaign/SenderId';
import AllCampaigns from '../../../pages/campaign/AllCampaigns';
import CreateCampaign from '../../../pages/campaign/CreateCampaign';
import Dashboard from '../../../pages/dashboard/Dashboard';
import GiftCards from '../../../pages/services/GiftCards';
import PayBills from '../../../pages/services/PayBills';
import WhatsappService from '../../../pages/services/WhatsappService';
import DataService from '../../../pages/services/DataService';
import Wallet from '../../../pages/wallet/Wallet';

import Prices from '../../../pages/price/prices';
import Reciept from '../../../pages/Receipt';
import { PrivateRoute } from './PrivateRoute';
import { ROUTES } from '../routes/routes';
import { fetchUserData } from '../../../storage/sessionStorage';
import ServiceReportDetail from '../../../pages/report/ServiceReportDetail';
import { AppContext } from '../../../utilities/context';

export const protectedRoutes = [
  {
    component: Dashboard,
    routes: ROUTES.DASHBOARD,
  },
  {
    component: GiftCards,
    routes: ROUTES.GIFT_CARDS,
  },
  {
    component: PayBills,
    routes: ROUTES.PAY_BILLS,
  },
  {
    component: DataService,
    routes: ROUTES.DATA_SERVICE,
  },
  {
    component: Prices,
    routes: ROUTES.PRICES,
  },
  {
    component: WhatsappService,
    routes: ROUTES.WHATSAPP_SERVICE,
  },
  {
    component: Contacts,
    routes: ROUTES.CONTACTS,
  },
  {
    component: Wallet,
    routes: ROUTES.WALLET,
  },
  {
    component: CreateCampaign,
    routes: ROUTES.CREATE_CAMPAIGN,
  },
  {
    component: SenderId,
    routes: ROUTES.SENDER_ID,
  },
  {
    component: AllCampaigns,
    routes: ROUTES.ALL_CAMPAIGN,
  },
  {
    component: AuditTrail,
    routes: ROUTES.AUDIT_TRAIL,
  },
  {
    component: ServiceReportDetail,
    routes: ROUTES.SERVICE_REPORT_DETAIL,
  },
  {
    component: ServiceReport,
    routes: ROUTES.SERVICE_REPORT,
  },
  {
    component: TransactionReport,
    routes: ROUTES.TRANSACTION_REPORT,
  },
  { component: Settings, routes: ROUTES.SETTINGS },
  { component: SMSService, routes: ROUTES.SMS_SERVICE },
  {
    component: AirtimeService,
    routes: ROUTES.AIRTIME_SERVICE,
  },
  // {
  //   component: Reciept,
  //   routes: ROUTES.RECIEPT,
  // },
];

const PrivateRoutes = ({ component: Component, ...rest }) => {
  const { token } = fetchUserData();

  return (
    <Route
      {...rest}
      render={(props) => (token ? <Component {...props} /> : <Redirect to="/auth/signin" />)}
    />
  );
};

export default withRouter(PrivateRoutes);

export const ProtectedRoutesGroup = () => {
  const { checkoutData, setCheckoutData } = useContext(AppContext);
  const { url, refetch } = checkoutData;

  const { token } = fetchUserData();
  if (token && window.location.pathname === '/') {
    window.location.pathname = '/dashboard';
  }
  // Check Checkout Event
  const receiveMessage = async (event) => {
    const { data: response } = event || {};
    const { name } = response || {};

    if (name === 'close') setCheckoutData({});
    else if (name === 'done') {
      // eslint-disable-next-line no-unused-expressions
      refetch && refetch();
      setCheckoutData({});
    }
    // }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('message', receiveMessage, false);
    }
    // eslint-disable-next-line
  });

  return (
    <>
      <Switch>
        {protectedRoutes.map((route, index) => (
          <PrivateRoute
            key={index}
            exact
            component={route.component}
            showHeader
            showSideMenu
            {...route.routes}
          />
        ))}
        <Route path="/reciept/:id" render={() => <Reciept />} />
        <Router exact path="*" component={() => <Redirect to="/dashboard" />} />
      </Switch>
      {url && <Iframe url={url} id="checkout" />}
    </>
  );
};
