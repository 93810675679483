import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Controller } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import countries from '../../utilities/CountryList';

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export const ControlledAutocomplete = ({
  onChange: ignored,
  control,
  defaultValue,
  name,
  errors,
}) => {
  const classes = useStyles();
  return (
    <Controller
      render={({ onChange, ...props }) => (
        <Autocomplete
          classes={{
            option: classes.option,
          }}
          options={countries}
          getOptionLabel={(option) => option.label}
          renderOption={(option) => (
            <>
              <span>{countryToFlag(option.code)}</span>
              {option.label} ({option.code}) +{option.phone}
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select country"
              variant="outlined"
              name={name}
              error={!!errors[name]?.message}
              helperText={errors[name]?.message}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
          onChange={(e, data) => onChange(data)}
          {...props}
        />
      )}
      onChange={([, data]) => data}
      defaultValue={defaultValue}
      name={name}
      control={control}
    />
  );
};

const CountrySelect = React.forwardRef(({ name, fullWidth, errors }, ref) => {
  const classes = useStyles();

  return (
    <Autocomplete
      id="country-select-demo"
      //   style={{ width: 300 }}
      options={countries}
      classes={{
        option: classes.option,
      }}
      autoHighlight
      getOptionLabel={(option) => option.label}
      renderOption={(option) => (
        <>
          <span>{countryToFlag(option.code)}</span>
          {option.label} ({option.code}) +{option.phone}
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select country"
          variant="outlined"
          name={name}
          error={!!errors[name]?.message}
          helperText={errors[name]?.message}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
          inputRef={ref}
        />
      )}
      fullWidth={fullWidth}
    />
  );
});

export default CountrySelect;
