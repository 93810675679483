import React, { useEffect, useCallback, useState } from 'react';
import Moment from 'react-moment';
// import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { Button, Table } from '../../components/ui';
import { fetchServiceReportAction } from '../../redux/actions/serviceReport';
import { APIExportServiceReport } from '../../api/backendRoutes';
import { errorParser } from '../../utilities/helper';
import { ROUTES } from '../../components/router/routes/routes';

const ServiceReport = () => {
  const { serviceReport, meta, fetching } = useSelector((state) => {
    return {
      serviceReport: state.serviceReport.serviceReport,
      meta: state.serviceReport.serviceReportMeta,
      fetching: state.serviceReport.fetchingServiceReport,
    };
  });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const FetchService = useCallback(
    (params = { limit: 25 }) => {
      dispatch(fetchServiceReportAction(params));
    },
    [dispatch]
  );

  useEffect(() => {
    FetchService();
  }, []);

  // const getCSV = () => {
  //   const csv = [
  //     ['Group Name', 'Service Type', 'Service', 'Recipient', 'Delivered', 'Date', 'Time'],
  //     ...serviceReport.map((service) => [
  //       service.groupName,
  //       service.serviceType,
  //       service.service,
  //       service.totalReceipients,
  //       service.totalDelivered,
  //       moment(service.createdAt).format('D MMM YYYY'),
  //       moment(service.createdAt).format('h:mm:SSa'),
  //     ]),
  //   ]
  //     .map((e) => e.join(','))
  //     .join('\n');

  //   const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  //   const { URL } = window;
  //   return URL.createObjectURL(blob);
  // };

  const getCSV = async () => {
    try {
      setLoading(true);
      const resp = await APIExportServiceReport();
      if (resp.data.message === 'ok') {
        toast.success('A generated report has been sent to your mail');
        setLoading(false);
      } else {
        setLoading(false);
        throw resp?.data?.error;
      }
    } catch (err) {
      toast.error(errorParser(err) || 'error');
      setLoading(false);
    }
  };

  return (
    <div className="report-wrapper">
      <div className="header">
        <h5>Service Report</h5>
        {/* <a href={getCSV()} download="ServiceReport"> */}
        {serviceReport.length > 0 && (
          <Button value="Download CSV" loading={loading} onClick={() => getCSV()} />
        )}
        {/* </a> */}
      </div>
      <section>
        <Table
          header={[
            {
              key: 'createdAt',
              label: 'Date',
              render: (date) => (
                <div className="td-width-100">
                  <div>
                    <Moment format="D MMM, YYYY" date={date} />
                  </div>
                  <div>
                    <Moment format="h:mm:SSa" date={date} />
                  </div>
                </div>
              ),
            },
            {
              key: 'groupName',
              label: 'Group Name',
              render: (groupName) => {
                return <div className="td-width-150">{groupName}</div>;
              },
            },
            {
              key: 'serviceType',
              label: 'Service Type',
              render: (serviceType) => {
                return <div className="td-width-100">{serviceType}</div>;
              },
            },
            {
              key: 'service',
              label: 'Service',
              render: (service) => {
                return <div className="td-width-100">{service}</div>;
              },
            },
            { key: 'totalReceipients', label: 'Total Recipients' },
            { key: 'totalDelivered', label: 'Total Delivered' },
            {
              key: '',
              label: 'Action',
              render: (service, record) => {
                return (
                  <div className="">
                    <a href={`${ROUTES.SERVICE_REPORT.path}/${record._id}`}>View</a>
                  </div>
                );
              },
            },
          ]}
          data={serviceReport}
          filter={{
            Date: [
              { value: 'Today', label: 'Today' },
              { value: 'This Week', label: 'This Week' },
              { value: 'This Month', label: 'This Month' },
              { value: 'Last 30 Days', label: 'Last 30 Days' },
              { value: 'This Year', label: 'This Year' },
              { value: 'Period', label: 'Specific Period' },
            ],
            serviceType: [
              { value: 'Campaign', label: 'Bulk Service' },
              { value: 'Service', label: 'Service' },
              // { value: 'Schedulled Service', label: 'Schedulled Service' },
            ],
            service: [
              { value: 'Bill Payment', label: 'Bill Payment' },
              { value: 'Airtime', label: 'Airtime' },
              { value: 'SMS', label: 'SMS' },
              { value: 'Data', label: 'Data' },
            ],
            search: 'search',
          }}
          showFilter
          paginate
          meta={meta}
          handlePagination={FetchService}
          filterAction={FetchService}
          loading={fetching}
        />
      </section>
    </div>
  );
};

export default ServiceReport;
