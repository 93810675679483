import React, { useCallback, useEffect, useState, useContext } from 'react';
import Moment from 'react-moment';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Table, Button, Options } from '../../components/ui';
import { fetchCampaignsAction } from '../../redux/actions/campaign';
import EditCampaign from './components/EditCampaign';
import { ROUTES } from '../../components/router/routes/routes';
import { AppContext } from '../../utilities/context';

const StyledNoCampaign = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  .header {
    font-weight: bold;
    font-size: 20px;
  }
  .subtitle {
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: normal;
  }
`;

const AllCampaigns = () => {
  const history = useHistory();
  const { setGoBackMethod } = useContext(AppContext);
  const [showEdit, setShowEdit] = useState(false);
  const [campaignId, setCampaignId] = useState(null);
  const dispatch = useDispatch();
  const allCampaign = useCallback(
    (params = { limit: 25 }) => {
      dispatch(fetchCampaignsAction(params));
    },
    [dispatch]
  );
  
  const { campaigns, fetchingCampaigns, meta } = useSelector(({ campaign }) => {
    const computed = campaign.listCampaigns.map((c) => ({
      campaign_name: c.campaignName,
      campaign_type: c.campaignType,
      campaignServiceName: c.campaignServiceName,
      recipients: 'N/A',
      date_created: c.createdAt,
      status: c.campaignStatus,
      totalBeneficiaries: c.totalBeneficiaries,
      totalSuccessfulRecipient: c.totalSuccessfulRecipient,
      id: c._id,
      // startDate: c.campaignType === 'immediate' ? c.createdAt : c.startDate,
      // endDate: c.campaignType === 'immediate' ? c.createdAt : c.endDate,
      startDate: c.startDate,
      endDate: c.endDate,
    }));
    return {
      campaigns: computed,
      meta: campaign.listCampaignsMeta,
      ...campaign,
    };
  });
  useEffect(() => {
    allCampaign();
  }, []);

  const handleEditPage = (data, id) => {
    setShowEdit(true);
    setGoBackMethod(() => setShowEdit(false));
    setCampaignId(data);
  };
  return (
    <>
      {/* {!showEdit && campaigns.length !== 0 && ( */}

      <div className="allcampaign-wrapper ">
        <div className="header">
          <h5>All Bulk</h5>

          <Button
            group="secondary round"
            value="Send Bulk"
            onClick={() => {
              history.push(ROUTES.CREATE_CAMPAIGN.path);
            }}
          />
        </div>

        <section>
          <Table
            header={[
              {
                key: 'campaign_name',
                label: 'Bulk Name',
                render: (name) => <div className="td-width-100 text-capitalize">{name}</div>,
              },
              {
                key: 'campaign_type',
                label: 'Bulk Type',
                render: (campaign_type) => {
                  return <div className="td-width-100 text-capitalize">{campaign_type}</div>;
                },
              },
              {
                key: 'campaignServiceName',
                label: 'Service Name',
                render: (campaignServiceName) => {
                  return <div className="td-width-100 text-capitalize">{campaignServiceName}</div>;
                },
              },
              { key: 'totalSuccessfulRecipient', label: 'Successful' },
              { key: 'totalBeneficiaries', label: 'Total' },
              {
                key: 'startDate',
                label: 'Start Date',
                render: (date) => (
                  <div className="td-width-100">
                    <Moment format="D MMM, YYYY  - hh:mma" date={date} />
                  </div>
                ),
              },
              {
                key: 'endDate',
                label: 'End Date',
                render: (date) => (
                  <div className="td-width-100">
                    <Moment format="D MMM, YYYY  - hh:mma" date={date} />
                  </div>
                ),
              },
              {
                key: 'status',
                label: 'Status',
                render: (status) => (
                  <div
                    className="text-capitalize"
                    style={{
                      color: `${
                        status === 'pending'
                          ? '#F2994A'
                          : status === 'completed'
                          ? '#63B67F'
                          : '#828282'
                      }`,
                    }}
                  >
                    {status}
                  </div>
                ),
              },
              {
                key: 'id',
                render: (id, row) =>
                  row.campaign_type === 'recurring' &&
                  row.status !== 'completed' && (
                    <div key={id}>
                      <Options
                        meta={id}
                        // options={[
                        //   { icon: 'pencil', label: 'Edit', action: () => console.log(row) },
                        // ]}
                        options={[{ icon: 'pencil', label: 'Edit', action: handleEditPage }]}
                      />
                    </div>
                  ),
              },
            ]}
            data={campaigns}
            loading={fetchingCampaigns}
            showFilter
            paginate
            meta={meta}
            handlePagination={allCampaign}
            filterAction={allCampaign}
            filter={{
              Date: [
                { value: 'Today', label: 'Today' },
                { value: 'This Week', label: 'This Week' },
                { value: 'This Month', label: 'This Month' },
                { value: 'Last 30 Days', label: 'Last 30 Days' },
                { value: 'This Year', label: 'This Year' },
                { value: 'Period', label: 'Specific Period' },
              ],
              bulkType: [
                { value: 'recurring', label: 'Recurring' },
                { value: 'schedulled', label: 'Schedulled' },
                { value: 'immediate', label: 'Immediate' },
              ],
              serviceName: [
                { value: 'Airtime', label: 'Airtime' },
                { value: 'SMS', label: 'SMS' },
                { value: 'Data', label: 'Data' },
              ],
              status: [
                { value: 'completed', label: 'completed' },
                { value: 'pending', label: 'Pending' },
                { value: 'failed', label: 'Failed' },
              ],
            }}
          />
        </section>
      </div>
      {/* )} */}
      {campaigns.length === 0 && !fetchingCampaigns && (
        <StyledNoCampaign>
          {/* <Icon icon="campaignplaceholder" width={500} /> */}
          {/* <div className="header">You have not created any bulk service yet</div>
          <div className="subtitle">A campaign helps you reach a larger audience</div> */}
          {/* <Button
            group="primary"
            value="Refetch"
            onClick={() => {
              // history.push(ROUTES.CREATE_CAMPAIGN.path);
              allCampaign();
            }}
          /> */}
        </StyledNoCampaign>
      )}
      {showEdit && <EditCampaign id={campaignId} />}
    </>
  );
};

export default AllCampaigns;
