import { combineReducers } from 'redux';

import auditReducer from './auditTrail';
import serviceReducer from './serviceReport';
import serviceDetailReducer from './serviceReportDetail';
import transactionReport from './transactionReport';
import usersReducer from './users';
import userProfile from './userProfile';
import merchant from './merchant';
import provider from './provider';
import wallet from './wallet';
import airtime from './airtime';
import sms from './sms';
import data from './data';
import services from './services';
import contacts from './contacts';
import campaign from './campaign';
import senderId from './senderId';
import prices from './prices';

const rootReducer = combineReducers({
  transactionReport,
  serviceReport: serviceReducer,
  serviceReportDetail: serviceDetailReducer,
  audit: auditReducer,
  users: usersReducer,
  profile: userProfile,
  merchant,
  provider,
  wallet,
  airtime,
  sms,
  data,
  services,
  contacts,
  campaign,
  senderId,
  prices,
});

export default rootReducer;
