import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ungroupedContacts: [],
  ungroupedFetchingContacts: false,
  ungroupedContactsMeta: {},
  error: false,

  groupedContacts: [],
  fetchingGroupedContacts: false,
  groupedContactsMeta: {},
  errorGrouped: false,

  contactGroups: [],
  fetchingContactGroups: false,
  errorContactGroups: false,

  uploadSuccess: false,
  uploadingContacts: false,
  errorUploadingContacts: false,

  createContactGroupSuccess: {},
  creatingContactGroup: false,
  createContactGroupError: false,
};

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    fetchContactsSuccessReducer: (state, { payload }) => {
      state.ungroupedContacts = payload.data;
      state.ungroupedFetchingContacts = false;
      state.ungroupedContactsMeta = {
        limit: payload.limit,
        page: payload.page,
        total: payload.total,
      };
    },
    fetchingContactsReducer: (state) => {
      state.ungroupedContacts = [];
      state.ungroupedFetchingContacts = true;
      state.error = false;
    },
    fetchingContactsErrorReducer: (state) => {
      state.ungroupedContacts = [];
      state.ungroupedFetchingContacts = false;
      state.error = true;
    },

    fetchContactGroupsSuccessReducer: (state, { payload }) => {
      state.contactGroups = payload.data;
      state.fetchingContactGroups = false;
    },
    fetchingContactGroupsReducer: (state) => {
      state.contactGroups = [];
      state.fetchingContactGroups = true;
      state.errorContactGroups = false;
    },
    fetchingContactGroupsErrorReducer: (state) => {
      state.contactGroups = [];
      state.fetchingContactGroups = false;
      state.errorContactGroups = true;
    },

    fetchGroupedContactsSuccessReducer: (state, { payload }) => {
      state.groupedContacts = payload.data;
      state.fetchingGroupedContacts = false;
      state.groupedContactsMeta = {
        limit: payload.limit,
        page: payload.page,
        total: payload.total,
      };
    },
    fetchingGroupedContactReducer: (state) => {
      state.groupedContacts = [];
      state.fetchingGroupedContacts = true;
      state.errorGrouped = false;
    },
    fetchingGroupedContactsErrorReducer: (state) => {
      state.groupedContacts = [];
      state.fetchingGroupedContacts = false;
      state.errorGrouped = true;
    },

    uploadContactSuccessReducer: (state, { payload }) => {
      state.uploadingContacts = false;
      state.uploadSuccess = payload.data;
    },
    uploadingContactReducer: (state) => {
      state.uploadSuccess = false;
      state.uploadingContacts = true;
      state.errorUploadingContacts = false;
    },
    uploadContactErrorReducer: (state) => {
      state.uploadSuccess = false;
      state.uploadingContacts = false;
      state.errorUploadingContacts = true;
    },

    createContactGroupSuccessReducer: (state, { payload }) => {
      state.createContactGroupSuccess = payload.data;
      state.creatingContactGroup = false;
    },
    creatingContactGroupSuccessReducer: (state) => {
      state.createContactGroupSuccess = false;
      state.creatingContactGroup = true;
      state.createContactGroupError = false;
    },
    createContactGroupErrorReducer: (state) => {
      state.createContactGroupSuccess = false;
      state.creatingContactGroup = false;
      state.createContactGroupError = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  fetchContactsSuccessReducer,
  fetchingContactsReducer,
  fetchingContactsErrorReducer,

  fetchContactGroupsSuccessReducer,
  fetchingContactGroupsReducer,
  fetchingContactGroupsErrorReducer,

  fetchGroupedContactsSuccessReducer,
  fetchingGroupedContactReducer,
  fetchingGroupedContactsErrorReducer,

  uploadContactSuccessReducer,
  uploadingContactReducer,
  uploadContactErrorReducer,

  createContactGroupSuccessReducer,
  creatingContactGroupSuccessReducer,
  createContactGroupErrorReducer,
} = contactsSlice.actions;

export default contactsSlice.reducer;
