import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Toast from 'react-hot-toast';
import { Row, Col } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { Button } from '../../../components/ui';
import { ROUTES } from '../../../components/router/routes/routes';
import { PasswordInput, Switch } from '../../../components/ui/inputs';
import { API2FA, APIchangePassword } from '../../../api/backendRoutes';
import { errorParser } from '../../../utilities/helper';
import { useUser } from '../../../utilities/hooks/useUser';
import Disable2FAModal from './Disable2FA';

const StyledPassword = styled.div`
  background: #ffffff;
  padding: ${isMobile ? '15px' : '30px'};
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.04);
  form {
    margin: 20px 0px;
  }
`;

const ChangePassword = () => {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const [formData, setFormData] = useState({
    oldPassword: '',
    password: '',
    confirmPassword: '',
    fetching: false,
    error: false,
    errorMsg: '',
  });
  const { user, setUser, state } = useUser();
  const handlePasswordChange = async (e) => {
    e.preventDefault();
    const { oldPassword, password, confirmPassword } = formData;

    if (password === confirmPassword) {
      setFormData((d) => ({ ...d, fetching: true }));
      try {
        const resp = await APIchangePassword({
          oldpassword: oldPassword,
          password,
          confirmPassword,
        });
        if (resp.data.message === 'ok') {
          Toast.success('Passwords changed');
          setFormData((d) => ({ ...d, fetching: false }));
          localStorage.clear();
          history.push(ROUTES.SIGN_IN.path);
        } else {
          setFormData((d) => ({
            ...d,
            fetching: false,
            error: true,
            errorMsg: resp?.data?.error?.data?.error,
          }));
          throw resp?.data?.error;
        }
      } catch (err) {
        setFormData((d) => ({ ...d, fetching: false }));
        Toast.error(errorParser(err) || 'Error changing password');
      }
    } else {
      setFormData((d) => ({ ...d, error: 'Passwords do not match' }));
      Toast.error('Passwords do not match');
    }
  };

  const update2FA = async () => {
    if (user['2FA']) {
      await API2FA({
        set2FA: !user['2FA'],
      });
      setShowModal(true);
    } else {
      const res = await API2FA({
        set2FA: !user['2FA'],
      });
      if (res.data.success === true) {
        Toast.success(
          !user['2FA'] === true
            ? 'Two Factor Authentication Enabled'
            : 'Two Factor Authentication Disabled'
        );
        setUser({ ...user, '2FA': !user['2FA'] });
      }
    }
  };

  const handleInputChange = ({ name, value }) => {
    setFormData((v) => ({ ...v, [name]: value, error: false }));
  };
  return (
    <StyledPassword>
      <Row>
        <Col>
          <div>Change Password</div>
        </Col>
      </Row>
      <form onSubmit={handlePasswordChange}>
        <Row>
          <Col md={6}>
            <PasswordInput
              placeholder="Enter Current Password"
              label="Current Password"
              name="oldPassword"
              validate="required"
              onChange={handleInputChange}
              defaultValue={formData.oldPassword}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <PasswordInput
              placeholder="Enter New Password"
              label="New Password"
              name="password"
              validate="required"
              onChange={handleInputChange}
              defaultValue={formData.password}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <PasswordInput
              placeholder="Confirm  Password"
              label="confirm Password"
              name="confirmPassword"
              validate="required"
              onChange={handleInputChange}
              defaultValue={formData.confirmPassword}
            />
          </Col>
        </Row>
        {state.fetchingUser ? (
          <></>
        ) : (
          <Row>
            <Col md={6}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  margin: '20px 0px',
                }}
              >
                <div> Two factor Authentication</div>
                <Switch name="twofa" value={user['2FA']} onChange={update2FA} />
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col className="btn-align-left">
            <Button value="Save changes" type="submit" loading={formData.fetching} />
          </Col>
        </Row>
        {formData.error && (
          <div
            style={{
              fontSize: '12px',
              color: 'red',
              margin: '10px 0px',
            }}
          >
            {formData.errorMsg}
          </div>
        )}
      </form>
      <Disable2FAModal
        show={showModal}
        closeModal={() => setShowModal(false)}
        action={() => {
          setUser({ ...user, '2FA': !user['2FA'] });
        }}
      />
    </StyledPassword>
  );
};

export default ChangePassword;
