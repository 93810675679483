import React, { useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import Moment from 'react-moment';
// import NumberFormat from 'react-number-format';
import { APIGetReciept } from '../../api/backendRoutes';
import { Button, Modal } from '../../components/ui';
import { fetchUserData } from '../../storage/sessionStorage';
import logo from '../../assets/images/Logo.svg';

// import { Switch } from '../../../components/ui/inputs';
const saveAs = (blob, fileName) => {
  const elem = window.document.createElement('a');
  elem.href = blob;
  elem.download = fileName;
  //  elem.style = "display:none;";
  (document.body || document.documentElement).appendChild(elem);
  if (typeof elem.click === 'function') {
    elem.click();
  } else {
    elem.target = '_blank';
    elem.dispatchEvent(
      new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
    );
  }
  URL.revokeObjectURL(elem.href);
  elem.remove();
};

const exportAsPicture = (transId) => {
  //   var html = document.getElementsByTagName("HTML")[0];
  //   console.log(html);
  //   //   var body = document.getElementsByTagName("BODY")[0];
  //   //   var htmlWidth = html.clientWidth;
  //   //   var bodyWidth = body.clientWidth;

  const data = document.getElementById('exportContainer');
  if (data !== null) {
    //   //     var newWidth = data.scrollWidth - data.clientWidth;

    //   //     if (newWidth > data.clientWidth) {
    //   //       htmlWidth += newWidth;
    //   //       bodyWidth += newWidth;
    //   //     }

    //   //     html.style.width = htmlWidth + "px";
    //   //     body.style.width = bodyWidth + "px";
    //   //     html.style.width = htmlWidth + "px";
    //   //     body.style.width = bodyWidth + "px";

    html2canvas(data, { backgroundColor: null })
      .then((canvas) => {
        const image = canvas.toDataURL('image/png', 1.0);
        return image;
      })
      .then((image) => {
        saveAs(image, `${transId}.png`);
        // saveAs(image, "exported-vis.png");
        // html.style.width = null;
        // body.style.width = null;
      });
  }
};

const RecieptModal = ({ show, onClose,token, id, respData }) => {
  const userData = fetchUserData();
  const [loading, setLoading] = useState({ status: true, error: '' });
  const [recieptData, setReciepData] = useState(null);
  useEffect(async () => {
    try {
      const res = await APIGetReciept(id);
      if (res.data.success && res.data.data) {
        setReciepData(res.data.data);
        setLoading({ status: false, error: '' });
      } else {
        setLoading({ status: false, error: 'Transaction not successful, reciept does not exist' });
      }
    } catch (err) {
      setLoading({ status: false, error: 'Transaction reciept does not exist' });
    }
  }, []);

  return (
    <Modal show={show} onClose={onClose}>
      {loading.status ? (
        <h5 className="text-center">Genrating report...</h5>
      ) : loading.error ? (
        <h5 className="text-center">{loading.error}</h5>
      ) : (
        <>
          <div id="exportContainer" className="p-4 bg-light">
            <div
              className="d-flex mb-2"
              style={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img alt="logo" src={logo} style={{ height: 40, width: 'auto', display: 'block' }} />
            </div>
            <h6 style={{ lineHeight: '25px' }}>
              Hi <span className="font-weight-bold">{userData?.firstName}</span>, <br /> Thank you
              for your purchase. This document serves as a receipt for your purchase, No payment is
              due with this receipt.
            </h6>
            <br />
            <h5>Payment details</h5>
            <br />
            {/* {recieptData?.totalTransactionCost > 0 && ( */}
            <div
              className="d-flex mb-2"
              style={{
                justifyContent: 'space-between',
              }}
            >
              <p className="text-small font-weight-bold">Amount</p>
              <p className="text-small">
                {parseFloat(recieptData?.totalTransactionCost).toFixed(2) || 0}
              </p>
            </div>
            {token && (
              <div
                className="d-flex mb-2"
                style={{
                  justifyContent: 'space-between',
                }}
              >
                <p className="text-small font-weight-bold">Token</p>
                <p className="text-small">{token || 0}</p>
              </div>
            )}
            {/* )} */}
            {recieptData?.transactions[0]?.category && (
              <div
                className="d-flex mb-2"
                style={{
                  justifyContent: 'space-between',
                }}
              >
                <p className="text-small font-weight-bold">Paid for</p>
                <p className="text-small">{recieptData?.transactions[0]?.category}</p>
              </div>
            )}
            {respData?.successNumbers?.length > 0 && (
              <div
                className="d-flex mb-2"
                style={{
                  justifyContent: 'space-between',
                }}
              >
                <p className="text-small font-weight-bold">Successful Trans.</p>
                <p className="text-small d-flex flex-wrap justify-content-end">
                  {respData?.successNumbers.map((data, i) => (
                    <span className="d-block ">
                      {data.phoneNumber}{i + 1 === respData?.successNumbers.length ? '.' : ','}
                    </span>
                  ))}
                </p>
              </div>
            )}
            {respData?.failedNumbers?.length > 0 && (
              <div
                className="d-flex mb-2"
                style={{
                  justifyContent: 'space-between',
                }}
              >
                <p className="text-small font-weight-bold">Failed Trans.</p>
                <p className="text-small d-flex flex-wrap justify-content-end">
                  {respData?.failedNumbers.map((data, i) => (
                    <span className="d-block ">
                      {data.phoneNumber}{i + 1 === respData?.failedNumbers.length ? '.' : ','}{' '}
                    </span>
                  ))}
                </p>
              </div>
            )}

            {recieptData.transactions[0]?.transactionReference && (
              <div
                className="d-flex mb-2"
                style={{
                  justifyContent: 'space-between',
                }}
              >
                <p className="text-small font-weight-bold">Transaction Reference</p>
                <p className="text-small">{recieptData.transactions[0]?.transactionReference}</p>
              </div>
            )}
            <div
              className="d-flex mb-2"
              style={{
                justifyContent: 'space-between',
              }}
            >
              <p className="text-small font-weight-bold">Transaction Date</p>
              <p className="text-small">
                <Moment format="D MMM, YYYY" date={recieptData.transactions[0]?.createdAt} /> -
                <Moment format="h:mm:SSa" date={recieptData.transactions[0]?.createdAt} />
              </p>
            </div>
          </div>
          <div className="d-flex">
            <div className="mr-4">
              <Button type="submit" value="Download" onClick={() => exportAsPicture(id)} />
            </div>
            <div>
              <Button type="submit" value="Print" onClick={() => window.print()} />
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default RecieptModal;
