import React, { useState } from 'react';
import styled from 'styled-components';
import Icon from '../ui/Icon';

function Notification() {
  const [show, setShow] = useState(false);
  const toggle = () => {
    setShow((prev) => !prev);
  };
  return (
    <>
      {show ? (
        <Notification.Wrapper>
          <div className="side-container">
            <div className="d-flex p-3 border-bottom">
              <h4>Notifications</h4>{' '}
              <Icon
                icon="close"
                style={{ cursor: 'pointer', marginLeft: 'auto' }}
                onClick={toggle}
              />
            </div>
            <div className="notification-content p-3">No notification at the moment.</div>
          </div>
        </Notification.Wrapper>
      ) : (
        <Icon icon="bell" margin={[0, 25]} onClick={toggle} />
      )}
    </>
  );
}

export default Notification;

Notification.Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  min-width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  z-index: 20;
  flex-direction: row-reverse;
  .side-container {
    background: white;
    height: 100vh;
    width: 430px;
    .notification-content {
      overflow-y: scroll;
      height: inherit;
    }
  }
  @media screen and (max-width: 500px) {
    .side-container {
      width: 100%;
    }
  }
`;
