import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import Icon from './Icon';
import actionsIcon from '../../assets/icons/actionsIcon.svg';



const Options = ({ name, src, margin, options = [], meta }) => {
  return (
    <div className="options-wrapper" isMobile={isMobile}>
      <NavDropdown
        title={
          <div className="option">
            <img src={actionsIcon} alt="more" icon="more-alt" margin={[0, 10]} />
          </div>
        }
      >
        {options.map(({ label, action, icon }, key) => (
          <NavDropdown.Item
            onClick={() => action(meta)}
            key={key}
            style={{ display: 'flex', margin: '5px 0px', gap: '5px', alignItems: 'center' }}
          >
            {icon && <Icon icon={icon} margin={[0, 10]} />}
            {label}
          </NavDropdown.Item>
        ))}
      </NavDropdown>
    </div>
  );
};

export default Options;
