// import Toast from 'react-hot-toast';
import { APIPriceTable } from '../../api/backendRoutes';
import {
  fetchPricesSuccessReducer,
  fetchingPricesReducer,
  fetchingPricesErrorReducer,
} from '../slices/prices';

export const fetchPricesAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchingPricesReducer());
    try {
      const resp = await APIPriceTable(params);
      if (resp.data.message === 'ok') {
        dispatch(fetchPricesSuccessReducer(resp.data));
      } else {
        dispatch(fetchingPricesErrorReducer());
        throw resp?.data?.error;
      }
    } catch (error) {
      // Toast.error(error?.response?.data?.error?.message || 'Server error');
      dispatch(fetchingPricesErrorReducer());
    }
  };
