import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Modal, Button } from '../../../components/ui';
import { Input, InputSelect, PhoneInput } from '../../../components/ui/inputs';
import { APIaddTeamMate } from '../../../api/backendRoutes';
import { fetchUserData } from '../../../storage/sessionStorage';
import { errorParser } from '../../../utilities/helper';

const CreateUser = ({ show, onClose, roles, next }) => {
  const user = fetchUserData();
  const [error, setError] = useState([false, '']);
  const [formData, setFormData] = useState({
    email: '',
    phone_number: '',
    role: '',
    fetching: false,
  });
  const handleInputChange = ({ name, value }) => {
    setFormData((v) => ({ ...v, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const newPayload = {
      companyId: user.companyId,
      firstName: formData.first_name,
      lastName: formData.last_name,
      phoneNumber: formData.phone_number,
      email: formData.email,
      memberRole: formData.role,
    };
    try {
      setFormData((v) => ({ ...v, fetching: true }));
      const resp = await APIaddTeamMate(newPayload);
      if (resp.data.success) {
        setFormData({ email: '', phone_number: '', role: '', fetching: false });
        setError([false, '']);
        next();
      } else {
        setFormData((v) => ({ ...v, fetching: false }));
        throw resp?.data.error;
      }
    } catch (err) {
      const errMessage = errorParser(err);
      setFormData((v) => ({ ...v, fetching: false }));
      setError([true, errMessage]);
    }
  };
  return (
    <>
      <Modal show={show} onClose={onClose} title="Add New User">
        <form style={{ marginTop: 20 }} onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Input
                placeholder="First Name"
                label="First Name"
                type="text"
                onChange={handleInputChange}
                defaultValue={formData.first_name}
                name="first_name"
              />
            </Col>
            <Col>
              <Input
                placeholder="Last Name"
                label="Last Name"
                type="text"
                onChange={handleInputChange}
                defaultValue={formData.last_name}
                name="last_name"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                placeholder="Email"
                label="Email"
                type="email"
                onChange={handleInputChange}
                defaultValue={formData.email}
                name="email"
              />
            </Col>
            <Col>
              <PhoneInput
                label="Phone Number"
                placeholder="Phone Number e.g 090xxxxxxxx"
                name="phone_number"
                validate="required|phone_number"
                defaultValue={formData.phone_number}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <InputSelect
                placeholder="Select Role"
                label="Role"
                onChange={handleInputChange}
                defaultValue={formData.role}
                options={roles?.map((r) => ({ value: r.id, label: r.roleName }))}
                name="role"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                value="Submit"
                type="submit"
                margin={[10]}
                center
                size="lg"
                width={200}
                loading={formData.fetching}
              />
            </Col>
          </Row>
          {error[0] && (
            <div
              style={{
                fontSize: '12px',
                color: 'red',
                marginTop: '10px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {error[1]}
            </div>
          )}
        </form>
      </Modal>
    </>
  );
};
export default CreateUser;
