import React from 'react';
import styled from 'styled-components';

const StyledImage = styled.div.attrs((props) => ({ ...props }))`
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  width: ${({ width }) => (width ? `${width}px` : '')};
  height: ${({ height }) => (height ? `${height}px` : '')};
  border-radius: ${({ round }) => (round ? '50%' : 'none')};
  overflow: hidden;

  img {
    margin: auto;
    height: inherit;
    width: inherit;
  }
`;

const Image = ({
  src = '',
  name = '',
  margin = [],
  padding = [],
  color = '',
  size = 'sm',
  width,
  height,
  round,
}) => {
  return (
    <StyledImage
      margin={margin.length > 0 && `${margin.join('px ')}px;`}
      padding={padding.length > 0 && `${padding.join('px ')}px;`}
      color={color}
      size={size}
      width={width}
      height={height}
      round={round}
    >
      <img src={src} alt={name} width={width} />
    </StyledImage>
  );
};

export default Image;
