import React, { useEffect, useState } from 'react';
import Icon from './Icon';

const Upload = ({
  icon = 'upload',
  text = '',
  hint,
  name = 'csv',
  multiple = false,
  show = false,
  accept = '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  onChange,
  value,
  required,
  className,
  iconWidth,
}) => {
  const [state, setState] = useState({ key: new Date(), files: null });
  useEffect(() => {
    if (!value) {
      setState({ key: new Date(), files: null });
    }
  }, [value]);

  useEffect(() => {
    if (onChange) {
      onChange({ name, value: state.files });
    }
  }, [state.files]);

  const handleInputChange = (e) => {
    const { files } = e.target;
    // const newFile = [...files];
    setState((d) => ({ ...d, files }));
  };

  const removeFile = (n) => {
    setState((d) => {
      const files = [...d.files];
      const fileIndex = files.findIndex((f) => f.name === n);
      const newFiles = files.splice(fileIndex, 1);

      return { ...d, files: newFiles };
    });
  };
  const handleCancel = () => {
    setState({ key: new Date(), files: null });
  };
  return (
    <div className={className} style={{ marginBottom: 10 }}>
      <div className="upload-wrapper">
        <label htmlFor={name}>
          <input
            type="file"
            name={name}
            id={name}
            onChange={handleInputChange}
            multiple={multiple}
            accept={accept}
            required={required}
          />
          <span className="link" style={{ fontWeight: 500, display: 'flex', alignItems: 'center' }}>
            {icon && <Icon icon={icon} width={iconWidth} margin={[0, 10, 0, 0]} />}
            {text}
          </span>
        </label>
        {hint && <span style={{ marginLeft: 5, fontSize: 14 }}>{hint}</span>}
        {show && state?.files?.length > 0 && (
          <span className="link cancel" onClick={handleCancel} key={state.key}>
            Cancel
          </span>
        )}
      </div>

      {show && state.files && <Upload.Result files={state.files} removeFile={removeFile} />}
    </div>
  );
};

Upload.Result = ({ files = [], removeFile }) => {
  // const handleRemoveFile = (name) => {
  //   if (removeFile) {
  //     removeFile(name);
  //   }
  // };

  return (
    <div className="uploadResult">
      <div>
        <Icon icon="fileupload" margin={[0, 15, 0, 0]} />
        <div className="files">
          {Array.from(files).map(({ name, size = 0 }, index) => (
            <span key={index}>
              <span>
                {name} ({size > 1024 ? `${parseInt(size / 1024, 10)}kb` : `${size}bytes`})
              </span>
              {/* <span style={{ fontWeight: 500, color: 'red', cursor: 'pointer', marginLeft: 10 }}>
                <Icon
                  icon="close"
                  width={20}
                  style={{ cursor: 'pointer', marginLeft: 'auto' }}
                  onClick={() => handleRemoveFile(name)}
                />
              </span> */}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Upload;
