import React from 'react';



const Checkbox = React.forwardRef(({ label, name }, ref) => {
  return (
    <div className="checkbox-wrapper">
      <input type="checkbox" ref={ref} name={name} id={name} />
      <label htmlFor={name}>{typeof label === 'function' ? label() : label}</label>
    </div>
  );
});

export default Checkbox;
