export const getCountryCode = (text) => {
  if (typeof text !== 'string') throw new Error('Invalid Country Code');
  if (text.toLowerCase()) return '234';
};

export const getCurrencyType = (text) => {
  if (typeof text !== 'string') throw new Error('Invalid Country Type');
  if (text.toLowerCase()) return 'NGN';
};
export const hexToRgb = (hex)=> {
  const normal = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
  if (normal) return normal.slice(1).map((e) => parseInt(e, 16));

  const shorthand = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i);
  if (shorthand) return shorthand.slice(1).map((e) => 0x11 * parseInt(e, 16));

  return null;
}
