import React, { useState } from 'react';
import { Accordion as BAccordion } from 'react-bootstrap';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';



const Accordion = ({ data, defaultActiveKey }) => {
  const [activeKey, setActiveKey] = useState(defaultActiveKey);

  return (
    <div className="accordion-wrapper">
      <BAccordion defaultActiveKey={activeKey} onSelect={(k) => setActiveKey(k)}>
        {data.map(({ header, body, eventKey }) => (
          <>
            <div className="accordion-header">
              {header}
              <div className="accordion-body">
                <BAccordion.Collapse eventKey={eventKey}>{body}</BAccordion.Collapse>
              </div>
            </div>
          </>
        ))}
      </BAccordion>
    </div>
  );
};

Accordion.Toggle = ({ value, eventKey, callback }) => {
  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

  return (
    <span className="accordion-toggle" onClick={(e) => decoratedOnClick(e)}>
      {value}
    </span>
  );
};

export default Accordion;
