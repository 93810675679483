import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Button, Card } from '../../../components/ui';
import { Input, InputSelect, PhoneInput } from '../../../components/ui/inputs';
import WalletBalance from '../../wallet/components/WalletBalance';
import SuccessModal from '../../wallet/components/PaymentSuccess';
import CheckOut from '../../wallet/components/CheckOut';
import {
  APIGetBillersCategoryServices,
  APIGetTVPlans,
  APIPayBillers,
  APIvalidateBillersCableTv,
} from '../../../api/backendRoutes';
import { fetchWallet } from '../../../redux/actions/wallet';
import { errorParser } from '../../../utilities/helper';

const CableTV = ({ id }) => {
  const dispatch = useDispatch();
  const [selectedBiller, setSelectedBiller] = useState();
  const [bouquet, setBouquet] = useState([]);
  const [biller, setBiller] = useState([]);
  const [plans, setPlans] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [showCheckOut, setShowCheckOut] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState({ show: false, headerText: null });
  const [price, setPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [resolvedData, setResolvedData] = useState(null);
  const handleChange = async ({ name, value }) => {
    if (name === 'customerID' && value?.length > 8) {
      try {
        setLoading(true);
        const val = selectedBiller.biller.split('-');
        const resolve = await APIvalidateBillersCableTv({
          customerID: value,
          serviceCode: val[1],
          serviceType: 'cable_tv',
          apiServiceId: val[0],
          serviceId: id,
          // phoneNumber: selectedBiller.phoneNumber,
        });
        if (resolve.data.message === 'ok') {
          setLoading(false);
          const { data } = resolve.data;
          if (data.statusDescription.customerNo === undefined) {
            setResolvedData({ error: 'Unable to verify data, please try again' });
          } else {
            setResolvedData(data.statusDescription);
          }
        } else {
          throw resolve?.data?.error;
        }
      } catch (error) {
        setLoading(false);
        setResolvedData({ error: 'Unable to verify data, please try again' });
      }
    }
    if (name === 'bouquet') {
      setPlans(bouquet?.filter((dt) => dt.code === value)[0]?.availablePricingOptions);
      setPrice(0);
    }
    if (name === 'biller') {
      setPlans([]);
      setResolvedData(null);
      setPrice(0);
      setSelectedBiller((v) => ({ ...v, invoicePeriod: '', customerID: '' }));
      // const newArr = selectedBiller?.invoicePeriod?.split('|');
      // setPrice(newArr?.length > 0 ? newArr[0] : 0);
      const val = value.split('-');
      try {
        const resp = await APIGetTVPlans({
          cableTvCategory: val[1],
          serviceId: id,
        });
        if (resp.data.message === 'ok') {
          setBouquet(resp.data.data);
          setDisabled(false);
        } else {
          throw resp?.data?.error;
        }
      } catch (err) {
        // console.log(err);
      }
    }
    setSelectedBiller((v) => ({ ...v, [name]: value }));
  };
  // eslint-disable-next-line no-unused-vars
  const fetchUserWallet = useCallback(() => {
    dispatch(fetchWallet());
  }, [dispatch]);
  const { balance } = useSelector((state) => {
    const newId = state?.services?.services?.filter((s) => s.serviceName === 'PayBills')[0];
    return {
      balance: state?.wallet?.walletNfo?.balance,
      serviceId: newId?._id,
    };
  });

  useEffect(async () => {
    try {
      const resp = await APIGetBillersCategoryServices({
        serviceId: id,
      });
      if (resp.data.message === 'ok') {
        const { data } = resp.data;
        setBiller(data);
        setDisabled(false);
      } else {
        throw resp?.data?.error;
      }
    } catch (err) {
      // console.log(err);
    }
  }, []);

  useEffect(async () => {
    const newArr = selectedBiller?.invoicePeriod?.split('|');
    setPrice(newArr?.length > 0 && newArr[0] !== '' && plans.length > 0 ? newArr[0] : 0);
  }, [selectedBiller]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowCheckOut(true);
  };
  const handleCloseSuccessModal = () => {
    // fetchUserWallet();
    // setShowSuccessModal({ show: false, headerText: null });
    window.location.reload();
  };
  const handleSend = async () => {
    setShowCheckOut(false);

    const newArr = selectedBiller.invoicePeriod.split('|');
    const val = selectedBiller?.biller.split('-');
    const newPayload = {
      customerID: `${resolvedData?.customerNo}`,
      amount: newArr[0],
      apiServiceId: val[0],
      customerName: `${resolvedData?.firstname} ${resolvedData?.lastname}`,
      productCodes: [selectedBiller.bouquet],
      invoicePeriod: newArr[1],
      serviceId: id,
      billerSubCategory: val[1],
      phoneNumber: selectedBiller.phoneNumber,
      serviceType: 'cable_tv',
    };
    setLoading(true);
    try {
      const resp = await APIPayBillers(newPayload);
      if (resp.data.message === 'ok') {
        setLoading(false);
        setShowSuccessModal({
          show: true,
          transactionId: resp.data.data.transactionReference,
          receipt: true,
        });
      } else {
        setLoading(false);
        throw resp?.data?.error;
      }
    } catch (err) {
      setResolvedData({
        ...resolvedData,
        error: errorParser(err),
      });
      setLoading(false);
    }
  };

  return (
    <div className="cabletv-wrapper">
      <section>
        <Row>
          <Col md={8} style={{ marginBottom: 20 }}>
            <form onSubmit={handleSubmit}>
              <Card>
                <Row>
                  <Col>
                    <InputSelect
                      placeholder="Enter or Select Biller"
                      label="Enter or Select Biller"
                      name="biller"
                      validate="required"
                      onChange={handleChange}
                      options={biller?.map((b) => ({
                        value: `${b?.id}-${b?.service.toLowerCase()}`,
                        label: b?.service.toUpperCase(),
                        icon: b?.service.toUpperCase(),
                      }))}
                      iconStart
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      placeholder="Enter IUC Number"
                      label="Enter IUC Number"
                      name="customerID"
                      validate="required"
                      onChange={handleChange}
                      inputProps={{ maxLength: 10 }}
                      value={selectedBiller?.customerID}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {resolvedData?.customerNo && (
                      <div
                        style={{
                          borderLeft: '2px solid #16568b',
                          padding: '10px 15px',
                          background: 'rgba(80, 159, 239, 0.03)',
                          fontSize: '12px',
                          color: '#505050',
                          marginBottom: '20px',
                        }}
                      >
                        Account Name:
                        <span
                          style={{
                            fontWeight: 'bold',
                            fontSize: '14px',
                            color: '#17568B',
                            cursor: 'pointer',
                          }}
                        >
                          {resolvedData?.customerName}
                        </span>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputSelect
                      placeholder="Select Bouquet"
                      label="Select Bouquet"
                      name="bouquet"
                      validate="required"
                      onChange={handleChange}
                      options={
                        bouquet.length > 0
                          ? bouquet?.map((v) => ({
                              value: v.code,
                              label: v.name,
                            }))
                          : []
                      }
                      loading={disabled}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputSelect
                      placeholder="Select a plan"
                      label="Select a plan"
                      name="invoicePeriod"
                      validate="required"
                      onChange={handleChange}
                      options={plans?.map((dt) => ({
                        label: `N${dt.price} | ${dt.monthsPaidFor} Months(s)`,
                        value: `${dt.price}|${dt.invoicePeriod}`,
                      })) || []}
                      loading={disabled}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <PhoneInput
                      label="Enter Phone Number"
                      placeholder="Enter Phone Number "
                      name="phoneNumber"
                      validate="required|phone_number"
                      hint=""
                      onChange={handleChange}
                    />
                    {(resolvedData?.error || resolvedData === {}) && (
                      <div className="error">
                        {resolvedData?.error || 'Unable to resolve Customer Number'}
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="submit">
                  <Col>
                    <Button
                      disabled={resolvedData?.customerNo === undefined}
                      value="Pay"
                      width="100%"
                      size="lg"
                      type="submit"
                      loading={loading}
                    />
                  </Col>
                  {/* <Col md={6}>
                    <Button
                      value="Schedule for Later"
                      group="outline"
                      width="100%"
                      size="lg"
                      onClick={() => setShowSchedule(true)}
                    />
                  </Col> */}
                </Row>
              </Card>
            </form>
          </Col>
          <Col md={4}>
            <WalletBalance price={price ?? '0'} />
          </Col>
        </Row>
      </section>
      <SuccessModal
        show={showSuccessModal.show}
        transactionId={showSuccessModal.transactionId}
        onClose={handleCloseSuccessModal}
        price={price}
        receipt={showSuccessModal.receipt}
        setShowSuccessModal={setShowSuccessModal}
      />
      <CheckOut
        show={showCheckOut}
        onClose={() => setShowCheckOut(false)}
        execute={handleSend}
        price={price}
        disableWallet={price > balance}
      />
    </div>
  );
};

export default CableTV;
