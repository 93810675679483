import React, { useEffect, useCallback, useState } from 'react';
import Moment from 'react-moment';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button } from '../../components/ui';
import { fetchAuditTrailsAction } from '../../redux/actions/auditTrail';
import { APIExportAudit } from '../../api/backendRoutes';
import { errorParser } from '../../utilities/helper';

const AuditTrail = () => {
  const [loading, setLoading] = useState(false);
  const { auditTrails, meta, fetching } = useSelector((state) => {
    return {
      auditTrails: state.audit.auditTrails,
      meta: state.audit.auditTrailsMeta,
      fetching: state.audit.fetchingAuditTrails,
    };
  });

  const dispatch = useDispatch();

  const FetchAudt = useCallback(
    (params = { limit: 25 }) => {
      dispatch(fetchAuditTrailsAction(params));
    },
    [dispatch]
  );

  useEffect(() => {
    FetchAudt();
  }, []);

  const getCSV = async () => {
    try {
      setLoading(true);
      const resp = await APIExportAudit();
      if (resp.data.message === 'ok') {
        toast.success('A generated report has been sent to your mail');
        setLoading(false);
      } else {
        setLoading(false);
        throw resp?.data?.error;
      }
    } catch (err) {
      toast.error(errorParser(err) || 'error');
      setLoading(false);
    }
  };

  return (
    <div className="report-wrapper">
      <div className="header">
        <h5>Audit Trail</h5>

        {auditTrails.length > 0 && (
          <Button value="Download CSV" loading={loading} onClick={() => getCSV()} />
        )}
      </div>
      <section>
        <Table
          header={[
            {
              key: 'firstName',
              label: 'User',
              render: (firstName, { lastName }) => (
                <>
                  <div>
                    {firstName} {lastName}
                  </div>
                </>
              ),
            },
            {
              key: 'activityType',
              label: 'Activity Type',
              render: (activityType) => {
                return <div className="td-width-100">{activityType}</div>;
              },
            },
            {
              key: 'description',
              label: 'Description',
              render: (description) => {
                return <div className="td-width-150 text-capitalize">{description}</div>;
              },
            },
            {
              key: 'createdAt',
              label: 'Date',
              render: (createdAt) => {
                const newDate = new Date(createdAt);
                return (
                  <div className="td-width-100">
                    <Moment format="D MMM, YYYY" date={newDate} /> <Moment format="h:mm:SSa" date={newDate} />
                  </div>
                );
              },
            },
          ]}
          data={auditTrails}
          filterAction={FetchAudt}
          filter={{
            Date: [
              { value: 'Today', label: 'Today' },
              { value: 'This Week', label: 'This Week' },
              { value: 'This Month', label: 'This Month' },
              { value: 'Last 30 Days', label: 'Last 30 Days' },
              { value: 'This Year', label: 'This Year' },
              { value: 'Period', label: 'Specific Period' },
            ],
            activityType: [
              { value: 'Wallet Credit', label: 'Wallet Credit' },
              { value: 'Wallet Debit', label: 'Wallet Debit' },
              { value: 'Wallet Topup', label: 'Wallet Topup' },
              { value: 'Insufficient Balance', label: 'Insufficient Balance' },
              { value: 'SMS', label: 'SMS' },
              { value: 'Airtime Purchase', label: 'Airtime Purchase' },
              { value: 'Data Topup Purchase', label: 'Data Topup Purchase' },
              { value: 'Log In', label: 'Log In' },
            ],
            search: 'search',
          }}
          showFilter
          paginate
          meta={meta}
          handlePagination={FetchAudt}
          loading={fetching}
        />
      </section>
    </div>
  );
};

export default AuditTrail;
