import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Icon, Tabs } from '../../components/ui';
import CreateSenderID from './components/CreateSenderID';
import { fetchSenderIdAction } from '../../redux/actions/senderId';

const SenderId = () => {
  const [showCreateId, setShowCreateId] = useState(false);

  const dispatch = useDispatch();
  const fetchId = useCallback(() => {
    dispatch(fetchSenderIdAction());
  }, [dispatch]);

  const { senderId, fetching } = useSelector((state) => {
    const temp = state.senderId.senderId;
    const computed = temp?.map((v) => ({
      sender_id: v.name,
      country: v.countryId?.name || 'N/A',
      id: v._id,
      status: v.approval_status,
    }));
    return {
      senderId: computed,
      fetching: state.senderId.fetching
    };
  });
  useEffect(() => {
    fetchId();
  }, []);
  const ShowSenderId = ({ senderIdData }) => {
    return (
      <>
        <Table
          header={[
            { key: 'sender_id', label: 'Sender/Device ID' },
            { key: 'country', label: 'Country' },
            {
              key: 'status',
              label: 'Status',
              render: (status) => (
                  <div
                    style={{
                      background: 'rgba(99, 182, 127, 0.15)',
                      color: `${
                        status === 'approved'
                          ? '#63B67F'
                          : status === 'pending'
                          ? '#828282'
                          : '#DF374B'
                      }`,
                      borderRadius: '8px',
                      width: 'fit-content',
                      padding: '5px 15px',
                      textTransform: 'capitalize',
                      fontWeight: 'bold',
                    }}
                  >
                    {status}
                  </div>
              ),
            },
            {
              key: 'menu',
              render: (datum) => (
                <div>{' '}</div>
                // <Table.Actions
                //   actions={[
                //     { icon: 'pencil-alt', title: 'Edit Group' },
                //     { icon: 'trash', type: 'danger', title: 'Delete Group' },
                //   ]}
                // />
              ),
            },
          ]}
          data={senderIdData}
          loading={fetching}
        />
      </>
    );
  };
  return (
    <div className="h-100">
      {senderId.length === 0 && !fetching &&  (
        <div className="campaign__no--sender">
          <Icon icon="senderId" width={300} />
          <div className="title">
            <div className="header">No SenderID registered yet</div>
            <div className="subtitle">
              A sender ID is required to send sms messages on BluIntouch
            </div>
          </div>
          <Button value="Add SenderID" onClick={() => setShowCreateId(true)} />
        </div>
      )}
      {senderId.length > 0 && !fetching && (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h5>Manage Your Identifications</h5>
            <Button
              group="secondary round"
              value="Create New"
              icon="plus-circle"
              onClick={() => setShowCreateId(true)}
            />
          </div>

          <section>
            <Tabs
              header={[
                { key: 'all', label: 'All' },
                { key: 'pending', label: 'Pending' },
                { key: 'approved', label: 'Approved' },
                { key: 'rejected', label: 'Rejected' },
              ]}
              data={{
                all: <ShowSenderId senderIdData={senderId} />,
                approved: (
                  <ShowSenderId senderIdData={senderId.filter((f) => f.status === 'approved')} />
                ),
                pending: (
                  <ShowSenderId senderIdData={senderId.filter((f) => f.status === 'pending')} />
                ),
                rejected: (
                  <ShowSenderId senderIdData={senderId.filter((f) => f.status === 'rejected')} />
                ),
              }}
            />
          </section>
        </>
      )}
      <CreateSenderID
        fetchId={fetchId}
        show={showCreateId}
        onClose={() => setShowCreateId(false)}
      />
    </div>
  );
};

export default SenderId;
