import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '../../components/ui';
// import Dealer from './components/Dealer';
import Internet from './components/Internet';
import CableTV from './components/CableTV';
import Electricity from './components/Electricity';

const PayBills = () => {
  const { services, fetching } = useSelector((state) => {
    return {
      services: state.services.services
        .filter(
          (dt) =>
            dt.serviceName === 'Cable TV' ||
            dt.serviceName === 'Electricity' ||
            dt.serviceName === 'Internet'
        )
        .sort((a, b) => (a.serviceName > b.serviceName ? 1 : -1)),
      fetching: state.services.fetchServices,
      error: state.services.error,
    };
  });

  const [bill, setBill] = useState();

  useState(() => {
    setBill(services[0]);
  }, [fetching]);
  return (
    <div className="paybill-wrapper">
      <div className="header">
        <h5>Select Bill Category</h5>
      </div>

      <section>
        <div className="billers">
          {services.map((service) => (
            <div
              key={service._id}
              className="biller-card"
              onClick={() => setBill(service)}
              id={bill?.serviceName === service.serviceName ? 'active' : null}
            >
              <div className="content">
                <Icon
                  icon={
                    service.serviceName === 'Cable TV'
                      ? 'cabletv'
                      : service.serviceName.toLowerCase()
                  }
                />
                <span>{service.serviceName}</span>
              </div>
            </div>
          ))}
        </div>

        {fetching ? (
          'loading'
        ) : (
          <section className="info-sec">
            <div className="header">{bill?.name && `Fill in your information ${bill?.name}`}</div>
            {bill?.serviceName === 'Cable TV' && (
              <CableTV id={services.filter((dt) => dt.serviceName === 'Cable TV')[0]._id} />
            )}
            {bill?.serviceName === 'Electricity' && (
              <Electricity id={services.filter((dt) => dt.serviceName === 'Electricity')[0]._id} />
            )}
            {bill?.serviceName === 'Internet' && (
              <Internet id={services.filter((dt) => dt.serviceName === 'Internet')[0]._id} />
            )}
            {/* {bill?.name === 'Internet' && <h1>Coming soon...</h1>} */}
            {bill?.name === 'Dealer' && <h1>Coming soon...</h1>}
            {/* {bill.name === 'Dealer' && <Dealer />} */}
            {bill?.name === 'More' && <h1>Coming soon...</h1>}
            {bill?.name === 'Insurance' && <h1>Coming soon...</h1>}
            {bill?.name === 'Waste/Bin' && <h1>Coming soon...</h1>}
          </section>
        )}
      </section>
    </div>
  );
};

export default PayBills;
