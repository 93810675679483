import React from 'react';
import { Route } from 'react-router-dom';
import Container from '../../layout/Container';
import { ROUTES } from '../routes/routes';
import { deleteUserData } from '../../../storage/sessionStorage';

const PrivateRoute = ({
  component: Component,
  allowNavigation = false,
  showHeader = true,
  showSideMenu = true,
  onComponent,
  path,
  meta,
  ...rest
}) => {
  // useEffect(() => {
  const expiration = localStorage.getItem('expiration');
  if (expiration && expiration < Date.now()) {
    deleteUserData();
    localStorage.clear();
    setTimeout(() => {
      window.location.pathname = ROUTES.SIGN_IN.path;
    }, 200);
    // history.push(ROUTES.SIGN_IN.path);
  }
  // }, []);

  return (
    <Route
      {...rest}
      path={path}
      render={(props) => (
        <Container showHeader={showHeader} showSideMenu={showSideMenu} meta={meta}>
          <Component {...props} {...onComponent} />
        </Container>
      )}
    />
  );
};

export { PrivateRoute };
