import React from 'react';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router';
import { ROUTES } from '../router/routes/routes';
import Icon from './Icon';
import Switch from './inputs/Switch/Switch';
import Modal from './Modal';

const Finalize = ({ show, onClose, price, headerText, subtitle, receipt = false }) => {
  const history = useHistory();
  return (
    <Modal show={show} onClose={onClose}>
      <div className="finalize-wrapper">
        <Icon icon="success" height="40px" />
        <div className="header">{headerText}</div>
        {subtitle && (
          <div className="subtitle">
            The Payment of
            {price && (
              <NumberFormat
                displayType="text"
                thousandSeparator
                prefix="₦"
                fixedDecimalScale
                decimalScale={2}
                value={price}
                style={{ margin: '0px 5px' }}
              />
            )}
            was deducted successfully from your wallet
          </div>
        )}
        {receipt && (
          <>
            <div className="opt">
              <Switch name="recurring" width={10} /> <div>Set as Recurring</div>
            </div>
            <div
              className="opt"
              style={{
                gap: '10px',
                cursor: 'pointer',
              }}
              onClick={() => history.push(ROUTES.RECIEPT.path)}
            >
              <Icon icon="receipt" width={15} /> <div>View Receipt</div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default Finalize;
