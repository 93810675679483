import React from 'react';
import { Link } from 'react-router-dom';
import LaptopImage from '../assets/images/authentication.svg';
import SecurityImage from '../assets/images/secure-lock.svg';
import countries from '../assets/data/countries.json';
import { ROUTES } from '../components/router/routes/routes';
import { SvgIcon } from '../components/ui/Icon';

// export const BASE_URL = 'http://oldallmessagingapi-env.eba-r96rxjid.eu-west-1.elasticbeanstalk.com';
export const BASE_URL =
  process.env.REACT_APP_BASE_URL || 'https://dev-bluintouch-api-gateway.blusalt.net/api/v1';
// export const BASE_URL = 'https://bluintouch.herokuapp.com';

export const API_URL = {
  USER: `${BASE_URL}/user`,
  LOG_IN: `${BASE_URL}/auth/login`,
  SIGN_UP: `${BASE_URL}/clients/register`,
  RESEND_OTP: `${BASE_URL}/otp`,
  VERIFY_OTP: `${BASE_URL}/otp/verify`,
  VERIFY_ACCOUNT: `${BASE_URL}/auth/confirm-account`,
  CLIENTS: `${BASE_URL}/clients`,
  UPLOAD_AVATAR: `${BASE_URL}/clients/upload`,
  SEND_SMS: `${BASE_URL}/sms/messages`,
  SEND_SMS_UPLOAD: `${BASE_URL}/sms/messages/upload`,
  SEND_AIRTIME: `${BASE_URL}/topup`,
  SEND_AIRTIME_UPLOAD: `${BASE_URL}/topup/upload`,
  SEND_WHATSAPP: `${BASE_URL}/whatsapp/messages`,
  CHANGE_PASSWORD: `${BASE_URL}/auth/change-password`,
  FORGOT_PASSWORD: `${BASE_URL}/auth/forgot-password`,
  RESET_PASSWORD: `${BASE_URL}/auth/reset-password`,
  REVERIFY_EMAIL: `${BASE_URL}/auth/verify/resend-email`,
  DATAPLAN_LIST: `${BASE_URL}/datatopup/listdataplans`,
  SINGLE_DATAPLAN: `${BASE_URL}/datatopup/vend/single`,
  FILE_UPLOAD_DATAPLAN: `${BASE_URL}/datatopup/vend`,
  MSISDN_OPERATOR: `${BASE_URL}/datatopup/listmatchedmsisdns`,
  MATCH_MSISDN_OPERATOR: `${BASE_URL}/datatopup/matchmsisdnToOperatorsSync`,
};

export const BRAND_LIST = [
  {
    value: 'hospitality',
    label: 'Hospitality',
  },
  { value: 'technology', label: 'Technology' },
  { value: 'financial', label: 'Financial' },
  { value: 'real_estate', label: 'Real Estate' },
  { value: 'education', label: 'Education' },
];

export const STORAGE = {
  USER: 'blu-user',
  TOKEN: 'blu-token',
  TOKEN_EXPIRE: 'blu-token-exp',
};

export const AUTH_DATA = {
  [ROUTES.SIGN_IN.path]: {
    title_mobile: 'Welcome Back, Login',
    navigation: (
      <>
        New User? <Link to={ROUTES.SIGN_UP.path}>Sign Up</Link>
      </>
    ),
    title: ['Welcome back,', 'Login to continue'],
    subtitle: '',
    image: LaptopImage,
  },
  [ROUTES.SIGN_UP.path]: {
    title_mobile: 'Hello, Sign Up',
    navigation: (
      <>
        Already have an account? <Link to={ROUTES.SIGN_IN.path}>Login</Link>
      </>
    ),
    title: 'All-In-One platform for businesses to access digital services',
    subtitle: 'Airtime, Data bundles, Messaging, Bill payments and more across borders',
    image: LaptopImage,
  },
  [ROUTES.FORGOT_PASSWORD.path]: {
    title_mobile: 'Forgot Your Password?',
    navigation: null,
    title: 'Enter email to reset your password',
    subtitle: '',
    image: SecurityImage,
  },
  [ROUTES.RESET_PASSWORD.path]: {
    title_mobile: 'Reset Your Password',
    navigation: null,
    title: 'Enter new password for your account',
    subtitle: '',
    image: SecurityImage,
  },
};

export const SIDE_MENU = [
  {
    title: 'Dashboard',
    itemId: ROUTES.DASHBOARD.path,
    elemBefore: () => <SvgIcon icon="bar" />,
  },
  {
    title: 'Bulk Service',
    itemId: ROUTES.CAMPAIGN.path,
    elemBefore: () => <SvgIcon icon="megaphone" />,
    subNav: [
      {
        title: 'All Bulk',
        itemId: ROUTES.ALL_CAMPAIGN.path,
      },
      {
        title: 'Send Bulk',
        itemId: ROUTES.CREATE_CAMPAIGN.path,
      },
      {
        title: 'Sender ID',
        itemId: ROUTES.SENDER_ID.path,
      },
    ],
  },
  {
    title: 'Services',
    itemId: ROUTES.SERVICE.path,
    elemBefore: () => <SvgIcon icon="badgecheck" />,
    subNav: [],
  },
  {
    title: 'Contacts',
    itemId: ROUTES.CONTACTS.path,
    elemBefore: () => <SvgIcon icon="users" />,
  },
  {
    title: 'Prices',
    itemId: '/prices',
    elemBefore: () => <SvgIcon icon="cash" />,
  },
  {
    title: 'Wallet',
    itemId: ROUTES.WALLET.path,
    elemBefore: () => <SvgIcon icon="wallet" color="white" />,
  },
  {
    title: 'Reports',
    itemId: ROUTES.REPORT.path,
    elemBefore: () => <SvgIcon icon="doc" />,
    subNav: [
      {
        title: 'Audit Trail',
        itemId: ROUTES.AUDIT_TRAIL.path,
      },
      {
        title: 'Service Report',
        itemId: ROUTES.SERVICE_REPORT.path,
      },
      {
        title: 'Transaction History',
        itemId: ROUTES.TRANSACTION_REPORT.path,
      },
    ],
  },
  {
    title: 'Settings',
    itemId: ROUTES.SETTINGS.path,
    elemBefore: () => <SvgIcon icon="cogwhite" color="#ffffff" />,
  },
];

export const COUNTRIES = countries;
