import React, { useEffect, useState } from 'react';
import { Checkbox as MCheckbox, FormControlLabel } from '@material-ui/core';

const CheckBox = ({
  label,
  disabled = false,
  variant = 'outlined',
  hint,
  value,
  name,
  defaultValue,
  onChange,
  validate,
  ...rest
}) => {
  const [data, setData] = useState(value);

  useEffect(() => {
    if (onChange) {
      onChange({ name, value: !data });
    }
  }, []);

  const handleOnChange = (e) => {
    const { name: n, checked: v } = e.target;
    setData(v);

    if (onChange) {
      onChange({ name: n, value: v });
    }
  };

  return (
    <div className="input-wrapper" style={{ padding: 0 }}>
      <FormControlLabel
        control={
          <MCheckbox
            color="primary"
            checked={data}
            onChange={handleOnChange}
            name={name}
            required={!!validate}
          />
        }
        disabled={disabled}
        label={label}
        variant={variant}
        aria-label={label}
        aria-describedby={label}
        style={{ margin: 0 }}
        {...rest}
      />
    </div>
  );
};

export default CheckBox;
