export const storeUserData = (userData) => {
  const data = JSON.stringify(userData);
  try {
    localStorage.setItem('userData', data);
  } catch (error) {
    throw new Error('Session storage permission is needed');
  }
};

export const fetchUserData = () => {
  try {
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);
    return userData || {};
  } catch (error) {
    throw new Error('Session storage permission is needed');
  }
};

export const deleteUserData = async () => {
  try {
    await localStorage.removeItem('userData');
  } catch (error) {
    throw new Error('Session storage permission is needed');
  }
};

// store user data temporarily for otp verification
export const storeUserDataTemp = (userData) => {
  const data = JSON.stringify(userData);
  try {
    sessionStorage.setItem('userData', data);
  } catch (error) {
    throw new Error('Session storage permission is needed');
  }
};

export const fetchTempUserData = () => {
  try {
    let userData = sessionStorage.getItem('userData');
    userData = JSON.parse(userData);
    return userData || {};
  } catch (error) {
    throw new Error('Session storage permission is needed');
  }
};
