import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import { validator } from '../../../../utilities/helper';
import './Input.scss';

const CustomTextField = styled(TextField)({
  '& input:active + fieldset': {
    borderColor: '#01AAE4 !important',
    borderWidth: 2,
  },
  '& input:active + div + fieldset': {
    borderColor: '#01AAE4 !important',
    borderWidth: 2,
  },
  '& input:valid:focus + fieldset': {
    borderColor: '#01AAE4 !important',
    borderWidth: 2,
  },
  '& input:valid:focus + div + fieldset': {
    borderColor: '#01AAE4 !important',
    borderWidth: 2,
  },
});

const Input = ({
  label,
  placeholder = '',
  disabled = false,
  type = 'text',
  variant = 'outlined',
  hint,
  value,
  name,
  defaultValue,
  multiline = false,
  rows = 3,
  onChange,
  validate,
  icon,
  currency,
  iconPosition,
  index,
  height,
  padding,
  onBlur,
  ...rest
}) => {
  const [errors, setErrors] = useState([]);
  const [data, setData] = useState(value || defaultValue);

  useEffect(() => {
    if (onChange) {
      onChange({ name, value: data, index });
    }
  }, []);

  const handleValidation = (e) => {
    e.preventDefault();
    const { name: n, value: v } = e.target;
    const errs = validate ? validator(v, validate) : [];
    setErrors(errs || []);

    if (onChange) {
      onChange({ name: n, value: v, error: errs.join('\n'), idx: index });
    }
  };

  const handleOnChange = (e) => {
    const { name: n, value: v } = e.target;
    setData(v);
    if (errors) {
      handleValidation(e);
    } else if (onChange) {
      onChange({ name: n, value: v, idx: index });
    }
  };

  return (
    <div className="input-wrapper" style={{ padding }}>
      <CustomTextField
        name={name}
        defaultValue={defaultValue}
        label={label}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        variant={variant}
        aria-label={label}
        aria-describedby={label}
        multiline={multiline}
        rows={rows}
        value={defaultValue}
        onChange={handleOnChange}
        onBlur={handleValidation}
        error={errors.length > 0}
        helperText={errors.length > 0 ? errors[0] : ''}
        required={!!validate}
        inputProps={{
          onBlur: () => {
            if (onBlur) {
              onBlur();
            }
          },
        }}
        // InputProps={{
        //   startAdornment: icon ? (
        //     <InputAdornment>
        //       <Icon icon={icon} />
        //     </InputAdornment>
        //   ) : currency ? (
        //     <InputAdornment>{currency}</InputAdornment>
        //   ) : null
        // }}
        {...rest}
      />
      {hint && <div className="hint">{hint}</div>}
    </div>
  );
};

export default Input;
