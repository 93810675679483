import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  payload: {
    phone_numbers: [],
  },
  sendingSms: false,
  success: false,
  error: false,
};

export const smsSlice = createSlice({
  name: 'sms',
  initialState,
  reducers: {
    sendSmsSuccessReducer: (state, { payload }) => {
      state.sendingSms = false;
      state.success = true;
      state.payload = payload;
    },
    sendingSmsReducer: (state) => {
      state.sendingSms = true;
      state.success = false;
      state.payload.phone_numbers = [];
    },
    sendingSmsErrorReducer: (state) => {
      state.sendingSms = false;
      state.success = false;
      state.payload.phone_numbers = [];
      state.error = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const { sendSmsSuccessReducer, sendingSmsReducer, sendingSmsErrorReducer } =
  smsSlice.actions;

export default smsSlice.reducer;
