// import Toast from 'react-hot-toast';
import { APIfetchMerchantAuditReports } from '../../api/backendRoutes';
import {
  fetchAuditTrailsSuccessReducer,
  fetchingAuditTrailsReducer,
  fetchingAuditTrailsErrorReducer,
} from '../slices/auditTrail';

export const fetchAuditTrailsAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchingAuditTrailsReducer());
    try {
      const resp = await APIfetchMerchantAuditReports(params);
      if (resp.data.message === 'ok') {
        dispatch(fetchAuditTrailsSuccessReducer(resp.data));
      } else {
        dispatch(fetchingAuditTrailsErrorReducer());
        throw resp?.data?.error;
      }
    } catch (error) {
      // Toast.error(error?.response?.data?.error?.message || 'Server error');
      dispatch(fetchingAuditTrailsErrorReducer());
    }
  };
