import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const StyledUploadResult = styled.div`
  background-color: rgba(80, 159, 239, 0.03);
  border-radius: 6px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  margin: 10px 0;
  justify-content: space-between;
  width: 100%;
  flex-grow: 1;
  > div {
    display: flex;
    font-size: 14px;
    width: 100%;
  }

  .files {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;

    > span {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 10px;
        cursor: pointer;
      }
    }
  }
`;
const StyledProgress = styled.span`
background: #63B67F;
padding: 5px;
border-radius: 30px;
width:${({upload})=>upload ? "100%":'0%'};
transition: all ease-in .5s;
`
const UploadResult = ({ files = [], removeFile,upload }) => {
  // const handleRemoveFile = (name) => {
  //   if (removeFile) {
  //     removeFile(name);
  //   }
  // };

  return (
    <StyledUploadResult>
      <div>
        <Icon icon="fileupload" margin={[0, 15, 0, 0]} />
        <div className="files">
          {Array.from(files).map(({ name, size = 0 }, index) => (
            <span key={index} className="file">
              <span style={{ display: 'flex', gap: '10px', flexDirection:'column',width:'100%'  }}>
                <span style={{ display: 'flex', gap: '10px',justifyContent:'space-between'}}>
                  <span>
                    {name} ({size > 1024 ? `${parseInt(size / 1024, 10)}kb` : `${size}bytes`})
                  </span>
                  <span>{upload ? '100%' : '0%'}</span>
                </span>
                <StyledProgress upload={upload}/>
              </span>
              <span style={{ display: 'flex', gap: '10px', alignItems: 'center',padding:'0px 15px' }}>
                {/* <Button value="Browse" /> */}
                <span
                  onClick={() => removeFile()}
                  style={{
                    fontSize: '16px',
                    color: 'red',
                    padding: '5px 10px',
                    cursor: 'pointer',
                  }}
                >
                  Cancel
                </span>
              </span>
            </span>
          ))}
        </div>
      </div>
    </StyledUploadResult>
  );
};

export default UploadResult;
