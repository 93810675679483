import React from 'react';
import { Card as BCard } from 'react-bootstrap';



const Card = ({
  header,
  title,
  subtitle,
  children,
  footer,
  action,
  noWrapper = false,
  styleBody = {},
  ...rest
}) => {
  return (
    <>
      {header && <div className="card-header-wrapper">{header}</div>}
      <div className="card-wrapper" {...rest}>
        <BCard>
          <BCard.Body style={styleBody}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {title && <BCard.Title style={{color:'#828282'}}>{title}</BCard.Title>}
              {action && <a href="#" style={{ textDecoration :'underline'}}>{action}</a>}
            </div>
            {subtitle && <BCard.Subtitle>{subtitle}</BCard.Subtitle>}

            {noWrapper ? <>{children}</> : <div className="card-text">{children}</div>}

            {footer && <>{footer}</>}
          </BCard.Body>
        </BCard>
      </div>
    </>
  );
};

Card.Footer = ({ children, ...rest }) => {
  return <BCard.Footer {...rest}>{children}</BCard.Footer>;
};

export default Card;
