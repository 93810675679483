import React from 'react';
import { TextField } from '@material-ui/core';

const MaterialInput = React.forwardRef(
  (
    { errors, name, type, placeholder, label, fullWidth, readOnly, helperTexts, InputProps },
    ref
  ) => {
    return (
      <TextField
        error={!!errors[name]?.message}
        name={name}
        defaultValue=""
        label={label}
        helperText={errors ? errors[name]?.message : helperTexts}
        type={type}
        placeholder={placeholder ?? label}
        fullWidth={fullWidth}
        inputRef={ref}
        disabled={readOnly}
        variant="outlined"
        InputProps={InputProps}
      />
    );
  }
);

export default MaterialInput;
