// import Toast from 'react-hot-toast';
import { APIlistSenderId } from '../../api/backendRoutes';
import { fetchSenderIdReducer, fetchingSenderIdReducer, errorReducer } from '../slices/senderId';

export const fetchSenderIdAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchingSenderIdReducer());
    try {
      const resp = await APIlistSenderId();
      if (resp.data.message === 'ok') {
        dispatch(fetchSenderIdReducer(resp.data.data));
      } else {
        dispatch(errorReducer(resp.data));
        throw resp?.data?.error;
      }
    } catch (error) {
      // Toast.error(error?.data?.message || 'Server error');
    }
  };
