/* eslint-disable react/react-in-jsx-scope */
import { Typography, Grid, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState, useContext } from 'react';
import Toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../components/router/routes/routes';
import { Modal, Logo } from '../../../components/ui';
import { OTPInput } from '../../../components/ui/inputs';
import { APIDisable2FA } from '../../../api/backendRoutes';
import { errorParser } from '../../../utilities/helper';
import { AppContext } from '../../../utilities/context/AppContext';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `20px`,
    display: 'flex',
    alignItems: 'center',
    // minHeight: '100vh',
  },
  container: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    padding: '43px 0 26px',
  },
  typography: {
    textAlign: 'center',
    lineHeight: '54.6px',
    fontWeight: 700,
    fontSize: '32px',
    color: '#071B2B',
    marginBottom: '44px',
  },
  heading: {
    fontSize: '22px',
    fontWeight: 400,
    lineHeight: '29px',
    textAlign: 'center',
    color: '#505050',
    marginBottom: '40px',
  },
  code: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '21px',
    textAlign: 'left',
    '& span': {
      color: `${theme.palette.primary.main}`,
      cursor: 'pointer',
    },
  },
}));

function Disable2FAModal({ show, closeModal, action }) {
  const { partnerInfo } = useContext(AppContext);
  const classes = useStyles();
  const history = useHistory();
  const [OTP, setOTP] = useState('');

  const handleVerification = async (e) => {
    e.preventDefault();
    try {
      const resp = await APIDisable2FA({
        token: OTP,
      });

      if (resp.data.success) {
        action();
        closeModal();
      } else {
        throw resp?.data?.error;
      }
    } catch (err) {
      Toast.error(errorParser(err));
    }
  };
  return (
    <Modal size="lg" show={show} onClose={closeModal} title="Confirm Action">
      <Grid container spacing={0} className={classes.root} justify="center">
        <Grid
          item
          container
          className={classes.container}
          direction="column"
          spacing={0}
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item sm={12}>
            <Box mb={1} onClick={() => history.push('/')}>
              <Logo src={partnerInfo.logoUrl || '/logo-black.svg'} name="Blusalt" />
            </Box>
          </Grid>
          <Grid item sm={12}>
            <Typography variant="h3" className={classes.typography}>
              Disable Two Factor Auththentication
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <Typography className={classes.heading}>
              Enter the code sent to your email
              <Typography style={{ marginTop: 10 }}>Do not refresh this page!</Typography>
            </Typography>
          </Grid>
          <form style={{ width: '100%' }} onSubmit={handleVerification}>
            <Grid item sm={12} container justify="center">
              <Box mb="62px" width="297px">
                <OTPInput
                  required
                  autoFocus
                  // isNumberInput
                  length={6}
                  className="otpContainer"
                  inputClassName="otpInput"
                  onChangeOTP={(otp) => setOTP(otp)}
                />
              </Box>
            </Grid>
            <Grid item container direction="column" align="center">
              <Grid item sm={12}>
                <Box mb="17px" width="240px">
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={handleVerification}
                  >
                    Verify
                  </Button>
                </Box>
              </Grid>
              <Grid item sm={12}>
                <Box mb="18px" width="240px">
                  <Button
                    type="button"
                    color="primary"
                    variant="outlined"
                    onClick={() => history.push(ROUTES.SIGN_IN.path)}
                    fullWidth
                  >
                    Back
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Modal>
  );
}

export default Disable2FAModal;
