import React, { useEffect, useCallback, useState } from 'react';
import Moment from 'react-moment';
import { Box } from 'rebass';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table } from '../../components/ui';
import { fetchTransactionReportAction } from '../../redux/actions/transactionReport';
import { APIExportTransactionReport } from '../../api/backendRoutes';
import { errorParser, toCurrency } from '../../utilities/helper';

const TransactionReport = () => {
  const { transactions, meta, fetching } = useSelector((state) => {
    return {
      transactions: state.transactionReport.transactionReport,
      meta: state.transactionReport.transactionReportMeta,
      fetching: state.transactionReport.fetchingTransactionReport,
    };
  });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const FetchTransaction = useCallback(
    (params = { limit: 25 }) => {
      dispatch(fetchTransactionReportAction(params));
    },
    [dispatch]
  );
  useEffect(() => {
    FetchTransaction();
  }, []);

  // const getCSV = () => {
  //   const csv = [
  //     ['Name', 'Time', 'Date'],
  //     ...transactions.map((t) => [
  //       `${t.firstName}  ${t.lastName}`,
  //       moment(t.createdAt).format('h:mm:SSa'),
  //       moment(t.createdAt).format('D MMM YYYY'),
  //     ]),
  //   ]
  //     .map((e) => e.join(','))
  //     .join('\n');

  //   const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  //   const { URL } = window;
  //   return URL.createObjectURL(blob);
  // };

  const getCSV = async () => {
    try {
      setLoading(true);
      const resp = await APIExportTransactionReport();
      if (resp.data.message === 'ok') {
        toast.success('A generated report has been sent to your mail');
        setLoading(false);
      } else {
        setLoading(false);
        throw resp?.data?.error;
      }
    } catch (err) {
      setLoading(false);
      toast.error(errorParser(err));
    }
  };
  return (
    <div className="report-wrapper">
      <div className="header">
        <h5>Transaction Report</h5>
        {transactions.length > 0 && (
          <Button value="Download CSV" loading={loading} onClick={() => getCSV()} />
        )}
      </div>
      <section>
        <Table
          header={[
            {
              key: 'createdAt',
              label: 'Date',
              render: (createdAt) => (
                <div className="td-width-100">
                  <div>
                    <Moment format="D MMM, YYYY" date={createdAt} />
                  </div>
                  <div>
                    <Moment format="h:mm:SSa" date={createdAt} />
                  </div>
                </div>
              ),
            },
            {
              key: '_id',
              label: 'Transaction ID',
              render: (_id) => <div className="td-width-150">{_id}</div>,
            },
            {
              key: 'amount',
              label: 'Amount',
              render: (amount, r) => <span className={r.actionType === 'Wallet Credit'? 'text-success' : 'text-danger'}>{toCurrency(amount)}</span>,
            },
            { key: 'balance', label: 'Balance', render: (balance) => toCurrency(balance) },
            { key: 'actionType', label: 'Type' },
            {
              key: 'status',
              label: 'Transaction Status',
              render: (status) => (
                <Box color={status.toLowerCase() === 'failed' ? 'red' : 'green'}>{status}</Box>
              ),
            },
            { key: 'category', label: 'Category' },
          ]}
          data={transactions}
          filter={{
            Date: [
              { value: 'Today', label: 'Today' },
              { value: 'This Week', label: 'This Week' },
              { value: 'This Month', label: 'This Month' },
              { value: 'Last 30 Days', label: 'Last 30 Days' },
              { value: 'This Year', label: 'This Year' },
              { value: 'Period', label: 'Specific Period' },
            ],
            status: [
              { value: 'Successful', label: 'Successful' },
              { value: 'Failed', label: 'Failed' },
              { value: 'Pending', label: 'Pending' },
            ],
            category: [
              { value: 'SMS', label: 'SMS' },
              { value: 'Airtime', label: 'Airtime' },
              { value: 'Data', label: 'Data' },
              { value: 'Data Topup', label: 'Data Topup' },
            ],
          }}
          showFilter
          paginate
          meta={meta}
          handlePagination={FetchTransaction}
          filterAction={FetchTransaction}
          loading={fetching}
        />
      </section>
    </div>
  );
};

export default TransactionReport;
