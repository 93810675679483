import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';

import { Switch, DateInput, TimeInput } from '../../../components/ui/inputs';
import { Button, Modal } from '../../../components/ui';

const StyledSchedule = styled.div`
  color: #505050;
  display: flex;
  input {
    font-size: 16px;
  }

  .react-time-picker__wrapper {
    border: 1px solid #bdbdbd;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 0px 10px;
  }
`;

const Schedule = ({ show, setShow, handleSchedule, loading }) => {
  const [showForm, setShowForm] = useState(false);
  const [error, setError] = useState([false, '']);
  const [formData, setFormData] = useState({
    recurring: false,
  });

  useEffect(() => {
    setFormData({
      startDate: null,
      startTime: null,
      recurring: false,
      endDate: null,
      endTime: null,
      type: [
        { value: 'Daily', active: false },
        { value: 'Weekly', active: false },
        { value: 'Monthly', active: false },
        { value: 'Annually', active: false },
      ],
    });
  }, []);
  const handleRecurring = ({ name, value }) => {
    setFormData((v) => ({ ...v, [name]: value }));
    setShowForm(false);
  };
  const handleType = (value, idx) => {
    const { type } = formData;
    setFormData((v) => ({ ...v, frequency: value }));
    const newType = type.map((t) => {
      if (t.value === value) {
        return {
          ...t,
          active: !t.active,
        };
      }
      return {
        ...t,
        active: false,
      };
    });
    setFormData((v) => ({ ...v, type: newType }));
    setShowForm(true);
  };
  const handleDate = ({ name, value }) => {
    setError([false, '']);
    setFormData((v) => ({ ...v, [name]: value }));
  };
  const handleValidation = (param) => {
    let fault = [false, ''];
    if (moment(param.startDate) < moment() || moment(param.endDate) < moment()) {
      fault = [true, 'You cant select past date'];
    }
    switch (param.campaignFrequency) {
      case undefined:
        if (moment(param.startDate) < moment()) fault = [true, 'You cant select past time'];
        break;
      case 'Daily':
        if (moment(param.endDate) < moment(param.startDate).add(1, 'day'))
          fault = [true, 'Please pick a date after your start date'];
        break;
      case 'Weekly':
        if (moment(param.endDate) < moment(param.startDate).add(7, 'day'))
          fault = [true, 'Please pick a date at least a week from start date'];
        break;
      case 'Monthly':
        if (moment(param.endDate) < moment(param.startDate).add(30, 'day'))
          fault = [true, 'Please pick a date at least a month from start date'];
        break;
      case 'Annually':
        if (moment(param.endDate) < moment(param.startDate).add(365, 'day'))
          fault = [true, 'Please pick a date at least a year from start date'];
        break;
      default:
        return;
    }
    setError(fault);
    if (!fault[0]) {
      handleSchedule(param);
    }
  };

  const scheduleCampaign = () => {
    let scheduleParams = {};
    const { startDate, startTime, recurring, endDate, endTime, frequency } = formData;
    if (startDate === null || startTime === null) {
      setError([true, 'Please select a start time and date']);
    } else if (recurring === false && startDate !== null && startTime !== null) {
      setError([false, '']);
      const fd = startDate.toISOString(true);
      const ft = moment(startTime, 'hh:mm').toISOString(true);
      const newstartDate = [fd?.split('T')[0], ft?.split('T')[1]].join('T');
      scheduleParams = {
        isCampaignRecurring: recurring,
        startDate: newstartDate,
      };
      handleValidation(scheduleParams);
    } else if ((recurring === true && endDate === null) || endTime === null) {
      setError([true, 'Please select a end time and date']);
    } else if (
      recurring === true &&
      endDate !== null &&
      endTime !== null &&
      startDate !== null &&
      startTime !== null
    ) {
      const fd = startDate.toISOString(true);
      const ft = moment(startTime, 'hh:mm').toISOString(true);
      const newstartDate = [fd.split('T')[0], ft.split('T')[1]].join('T');
      const ed = endDate.toISOString(true);
      const et = moment(endTime, 'hh:mm').toISOString(true);
      const newendDate = [ed.split('T')[0], et.split('T')[1]].join('T');
      scheduleParams = {
        isCampaignRecurring: recurring,
        startDate: newstartDate,
        endDate: newendDate,
        campaignFrequency: frequency,
      };
      handleValidation(scheduleParams);
    }
  };
  return (
    <StyledSchedule>
      <Modal title="Schedule Campaign" show={show} onClose={() => setShow(false)}>
        <Row>
          <Col>
            <DateInput
              disableToolbar
              name="startDate"
              type="date"
              defaultValue={formData?.startDate}
              placeholder="Select Start Date"
              minDate={moment()}
              id="outlined"
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              onChange={handleDate}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <TimeInput name="startTime" onChange={handleDate} placeholder="Select start time" />
          </Col>
        </Row>
        <Row>
          <Col
            className=""
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              color: '#17568B',
              fontSize: '16px',
            }}
          >
            <div>Set as Recurring</div>
            <Switch name="recurring" onChange={handleRecurring} />
          </Col>
        </Row>
        <Row
          style={{
            margin: '20px 0px',
          }}
        >
          {formData.recurring &&
            formData.type.map((b, idx) => (
              <Col
                key={idx}
                md={3}
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(4,1fr)',
                  justifyContent: 'space-between',
                  height: 'fit-content',
                }}
              >
                <Button
                  value={b.value}
                  group={b.active ? 'primary' : 'outline'}
                  borderRadius="30px"
                  width={100}
                  onClick={() => handleType(b.value, idx)}
                />
              </Col>
            ))}
        </Row>
        {showForm && (
          <>
            <Row>
              <Col className="mb-3">
                <DateInput
                  disableToolbar
                  name="endDate"
                  placeholder="Select End Date"
                  defaultValue={formData?.endDate}
                  onChange={handleDate}
                  minDate={moment()}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TimeInput name="endTime" onChange={handleDate} placeholder="Select end time" />
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              value="Schedule"
              size="lg"
              width={150}
              onClick={scheduleCampaign}
              loading={loading}
            />
          </Col>
        </Row>
        {error[0] && (
          <div
            style={{
              display: 'flex',
              color: 'red',
              fontSize: '12px',
              justifyContent: 'center',
              margin: '10px 0px',
            }}
          >
            {error[1]}
          </div>
        )}
      </Modal>
    </StyledSchedule>
  );
};

export default Schedule;
