import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import Moment from 'react-moment';
import { Card, Icon, Table, Options, Loader } from '../../components/ui';
import { Line } from '../../components/charts';
import {
  fetchTotalCampaignAction,
  fetchTotalPendingCampaignAction,
  fetchScheduledCampaignAction,
  fetchCampaignsAction,
} from '../../redux/actions/campaign';
import { fetchMonthlyWalletAnalyticsAction } from '../../redux/actions/wallet';
import { fetchSenderIdAction } from '../../redux/actions/senderId';
import { fetchRecentTransactionsAction } from '../../redux/actions/transactionReport';
import { ROUTES } from '../../components/router/routes/routes';

const Dashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [scheduledCampaignList, setScheduledCampaignList] = useState([]);
  const [analytics, setAnalytics] = useState({
    currentWeekCount: 0,
    previousWeekCount: 0,
    previousWeekTotalCount: 0,
    previousWeekCampaignTotal: [],
    percentageIncreaseCampaign: 0,
    percentageIncreasePending: 0,
  });

  const fetchTotalCampaign = useCallback(
    (params = {}) => {
      dispatch(fetchTotalCampaignAction(params));
    },
    [dispatch]
  );

  const fetchScheduled = useCallback(() => {
    dispatch(fetchScheduledCampaignAction());
  }, [dispatch]);

  const fetchTotalPendingCampaign = useCallback(
    (params = {}) => {
      dispatch(fetchTotalPendingCampaignAction(params));
    },
    [dispatch]
  );

  const fetchMonthlyWalletAnalytics = useCallback(
    (params = {}) => {
      dispatch(fetchMonthlyWalletAnalyticsAction(params));
    },
    [dispatch]
  );
  const fetchId = useCallback(() => {
    dispatch(fetchSenderIdAction());
  }, [dispatch]);
  const fetchRecentTransactions = useCallback(
    (params = {}) => {
      dispatch(fetchRecentTransactionsAction(params));
    },
    [dispatch]
  );
  const allCampaign = useCallback(() => {
    dispatch(fetchCampaignsAction());
  }, [dispatch]);
  const {
    totalCampaign,
    pendingCampaign,
    monthlyWalletAnalytics,
    fetchingMonthlyAnalytics,
    scheduledCampaign,
    listCampaigns,
    recentTransactions,
    fetchingRecentTransactions,
  } = useSelector((state) => {
    return {
      totalCampaign: state?.campaign?.totalCampaign || 0,
      fetchingTotal: state?.contacts?.fetchingTotalCampaign,

      pendingCampaign: state?.campaign?.totalPendingCampaign || 0,
      fetchingPending: state?.contacts?.fetchingPendingCampaign,

      monthlyWalletAnalytics: state.wallet.monthlyAnalytics,
      fetchingMonthlyAnalytics: state.wallet.fetchingMonthlyAnalytics,
      errorFetchingMonthlyAnalytics: state.wallet.errorFetchingMonthlyAnalytics,

      scheduledCampaign: state?.campaign.scheduledCampaign,
      listCampaigns: state.campaign.listCampaigns,
      recentTransactions: state?.transactionReport.recentTransactions,
      fetchingRecentTransactions: state?.transactionReport.fetchingRecentTransactions,
      errorFecthingRecentTransactions: state?.transactionReport.errorFecthingRecentTransactions,
    };
  });
  useEffect(() => {
    fetchTotalCampaign();
    fetchTotalPendingCampaign({ status: 'pending' });
    fetchMonthlyWalletAnalytics();
    fetchScheduled();
    fetchRecentTransactions();
    allCampaign();
    fetchId();
  }, []);

  useEffect(() => {
    const sc = scheduledCampaign.filter((s) => s.campaignStatus !== 'completed');
    const computedSchedule = sc.map((s) => ({
      campaign: s.campaignServiceType?.serviceTypeName || 'N/A',
      name: s?.campaignName || 'N/A',
      date: s.startDate,
    }));

    setScheduledCampaignList(computedSchedule);
    const currentWeekCampaign = listCampaigns.filter(
      (c) => moment(c.createdAt) > moment().startOf('week')
    );
    const previousWeekCampaignTotal = listCampaigns.filter(
      (c) => moment(c.createdAt) < moment().startOf('week')
    );
    const previousWeekCampaign = listCampaigns.filter(
      (c) =>
        moment(c.createdAt) < moment().startOf('week') &&
        moment(c.createdAt) > moment().startOf('week').subtract(6, 'days')
    );
    const currentWeekCount = currentWeekCampaign.length;
    const previousWeekCount = previousWeekCampaign.length;
    const previousWeekTotalCount = previousWeekCampaignTotal.length;
    const previousWeekTotalPending = previousWeekCampaignTotal.filter(
      (p) => p.campaignStatus === 'pending'
    ).length;

    const percentageIncreaseCampaign = Math.floor(
      ((totalCampaign - previousWeekTotalCount) / totalCampaign) * 100
    );
    const percentageIncreasePending = Math.floor(
      ((pendingCampaign - previousWeekTotalPending) / pendingCampaign) * 100
    );
    setAnalytics({
      currentWeekCount,
      previousWeekCount,
      previousWeekTotalCount,
      previousWeekCampaignTotal,
      percentageIncreaseCampaign,
      percentageIncreasePending,
    });
    if (currentWeekCount > previousWeekCount) {
      const percentageIncrease = Math.floor(
        ((currentWeekCount - previousWeekCount) / currentWeekCount) * 100
      );
      setAnalytics((a) => ({
        ...a,
        percentageIncrease,
      }));
    } else if (currentWeekCount < previousWeekCount) {
      const percentageDecrease = Math.floor(
        ((previousWeekCount - currentWeekCount) / previousWeekCount) * 100
      );
      setAnalytics((a) => ({
        ...a,
        percentageDecrease,
      }));
    }
  }, [scheduledCampaign, listCampaigns]);

  return (
    <div className="dashboard-wrapper">
      <Row className="analytics border-0">
        <Col>
          <SmallCard
            icon="gramaphone"
            title={totalCampaign?.toLocaleString()}
            subtitle="Total Bulk"
            trend={0}
          />
        </Col>
        <Col>
          <SmallCard
            icon="pending"
            title={pendingCampaign?.toLocaleString()}
            subtitle="Pending Bulk Count"
            // trend={analytics.percentageIncreasePending || 0}
            trend={0}
          />
        </Col>
        <Col>
          <SmallCard
            icon="graph"
            title={analytics.currentWeekCount}
            subtitle="Bulk per Week"
            // trend={analytics.percentageDecrease || analytics.percentageIncrease}
            trend={0}
            trendIcon={
              analytics.currentWeekCount > analytics.previousWeekCount ? 'trendRise' : 'trendFall'
            }
          />
        </Col>
      </Row>

      <Row>
        <Col md={6} style={{ marginBottom: 40 }}>
          <Card
            header="Quick Access"
            title="Explore our Services"
            style={{ height: 'calc(100% - 50px)', border: '1px solid #E0E0E0', boxShadow: 'none' }}
          >
            <Card>
              <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <div className="data" style={{ display: 'flex' }}>
                  <Row>
                    <Col md={2}>
                      <Icon icon="sms" color="blue" margin={[0, 20, 0, 10]} />
                    </Col>
                    <Col md={10}>
                      <div>
                        <div style={{ color: 'var(--primary-color)' }}>SMS</div>
                        <div style={{ fontWeight: 300, color: '#505050' }}>
                          Schedule/send personalised sms on any network
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Icon
                  icon="arrow-circle"
                  onClick={() => history.push(ROUTES.SMS_SERVICE.path)}
                  margin={[0, 10]}
                />
              </div>
            </Card>
            <Card>
              <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <div className="data" style={{ display: 'flex' }}>
                  <Row>
                    <Col md={2}>
                      <Icon icon="data" color="blue" margin={[0, 20, 0, 10]} />
                    </Col>
                    <Col md={10}>
                      <div>
                        <div style={{ color: 'var(--primary-color)' }}>Data</div>
                        <div style={{ fontWeight: 300, color: '#505050' }}>
                          Buy/Schedule data purchase for any network provider
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Icon
                  icon="arrow-circle"
                  onClick={() => history.push(ROUTES.DATA_SERVICE.path)}
                  margin={[0, 10]}
                />
              </div>
            </Card>
            <Card>
              <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <div className="data" style={{ display: 'flex' }}>
                  <Row>
                    <Col md={3}>
                      <Icon icon="cellphone" color="blue" margin={[0, 20, 0, 10]} />
                    </Col>
                    <Col md={9}>
                      <div style={{ marginLeft: '-7px' }}>
                        <div style={{ color: 'var(--primary-color)' }}>Airtime</div>
                        <div style={{ fontWeight: 300, color: '#505050' }}>
                          Buy/Schedule airtime for any network
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Icon
                  icon="arrow-circle"
                  onClick={() => history.push(ROUTES.AIRTIME_SERVICE.path)}
                  margin={[0, 10]}
                />
              </div>
            </Card>
          </Card>
        </Col>
        <Col md={6} style={{ marginBottom: 40 }}>
          <Card
            header="Wallet Overview"
            style={{ height: 'calc(100% - 50px)', border: '1px solid #E0E0E0', boxShadow: 'none' }}
            styleBody={{ height: 300, padding: '10px', paddingLeft: '0px', position: 'relative' }}
            noWrapper
          >
            <Line data={monthlyWalletAnalytics} loading={fetchingMonthlyAnalytics} />
          </Card>
        </Col>
      </Row>

      <Row>
        <Col style={{ marginBottom: 40 }}>
          <Card
            className="analytics"
            header={
              <>
                <div>Recent Activities</div>
                <div
                  onClick={() => history.push(ROUTES.SERVICE_REPORT.path)}
                  style={{
                    fontSize: '14px',
                    fontWeight: '400px',
                    color: 'var(--primary-color)',
                    cursor: 'pointer',
                  }}
                >
                  View all
                </div>
              </>
            }
            styleBody={{ padding: 0 }}
            style={{
              height: '400px',
              overflow: 'scroll',
            }}
          >
            <Table
              noBorder
              hideHeader
              dontShowEmptyState
              header={[
                {
                  key: 'category',
                  label: 'Campaign',
                  render: (campaign, { amount, isDebit }) => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Icon icon={isDebit ? 'negative' : 'positive'} margin={[0, 20, 0, 0]} />
                      <span style={{ fontWeight: 500 }}>{campaign}</span>
                    </div>
                  ),
                },
                {
                  key: 'amount',
                  label: 'Amount',
                  render: (amount, { isDebit }) => (
                    <NumberFormat
                      value={amount}
                      displayType="text"
                      thousandSeparator
                      prefix={isDebit ? '-₦' : '+₦'}
                      fixedDecimalScale
                      decimalScale={2}
                    />
                  ),
                },
                {
                  key: 'createdAt',
                  label: 'Date',
                  render: (date) => <Moment format="DD MMM, YYYY" date={date} />,
                },
              ]}
              data={recentTransactions}
              variant="secondary"
            />

            {fetchingRecentTransactions && (
              <div className="loader">
                <Loader color="black" size="lg" />
              </div>
            )}
          </Card>
        </Col>
        <Col style={{ marginBottom: 40 }}>
          <Card
            className="activities"
            header={
              <>
                <div>Scheduled Bulk Service</div>
                <div
                  onClick={() => history.push(ROUTES.ALL_CAMPAIGN.path)}
                  style={{
                    fontSize: '14px',
                    fontWeight: '400px',
                    color: 'var(--primary-color)',
                    cursor: 'pointer',
                  }}
                >
                  View all
                </div>
              </>
            }
            styleBody={{ padding: 0 }}
            style={{
              height: '400px',
              overflow: 'scroll',
            }}
          >
            <Table
              noBorder
              hideHeader
              header={[
                {
                  key: 'date',
                  label: 'Date',
                  render: (date) => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Icon icon="calendar" margin={[0, 20, 0, 0]} />
                      <Moment format="DD MMM, YYYY" date={date} />
                    </div>
                  ),
                },
                {
                  key: 'campaign',
                  label: 'Campaign',
                  render: (campaign, { amount }) => (
                    <span style={{ fontWeight: 500 }}>{campaign}</span>
                  ),
                },
                {
                  key: 'name',
                  label: 'Name',
                  render: (name) => <span style={{ fontWeight: 500 }}>{name}</span>,
                },
                {
                  key: 'id',
                  label: 'Amount',
                  render: (_, id) => (
                    <div
                      key={id}
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Options
                        meta={id}
                        options={[
                          { icon: 'pencil', label: 'Edit', action: () => null },
                          { icon: 'trash', label: 'Delete', action: () => null },
                        ]}
                      />
                    </div>
                  ),
                },
              ]}
              data={scheduledCampaignList}
              variant="secondary"
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const StyledCard = styled.div`
  display: flex;
  justify-content: space-around;
  /*max-width: 300px;
  margin: auto;*/
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  padding: 20px;

  div.icon {
    padding-right: 0px;
  }

  div.data {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .join {
      margin-bottom: 15px;
    }
  }

  .large {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    color: #111111;
  }

  .name {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: var(--primary-color);
  }

  .trend {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #828282;
    display: flex;
    align-items: center;
    .nfo {
      opacity: 0.7;
      margin: 0px 5px;
    }
    .icon-wrapper {
      margin: 0px 10px 0px 0px;
    }
  }
`;

const SmallCard = ({ icon, trend = '46', title, subtitle, trendIcon = 'trendRise' }) => {
  return (
    <StyledCard>
      <div className="icon">
        <Icon icon={icon} />
      </div>
      <div className="data">
        <div className="join">
          <div className="large">{title}</div>
          <div className="name">{subtitle}</div>
        </div>
        <div className="trend">
          <Icon icon={trendIcon} />
          {trend}% <span className="nfo"> This Week </span>
        </div>
      </div>
    </StyledCard>
  );
};

export default Dashboard;
