import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { useUpdate } from '../../utilities/hooks/useUpdate';
import { Button, Card } from '../../components/ui';
import { Input, InputSelect } from '../../components/ui/inputs';
import WalletBalance from '../wallet/components/WalletBalance';
import CheckOut from '../wallet/components/CheckOut';
import { sendSmsAction } from '../../redux/actions/sms';
import { fetchWallet } from '../../redux/actions/wallet';
import { fetchSenderIdAction } from '../../redux/actions/senderId';

import SuccessModal from '../wallet/components/PaymentSuccess';
import Schedule from './components/Schedule';
import CreateSenderID from '../campaign/components/CreateSenderID';
import { ROUTES } from '../../components/router/routes/routes';
import { APIGetSMSPricing, APIdefaultPrice, APIscheduleSmsService } from '../../api/backendRoutes';
import { fetchUserData } from '../../storage/sessionStorage';
import { errorParser } from '../../utilities/helper';

const StyledSMSService = styled.div``;

const mapStateToProps = ({ sms, services }) => {
  return {
    payload: sms.payload,
    sendingSms: sms.sendingSms,
    success: sms.success,
    smserror: sms.error,

    serviceTypes: services.serviceTypes,
  };
};

const SMSService = () => {
  const user = fetchUserData();
  const history = useHistory();
  const [showSuccessModal, setShowSuccessModal] = useState({ show: false, headerText: null });
  const [showCheckOut, setShowCheckOut] = useState(false);
  const [price, setPrice] = useState(0);
  const [defaultPrice, setDefaultPrice] = useState(null);
  const [sendError, setSendError] = useState([false, '']);
  const [showSchedule, setShowSchedule] = useState(false);
  const [showCreateId, setShowCreateId] = useState(false);
  const [formData, setFormData] = useState({
    sender_id: '',
    phone_numbers: '',
    message: '',
    file: null,
    error: [],
    fetching: false,
    messageCount: 0,
  });
  const [smsPayload, setSmspayload] = useState({});
  const dispatch = useDispatch();

  const fetchId = useCallback(() => {
    dispatch(fetchSenderIdAction());
  }, [dispatch]);
  useEffect(() => {
    fetchId();
  }, []);
  const { senderId } = useSelector((state) => {
    const newSenderId = state.senderId.senderId
      .filter((s) => s.approval_status === 'approved')
      .map((s) => ({ value: s.name, label: s.name }));
    return {
      senderId: newSenderId,
    };
  });

  const SendSms = useCallback((params) => dispatch(sendSmsAction(params)), [dispatch]);

  const fetchUserWallet = useCallback(() => {
    dispatch(fetchWallet());
  }, [dispatch]);

  const { sendingSms, success, smserror, payload } = useSelector(mapStateToProps);
  const { smsMeta, balance } = useSelector((state) => {
    const service = state.services.services;
    return {
      smsMeta: service.filter((s) => s.serviceName === 'SMS')[0],
      balance: state.wallet.walletNfo.balance,
    };
  });

  const handleInputChange = ({ name, value, error }) => {
    const { message } = formData;
    if (error) {
      setFormData((v) => ({ ...v, error: [true, error] }));
    } else {
      setFormData((v) => ({ ...v, [name]: value, messageCount: message?.length, error: [] }));
    }
  };
  // fetch default price
  useEffect(async () => {
    try {
      if (smsMeta?._id) {
        const resp = await APIdefaultPrice({ serviceId: smsMeta?._id });
        if (resp.data.message === 'ok') {
          const newDefaultPrice = resp.data.data;
          setDefaultPrice(newDefaultPrice?.price);
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }, [smsMeta]);
  

  const fetchSMSPrice =  async () => {
    const phoneArr = formData.phone_numbers?.split(',').filter((d) => d.trim() !== '') || [];

    // get price from API
    if (phoneArr.length) {
      const resp = await APIGetSMSPricing({
        serviceId: smsMeta._id,
        phoneNumbers: phoneArr,
        message: formData.message,
      });
      setPrice(resp.data.data.totalPrice);
    }
  }

  const handleSend = async () => {
    setShowCheckOut(false);
    await SendSms(smsPayload);
  };
  useUpdate(() => {
    if (success) {
      setShowSuccessModal((s) => ({
        ...s,
        show: true,
        transactionId: payload.data.transactionReference,
        respData: payload.data,
        receipt: true,
      }));
      fetchUserWallet();
    } else if (smserror) {
      setSendError([true, 'Error sending sms']);
    }
  }, [success]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { message, phone_numbers, sender_id, error } = formData;

    if (error.length <= 0 && phone_numbers && message.length > 1) {
      const body = {
        message: message.trim(),
        phoneNumbers: phone_numbers.split(',').filter((d) => d.trim() !== ''),
        serviceId: smsMeta._id,
        senderId: sender_id,
        currencyType: 'NGN',
        countryCode: '234',
        // amount: defaultPrice,
      };

      setSmspayload(body);
      setShowCheckOut(true);
    }
  };
  const handleCloseSuccessModal = () => {
    setShowSuccessModal((s) => ({ ...s, show: false }));
    history.go(0);
    setFormData(() => ({
      sender_id: '',
      phone_numbers: '',
      message: '',
      file: null,
      error: [],
      fetching: false,
      messageCount: 0,
    }));
  };

  const handleShowSchedule = () => {
    const { message, sender_id, phone_numbers } = formData;
    if (message !== '' && sender_id !== '' && phone_numbers !== '') {
      setShowSchedule(true);
    } else {
      setSendError([true, 'Please fill all fields']);
    }
  };

  const handleSchedule = async (param) => {
    const { message, sender_id, phone_numbers } = formData;
    const body = {
      ...param,
      campaignScheduleType: 'ScheduleService',
      serviceType: 'SMS',
      serviceId: smsMeta._id,
      companyId: user.companyId,
      campaignServiceData: {
        message: message.trim(),
        phoneNumbers: phone_numbers.split(',').filter((d) => d.trim() !== ''),
        sender_id,
        currency_type: 'NGN',
        country_name: '234',
        amount: defaultPrice,
      },
    };
    try {
      setFormData((v) => ({ ...v, fetching: true }));
      // const resp = await APIscheduleService(body);
      const resp = await APIscheduleSmsService(body);
      if (resp.data.message === 'ok') {
        setFormData((v) => ({ ...v, fetching: false }));
        setShowSchedule(false);
        setShowSuccessModal((s) => ({
          ...s,
          show: true,
          headerText: 'SMS has been scheduled',
        }));
      } else {
        setFormData((v) => ({ ...v, fetching: false }));
        throw resp?.data?.error;
      }
    } catch (err) {
      toast.error(errorParser(err) || 'Server error');
      // console.log(err);
      setFormData((v) => ({ ...v, fetching: false }));
    }
  };
  return (
    <StyledSMSService>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h5>Send SMS</h5>
      </div>
      <section>
        <Row>
          <Col lg={8} style={{ marginBottom: 20 }}>
            <form onSubmit={handleSubmit}>
              <Card>
                <Row>
                  <Col>
                    <InputSelect
                      placeholder="Select Sender ID"
                      label="Select sender ID"
                      name="sender_id"
                      hint={
                        <div>
                          Don&apos;t have an ID?{' '}
                          <span
                            onClick={() => setShowCreateId(true)}
                            style={{
                              cursor: 'pointer',
                              fontWeight: 'bold',
                              color: 'var(--primary-color)',
                            }}
                          >
                            Create One
                          </span>
                        </div>
                      }
                      onChange={handleInputChange}
                      defaultValue={formData.sender_id}
                      options={senderId}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      placeholder="Enter or paste recipients (e.g +23490xxxxxxxx) - Separate numbers with comma"
                      label="Enter Recipients"
                      onChange={handleInputChange}
                      value={formData.file ? '' : formData.phone_numbers}
                      disabled={!!formData.file}
                      name="phone_numbers"
                      onBlur={fetchSMSPrice}
                      validate="required|multi_phone_numbers:,:5"
                      hint="*You can only enter 5 numbers"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {formData.error[1] === 'You cannot enter more than 5 numbers' && (
                      <div
                        style={{
                          borderLeft: '2px solid red',
                          padding: '10px 15px',
                          background: 'rgba(80, 159, 239, 0.03)',
                          fontSize: '12px',
                          color: '#505050',
                          marginBottom: '20px',
                        }}
                      >
                        You have exceeded the recommended limit.
                        <span
                          style={{
                            fontWeight: 'bold',
                            fontSize: '14px',
                            color: 'var(--primary-color)',
                            cursor: 'pointer',
                          }}
                          onClick={() => history.push(ROUTES.CREATE_CAMPAIGN.path)}
                        >
                          Create Bulk Service?
                        </span>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      multiline
                      placeholder="Enter your message here"
                      label="Enter message"
                      rows={10}
                      name="message"
                      onChange={handleInputChange}
                      validate="required"
                      onBlur={fetchSMSPrice}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      fontSize: '14px',
                      fontWeight: 'lighter',
                    }}
                  >
                    <div>
                      {formData.messageCount || 0}/{Math.ceil(formData.messageCount / 160 || 0)}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="btn-container">
                    <Button
                      type="submit"
                      value="Send Now"
                      loading={sendingSms}
                      disabled={formData?.phone_numbers === undefined || defaultPrice === 0}
                    />
                    <Button
                      value="Schedule for Later"
                      group="outline"
                      size="lg"
                      onClick={handleShowSchedule}
                      disabled={formData?.phone_numbers === undefined || defaultPrice === 0}
                    />
                  </Col>
                </Row>
                {sendError[0] && (
                  <div
                    style={{
                      fontSize: '14px',
                      color: 'red',
                      display: 'flex',
                      justifyContent: 'center',
                      margin: '20px 0px',
                    }}
                  >
                    {sendError[1]}
                  </div>
                )}
              </Card>
            </form>
          </Col>
          <Col lg={4}>
            <WalletBalance price={price} />
          </Col>
        </Row>
      </section>
      <SuccessModal
        show={showSuccessModal.show}
        setShowSuccessModal={setShowSuccessModal}
        transactionId={showSuccessModal.transactionId}
        respData={showSuccessModal?.respData}
        onClose={handleCloseSuccessModal}
        price={price}
        headerText={showSuccessModal.headerText}
        receipt={showSuccessModal.receipt}
      />
      <CheckOut
        show={showCheckOut}
        onClose={() => setShowCheckOut(false)}
        execute={handleSend}
        price={price}
        disableWallet={price > balance}
      />
      <Schedule
        show={showSchedule}
        setShow={setShowSchedule}
        handleSchedule={handleSchedule}
        loading={formData.fetching}
      />
      <CreateSenderID show={showCreateId} onClose={setShowCreateId} />
    </StyledSMSService>
  );
};

export default SMSService;
