// import Toast from 'react-hot-toast';
import { APIfetchMerchantServiceReportDetail } from '../../api/backendRoutes';
import {
  fetchServiceReportDetailSuccessReducer,
  fetchingServiceReportDetailReducer,
  fetchingServiceReportDetailErrorReducer,
} from '../slices/serviceReportDetail';

export const fetchServiceReportDetailAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchingServiceReportDetailReducer());
    try {
      const resp = await APIfetchMerchantServiceReportDetail(params);
      if (resp.data.message === 'ok') {
        dispatch(fetchServiceReportDetailSuccessReducer(resp.data));
      } else {
        dispatch(fetchingServiceReportDetailErrorReducer());
        throw resp?.data?.error;
      }
    } catch (error) {
      // Toast.error(error?.data?.message || 'Server error');
    }
  };
