import React from 'react';
import { Card } from '../../components/ui';

const GiftCards = () => {
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h5>Select a Giftcard</h5>
      </div>

      <section>
        <div style={{ display: 'flex', flexWrap: 'no-wrap', overflowX: 'auto' }}>
          <Card style={{ padding: '30px 20px' }} />
        </div>
      </section>
    </div>
  );
};

export default GiftCards;
