import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import { isMobile } from 'react-device-detect';

const GlobalStyle = createGlobalStyle`
    ${normalize}
    
    h1,h2,h3,h4,h5,h6,p,ul{
      padding: 0;
      margin: 0;
    }
    h1,h2,h3,h4,h5,h6{
        font-family: var(--primary-font);
    }
    // h5 {
    //     font-size: ${isMobile ? '20px' : '30px'};
    //     font-weight: ${isMobile ? '500' : '200'};
    // }
    a {
        color: var(--primary-color);
    }
    .recharts-cartesian-axis-tick {    
        font-size: 12px;
        font-weight: 300;
        font-family: var(--primary-font);
    }

    .scrollbar::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.2);
		background-color: #F5F5F5;
	}

	.scrollbar::-webkit-scrollbar {
		width: 5px;
		background-color: #F5F5F5;
	}

	.scrollbar::-webkit-scrollbar-thumb {
		-webkit-box-shadow: inset 0 0 3px rgba(0,0,0,.2);
		background-color: #9cb4ca;
        border-radius: 3px;
	}
    .link {
        cursor: pointer;
        font-weight: 400;
        color: var(--primary-color);
    }
    .link:hover {
        color: var(--primary-color);
        text-decoration: underline;
    }
    .modal-content {
        padding: 10px 30px;
    }
    .modal-header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;
        
    }
    .modal-title {
        font-size: 16px;
        font-weight: 600 !important;
    }
    .modal-body {
        padding: 1rem 0;
    }
    ${
      isMobile
        ? `
    .card--mobile {
        padding: 15px 0 !important;

        .card-body {
            padding: 10px 0 !important;
        }
    }
    `
        : `.card--mobile { 
            padding: 20px 15px;
        }`
    };
    object {
        pointer-events: none;
    }
    ::-webkit-input-placeholder {font-style: ; background: none !important;} /*Chrome */
    :-moz-placeholder {font-style: italic} /*ff*/
    :-ms-input-placeholder {font-style: italic} /*ie latest */
    input:-webkit-autofill, select:-webkit-autofill, textarea:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 500px white inset;
    }
    section {
        padding: 20px 0;
    }
    .no-border {
        fieldset {
            border: none !important;
        }
    }
    .nopadding {
        .modal-content, .modal-body {
            padding: 0;
        }
    }
    iframe {
	z-index: 2147483647;
	background: rgba(0, 0, 0, 0.5);
	border: 0px none transparent;
	overflow: hidden;
	margin: 0px;
	padding: 0px;
	-webkit-tap-highlight-color: transparent;
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	transition: opacity 0.3s ease 0s;
	visibility: visible;
}
`;

export default GlobalStyle;
