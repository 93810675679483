import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: 20,
    height: 20,
    marginLeft: '15px',
  },
});

const MaterialCheckbox = React.forwardRef(({ name, label, errors, checked }, ref) => {
  const classes = useStyles();
  return (
    <>
      <FormControlLabel
        control={<Checkbox color="primary" className={classes.root} />}
        name={name}
        inputRef={ref}
        label={label}
        defaultChecked={checked}
      />
    </>
  );
});

export default MaterialCheckbox;
