import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';

import { Row, Col } from 'react-bootstrap';
import { Button, Card, Image, Tabs, Loader } from '../../components/ui';
import { Input, InputSelect } from '../../components/ui/inputs';
import WalletBalance from '../wallet/components/WalletBalance';
import { errorParser, extractCountryCode, reformatDataPlan } from '../../utilities/helper';
import { buyDataAction } from '../../redux/actions/data';
import CheckOut from '../wallet/components/CheckOut';
import SuccessModal from '../wallet/components/PaymentSuccess';
import Schedule from './components/Schedule';
import { useUpdate } from '../../utilities/hooks/useUpdate';
import { fetchWallet } from '../../redux/actions/wallet';
import { ROUTES } from '../../components/router/routes/routes';
import { APIfetchOperator, APIscheduleDataService } from '../../api/backendRoutes';
import { getCountryCode, getCurrencyType } from '../../utilities/helpers';
import { fetchUserData } from '../../storage/sessionStorage';

const mapStateToProps = ({ data }) => {
  return {
    dataOper: data.dataPlans,
    fetching: data.fetchingOperator,
    errorBuyingData: data.error,
    dataTopUp: data.dataTopUp,
    buying: data.buyingData,
    success: data.success,
  };
};

const DataService = () => {
  const user = fetchUserData();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [operators, setOperators] = useState(null);
  const [price, setPrice] = useState(0);
  const [showCheckOut, setShowCheckOut] = useState(false);
  const [sendError, setSendError] = useState([false, '']);
  const [dataPayload, setDataPayload] = useState([]);
  const [completePayload, setCompletePayload] = useState({});
  const [showSuccessModal, setShowSuccessModal] = useState({ show: false, headerText: null });
  const [showSchedule, setShowSchedule] = useState(false);
  const [formData, setFormData] = useState({
    phone_numbers: '',
    dial_code: '234',
    amount: '',
    error: [],
    default_plan: {},
    fetching: false,
  });

  const [unsupportedPhones, setUnsupportedPhones] = useState([]);
  const dispatch = useDispatch();

  const FetchDataplans = useCallback((params) => {
    setPrice(0);
    setDataPayload([]);
    return APIfetchOperator(params);
  }, []);
  const BuyData = useCallback((params) => dispatch(buyDataAction(params)), [dispatch]);
  const fetchUserWallet = useCallback(() => {
    dispatch(fetchWallet());
  }, [dispatch]);
  const { errorBuyingData, buying, success, dataTopUp } = useSelector(mapStateToProps);

  const { dataMeta, balance } = useSelector((state) => {
    const service = state.services.services;
    return {
      dataMeta: service.filter((s) => s.serviceName === 'Data')[0],
      balance: state.wallet.walletNfo.balance,
    };
  });
  const handleDataPlanChange = ({ name, value, ext, id }) => {
    const newVal = value.split(' ')[0];
    setSendError([false, '']);
    setDataPayload((v) => ({
      ...v,
      [id]: {
        operator: name,
        cost: newVal,
        meta: ext,
      },
    }));
    const newPrice = Object.values({
      ...dataPayload,
      [id]: {
        operator: name,
        cost: newVal,
        meta: ext,
      },
    }).reduce((acc, d) => {
      return acc + parseFloat(d.cost || '0.00');
    }, 0);
    setPrice(newPrice.toFixed(2));
  };
  const showNetworkOperator = () => {
    const header = operators?.map((d) => {
      return {
        key: d.operator,
        label: <Image src={d?.logo[0]} name={d.operator} width={32} />,
      };
    });

    const data = operators.reduce((acc, d, i) => {
      return {
        ...acc,
        [d.operator]: (
          <Row>
            {d.phoneNumber.map((phone, idx) => (
              <>
                <Col
                  key={idx}
                  md={6}
                  style={{
                    marginTop: '40px',
                  }}
                >
                  <div
                    style={{
                      margin: '10px 0px',
                      opacity: '0.8',
                    }}
                  >
                    {phone}
                  </div>
                  {d.dataPlan.length > 0 ? (
                    <InputSelect
                      idx={idx + d.operator}
                      name={d.operator}
                      label={d.operator}
                      // validate="required"
                      meta={{
                        phoneNumber: phone,
                        operatorId: d.dataPlan[0]?.operatorId,
                        dataplan: d.dataPlan[0]?.plan,
                      }}
                      options={d.dataPlan.map((a) => ({
                        label: a.plan,
                        value: `${a.cost} ${a.plan}`,
                      }))}
                      onChange={handleDataPlanChange}
                    />
                  ) : (
                    'No data plan for this operator'
                  )}
                </Col>
              </>
            ))}
          </Row>
        ),
      };
    }, {});
    return <Tabs header={header} data={data} />;
  };

  const handleSend = async () => {
    setShowCheckOut(false);
    await BuyData(completePayload);
  };
  useUpdate(() => {
    if (success) {
      setShowSuccessModal((s) => ({
        ...s,
        show: true,
        transactionId: dataTopUp.data.transactionReference,
        respData: dataTopUp.data,
        receipt: true,
      }));
      fetchUserWallet();
    } else if (errorBuyingData) {
      setSendError([true, 'Error buying data']);
    }
  }, [success, errorBuyingData]);

  const getPayload = () => {
    const temp = Object.values(dataPayload);
    const computedPayload = temp.map((t) => ({
      phoneNumber: t.meta.phoneNumber,
      selectedPlan: {
        cost: +t.cost,
        operatorId: t.meta.operatorId,
        plan: t.meta?.dataplan,
      },
      operator: t.operator,
    }));
    const body = {
      customers: computedPayload,
      serviceId: dataMeta?._id,
      companyId: user?.companyId,
      countryCode: getCountryCode(computedPayload[0]?.operator || ''),
      currencyType: getCurrencyType(computedPayload[0]?.operator || ''),
    };
    return body;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = formData.phone_numbers.split(',').length > 0 && getPayload();
    const temp = payload.customers.map((s) => s.selectedPlan).filter((f) => f.plan === undefined);

    if (
      formData.phone_numbers.length &&
      temp.length === 0 &&
      payload.customers.length === operators.length
    ) {
      setCompletePayload(getPayload());
      setShowCheckOut(true);
    } else {
      setSendError([true, 'Please select data plan for all phone number']);
    }
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal((s) => ({ ...s, headerText: null, show: false }));
    history.go(0);
  };

  const handleInputChange = ({ name, value, error }) => {
    if (error) {
      setFormData((v) => ({ ...v, [name]: value, error: [true, error] }));
    } else {
      setFormData((v) => ({ ...v, [name]: value, error: [] }));
      const numArr = value.split(',').map((v) => v.trim());
      if (numArr.length > 0) {
        const formatedPhoneNumber = extractCountryCode(numArr);
        const arrayOfPromise =
          formatedPhoneNumber !== null &&
          formatedPhoneNumber.map((p) => {
            return new Promise((resolve, reject) => {
              FetchDataplans(p)
                .then((res) => resolve(res))
                .catch((err) => reject(err));
            });
          });
        if (arrayOfPromise) {
          setLoading(true);
          const arr = [];
          Promise.all(
            arrayOfPromise.map((p) =>
              p.catch((err) => {
                if (err?.response?.data?.data?.error?.phoneNumber) {
                  arr.push(err?.response?.data?.data?.error?.phoneNumber);
                }
                return err;
              })
            )
          ).then((resp) => {
            setUnsupportedPhones(arr);
            const computedOperator = resp
              .map((data, idx) => {
                if (data.data?.success) {
                  setLoading(false);
                  const operator = data?.data?.data?.operator;
                  const plans = data?.data?.data?.plans;
                  return {
                    operator: operator?.name,
                    logo: operator?.logoUrls,
                    country: operator?.country,
                    phoneNumber: `+${formatedPhoneNumber[idx].countryCode}${formatedPhoneNumber[idx].phoneNumber}`,
                    dataPlan: plans,
                  };
                }
                setLoading(false);
                return [];
              })
              .filter(
                (f) =>
                  f.operator !== undefined &&
                  f.logo !== undefined &&
                  f.phoneNumber !== undefined &&
                  f.dataPlan !== undefined
              );
            const reformatted = reformatDataPlan(computedOperator);

            setOperators(() => reformatted);
          });
        }
      }
    }
  };
  const handleShowSchedule = () => {
    const payload = getPayload();
    const temp = payload.customers.map((s) => s.selectedPlan).filter((f) => f.plan === undefined);
    if (temp.length === 0 && payload.customers.length > 0) {
      setShowSchedule(true);
    } else {
      setSendError([true, 'please selecte data plan for all phone number']);
    }
  };
  const handleSchedule = async (param) => {
    const payload = getPayload();
    const scheduleData = {
      ...param,
      campaignScheduleType: 'ScheduleService',
      serviceType: 'DATA',
      serviceId: dataMeta._id,
      companyId: user.companyId,
      campaignServiceData: {
        customers: payload.customers,
      },
    };
    try {
      setFormData((v) => ({ ...v, fetching: true }));
      // const resp = await APIscheduleService(scheduleData);
      const resp = await APIscheduleDataService(scheduleData);
      if (resp.data.message === 'ok') {
        setFormData((v) => ({ ...v, fetching: false }));
        setShowSchedule(false);
        setShowSuccessModal((s) => ({
          ...s,
          show: true,
          headerText: 'Data has been scheduled',
        }));
      } else {
        setFormData((v) => ({ ...v, fetching: false }));
        throw resp?.data?.error;
      }
    } catch (err) {
      toast.error(errorParser(err) || 'Server error');
      // console.log(err);
      setFormData((v) => ({ ...v, fetching: false }));
    }
  };

  return (
    <>
      <div className="data-wrapper">
        <div className="header">
          <h5>Buy Data</h5>
        </div>

        <section>
          <Row>
            <Col md={8} style={{ marginBottom: 20 }}>
              <form>
                <Card>
                  <Row>
                    <Col>
                      <Input
                        placeholder="Enter or paste recipients (e.g +23490xxxxxxxx) - Separate numbers with comma"
                        label="Enter Recipients"
                        onChange={handleInputChange}
                        defaultValue={formData.phone_numbers}
                        disabled={!!formData.file}
                        name="phone_numbers"
                        validate="required|multi_phone_numbers:,:5"
                        hint="* You can only enter 5 numbers, for multiple contacts run campaign"
                      />
                    </Col>
                  </Row>
                  <Col>
                    {formData.error[1] === 'You cannot enter more than 5 numbers' && (
                      <div className="exceed">
                        You have exceeded the recommended limit.
                        <span
                          className="nfo"
                          onClick={() => history.push(ROUTES.CREATE_CAMPAIGN.path)}
                        >
                          Create Bulk Service?
                        </span>
                      </div>
                    )}
                  </Col>
                  {loading && (
                    <div className="loading">
                      <Loader />
                    </div>
                  )}
                  <Row>
                    <Col>
                      {operators?.length > 0 && (
                        <>
                          <div style={{ fontWeight: 300 }}>Choose Default Data Plan</div>
                          <div className="shw-operator">{showNetworkOperator()}</div>
                        </>
                      )}
                    </Col>
                  </Row>
                  {unsupportedPhones.length > 0 && (
                    <div className="px-4">
                      <p className="text-danger">
                        Data bundles are unsupported for{' '}
                        {unsupportedPhones.length > 1 ? 'these' : 'this'} phone number
                        {unsupportedPhones.length > 1 ? 's' : ''}{' '}
                        {unsupportedPhones.map((dt) => `${dt}, `)}
                      </p>
                    </div>
                  )}
                  <Row>
                    <Col className="btn-container">
                      <Button
                        value="Send Now"
                        size="lg"
                        type="submit"
                        loading={buying}
                        onClick={handleSubmit}
                        disabled={formData.phone_numbers.split(',').length > 5}
                      />
                      <Button
                        value="Schedule for Later"
                        group="outline"
                        onClick={handleShowSchedule}
                        disabled={formData.phone_numbers.split(',').length > 5}
                      />
                    </Col>
                  </Row>
                  {sendError[0] && <div className="error">{sendError[1]}</div>}
                </Card>
              </form>
            </Col>
            <Col md={4}>
              <WalletBalance price={price} />
            </Col>
          </Row>
        </section>
        <CheckOut
          show={showCheckOut}
          onClose={() => setShowCheckOut(false)}
          execute={handleSend}
          price={price}
          disableWallet={price > balance}
        />
        <SuccessModal
          show={showSuccessModal.show}
          onClose={handleCloseSuccessModal}
          setShowSuccessModal={setShowSuccessModal}
          price={price}
          transactionId={showSuccessModal.transactionId}
          respData={showSuccessModal?.respData}
          headerText={showSuccessModal.headerText}
          receipt={showSuccessModal.receipt}
        />
        <Schedule
          show={showSchedule}
          setShow={setShowSchedule}
          handleSchedule={handleSchedule}
          loading={formData.fetching}
        />
      </div>
    </>
  );
};

export default DataService;