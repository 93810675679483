import React from 'react';
import { Tabs as BTabs, Tab } from 'react-bootstrap';



const Tabs = ({ header, data, activeKey, action, plan }) => {
  return (
    <>
      <div className="tabs-wrapper">
        <BTabs defaultActiveKey={activeKey || header[0]?.key} id="uncontrolled-tab-example">
          {header?.map(({ key, label }) => (
            <Tab eventKey={key} title={label} key={key}>
              {data[key]}
            </Tab>
          ))}
        </BTabs>
      </div>
    </>
  );
};

export default Tabs;
