import React, { useEffect, useState } from 'react';
import { TextField, IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import InputHint from '../common/InputHint/InputHint';
import { validator } from '../../../../utilities/helper';

const PasswordInput = ({
  label,
  placeholder = '',
  disabled = false,
  variant = 'outlined',
  hint,
  value,
  name,
  defaultValue,
  onChange,
  validate,
  ...rest
}) => {
  const [errors, setErrors] = useState([]);
  const [data, setData] = useState(defaultValue);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (onChange) {
      onChange({ name, value: data });
    }
  }, []);

  const handleValidation = (e) => {
    const { name: n, value: v } = e.target;
    const errs = validate ? validator(v, validate) : [];
    setErrors(errs);
    if (onChange) {
      onChange({ name: n, value: v, error: errs.join('\n') });
    }
  };

  const handleOnChange = (e) => {
    const { name: n, value: v } = e.target;
    setData(v);
    if (errors) {
      handleValidation(e);
    } else if (onChange) {
      onChange({ name: n, value: v });
    }
  };

  const onShowPassword = () => {
    setShowPassword((t) => !t);
  };

  return (
    <div className="input-wrapper">
      <TextField
        name={name}
        defaultValue={defaultValue}
        label={label}
        type={showPassword ? 'text' : 'password'}
        placeholder={placeholder || label}
        disabled={disabled}
        variant={variant}
        aria-label={label}
        aria-describedby={label}
        value={defaultValue}
        onChange={handleOnChange}
        onBlur={handleValidation}
        error={errors.length > 0}
        helperText={errors.length > 0 ? errors[0] : ''}
        required={!!validate}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={onShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...rest}
      />
      {errors.length === 0 && hint && <InputHint>{hint}</InputHint>}
    </div>
  );
};

export default PasswordInput;
