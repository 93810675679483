import React, { createContext, useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { APIgetCompanyDetailsByDomain } from '../../api/backendRoutes';
import { hexToRgb } from '../helpers';

const AppContext = createContext({});
const initialState = { showSideMenu: true, goBackMethod: null };
const AppProvider = ({ children }) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState(initialState);
  const [partnerInfo, setPartnerInfo] = useState({});
  const [, setError] = useState('');
  const [checkoutData, setCheckoutData] = useState({});
  useEffect(() => {
    if (isMobile) {
      setData((d) => ({ ...d, showSideMenu: false }));
    }
  }, [isMobile]);
  useEffect(async () => {
    // const url = window.location.href;
    setDataLoading(true);
    try {
      const resp = await APIgetCompanyDetailsByDomain({
        domainName:
          window.location.hostname === 'localhost'
            ? 'dev.bluintouch.com'
            : window.location.hostname,
      });
      if (resp.data.message === 'ok') {
        const newData = resp.data.data;
        
        // const primaryRgb = hexToRgb('#ff0000');
        // const secondaryRgb = hexToRgb('#3333cc');
        const primaryRgb = hexToRgb(newData.primaryColor);
        const secondaryRgb = hexToRgb(newData.secondaryColor);

        document.documentElement.style.setProperty(
          '--primary-color',
          `rgba(${primaryRgb[0]}, ${primaryRgb[1]}, ${primaryRgb[2]}, 1)`
        );
        document.documentElement.style.setProperty(
          '--secondary-color',
          `rgba(${secondaryRgb[0]}, ${secondaryRgb[1]}, ${secondaryRgb[2]}, 1)`
        );
        document.documentElement.style.setProperty(
          '--secondary-light',
          `rgba(${secondaryRgb[0]}, ${secondaryRgb[1]}, ${secondaryRgb[2]}, 0.1)`
        );
        document.documentElement.style.setProperty(
          '--secondary-light-2',
          `rgba(${secondaryRgb[0]}, ${secondaryRgb[1]}, ${secondaryRgb[2]}, 0.06)`
        );

        setPartnerInfo({...newData, '--primary-color':
          `rgba(${primaryRgb[0]}, ${primaryRgb[1]}, ${primaryRgb[2]}, 1)`, '--secondary-color':
          `rgba(${secondaryRgb[0]}, ${secondaryRgb[1]}, ${secondaryRgb[2]}, 1)`, '--secondary-light':
          `rgba(${secondaryRgb[0]}, ${secondaryRgb[1]}, ${secondaryRgb[2]}, 0.1)`, '--secondary-light-2':
          `rgba(${secondaryRgb[0]}, ${secondaryRgb[1]}, ${secondaryRgb[2]}, 0.06)` });
        setDataLoading(false);
      }
    } catch (err) {
      setError(err);
      setDataLoading(false);
    }
  }, []);
  const toggleSideMenu = () => {
    setData((d) => ({ ...d, showSideMenu: !d.showSideMenu }));
  };
  const setGoBackMethod = (method) => {
    setData((d) => ({ ...d, goBackMethod: method }));
  };
  const goBack = () => {
    if (data.goBackMethod) {
      data.goBackMethod();
    }
  };
  return (
    <AppContext.Provider
      value={{
        toggleSideMenu,
        setGoBackMethod,
        goBack,
        ...data,
        partnerInfo,
        dataLoading,
        setCheckoutData,
        checkoutData,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export { AppContext, AppProvider };