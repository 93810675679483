
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  usersByType: [],
  fetchingUsersByType: false,
  usersByTypeMeta: {},

  accountTypes: [],
  fetchingAccountTypes: false,
  accountTypesMeta: {},
};

export const users = createSlice({
  name: 'users',
  initialState,
  reducers: {
    fetchingAdminUsersByTypeReducer: (state) => {
      state.fetchingUsersByType = true;
      state.usersByType = [];
      state.usersByTypeMeta = {};
    },
    fetchAdminUsersByTypesSuccessReducer: (state, { payload }) => {
      state.usersByType = payload.data;
      state.fetchingUsersByType = false;
      state.usersByTypeMeta = {
        limit: payload.limit,
        page: payload.page,
        total: payload.total,
      };
    },
    fetchingAdminUsersByTypeErrorReducer: (state) => {
      state.fetchingUsersByType = false;
      state.usersByType = [];
      state.usersByTypeMeta = {};
    },

    fetchAccountTypesSuccessReducer: (state, { payload }) => {
      state.accountTypes = payload.data;
      state.fetchingAccountTypes = false;
      state.accountTypesMeta = {
        limit: payload.limit,
        page: payload.page,
        total: payload.total,
      };
    },
    fetchingAccountTypesReducer: (state) => {
      state.fetchingAccountTypes = true;
      state.accountTypes = [];
      state.accountTypesMeta = {};
    },
    fetchingAccountTypesErrorReducer: (state) => {
      state.fetchingAccountTypes = false;
      state.accountTypes = [];
      state.accountTypesMeta = {};
    },
  },
});

export const {
  fetchAdminUsersByTypesSuccessReducer,
  fetchingAdminUsersByTypeReducer,
  fetchingAdminUsersByTypeErrorReducer,

  fetchAccountTypesSuccessReducer,
  fetchingAccountTypesReducer,
  fetchingAccountTypesErrorReducer,
} = users.actions;

export default users.reducer;
