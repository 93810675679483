
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  transactionReport: [],
  fetchingTransactionReport: false,
  transactionReportMeta: {},

  recentTransactions: [],
  fetchingRecentTransactions: false,
  errorFecthingRecentTransactions: false,
};

export const transactionReportSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    fetchTransactionReportSuccessReducer: (state, { payload }) => {
      state.transactionReport = payload.data;
      state.fetchingTransactionReport = false;
      state.transactionReportMeta = {
        limit: payload.limit,
        page: payload.page,
        total: payload.total,
      };
    },
    fetchingTransactionReducer: (state) => {
      state.transactionReport = [];
      state.fetchingTransactionReport = true;
      state.transactionReportMeta = {};
    },
    fetchingTransactionReportErrorReducer: (state) => {
      state.transactionReport = [];
      state.fetchingTransactionReport = true;
      state.transactionReportMeta = {};
    },

    fetchRecentTransactioSuccessReducer: (state, { payload }) => {
      state.recentTransactions = payload.data;
      state.fetchingRecentTransactions = false;
      state.errorFecthingRecentTransactions = false;
    },
    fetchingRecentTransactionReducer: (state) => {
      state.recentTransactions = [];
      state.fetchingRecentTransactions = true;
      state.errorFecthingRecentTransactions = false;
    },
    fetchingRecentTransactionReportErrorReducer: (state) => {
      state.recentTransactions = [];
      state.fetchingRecentTransactions = true;
      state.errorFecthingRecentTransactions = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  fetchTransactionReportSuccessReducer,
  fetchingTransactionReducer,
  fetchingTransactionReportErrorReducer,

  fetchRecentTransactioSuccessReducer,
  fetchingRecentTransactionReducer,
  fetchingRecentTransactionReportErrorReducer,
} = transactionReportSlice.actions;

export default transactionReportSlice.reducer;
