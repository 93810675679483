import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  creatingMerchant: false,
  merchantCreated: {},
  merchantCreationFailed: false,
};

export const merchantSlice = createSlice({
  name: 'merchant',
  initialState,
  reducers: {
    createMerchantReducer: (state, { payload }) => {
      state.creatingMerchant = false;
      state.merchantCreated = payload.data;
      state.merchantCreationFailed = false;
    },
    creatingMerchantReducer: (state, { payload }) => {
      state.creatingMerchant = true;
      state.merchantCreated = {};
      state.merchantCreationFailed = false;
    },
    errorcreatingMerchantReducer: (state, { payload }) => {
      state.creatingMerchant = false;
      state.merchantCreated = {};
      state.merchantCreationFailed = payload;
    },
  },
});
export const { createMerchantReducer, creatingMerchantReducer, errorcreatingMerchantReducer } =
  merchantSlice.actions;

export default merchantSlice.reducer;
