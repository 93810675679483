import styled, { keyframes } from 'styled-components';

const rotation = keyframes`
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
`;
const Spinner = styled.div`
  height: ${(props) => props.size || '30px'};
  width: ${(props) => props.size || '30px'};
  border: ${(props) => props.spinnercolor || '1px solid #fff'};
  border-radius: 50%;
  border-top: none;
  border-right: none;
  margin: 0 auto;
  animation: ${rotation} 1s linear infinite;
`;

export default Spinner;
