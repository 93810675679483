import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Button, Loader, Logo } from '../../../components/ui';
import { ROUTES } from '../../../components/router/routes/routes';
import { SvgIcon } from '../../../components/ui/Icon';
import { APIverifyEmail } from '../../../api/backendRoutes';
import { AppContext } from '../../../utilities/context/AppContext';

const VerifyAccount = () => {
  const { token } = useParams();
  const { partnerInfo } = useContext(AppContext);
  const history = useHistory();
  const [state, setState] = useState({ email: null, status: null, fetching: false });

  useEffect(async () => {
    if (token) {
      setState((d) => ({ ...d, fetching: true }));
      try {
        const resp = await APIverifyEmail({
          token,
        });
        if (resp.data.message === 'ok') {
          setState((d) => ({ ...d, fetching: false }));
        }
      } catch (err) {
        setState((d) => ({ ...d, fetching: false }));
      }
    }
  }, [token]);

  return (
    <div className="verify-account-wrapper">
      {state.fetching === true && <Loader />}
      {state.fetching === false && (
        <>
          <Row>
            <Col>
              <Logo src={partnerInfo.logoUrl || '/logo-black.svg'} name="Blusalt" />
            </Col>
          </Row>
          <Row>
            <Col>
              <h4>Your email has been successfully verified</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <SvgIcon icon="thumbsUp" />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Button
                value="Proceed to login"
                variant="contained"
                type="button"
                onClick={() => history.push(ROUTES.SIGN_IN.path)}
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default VerifyAccount;
