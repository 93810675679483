/* eslint-disable no-unused-expressions */
import { Radio } from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Loader, Modal } from '../../../components/ui';
import { fetchGroupedContactsAction } from '../../../redux/actions/contacts';

const Wrapper = styled.div`
  .contact-container {
    max-height: 40vh;
    overflow-y: scroll;
    div {
      justify-content: space-between;
      cursor: pointer;
      :hover {
        background: #f2f2f2;
      }
    }
  }
`;

const GroupContactModal = ({
  show,
  setShow,
  setrecpt,
  setReadContact,
  setFormData,
  setPrice,
  type,
}) => {
  const dispatch = useDispatch();
  const fetchGroupedContacts = useCallback(
    (params = { limit: 100 }) => {
      dispatch(fetchGroupedContactsAction(params));
    },
    [dispatch]
  );

  useEffect(() => {
    fetchGroupedContacts();
  }, []);
  const { tableData, fetching } = useSelector((state) => {
    const newtableData = state.contacts.groupedContacts.map((c) => ({
      groupName: c.contactGroup.name,
      category: c.category.serviceTypeName || 'N/A',
      id: c.contactGroupId,
      count: c.count,
      date: new Date(c.createdAt).toLocaleDateString(),
      contact: c.contacts,
      createdAt: c.createdAt,
    }));
    return {
      groupedContacts: state.contacts.groupedContacts,
      fetching: state.contacts.fetchingGroupedContacts,
      tableData: newtableData.length
        ? newtableData.sort((a, b) => new Date(b.date) - new Date(a.date))
        : [],
    };
  });
  return (
    <Modal title={`${type} Contacts`} show={show} onClose={() => setShow(false)}>
      <Wrapper>
        <div className="contact-container">
          {fetching ? (
            <div className="loader">
              <Loader color="black" size="lg" />
            </div>
          ) : tableData.filter((dt) => dt.category === type).length ? (
            tableData
              .filter((dt) => dt.category === type)
              .map((contact, i) => (
                <div
                  key={i}
                  className="d-flex my-1 p-2"
                  onClick={() => {
                    if (type === 'Airtime') {
                      const computedPrice = contact.contact.reduce((acc, d) => {
                        return acc + parseInt(d.amount, 10);
                      }, 0);
                      setPrice(computedPrice);
                      setFormData((form) => ({
                        ...form,
                        airtimeRecpt: '',
                        contactGroupId: contact.id,
                        uploadSuccess: true,
                      }));
                    }
                    if (type === 'SMS') {
                      const newContact = contact.contact.map((dt) => ({
                        Phonenumber: dt.phoneNumber,
                        'Country Code': dt.countryCode,
                      }));
                      setrecpt && setrecpt(newContact);
                      setFormData((form) => ({
                        ...form,
                        smsRecpt: '',
                        airtimeRecpt: '',
                        file: undefined,
                        contactGroupId: contact.id,
                        contactGroupName: contact.groupName,
                        uploadSuccess: true,
                      }));
                    }
                    if (type === 'Data') {
                      const newContact = contact.contact.map((dt) => ({
                        phoneNumber: `${dt.phoneNumber}`,
                        countryCode: dt.countryCode,
                      }));
                      setReadContact && setReadContact(newContact);
                      setFormData((form) => ({
                        ...form,
                        file: undefined,
                        uploadSuccess: true,
                      }));
                    }
                    setShow(false);
                  }}
                >
                  <h5>
                    <Radio />
                    {contact.groupName}
                  </h5>
                  <h5>{contact.count}</h5>
                </div>
              ))
          ) : (
            <p>Contact does not exist</p>
          )}
        </div>
      </Wrapper>
    </Modal>
  );
};

export default GroupContactModal;
