import React from 'react';



const Logo = ({ src, name = '' }) => {
  return (
    <div className="logo-wrapper">
      <img src={src} alt={name} />
    </div>
  );
};

export default Logo;
