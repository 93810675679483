import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { COUNTRIES } from '../../../../utilities/constants';
import { Input, InputSelect as Select } from '../../../../components/ui/inputs';
import { Button } from '../../../../components/ui';
import { APIgetBusinessType } from '../../../../api/backendRoutes';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  typography: {
    lineHeight: '35.2px',
    fontWeight: 600,
    fontSize: '22px',
    color: '#505050',
    marginBottom: '24px',
  },
});

const CompanyInfo = ({ handleNext, companyInfo, handleInputChange, handleCountryChange }) => {
  const [businessTypes, setBusinessTypes] = useState([]);

  const fetchCompanyInfo = async () => {
    const res = await APIgetBusinessType();
    if (res.data.success) {
      setBusinessTypes(res.data.data.map((dt) => ({ label: dt.typeName, value: dt.typeName })));
    }
  };
  useEffect(() => {
    fetchCompanyInfo();
  }, []);
  const classes = useStyles();
  const handleSignUp = (e) => {
    e.preventDefault();
    handleNext();
  };

  return (
    <>
      <Typography className={classes.typography}>Company information</Typography>
      <form classes={classes.root} onSubmit={handleSignUp}>
        <div className="companyNfo-wrapper">
          <div>
            <Select
              placeholder="Business Type"
              label="Business Type"
              hint="*This email should be an admin or a primary contact for your brand"
              name="businessType"
              options={businessTypes}
              onChange={handleInputChange}
              value={companyInfo.businessType}
              validate="required"
            />
          </div>
          <div>
            <Input
              label="Business Name"
              name="businessName"
              hint="*This name would be used to send sms to your customers"
              onChange={handleInputChange}
              defaultValue={companyInfo.businessName}
              validate="required"
            />
          </div>
          <div>
            <Input
              label="Business Reg. No. (RC1234567)"
              name="businessRegNumber"
              hint="*Business Registration Number"
              onChange={handleInputChange}
              defaultValue={companyInfo.businessRegNumber}
              validate="businessRegNumber"
            />
          </div>
          <div>
            <Select
              placeholder="Select Country"
              label="Select Country"
              name="country"
              onChange={handleCountryChange}
              value={companyInfo.country}
              options={COUNTRIES.map(({ label }) => ({
                label,
                value: (label.split('(')[0] || '').trim(),
              }))}
              validate="required"
            />
          </div>
          <div>
            <Button value="Continue" variant="contained" type="submit" />
          </div>
        </div>
      </form>
    </>
  );
};

export default CompanyInfo;
