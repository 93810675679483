import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Controller } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const MaterialSelect = ({
  name,
  label,
  control,
  options,
  defaultValue,
  fullWidth,
  rules,
  ...props
}) => {
  const classes = useStyles();
  return (
    <FormControl variant="outlined" className={classes.formControl} {...props}>
      <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
      <Controller
        as={
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label={label}
          >
            {options.map((item, index) => (
              <MenuItem value={item.value} key={index}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        }
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{ required: rules }}
      />
    </FormControl>
  );
};

export default MaterialSelect;
