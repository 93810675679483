import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import toast from 'react-hot-toast';
import { Row, Col } from 'react-bootstrap';
import { Accordion, Avatar, Button, Icon, Upload } from '../../../components/ui';
import { Input, InputSelect } from '../../../components/ui/inputs';
import AvatarImage from '../../../assets/images/avatar.jpg';
import { fetchUserData, storeUserData } from '../../../storage/sessionStorage';
import { COUNTRIES } from '../../../utilities/constants';
import {
  APIDeativateUserAccount,
  APIEditUserData,
  APIfetchUserProfile,
  APIgetBusinessType,
} from '../../../api/backendRoutes';
import { ROUTES } from '../../../components/router/routes/routes';
import { errorParser } from '../../../utilities/helper';
import ConfirmDeactivateModal from './ConfirmDeactivateModal';

const StyledButton = styled.div`
  display: flex;
  gap: 30px;
  margin: 30px 0px;
`;
const StyledHeader = styled.div`
  display: flex;
  padding: 20px 0px;
  align-items: center;
  .accd {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }
`;
const StyledBody = styled.div`
  background-color: #fff;
`;

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const user = fetchUserData();
  const [formData, setFormData] = useState({
    ...user,
    fetching: false,
  });
  const [showModal, setShowModal] = useState(false);
  const [businessTypes, setBusinessTypes] = useState([]);

  const fetchCompanyInfo = async () => {
    const res = await APIgetBusinessType();
    if (res.data.success) {
      setBusinessTypes(res.data.data.map((dt) => ({ label: dt.typeName, value: dt.typeName })));
    }
  };
  useEffect(() => {
    fetchCompanyInfo();
    setFormData((v) => ({
      ...v,
      ...user,
      companyAddress: user?.merchantInformation[0]?.companyAddress,
      companyAddress_2: user?.merchantInformation[0]?.companyAddress_2,
      zipCode: user?.merchantInformation[0]?.zipCode,
      businessName: user?.merchantInformation[0]?.businessName,
      businessType: user?.merchantInformation[0]?.businessType,
      city: user?.merchantInformation[0]?.city,
      state: user?.merchantInformation[0]?.state,
    }));
  }, []);
  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      country,
      city,
      companyAddress,
      companyAddress_2,
      zipCode,
      state,
      businessType,
      businessName,
    } = formData;
    try {
      setLoading(true);
      const resp = await APIEditUserData({
        firstName,
        lastName,
        country,
        city,
        companyAddress,
        companyAddress_2,
        zipCode,
        state,
        businessType,
        businessName,
      });
      if (resp.data.message === 'successful') {
        const newUserProfile = await APIfetchUserProfile();
        storeUserData({ ...user, ...newUserProfile.data.data });
        toast.success('Profile Updated successfully');
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
        setLoading(false);
      } else {
        setLoading(false);
        throw resp?.data?.error || 'Error updating profile';
      }
    } catch (error) {
      toast.error(errorParser(error));
      setLoading(false);
    }
  };

  const handleInputChange = ({ name, value }) => {
    setFormData((v) => ({ ...v, [name]: value }));
    // console.log(formData);
  };

  const deactivateAccount = async () => {
    try {
      setLoading(true);
      const resp = await APIDeativateUserAccount();
      if (resp.data.message === 'successful') {
        setLoading(false);
        localStorage.clear();
        history.push(ROUTES.SIGN_IN.path);
      } else {
        setLoading(false);
        throw resp?.data?.error || 'Error updating profile';
      }
    } catch (error) {
      toast.error(errorParser(error));
      setLoading(false);
    }
  };

  const showProfile = () => {
    const profileNfo = [
      {
        header: (
          <StyledHeader>
            <Icon icon="adduser" width={18} margin={[0, 20, 0, 0]} />
            <div>Representative Information</div>
            <div className="accd">
              <Accordion.Toggle value={<Icon icon="caretdown" width={30} />} eventKey={1} />
            </div>
          </StyledHeader>
        ),
        body: (
          <StyledBody>
            <form>
              <Row>
                <Col>
                  <Input
                    label="First name"
                    placeholder="Enter First name"
                    name="firstName"
                    defaultValue={formData?.firstName}
                    onChange={handleInputChange}
                    type="text"
                    // disabled
                    validate="required"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    label="Last name"
                    placeholder="Enter Last name"
                    name="lastName"
                    defaultValue={formData?.lastName}
                    onChange={handleInputChange}
                    type="text"
                    // disabled
                    validate="required"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    label="Email Address"
                    placeholder="Enter Email Address"
                    name="email"
                    defaultValue={formData?.email}
                    onChange={handleInputChange}
                    type="email"
                    disabled
                    validate="required"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    label="Phone Number"
                    placeholder="Enter phone number"
                    name="phoneNumber"
                    defaultValue={formData?.phoneNumber}
                    onChange={handleInputChange}
                    type="text"
                    disabled
                    validate="required"
                  />
                </Col>
              </Row>
            </form>
          </StyledBody>
        ),
        eventKey: 1,
      },
      {
        header: (
          <StyledHeader>
            <Icon icon="building" width={18} margin={[0, 20, 0, 0]} />
            <div>Company Information</div>
            <div className="accd">
              <Accordion.Toggle value={<Icon icon="caretdown" width={30} />} eventKey={2} />
            </div>
          </StyledHeader>
        ),
        body: (
          <StyledBody>
            <Row>
              <Col>
                <Input
                  label="Business name"
                  placeholder="Enter Business name"
                  name="businessName"
                  defaultValue={formData?.businessName}
                  onChange={handleInputChange}
                  type="text"
                  validate="required"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <InputSelect
                  label="Sector"
                  placeholder="Enter Sector"
                  name="businessType"
                  type="text"
                  value={formData?.businessType}
                  onChange={handleInputChange}
                  validate="required"
                  options={businessTypes}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  label="Office Address 1"
                  placeholder="Enter Business name"
                  name="companyAddress"
                  defaultValue={formData?.companyAddress}
                  onChange={handleInputChange}
                  type="text"
                  // disabled
                  validate="required"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  label="Office Address 2"
                  placeholder="Enter Business name"
                  name="companyAddress_2"
                  defaultValue={formData?.companyAddress_2}
                  onChange={handleInputChange}
                  type="text"
                  // disabled
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  label="Zip Code"
                  placeholder="Enter Zip Code"
                  name="zipCode"
                  defaultValue={formData?.zipCode}
                  onChange={handleInputChange}
                  type="text"
                  // disabled
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  label="City"
                  placeholder="Enter City"
                  name="city"
                  type="text"
                  validate="required"
                  defaultValue={formData?.city}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  label="State"
                  placeholder="Enter State"
                  name="state"
                  type="text"
                  validate="required"
                  defaultValue={formData?.state}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <InputSelect
                  label="Country"
                  placeholder="Enter Country"
                  name="country"
                  type="text"
                  validate="required"
                  value={formData?.merchantInformation[0]?.country}
                  // defaultValue={formData?.merchantInformation[0]?.country}
                  onChange={handleInputChange}
                  options={[
                    ...COUNTRIES.map(({ label }) => ({
                      label,
                      value: (label.split('(')[0] || '').trim(),
                    })),
                  ]}
                />
              </Col>
            </Row>
          </StyledBody>
        ),
        eventKey: 2,
      },
    ];
    return <Accordion data={profileNfo} />;
  };

  return (
    <div className="profile-wrapper">
      <Row>
        <Col>
          <Col>{showProfile()}</Col>
          <Col>
            <StyledButton>
              <Button value="Update Profile" loading={loading} onClick={handleProfileUpdate} />
              <Button
                value="De-activate Profile"
                onClick={() => setShowModal(true)}
                group="outline_danger"
              />
            </StyledButton>
          </Col>
        </Col>
        <Col>
          <div className="avatar">
            <div>
              <Avatar src={user?.image_url || AvatarImage} width="200" height="200" />
              <div className="avatar-options">
                <div className="delete-option">
                  <Icon icon="trash" /> Delete
                </div>
                <Upload icon="upload" text="Upload" iconWidth={16} accept=".jpeg,.png" />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <ConfirmDeactivateModal
        show={showModal}
        closeModal={() => setShowModal(false)}
        action={deactivateAccount}
      />
    </div>
  );
};

export default Profile;
