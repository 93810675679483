import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';
import { Button } from '../../../../components/ui';
import { Input, PasswordInput, PhoneInput, CheckBox } from '../../../../components/ui/inputs';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  typography: {
    lineHeight: '35.2px',
    fontWeight: 600,
    fontSize: '22px',
    color: '#505050',
    marginBottom: '24px',
  },
});

const RepresentativeInfo = ({
  handleBack,
  handleInputChange,
  repInfo,
  handleSignUp,
  phoneCountry,
}) => {
  const classes = useStyles();

  const handleGoBack = () => {
    handleBack();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleSignUp();
  };
  return (
    <>
      <h4 className={classes.typography}>Representative information</h4>
      <form classes={classes.root} onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Input
              label="First Name"
              name="firstName"
              onChange={handleInputChange}
              defaultValue={repInfo.firstName}
              validate="required"
              hint="*This is the name customers would see in their phones as your brand"
            />
          </Col>
          <Col md={6}>
            <Input
              label="Last Name"
              name="lastName"
              onChange={handleInputChange}
              defaultValue={repInfo.lastName}
              validate="required"
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Input
              label="Email"
              name="email"
              type="email"
              onChange={handleInputChange}
              defaultValue={repInfo.email}
              validate="required|email"
            />
          </Col>
          <Col md={6}>
            <PhoneInput
              label="Phone Number"
              placeholder="Phone Number e.g 090xxxxxxxx"
              name="phoneNumber"
              validate="required|phone_number"
              defaultValue={repInfo.phoneNumber}
              defaultCountry={phoneCountry}
              hint="*This phone number should be an admin or a primary contact for your brand"
              onChange={handleInputChange}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <PasswordInput
              label="Password"
              name="password"
              onChange={handleInputChange}
              defaultValue={repInfo.password}
              validate="required"
            />
          </Col>
          <Col md={6}>
            <PasswordInput
              label="Confirm Password"
              name="confirmPassword"
              onChange={handleInputChange}
              defaultValue={repInfo.confirmPassword}
              validate="required"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <CheckBox
              name="terms"
              onChange={handleInputChange}
              value={repInfo.terms}
              label="I agree with the Terms of Service and Privacy Policy"
              validate="required"
            />
          </Col>
        </Row>
        <Row>
          <Col
            style={{ display: 'flex', justifyContent: 'space-around', gap: '20px', paddingTop: 20 }}
          >
            <Button
              type="button"
              size="lg"
              group="secondary"
              margin={isMobile ? 0 : [0, 10]}
              width={isMobile ? '100%' : 200}
              style={isMobile ? { flex: 1, marginRight: 10 } : {}}
              variant="outlined"
              onClick={handleGoBack}
            >
              Back
            </Button>
            <Button
              type="submit"
              size="lg"
              group="primary"
              margin={isMobile ? 0 : [0, 10]}
              width={isMobile ? '100%' : 200}
              variant="contained"
              style={isMobile ? { flex: 1, marginLeft: 10 } : {}}
              // disabled={Object.keys(formData.error || {}).length > 0}
              loading={repInfo.fetching}
            >
              Continue
            </Button>
          </Col>
        </Row>
        {repInfo.error && (
          <div
            style={{
              margin: '10px 0px',
              color: 'red',
              fontSize: '14px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {repInfo.errorMessage}
          </div>
        )}
      </form>
    </>
  );
};
export default RepresentativeInfo;
