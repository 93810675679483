import React, { useState } from 'react';
import styled from 'styled-components';
import { NavDropdown } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

const StyledOptions = styled.div`
  height: inherit;

  .dropdown-toggle::after {
    display: none !important;
    content: none;
  }

  ${({ isMobile: mobile }) =>
    mobile ? `.dropdown-toggle::after { display: none !important; }` : ''}
`;

const StyledOption = styled.span`
  display: flex;
  align-items: center;

  span {
    font-size: 14px;
  }
`;

const DropMenu = ({ name, src, margin, options = [], meta, onChange }) => {
  const [data, setData] = useState(name);

  const handleOptionChange = (value) => {
    setData(value);
    if (onChange) {
      onChange({ name, value });
    }
  };

  return (
    <StyledOptions isMobile={isMobile}>
      <NavDropdown title={<StyledOption>{data.name}</StyledOption>}>
        {options.map(({ label, value }, key) => (
          <NavDropdown.Item
            onClick={() => handleOptionChange(label, value)}
            key={key}
            style={{ display: 'flex', margin: '5px 0px', gap: '5px', alignItems: 'center' }}
          >
            {label}
          </NavDropdown.Item>
        ))}
      </NavDropdown>
    </StyledOptions>
  );
};

export default DropMenu;
