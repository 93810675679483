import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  serviceReportDetail: [],
  fetchingServiceReportDetail: false,
  serviceReportDetailMeta: {},
};

export const serviceReportDetailSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    fetchServiceReportDetailSuccessReducer: (state, { payload }) => {
      state.serviceReportDetail = payload.data;
      state.fetchingServiceReportDetail = false;
      state.serviceReportDetailMeta = {
        limit: payload.limit,
        page: payload.page,
        total: payload.total,
      };
    },
    fetchingServiceReportDetailReducer: (state) => {
      state.serviceReportDetail = [];
      state.fetchingServiceReportDetail = true;
      state.serviceReportDetailMeta = {};
    },
    fetchingServiceReportDetailErrorReducer: (state) => {
      state.serviceReportDetail = [];
      state.fetchingServiceReportDetail = true;
      state.serviceReportDetailMeta = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  fetchServiceReportDetailSuccessReducer,
  fetchingServiceReportDetailReducer,
  fetchingServiceReportDetailErrorReducer,
} = serviceReportDetailSlice.actions;

export default serviceReportDetailSlice.reducer;
