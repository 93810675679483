import styled, { css } from 'styled-components';
import { EyeOutline, EyeOff2Outline } from 'styled-icons/evaicons-outline';
import { AngleDown, AngleUp, Bars } from 'styled-icons/fa-solid';
import { Close } from 'styled-icons/evaicons-solid';

import arrowcircle from './arrow-circle.svg';
import badgecheck from './badge-check.svg';
import bar from './bar.svg';
import calendar from './calendar.svg';
import calendartwo from './calender-two.svg';
import cash from './cash.svg';
import doc from './doc.svg';
import megaphone from './megaphone.svg';
import morealt from './more-alt.svg';
import negative from './negative.svg';
import phone from './phone.svg';
import cellphone from './cellphone.svg';
import search from './search.svg';
import trash from './trash.svg';
import pencil from './pencil.svg';
import cog from './cog.svg';
import cogwhite from './cog-white.svg';
import bell from './bell.svg';
import openlink from './window.svg';
import whatsapp from './whatsapp.svg';
import upload from './upload.svg';
import album from './album.svg';
import users from './users.svg';
import wallet from './wallet.svg';
import electricity from './electricity.svg';
import trashopen from './trash-open.svg';
import cabletv from './cabletv.svg';
import tvlight from './tv-light.svg';
import close from './close.svg';
import fileupload from './file-upload.svg';
import burger from './burger.svg';
import goback from './go-back.svg';
import more from './more.svg';
import dealer from './dealer.svg';
import insurance from './insurance.svg';
import mobile from './mobile.svg';
import bank from './bank.svg';
import transfer from './transfer.svg';
import copy from './copy.svg';
import caretleft from './caret-left.svg';
import success from './success.svg';
import time from './time.svg';
import wallettwo from './wallet-two.svg';
import receipt from './receipt.svg';
import logout from './logout.svg';
import building from './building.svg';
import caretdown from './caret-down.svg';
import caretdownsm from './caret-down-sm.svg';
import campaignplaceholder from './campaign-placeholder.svg';
import adduser from './adduser.svg';
import avatar from './avatar.svg';
import eye from './eye.svg';
import eyeclose from './eye-close.svg';
import phoneBook from './phoneBook.svg';
import info from './info.svg';
import flagng from './flagng.svg';
import flagke from './flagke.svg';
import flaggh from './flaggh.svg';
import menucaret from './menu-caret.svg';
import cancel from './cancel.svg';
import DSTV from './dstv.svg';
import GOTV from './gotv.svg';
import STARTIMES from './startime.svg';
import senderId from './senderId.svg';
import PHCNEKO from './phcnEko.svg';
import PHCNKAN from './phcnKano.svg';
import PHCNENU from './phcnEnu.svg';
import PHCNJOS from './phcnJos.svg';
import PHCNPHE from './phcnPhe.svg';
import PHCNABJ from './phcnAbj.svg';
import PHCNIBD from './phcnIbd.svg';
import PHCNKAD from './phcnKad.svg';
import dropdown from './dropdown.svg';
import positive from './positive.svg';
import disabledWallet from './disabledWallet.svg';
import trendRise from './trendRise.svg';
import trendFall from './trendFall.svg';
import data from './data.svg';
import sms from './sms.svg';
import mtnLogo from './mtn-logo.svg';
import loginIcon from './loginIcon.svg';
import internet from './internet.svg';
import options from './options.svg';
import graph from './graph.svg';
import gramaphone from './gramaphone.svg';
import pending from './pending.svg';
import thumbsUp from './thumbs-up.svg';
import lock from './lock.svg';
import visa from './visa.svg';
import mastercard from './mastercard.svg';
import amex from './amex.svg';
import discover from './discover.svg';

const sharedStyle = css`
  color: var(--primary-color);
  width: 3rem;
  height: 3rem;
  position: absolute;
  right: 2rem;
`;

const sharedAngleStyle = css`
  color: #fff;
  width: 2rem;
  height: 2rem;
`;
const ClosedEye = styled(EyeOff2Outline)`
  ${sharedStyle};
  top: ${(props) => props.top || '4rem'};
`;
const Eye = styled(EyeOutline)`
  ${sharedStyle};
  top: ${(props) => props.top || '4rem'};
`;

const StyledAngeleDown = styled(AngleDown)`
  ${sharedAngleStyle};
`;
const StyledAngeleUp = styled(AngleUp)`
  ${sharedAngleStyle};
`;

const StyledBar = styled(Bars)`
  color: #000;
  width: 3rem;
  height: 3rem;
`;

const StyledClose = styled(Close)`
  color: ${({ color }) => color || '#000'};
  width: 3rem;
  height: 3rem;
`;

export {
  arrowcircle,
  badgecheck,
  bar,
  calendar,
  calendartwo,
  cash,
  doc,
  megaphone,
  morealt,
  negative,
  phone,
  cellphone,
  search,
  trash,
  pencil,
  cog,
  cogwhite,
  bell,
  openlink,
  whatsapp,
  upload,
  album,
  users,
  adduser,
  wallet,
  caretdownsm,
  electricity,
  trashopen,
  cabletv,
  tvlight,
  close,
  fileupload,
  burger,
  goback,
  more,
  dealer,
  insurance,
  mobile,
  bank,
  transfer,
  copy,
  caretleft,
  ClosedEye,
  Eye,
  StyledAngeleUp,
  StyledAngeleDown,
  StyledBar,
  StyledClose,
  success,
  time,
  wallettwo,
  receipt,
  campaignplaceholder,
  logout,
  building,
  caretdown,
  avatar,
  eye,
  eyeclose,
  phoneBook,
  info,
  flagng,
  flagke,
  flaggh,
  menucaret,
  cancel,
  DSTV,
  GOTV,
  senderId,
  PHCNEKO,
  PHCNKAN,
  PHCNIBD,
  PHCNPHE,
  PHCNJOS,
  PHCNABJ,
  PHCNENU,
  PHCNKAD,
  positive,
  disabledWallet,
  trendRise,
  trendFall,
  data,
  sms,
  mtnLogo,
  loginIcon,
  STARTIMES,
  internet,
  dropdown,
  options,
  graph,
  gramaphone,
  pending,
  thumbsUp,
  lock,
  discover,
  mastercard,
  amex,
  visa
};
