import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import { Button, Icon, Modal } from '../../../components/ui';
import FundWallet from './FundWallet';
import { OTPInput } from '../../../components/ui/inputs';
import { APISendTransactionOTP, APIverifyTransactionOTP } from '../../../api/backendRoutes';

const StyledPayment = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  .wallet {
    font-size: 18px;
    font-weight: lighter;
    margin: 10px 0px;
    text-align: center;
  }
`;
const StyledWallet = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  text-align: center;
  .wallet {
    font-size: 18px;
    font-weight: lighter;
    margin: 10px 0px;
  }
  .otpContainer{
    min-width: 80%;
  }
`;
const StyledOption = styled.div`
  color: ${({ disabled }) => (disabled ? '#828282' : 'var(--primary-color)')};
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  cursor: pointer;
  .title {
    flex-grow: 1;
  }
`;

const CheckOut = ({ execute, show, onClose, price, disableWallet }) => {
  const [showFundWalletModal, setShowFundWalletModal] = useState(false);
  const [OTP, setOTP] = useState('');
  const [modalCtr, setModalCtr] = useState({
    title: 'Select Payment Options',
    page: 1,
    goBack: false,
  });
  const handlePaymentOption = (type) => {
    if (type === 'wallet') {
      setModalCtr(() => ({ title: 'Pay with Wallet', page: 2, goBack: true }));
    }
  };
  const handleVerification = async () => {
    try {
      const resp = await APIverifyTransactionOTP({
        code: OTP,
      });

      if (resp.data.success) {
        execute();
      } else {
        throw resp?.data?.error;
      }
    } catch (error) {
      toast.error(error.response.data.data.error);
    }
  };

  useEffect(async () => {
    if (modalCtr.page === 2) {
      await APISendTransactionOTP();
    }
  }, [modalCtr.page]);

  const PaymentOption = () => {
    return (
      <StyledPayment>
        <StyledOption onClick={() => handlePaymentOption('wallet')}>
          <Icon icon="wallettwo" width={20} />
          <div className="title">Pay With Wallet</div>
          <Icon icon="arrowcircle" width={20} color="#17568B" />
        </StyledOption>
        {/* <StyledOption>
          <Icon icon="bank" width={20} color="#17568B" />
          <div className="title">Pay With Bank</div>
          <Icon icon="arrowcircle" width={20} color="#17568B" />
        </StyledOption>
        <StyledOption>
          <Icon icon="time" width={20} />
          <div className="title">Pay On Credit(Post Paid)</div>
          <Icon icon="arrowcircle" width={20} color="#17568B" />
        </StyledOption> */}
      </StyledPayment>
    );
  };
  const PayWithWallet = () => {
    return (
      <StyledWallet>
        <div className="wallet">
          This Service would cost
          <NumberFormat
            displayType="text"
            thousandSeparator
            prefix="₦"
            fixedDecimalScale
            decimalScale={2}
            value={price}
            style={{ margin: '0px 5px', fontWeight: 'bold', color: '#DF374B' }}
          />
          and would be deducted from your wallet. <br />
          An OTP has been sent to your mail to complete this transaction.
        </div>
        <div className="otpContainer mb-5">
          <OTPInput
            required
            autoFocus
            // isNumberInput
            length={6}
            inputClassName="otpInput"
            onChangeOTP={(otp) => setOTP(otp)}
          />
        </div>
        <div
          style={{
            display: 'flex',
            gap: '40px',
          }}
        >
          <Button
            value="Fund Wallet"
            group="primary"
            size="lg"
            onClick={() => setShowFundWalletModal(true)}
          />
          <Button
            value="Proceed"
            group="outline"
            size="lg"
            disabled={disableWallet || !OTP}
            onClick={() => !disableWallet && handleVerification()}
          />
        </div>
        {showFundWalletModal && (
          <FundWallet show={showFundWalletModal} onClose={() => setShowFundWalletModal(false)} />
        )}
      </StyledWallet>
    );
  };
  const handleGoBack = () => {
    setModalCtr(() => ({
      title: 'Select Payment Options',
      page: 1,
      goBack: false,
    }));
  };
  //   const handleCloseCheckout = ()=>{
  //       setShowModal(false)
  //   }
  return (
    <Modal
      show={show}
      onClose={onClose}
      title={modalCtr.title}
      goBack={modalCtr.goBack}
      goBackMethod={handleGoBack}
    >
      {modalCtr.page === 1 && PaymentOption()}
      {modalCtr.page === 2 && PayWithWallet()}
    </Modal>
  );
};

export default CheckOut;
