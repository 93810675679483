import React, { useContext, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Moment from 'react-moment';
import { Table, Options } from '../../components/ui';
import { fetchGroupedContactsAction } from '../../redux/actions/contacts';
import { AppContext } from '../../utilities/context';

const StyledOptions = styled.div`
  display: flex;
  gap: 10px;
`;

const GroupedContacts = ({ setShowEdit, setCurrentContactGroup, refresh }) => {
  const { setGoBackMethod } = useContext(AppContext);

  const dispatch = useDispatch();

  const fetchGroupedContacts = useCallback(
    (params = { limit: 25 }) => {
      dispatch(fetchGroupedContactsAction(params));
    },
    [dispatch]
  );

  const { tableData, meta, fetching } = useSelector((state) => {
    const newtableData = state.contacts.groupedContacts.map((c) => ({
      groupName: c.contactGroup.name,
      category: c.category.serviceTypeName || 'N/A',
      id: c.contactGroupId,
      count: c.count,
      date: new Date(c.createdAt).toLocaleDateString(),
      contact: c.contacts,
      createdAt: c.createdAt,
    }));
    return {
      groupedContacts: state.contacts.groupedContacts,
      meta: state.contacts.groupedContactsMeta,
      fetching: state.contacts.fetchingGroupedContacts,
      tableData: newtableData.length
        ? newtableData.sort((a, b) => new Date(b.date) - new Date(a.date))
        : [],
    };
  });

  const handleEdit = (id) => {
    const contact = tableData.filter((t) => t.id === id);
    setCurrentContactGroup(contact);
    setGoBackMethod(() => setShowEdit(false));
    setShowEdit(true);
  };

  useEffect(() => {
    fetchGroupedContacts();
  }, []);

  // useEffect(() => {
  //   if (refresh) {
  //     fetchGroupedContacts();
  //   }
  // }, [refresh]);

  return (
    <div className="overflow--initial">
      <Table
        header={[
          {
            key: 'groupName',
            label: 'Name of Group',
            render: (groupName) => <div className="td-width-100">{groupName}</div>,
          },
          { key: 'category', label: 'Service' },
          {
            key: 'count',
            label: 'Total Numbers',
            render: (count) => <div className="td-width-100">{count}</div>,
          },
          {
            key: 'createdAt',
            label: 'Date Created',
            render: (date) => (
              <div className="td-width-100">
                <Moment format="D MMM, YYYY  - hh:mma" date={date} />
              </div>
            ),
          },
          {
            key: 'id',
            label: '',
            render: (id) => (
              <StyledOptions>
                <Options
                  meta={id}
                  options={[{ icon: 'pencil', label: 'Edit', action: handleEdit }]}
                />
              </StyledOptions>
            ),
          },
        ]}
        paginate
        meta={meta}
        data={tableData}
        filterAction={fetchGroupedContacts}
        filter={{
          Date: [
            { value: 'Today', label: 'Today' },
            { value: 'This Week', label: 'This Week' },
            { value: 'This Month', label: 'This Month' },
            { value: 'Last 30 Days', label: 'Last 30 Days' },
            { value: 'This Year', label: 'This Year' },
            { value: 'Period', label: 'Specific Period' },
          ],
          service: [
            { value: 'Airtime', label: 'Airtime' },
            { value: 'SMS', label: 'SMS' },
            { value: 'Data', label: 'Data' },
          ],
          search: 'search',
        }}
        handlePagination={fetchGroupedContacts}
        showFilter
        loading={fetching}
      />
    </div>
  );
};

export default GroupedContacts;
