import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import { pink } from '@material-ui/core/colors';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#17568b',
    },
    secondary: pink,
  },
  typography: {
    fontFamily: ['IBM Plex Sans', 'sans-serif'].join(','),
    h1: {
      fontWeight: 700,
      fontSize: '42px',
      fontStyle: 'normal',
      lineHeight: '55px',
      letterSpacing: '0px',
      textAlign: 'left',
    },
  },
});

theme.props = {
  MuiButton: {
    disableElevation: true,
  },
  MuiInput: {
    disableUnderline: true,
  },
  MuiTab: {
    textColor: 'primary',
  },
};

theme.overrides = {
  MuiButton: {
    root: {
      textTransform: 'none',
      borderRadius: '8px',
      height: `46px`,
      fontSize: '16px',
    },
    containedPrimary: {
      color: '#fff',
      fontWeight: 600,
      '&:hover': {
        backgroundColor: `rgba(80, 159, 239, 0.25)`,
        color: `#509FEF`,
        border: `1px solid #509FEF`,
      },
    },
    containedSecondary: {
      fontWeight: 700,
    },
  },
  MuiOutlinedInput: {
    root: {
      // border: `1px solid ${grey[500]}`,
      padding: `10px 0px 9px 0px`,
      height: `50px`,
      fontSize: `14px`,
      borderRadius: '8px',
      '&$focused': {
        // border: `1px solid ${theme.palette.primary.main}`,
      },
    },
  },
  MuiInputLabel: {
    root: {
      fontSize: '1.2rem',
    },
  },
  MuiTabs: {
    root: {
      color: `${theme.palette.primary.main}`,
    },
    indicator: {
      display: 'none',
    },
  },
  MuiAppBar: {
    root: {
      boxShadow: 'none',
    },
  },
  MuiTab: {
    root: {
      minWidth: '110px',
      width: '110px',
      height: '42px',
      textTransform: 'none',
      lineHeight: '20px',
      color: `${theme.palette.primary.main}`,
      borderRadius: '30px',
      fontWeight: 500,
      fontSize: '16px',
      opacity: 1,
      '&$selected': {
        color: `#fff`,
        backgroundColor: `${theme.palette.primary.main}`,
      },
      [theme.breakpoints.up('md')]: {
        minWidth: '110px',
      },
    },
  },
};

export default theme;
