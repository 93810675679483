import Toast from 'react-hot-toast';
import { APIdataOperators, APIdataTopUp } from '../../api/backendRoutes';
import { errorParser } from '../../utilities/helper';
import {
  fetchDataPlansReducer,
  fetchingDataReducer,
  errorReducer,
  buyDataReducer,
  buyingDataReducer,
} from '../slices/data';

export const dataPlanAction =
  (payload = {}) =>
  async (dispatch) => {
    dispatch(fetchingDataReducer());
    try {
      const resp = await APIdataOperators(payload);
      if (resp.data.message === 'ok') {
        dispatch(fetchDataPlansReducer(resp.data.data));
      } else {
        dispatch(errorReducer(resp.data));
        throw resp?.data?.error;
      }
    } catch (error) {
      dispatch(errorReducer());
      // Toast.error(error?.response?.data?.error?.message || 'Server error');
    }
  };

export const buyDataAction =
  (payload = {}) =>
  async (dispatch) => {
    dispatch(buyingDataReducer());
    try {
      const resp = await APIdataTopUp(payload);
      if (resp.data.message === 'ok') {
        dispatch(buyDataReducer(resp.data.data));
      } else {
        dispatch(errorReducer());
        throw resp?.data?.error;
      }
    } catch (error) {
      dispatch(errorReducer());
      Toast.error(errorParser(error) || 'Server error');
    }
  };
