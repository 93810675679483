import React, { useContext, useState, useCallback, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Navigation } from 'react-minimal-side-navigation';
// import { NavDropdown } from 'react-bootstrap';
import styled from 'styled-components';
import { Icon, Logo } from '../ui';
import { SIDE_MENU } from '../../utilities/constants';
import { AppContext } from '../../utilities/context/AppContext';
import { fetchWallet } from '../../redux/actions/wallet';
import { fetchServices } from '../../redux/actions/services';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import { useUser } from '../../utilities/hooks/useUser';
import { ROUTES } from '../router/routes/routes';

const StyledLogo = styled.div`
  padding: 1rem 2rem;
  div {
    border-bottom: 2px solid var(--secondary-color);
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`;

const StyledWalletBalance = styled.div`
  padding: 0.5rem 2rem 1rem;
  > div {
    min-height: 50px;
    border-radius: 4px;
    background: var(--secondary-color);
    padding: 10px;
    .dropdown a {
      display: flex;
      align-items: center;
      color: #ffffff;
      font-size: 12px;
      padding-left: 15px;
      &.dropdown-toggle {
        padding: 0;
        background: inherit;
        border: none;
      }
      &.dropdown-toggle::after {
        margin-left: 10px;
        display: none;
      }
    }
    .dropdown-menu {
      background: #ffffff;
      a {
        color: #333333;
        padding: 10px;
        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }
  img {
    cursor: pointer !important;
  }
  .dropdown-menu {
    top: 25px !important;
    left: -10px !important;
    width: 202px !important;
    border-radius: 0;
  }
`;
const StyledFooter = styled.div`
  align-items: center;
  border-top: 1px solid var(--secondary-color);
  padding: 30px 20px;
  box-shadow: 4px 0px 30px rgba(0, 0, 0, 0.1);
  .logout {
    display: flex;
    gap: 10px;
    cursor: pointer;
    width: fit-content;
  }
`;

const SideMenu = () => {
  const history = useHistory();
  const location = useLocation();
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [hideBal, setHideBal] = useState(true);
  const [sideMenu, setSideMenu] = useState(SIDE_MENU);
  const { showSideMenu, toggleSideMenu, partnerInfo } = useContext(AppContext);
  const { user } = useUser();

  const dispatch = useDispatch();

  const fetchUserWallet = useCallback(() => {
    dispatch(fetchWallet());
  }, [dispatch]);

  const signOut = () => {
    localStorage.clear();
    history.push(ROUTES.SIGN_IN.path);
  };
  const fetchAllServices = useCallback(
    (id) => {
      dispatch(
        fetchServices({
          companyId: id,
          isActive: true,
        })
      );
    },
    [dispatch]
  );

  const { balance } = useSelector((state) => {
    return {
      ...state.wallet.walletNfo,
    };
  });
  const { services } = useSelector((state) => {
    return {
      services: state.services.services,
      fetching: state.services.fetchServices,
      error: state.services.error,
    };
  });

  useEffect(() => {
    if (Object.keys(user).length) {
      fetchUserWallet();
      fetchAllServices(user.companyId);
    }
  }, [user]);

  useEffect(() => {
    if (services.length > 0) {
      const subNav = services
        .map((serv) => ({
          title: serv.serviceName,
          itemId: `/service/${serv.serviceName.toLowerCase()}`,
        }))
        .filter(
          (dt) =>
            dt.title === 'Airtime' ||
            dt.title === 'SMS' ||
            dt.title === 'Data' ||
            dt.title === 'PayBills'
        )
        .sort((a, b) => (a.title > b.title ? 1 : -1));
      const newMenu = SIDE_MENU.map((menu) => {
        if (menu.title === 'Services') {
          return {
            ...menu,
            subNav,
          };
        }
        return menu;
      });
      setSideMenu(newMenu);
    }
  }, [services]);

  return (
    <div
      className="sidemenu-wrapper scrollbar"
      id={showSideMenu ? 'showSideMenu' : 'hideSideMenu'}
      show={showSideMenu}
    >
      <div className="closeSideMenu" onClick={() => toggleSideMenu()}>
        <Icon icon="goback" margin={[10, 25, 0, 0]} />
      </div>
      <StyledLogo>
        <Logo src={partnerInfo.logoUrl || "/logo.svg"} name="Blusalt" />
      </StyledLogo>

      <StyledWalletBalance>
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              {/* <NavDropdown title="Wallet Balance" id="basic-nav-dropdown">
                <NavDropdown.Item
                  onClick={() => {
                    history.push(ROUTES.WALLET.path);
                  }}
                >
                  Fund Wallet
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => {
                    history.push(ROUTES.TRANSACTION_REPORT.path);
                  }}
                >
                  Transaction History
                </NavDropdown.Item>
                <NavDropdown.Item>Notifications</NavDropdown.Item>
              </NavDropdown> */}
              <div className="dropdown">
                <a
                  className="btn btn-secondary dropdown-toggle"
                  href="#"
                  role="button"
                  onClick={() => setToggleDropdown(!toggleDropdown)}
                >
                  Wallet Balance
                  <span>
                    <Icon icon="dropdown" />
                  </span>
                </a>

                <div
                  className={`dropdown-menu ${toggleDropdown ? 'show' : ''}`}
                  aria-labelledby="dropdownMenuLink"
                >
                  <Link className="dropdown-item" to={ROUTES.WALLET.path}>
                    Fund Wallet
                  </Link>
                  <Link className="dropdown-item" to={ROUTES.TRANSACTION_REPORT.path}>
                    Transaction History
                  </Link>
                  {/* <Link className="dropdown-item" to={ROUTES.WALLET.path}>
                    Notifications
                  </Link> */}
                </div>
              </div>
            </span>
            <div>
              <Icon
                icon={!hideBal ? 'eye' : 'eyeclose'}
                onClick={() => setHideBal(() => !hideBal)}
              />
            </div>
          </div>
          <div
            style={{
              fontSize: 20,
              fontWeight: 600,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {!hideBal && (
              <NumberFormat
                value={balance}
                displayType="text"
                thousandSeparator
                // prefix={bank?.currency}
                prefix="₦"
                fixedDecimalScale
                decimalScale={2}
              />
            )}
            {hideBal && <span>******</span>}
          </div>
        </div>
      </StyledWalletBalance>
      <Navigation
        // you can use your own router's api to get pathname
        activeItemId={location.pathname}
        onSelect={({ itemId }) => {
          history.push(itemId);
          if (itemId[0] !== '#') {
            toggleSideMenu();
          }
        }}
        items={sideMenu}
      />
      <StyledFooter>
        <div className="logout" onClick={() => signOut()}>
          <Icon icon="logout" />
          <div>Log Out</div>
        </div>
      </StyledFooter>
    </div>
  );
};

export default SideMenu;
