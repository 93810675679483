import React from 'react';
import { Modal as BModal } from 'react-bootstrap';
import Icon from './Icon';

const Modal = ({
  show,
  onClose,
  title,
  children,
  showHeader = true,
  footer,
  className,
  centered = true,
  nopadding,
  size = 'md',
  goBack,
  goBackMethod,
}) => {
  return (
    <>
      <BModal
        show={show}
        centered={centered}
        backdrop
        onHide={onClose}
        className={nopadding ? 'nopadding' : ''}
        size={size}
      >
        {showHeader && (
          <BModal.Header>
            {title && (
              <BModal.Title
                style={{ fontWeight: 300, display: 'flex', gap: '10px', alignItems: 'center' }}
              >
                {goBack && <Icon icon="goback" width={20} onClick={() => goBackMethod()} />}
                {title}
              </BModal.Title>
            )}
            <Icon
              icon="close"
              style={{ cursor: 'pointer', marginLeft: 'auto' }}
              onClick={() => onClose()}
            />
          </BModal.Header>
        )}
        <BModal.Body>{children}</BModal.Body>
        {footer && <BModal.Footer />}
      </BModal>
    </>
  );
};

export default Modal;
