import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Toast from 'react-hot-toast';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import Moment from 'react-moment';
import { Button, Table } from '../../../components/ui';
import {
  APIfetchCampaign,
  APIfetchContact,
  APIUpdateRecurringBulk,
} from '../../../api/backendRoutes';
import { DateInput, TimeInput } from '../../../components/ui/inputs';
import { errorParser } from '../../../utilities/helper';

const EditCampaign = ({ id }) => {
  const history = useHistory();
  const [campaignDetail, setCampaignDetail] = useState({});
  const [error, setError] = useState([false, '']);
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    startDate: null,
    startTime: null,
    recurring: false,
    endDate: null,
    endTime: null,
    isLoading: false,
    type: [
      { value: 'Daily', active: false },
      { value: 'Weekly', active: false },
      { value: 'Monthly', active: false },
      { value: 'Annually', active: false },
    ],
  });

  const fetchCampaignDetail = useCallback(async () => {
    try {
      const resp = await APIfetchCampaign(id);
      if (resp.data.message === 'ok') {
        const { data } = resp.data;
        const campaignBody =
          typeof data[0]?.campaignBody === 'string'
            ? JSON.parse(data[0]?.campaignBody)
            : data[0]?.campaignBody;
        setCampaignDetail((s) => ({
          ...s,
          senderId: campaignBody.senderId,
          message: campaignBody.message,
          delivered: data[0].totalSuccessfulRecipient,
          recipients: data[0].totalBeneficiaries,
          campaignName: data[0].campaignName,
          campaignType: data[0].campaignType,
          campaignStatus: data[0].campaignStatus,
          startDate: data[0].startDate,
          endDate: data[0].endDate,
          campaignFrequency: data[0].campaignFrequency,
          campaignBody: data[0].campaignBody,
        }));
      } else {
        throw resp?.data?.error;
      }
    } catch (err) {
      // setError(err);
    }
  }, []);
  const handleDate = ({ name, value }) => {
    setError([false, '']);
    setFormData((v) => ({ ...v, [name]: value }));
  };
  const handleType = (value, idx) => {
    const { type } = formData;
    setFormData((v) => ({ ...v, frequency: value }));
    const newType = type.map((t) => {
      if (t.value === value) {
        return {
          ...t,
          active: !t.active,
        };
      }
      return {
        ...t,
        active: false,
      };
    });
    setFormData((v) => ({ ...v, type: newType }));
    setShowForm(true);
  };
  const handleSchedule = async (param) => {
    setFormData((v) => ({ ...v, isLoading: true }));
    try {
      const resp = await APIUpdateRecurringBulk(id, param);
      if (resp.data.message === 'ok') {
        Toast.success('Bulk Service updated successfully');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        throw resp?.data?.error;
      }
      setFormData((v) => ({ ...v, isLoading: false }));
      history.push('/campaign/all');
    } catch (err) {
      setFormData((v) => ({ ...v, isLoading: false }));
      Toast.error(errorParser(err) || 'Server error');
    }
  };
  const handleValidation = (param) => {
    let fault = [false, ''];
    if (moment(campaignDetail.startDate) < moment() || moment(param.endDate) < moment()) {
      fault = [true, 'You cant select past date'];
    }
    switch (param.campaignFrequency) {
      case undefined:
        if (moment(campaignDetail.startDate) < moment())
          fault = [true, 'You cant select past time'];
        break;
      case 'Daily':
        if (moment(param.endDate) < moment(campaignDetail.startDate).add(1, 'day'))
          fault = [true, 'Please pick a date after your start date'];
        break;
      case 'Weekly':
        if (moment(param.endDate) < moment(campaignDetail.startDate).add(7, 'day'))
          fault = [true, 'Please pick a date at least a week from start date'];
        break;
      case 'Monthly':
        if (moment(param.endDate) < moment(campaignDetail.startDate).add(30, 'day'))
          fault = [true, 'Please pick a date at least a month from start date'];
        break;
      case 'Annually':
        if (moment(param.endDate) < moment(campaignDetail.startDate).add(365, 'day'))
          fault = [true, 'Please pick a date at least a year from start date'];
        break;
      default:
        return;
    }
    setError(fault);
    if (!fault[0]) {
      handleSchedule({
        ...param,
        campaignBody:
          typeof campaignDetail.campaignBody === 'string'
            ? JSON.parse(campaignDetail.campaignBody)
            : campaignDetail.campaignBody,
      });
    }
  };

  const scheduleCampaign = () => {
    let scheduleParams = {};
    const { endDate, endTime, frequency } = formData;
    if (endDate === null || endTime === null) {
      setError([true, 'Please select a end time and date']);
    } else if (endDate !== null && endTime !== null) {
      const ed = endDate.toISOString(true);
      const et = moment(endTime, 'hh:mm').toISOString(true);
      const newendDate = [ed?.split('T')[0], et?.split('T')[1]].join('T');
      scheduleParams = {
        endDate: newendDate,
        campaignFrequency: frequency,
      };
      handleValidation(scheduleParams);
    }
  };

  const fetchContacts = useCallback(async () => {
    try {
      const resp = await APIfetchContact(id);
      if (resp.data.message === 'ok') {
        const computeContact = resp.data?.data?.map((c) => ({
          phoneNumber: `0${c.phoneNumber}`,
          date: c.createdAt,
          cost: c.amount,
          status: 'Delivered',
        }));
        setCampaignDetail((s) => ({ ...s, contact: computeContact }));
      } else {
        throw resp?.data?.error;
      }
    } catch (err) {
      setError(err);
    }
  }, []);
  useEffect(async () => {
    fetchCampaignDetail();
    fetchContacts();
  }, []);

  return (
    <div className="campaign__edit">
      <div className="header">
        <h5>{campaignDetail.campaignName}</h5>
      </div>
      <div className="sub-header">
        <div>
          <span className="left">Frequency:</span>
          <span>{campaignDetail.campaignFrequency}</span>
        </div>
        <div>
          <span className="left">Status:</span>
          <span>{campaignDetail.campaignStatus}</span>
        </div>
        <div>
          <span className="left">Type:</span>
          <span>{campaignDetail.campaignType}</span>
        </div>
        {/* <div>
          <span className="left">Total Cost:</span>
          <span>NGN 3000</span>
        </div> */}
        {campaignDetail.senderId && (
          <div>
            <span className="left">SenderId:</span>
            <span>{campaignDetail.senderId || 'N/A'}</span>
          </div>
        )}
      </div>
      {campaignDetail.message && (
        <div className="title">
          <span>Message:</span>
          <span>{campaignDetail.message || 'N/A'}</span>
        </div>
      )}
      <Table
        header={[
          { key: 'phoneNumber', label: 'Phone Number' },
          { key: 'cost', label: 'Cost' },
          { key: 'status', label: 'Status' },
          {
            key: 'date',
            label: 'Date',
            render: (date) => {
              return <Moment format="D MMM, YYYY" date={date} />;
            },
          },
        ]}
        data={campaignDetail?.contact || []}
      />
      {campaignDetail.campaignType === 'recurring' &&
        campaignDetail.campaignStatus !== 'completed' && (
          <div className="mt-4 col-md-4">
            <Row
              style={{
                margin: '20px 0px',
              }}
            >
              {formData.type.map((b, idx) => (
                <Col
                  key={idx}
                  md={3}
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(4,1fr)',
                    justifyContent: 'space-between',
                    height: 'fit-content',
                  }}
                >
                  <Button
                    value={b.value}
                    group={b.active ? 'primary' : 'outline'}
                    borderRadius="30px"
                    width={100}
                    onClick={() => {
                      handleType(b.value, idx);
                    }}
                  />
                </Col>
              ))}
            </Row>
            {showForm && (
              <>
                <Row>
                  <Col className="mb-3">
                    <DateInput
                      disableToolbar
                      name="endDate"
                      placeholder="Select End Date"
                      defaultValue={formData?.endDate}
                      onChange={handleDate}
                      minDate={moment()}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TimeInput name="endTime" onChange={handleDate} placeholder="Select end time" />
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  value="Update"
                  size="lg"
                  width={150}
                  onClick={scheduleCampaign}
                  loading={formData.isLoading}
                />
              </Col>
            </Row>
            {error[0] && (
              <div
                style={{
                  display: 'flex',
                  color: 'red',
                  fontSize: '12px',
                  justifyContent: 'center',
                  margin: '10px 0px',
                }}
              >
                {error[1]}
              </div>
            )}
          </div>
        )}
    </div>
  );
};

export default EditCampaign;
