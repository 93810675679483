import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from '../../components/ui';
import { fetchServiceReportDetailAction } from '../../redux/actions/serviceReportDetail';

const ServiceReportDetail = () => {
  const { id } = useParams();
  const { serviceReport, meta, fetching } = useSelector((state) => {
    return {
      serviceReport: state.serviceReportDetail.serviceReportDetail,
      meta: {
        ...state.serviceReportDetail.serviceReportDetailMeta,
        limit: 25,
        serviceReportId: id,
      },
      fetching: state.serviceReportDetail.fetchingServiceReportDetail,
    };
  });
  const dispatch = useDispatch();

  const FetchService = useCallback(
    (params = { limit: 25, serviceReportId: id }) => {
      dispatch(fetchServiceReportDetailAction(params));
    },
    [dispatch]
  );

  useEffect(() => {
    FetchService();
  }, []);

  // const getCSV = () => {
  //   const csv = [
  //     ['Group Name', 'Service Type', 'Service', 'Recipient', 'Delivered', 'Date', 'Time'],
  //     ...serviceReport.map((service) => [
  //       service.groupName,
  //       service.serviceType,
  //       service.service,
  //       service.totalReceipients,
  //       service.totalDelivered,
  //       moment(service.createdAt).format('D MMM YYYY'),
  //       moment(service.createdAt).format('h:mm:SSa'),
  //     ]),
  //   ]
  //     .map((e) => e.join(','))
  //     .join('\n');

  //   const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  //   const { URL } = window;
  //   return URL.createObjectURL(blob);
  // };

  return (
    <div className="report-wrapper">
      <div className="header">
        <h5>Service Report</h5>
      </div>
      <section>
        <Table
          header={[
            {
              key: 'createdAt',
              label: 'Date',
              render: (date) => (
                <div className="td-width-100">
                  <div>
                    <Moment format="D MMM, YYYY" date={date} />
                  </div>
                  <div>
                    <Moment format="h:mm:SSa" date={date} />
                  </div>
                </div>
              ),
            },
            { key: 'phoneNumber', label: 'Phone Number' },
            {
              key: 'reason',
              label: 'Reason',
              render: (reason) => (
                <div>
                  <span>{reason || 'N/A'}</span>
                </div>
              ),
            },
            {
              key: 'status',
              label: 'Status',
              render: (status) => (
                <div>
                  <span className={status === 'Successful' ? 'text-success' : 'text-danger'}>
                    {status}
                  </span>
                </div>
              ),
            },
          ]}
          data={serviceReport}
          filter={{
            status: [
              { value: 'Successful', label: 'Successful' },
              { value: 'Failed', label: 'Failed' },
            ],
          }}
          showFilter
          meta={meta}
          paginate
          handlePagination={FetchService}
          filterAction={FetchService}
          loading={fetching}
        />
      </section>
    </div>
  );
};

export default ServiceReportDetail;
