import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { useUpdate } from '../../utilities/hooks/useUpdate';
import { Icon, Card, Button } from '../../components/ui';
import CheckOut from '../wallet/components/CheckOut';
import { Input, PhoneInput } from '../../components/ui/inputs';
import WalletBalance from '../wallet/components/WalletBalance';
import { buyAirtimeAction } from '../../redux/actions/airtime';
import { fetchWallet } from '../../redux/actions/wallet';
import SuccessModal from '../wallet/components/PaymentSuccess';
import { ROUTES } from '../../components/router/routes/routes';

const mapStateToProps = ({ airtime }) => {
  return {
    payload: airtime.payload,
    buyingAirtime: airtime.buyingAirtime,
    success: airtime.success,
    airtimeerror: airtime.error,
  };
};

const AirtimeService = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showCheckOut, setShowCheckOut] = useState(false);
  const [price, setPrice] = useState(0);
  const [showSuccessModal, setShowSuccessModal] = useState({ show: false, headerText: null });
  const [sendError, setSendError] = useState([false, '']);
  const { buyingAirtime, success, payload } = useSelector(mapStateToProps);

  const { airtimeMeta, balance } = useSelector((state) => {
    const service = state.services.services;
    return {
      airtimeMeta: service.filter((s) => s.serviceName === 'Airtime')[0],
      balance: state.wallet.walletNfo.balance,
    };
  });
  const buyAirtime = useCallback(async (params = {}) =>
    dispatch(buyAirtimeAction(params), [dispatch])
  );
  const fetchUserWallet = useCallback(() => {
    dispatch(fetchWallet());
  }, [dispatch]);
  const [airtimePayload, setAirtimePayload] = useState({});
  const [showCampaignNfo, setShowCampaignNfo] = useState(false);
  const [recepientNfo, setrecepientNfo] = useState([{ phone_number: '', amount: '' }]);

  const handleSend = async () => {
    setShowCheckOut(false);
    await buyAirtime(airtimePayload);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const body = {
      serviceId: airtimeMeta?._id,
      currencyType: 'NGN',
      countryCode: '234',
      // companyId: user?.companyId,
    };

    body.customers = recepientNfo
      .filter((f) => f.phone_number && f.amount)
      .map((d) => ({ phoneNumber: d.phone_number, amount: d.amount }));
    if (body.customers.length > 0 && !sendError[0]) {
      setAirtimePayload(body);
      setShowCheckOut(true);
    } else {
      setSendError([true, 'please fill all fields']);
    }
  };

  useEffect(() => {
    const tempData = recepientNfo.slice(0, recepientNfo.length - 1);
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < tempData.length; index++) {
      if (!tempData[index].amount) {
        setSendError([true, 'Invalid amount']);
      }
    }
  }, [recepientNfo]);

  const handleCloseSuccessModal = () => {
    setShowSuccessModal((s) => ({ ...s, show: false }));
    setrecepientNfo([]);
    setTimeout(() => {
      setrecepientNfo([{ phone_number: '', amount: '' }]);
    }, 100);
  };

  useUpdate(() => {
    if (success) {
      setShowSuccessModal({
        show: true,
        transactionId: payload.phone_numbers.data.transactionReference,
        respData: payload?.phone_numbers?.data,
        receipt: true,
      });
      fetchUserWallet();
    }
  }, [success]);

  useUpdate(() => {
    const computedPrice = recepientNfo
      .reduce((acc, d) => acc + parseFloat(d.amount || 0, 10), 0)
      .toFixed(2);
    setPrice(computedPrice);
  }, [recepientNfo]);

  const handleChangeRept = ({ name, value, idx }) => {
    setSendError([false, '']);
    const tempData = [...recepientNfo];
    tempData[idx] = { ...tempData[idx], [name]: value };
    const info = tempData.filter((r) => !r.phone_number || !r.amount);
    setrecepientNfo(() => [...tempData]);
    if (!info.length && tempData.length < 5) {
      setrecepientNfo(() => [...tempData, { phone_number: '', amount: '' }]);
      setShowCampaignNfo(false);
    } else if (tempData.length === 5) {
      setShowCampaignNfo(true);
    }
  };

  const handleDelete = (idx) => {
    const tempData = [...recepientNfo];
    tempData.splice(idx, 1);
    setrecepientNfo(() => [...tempData]);
    if (tempData.length === 0) {
      setrecepientNfo(() => [{ phone_number: '', amount: '' }]);
    }
  };

  const showIndividualAirtime = () => {
    return recepientNfo?.map((r, idx) => (
      <>
        <Row
          key={idx}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
          className="mb-4"
        >
          <Col md={5} sm={3}>
            {/* <Input
              name="phone_number"
              placeholder="Phone Number"
              type="text"
              defaultValue={r?.phone_number || ''}
              onChange={handleChangeRept}
              index={idx}
              validate="required|multi_phone_numbers:,:5"
            /> */}
            <PhoneInput
              placeholder="Phone Number "
              name="phone_number"
              // validate="required|phone_number"
              hint=""
              index={idx}
              onChange={handleChangeRept}
            />
          </Col>
          <Col md={5} sm={3}>
            <Input
              name="amount"
              placeholder="Amount"
              type="text"
              index={idx}
              defaultValue={r?.amount || ''}
              onChange={handleChangeRept}
              currency="NGN"
            />
          </Col>
          <Col md={2} sm={1} className="pb-4">
            <Icon icon="cancel" style={{ cursor: 'pointer' }} onClick={() => handleDelete(idx)} />
          </Col>
        </Row>
      </>
    ));
  };

  return (
    <div className="airtime-wrapper">
      <div className="header">
        <h5>Buy Airtime</h5>
      </div>

      <section>
        <Row>
          <Col md={8} style={{ marginBottom: 20 }}>
            <form onSubmit={handleSubmit}>
              <Card>
                <div className="recpt-wrapper">
                  {showIndividualAirtime()}
                  <Row>
                    <Col>
                      {showCampaignNfo && (
                        <div className="exceed">
                          If you want to reach a larger audience.
                          <span
                            className="nfo"
                            onClick={() => history.push(ROUTES.CREATE_CAMPAIGN.path)}
                          >
                            Create Bulk Service?
                          </span>
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>

                {Boolean(recepientNfo.length) && (
                  <Row>
                    <Col style={{ display: 'flex', justifyContent: 'center' }}>
                      <Button
                        // type="submit"
                        onClick={handleSubmit}
                        value="Pay"
                        size="lg"
                        loading={buyingAirtime}
                      />
                    </Col>
                  </Row>
                )}
                {sendError[0] && <div className="error">{sendError[1]}</div>}
              </Card>
            </form>
          </Col>

          <Col md={4}>
            <WalletBalance price={price} />
          </Col>
        </Row>
      </section>
      <CheckOut
        show={showCheckOut}
        onClose={() => setShowCheckOut(false)}
        transactionId={showSuccessModal.transactionId}
        execute={handleSend}
        price={price}
        disableWallet={price > balance}
      />
      <SuccessModal
        show={showSuccessModal.show}
        setShowSuccessModal={setShowSuccessModal}
        transactionId={showSuccessModal.transactionId}
        respData={showSuccessModal?.respData}
        onClose={handleCloseSuccessModal}
        receipt={showSuccessModal.receipt}
        price={price}
      />
    </div>
  );
};

export default AirtimeService;
