import React, { useState, useEffect } from 'react';
import { Button as BButton, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from './Loader';

const Button = ({
  type = 'button',
  group = 'primary',
  icon,
  value,
  children,
  size,
  width,
  submit = false,
  center,
  loading,
  disabled,
  borderRadius = '6px',
  style,
  overlay = false,
  overlayName,
  ...rest
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 600);
  }, [show]);
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {overlay ? overlayName : null}
    </Tooltip>
  );
  return (
    <div className="button-wrapper my-2">
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={overlay ? renderTooltip : null}
        show={overlay ? show : false}
      >
        <div onClick={() => setShow(true)}>
          <BButton
            type={type}
            className={group}
            disabled={loading || disabled}
            size={size}
            {...rest}
            style={{ width, paddingLeft: '20px', paddingRight: '20px' }}
          >
            {icon && <FontAwesomeIcon icon={icon} style={{ fontSize: 15, marginRight: 10 }} />}
            {!loading && (value || children)}
            {loading && <Loader color="white" size="sm" />}
          </BButton>
        </div>
      </OverlayTrigger>
    </div>
  );
};

export default Button;
