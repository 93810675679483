import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  serviceReport: [],
  fetchingServiceReport: false,
  serviceReportMeta: {},
};

export const serviceReportSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    fetchServiceReportSuccessReducer: (state, { payload }) => {
      state.serviceReport = payload.data;
      state.fetchingServiceReport = false;
      state.serviceReportMeta = {
        limit: payload.limit,
        page: payload.page,
        total: payload.total,
      };
    },
    fetchingServiceReducer: (state) => {
      state.serviceReport = [];
      state.fetchingServiceReport = true;
      state.serviceReportMeta = {};
    },
    fetchingServiceReportErrorReducer: (state) => {
      state.serviceReport = [];
      state.fetchingServiceReport = true;
      state.serviceReportMeta = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  fetchServiceReportSuccessReducer,
  fetchingServiceReducer,
  fetchingServiceReportErrorReducer,
} = serviceReportSlice.actions;

export default serviceReportSlice.reducer;
