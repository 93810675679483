// import Toast from 'react-hot-toast';
import {
  APIgetWalletActivities,
  APIgetWalletMonthlyAnalytics,
  APIwalletBalance,
} from '../../api/backendRoutes';
import {
  fetchUserWallet,
  fetchingUserWallet,
  errorFetchingUserWallet,
  fetchWalletActivitySuccessReducer,
  fetchingWalletActivityReducer,
  fetchingWalletActivityErrorReducer,
  fetchMonthlyWalletAnalyticsSuccessReducer,
  fetchingMonthlyWalletAnalyticsReducer,
  fetchingMonthlyWalletAnalyticsErrorReducer,
} from '../slices/wallet';

export const fetchWallet =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchingUserWallet());
    try {
      const resp = await APIwalletBalance();

      if (resp.data.message === 'successful') {
        dispatch(fetchUserWallet(resp.data));
      } else {
        dispatch(errorFetchingUserWallet());
        throw resp?.data?.error;
      }
    } catch (error) {
      dispatch(errorFetchingUserWallet());
      // Toast.error(error?.data?.message || 'Error Fetching Wallet');
    }
  };

export const fetchWalletActivitiesAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchingWalletActivityReducer());
    try {
      const resp = await APIgetWalletActivities(params);
      if (resp.data.message === 'ok') {
        dispatch(fetchWalletActivitySuccessReducer(resp.data));
      } else {
        dispatch(fetchingWalletActivityErrorReducer());
        throw resp?.data?.error;
      }
    } catch (error) {
      dispatch(fetchingWalletActivityErrorReducer());
      // Toast.error(error?.data?.message || 'Error Fetching Wallet');
    }
  };

export const fetchMonthlyWalletAnalyticsAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchingMonthlyWalletAnalyticsReducer());
    try {
      const resp = await APIgetWalletMonthlyAnalytics(params);
      if (resp.data.message === 'ok') {
        dispatch(fetchMonthlyWalletAnalyticsSuccessReducer(resp.data));
      } else {
        dispatch(fetchingMonthlyWalletAnalyticsErrorReducer());
        throw resp?.data?.error;
      }
    } catch (error) {
      dispatch(fetchingMonthlyWalletAnalyticsErrorReducer());
      // Toast.error(error?.data?.message || 'Error Fetching Wallet');
    }
  };

// getWalletMonthlyAnalytics
