import React, { useEffect, useCallback, useContext, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, } from '../../components/ui';
import { fetchPricesAction } from '../../redux/actions/prices';
import { AppContext } from '../../utilities/context';

const Prices = () => {
  const { partnerInfo } = useContext(AppContext);
  const { prices, meta, fetching } = useSelector((state) => {
    return {
      prices: state.prices.prices,
      meta: state.prices.pricesMeta,
      fetching: state.prices.fetchingPrices,
    };
  });

  const dispatch = useDispatch();

  const FetchPrices = useCallback(
    (params = { limit: 25 }) => {
      dispatch(fetchPricesAction({ params, id: partnerInfo.id}));
    },
    [dispatch]
  );

  useEffect(() => {
    FetchPrices();
  }, []);

  

  return (
    <div className="report-wrapper">
      <div className="header">
        <h5>Prices</h5>
      </div>
      <section>
        <Table
          header={[
            {
              key: 'priceModelName',
              label: 'Name',
            },
            {
              key: 'country',
              label: 'Country',
            },
            {
              key: 'countryCode',
              label: 'Country Code',
            },
            {
              key: 'type',
              label: 'Charge Type',
            },
            {
              key: 'value',
              label: 'Value/Price',
              render: (value, row) => (
                <span>{value ? `${value}%` : `${row.currency}${row.price}`}</span>
              ),
            },
          ]}
          data={prices}
          filterAction={FetchPrices}
          filter={{
            Date: [
              { value: 'Today', label: 'Today' },
              { value: 'This Week', label: 'This Week' },
              { value: 'This Month', label: 'This Month' },
              { value: 'Last 30 Days', label: 'Last 30 Days' },
              { value: 'This Year', label: 'This Year' },
              { value: 'Period', label: 'Specific Period' },
            ],
            keyword: 'keyword',
          }}
          showFilter
          paginate
          meta={meta}
          handlePagination={FetchPrices}
          loading={fetching}
        />
      </section>
    </div>
  );
};

export default Prices;
